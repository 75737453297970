export const required = value => (value && value.length ? undefined : 'This field is required');
export const URL_REGEXP = /^[A-Za-z][A-Za-z\d.+-]*:\/*(?:\w+(?::\w+)?@)?[^\s/]+(?::\d+)?(?:\/[\w#!:.?+=&%@\-/]*)?$/;
export const EMAIL_REGEXP = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{1,63}$/i;
export const PHONE_REGEXP = /^(?:\+?1\s*(?:[.-]\s*)?)?(?:\(\s*([2-9]1[02-9]|[2-9][02-8]1|[2-9][02-8][02-9])\s*\)|([2-9]1[02-9]|[2-9][02-8]1|[2-9][02-8][02-9]))\s*(?:[.-]\s*)?([2-9]1[02-9]|[2-9][02-9]1|[2-9][02-9]{2})\s*(?:[.-]\s*)?([0-9]{4})(?:\s*(?:#|x\.?|ext\.?|extension)\s*(\d+))?/;
export const maxLength = max => value => (value && value.length > max ? `Must be ${max} characters or less` : undefined);
export const maxLength120 = maxLength(120);
export const maxLength255 = maxLength(255);
export const validatePhone = value => (value && !PHONE_REGEXP.test(value)
  ? 'Invalid phone number, must be 10 digits' : undefined);
export const validateUrl = value => (value && !URL_REGEXP.test(value)
  ? 'Invalid Url' : undefined);
export const validateEmail = value => (value && !EMAIL_REGEXP.test(value)
  ? 'Invalid email address' : undefined);
