/* eslint-disable max-len */
const questionList = [
  {
    label: 'Is AV investing on the same terms as the lead investor? If there is no lead investor, is AV investing on the same terms as all other major investors in this round?',
    exp: '',
    value: null,
  },
  {
    label: 'Is this investment a fully qualified VC investment? (i.e. no tokens, AV not investing through a 3rd-party entity)',
    exp: '',
    value: null,
  },
  {
    label: 'Do all investment team members certify that this investment poses no conflict of interest for them personally?',
    exp: '',
    value: null,
  },
  {
    label: 'Did AV a) obtain pro-rata rights, or b) ask the company for a side letter to obtain these rights?',
    exp: '',
    value: null,
  },
  {
    label: 'Does the investment team certify that AV\'s investment in this round does not explicitly alter the terms of our investment from a prior round? (e.g. pay-to-play provisions)',
    exp: '',
    value: null,
  },
  {
    label: 'Is this an investment in either a) a priced equity round, b) a capped Note, or c) a capped SAFE?',
    exp: '',
    value: null,
  },
];

export default questionList;
