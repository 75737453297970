import React, { Component, createRef } from 'react';
import { Card, CardBody } from 'reactstrap';
import PropTypes from 'prop-types';
import SearchForm from './SearchForm';
import SearchResult from './SearchResult';

class SearchCard extends Component {
  static filterSearchResults = (array, filter, searchType, arrayLength) => array.filter((item) => {
    if (filter) {
      const lowerCaseFilter = typeof filter !== 'string' ? filter : filter.toLowerCase();
      return Object.keys(item).some((key) => {
        const pureKey = JSON.stringify(item[key]).toLowerCase();
        return pureKey.includes(lowerCaseFilter);
      });
    }
    return item;
  }).slice(0, arrayLength).map((originalItem) => {
    const item = { ...originalItem };
    switch (searchType) {
      case 'Company':
        item.type = 'company';
        item.title = item.name;
        break;
      case 'Funds':
        item.type = 'fund';
        item.id = item.fund_id;
        item.title = item.name;
        break;
      case 'Investments':
        item.type = 'investment';
        item.title = item.name;
        break;
      default:
        item.type = 'investor';
        break;
    }
    return item;
  });


  constructor(props) {
    super(props);
    this.state = { filter: '', focused: null };
    this.searchInputRef = createRef();
    this.handleInternalKeyPress = this.handleInternalKeyPress.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  focusNextResultItem = (index, moveBy, refs) => {
    // eslint-disable-next-line no-nested-ternary
    const nextIndex = index + moveBy < 0 ? refs.length - 1 : index + moveBy > refs.length - 1 ? 0 : index + moveBy;
    if (refs[nextIndex]) {
      this.setState({ focused: nextIndex });
      refs[nextIndex].current.focus();
    }
  };

  handleInternalKeyPress = (e, index, refs) => {
    if (e.keyCode === 38 || e.keyCode === 40) {
      e.preventDefault();
    }
    const arrowIndexMap = {
      ArrowDown: 1,
      ArrowUp: -1,
    };

    return arrowIndexMap[e.key] && this.focusNextResultItem(index, arrowIndexMap[e.key], refs);
  };

  handleSubmit(e) {
    const { filter } = this.state;
    console.log(`Value is ${filter}`);
    e.preventDefault();
  }

  render() {
    const {
      searchType,
      isFetching,
      linkTail,
      arrayLength,
      maxHeight,
      array,
    } = this.props;
    const { filter, focused } = this.state;
    const filteredArray = this.constructor.filterSearchResults(array, filter, searchType, arrayLength);
    const refs = filteredArray.map(() => createRef());
    refs.push(this.searchInputRef);

    const results = () => (
      filteredArray.map((result, i) => (
        <SearchResult
          focused={focused === i}
          key={result.id}
          title={result.title}
          id={result.id}
          type={result.type}
          linkTail={linkTail}
          className="with-shadow"
          index={i}
          ref={refs[i]}
          handleKeyDown={e => this.handleInternalKeyPress(e, i, refs)}
          closeSearch={() => this.setState({ filter: '', focused: null })}
        />
      ))
    );
    return (
      <Card>
        <CardBody>
          <div className="card__title">
            <h5 className="bold-text">Search {searchType}</h5>
          </div>
          <SearchForm
            key="searchForm"
            isFetching={isFetching}
            handleKeyDown={e => this.handleInternalKeyPress(e, -1, refs)}
            onSearchChange={e => this.setState({ filter: e, focused: null })}
            value={filter}
            ref={this.searchInputRef}
            handleSubmit={e => this.handleSubmit(e)}
          />
          <div
            style={{
              position: 'absolute',
              top: '65%',
              left: '0px',
              zIndex: 100,
              width: '100%',
              backgroundColor: 'white',
              overflowY: 'scroll',
              borderRadius: '5px',
              maxHeight: `${maxHeight}vh`,
              paddingRight: '20px',
            }}
          >
            <div>
              {
                (this.searchInputRef.current && this.searchInputRef.current.value) && results()
              }
            </div>
          </div>
        </CardBody>
      </Card>
    );
  }
}

SearchCard.propTypes = {
  searchType: PropTypes.string.isRequired,
  isFetching: PropTypes.bool.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  array: PropTypes.array.isRequired,
  linkTail: PropTypes.string,
  arrayLength: PropTypes.number,
  maxHeight: PropTypes.number,
};

SearchCard.defaultProps = {
  arrayLength: 20,
  maxHeight: 50,
  linkTail: '',
};

export default SearchCard;
