export const RESET_QUERY_STRING = 'RESET_QUERY_STRING';
export const SET_QUERY_STRING = 'SET_QUERY_STRING';

// Resets the currently visible error message.
export const resetQueryString = () => ({
  type: RESET_QUERY_STRING,
});

export const setQueryString = queryString => ({
  type: SET_QUERY_STRING,
  queryString,
});
