import React, { useEffect, useState } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import {
  Container, Card, CardBody, Col, Row,
} from 'reactstrap';
import {
  MenuItem, TextField,
} from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import usePendingInvestmentDetail from '../shared/hooks/usePendingInvestmentDetail';
import DealInformation from '../shared/components/DealInformation';
import AvTextField from '../shared/components/AvTextField';
import { updatePendingInvestment } from '../shared/services/apiGateway';
import generateRequest from '../shared/services/generateRequest';
import PartialPageLoader from '../shared/components/Loader/PartialPageLoader';
import {
  approvalProcesses, getName, getSponsorTeam, signatureBlock, validateForm,
} from './helpers';
import AVSnackbar from '../shared/components/SnackBar';
import getStageStatus from '../shared/utils/checkUserPermissions';
import StageStatusMessage from '../shared/components/StageStatusMessage';
import CloseOnConfirmModal from '../shared/components/CloseOnConfirmModal';
import getSearchParams from '../shared/utils/getSearchParams';
import truncateNDecimals from '../shared/utils/truncateNDecimals';

function EntityNameSignatureBlock() {
  const { id } = useParams();
  const closeOnConfirm = getSearchParams('closeoncompletion');
  const history = useHistory();

  const initialErrorState = {
    approvalProcess: '',
    finalDealScore: '',
    dateDealApproved: '',
    investmentEntityName: '',
  };

  const [approvalProcess, setApprovalProcess] = useState('');
  const [finalDealScore, setFinalDealScore] = useState('');
  const [dateDealApproved, setDateDealApproved] = useState(null);
  const [investmentEntityName, setInvestmentEntityName] = useState('');
  const [errors, setErrors] = useState(initialErrorState);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarErrorOpen, setSnackbarErrorOpen] = useState(false);
  const [stageStatus, setStageStatus] = useState({
    isEditableByRole: false,
  });
  const [completionModalOpen, setCompletionModalOpen] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isPostingData, setIsPostingData] = useState(false);
  const [isSaving, setIsSaving] = useState(false);

  const dealInfo = usePendingInvestmentDetail(id, (deal) => {
    setStageStatus({
      ...getStageStatus(deal),
    });
  });

  useEffect(() => {
    if (dealInfo) {
      setApprovalProcess(dealInfo.approvalProcess || '');
      setFinalDealScore(dealInfo.finalDealScore || '');
      setDateDealApproved(dealInfo.dateDealApproved || '');
      setInvestmentEntityName(dealInfo.investmentEntityName || '');
    }
  }, [dealInfo]);

  /**
   * Submit the data to the backend.
   * @param {boolean} confirmStepCompletion - Confirm the completion of the current step.
   * Will only set the current step as completed in the backend if confirm is true.
   */
  const createAndPostData = async (confirmStepCompletion) => {
    // Only validate required fields if user is trying to complete this step
    if (confirmStepCompletion && (JSON.stringify(validateForm(
      initialErrorState,
      setErrors,
      {
        approvalProcess, finalDealScore, dateDealApproved, investmentEntityName,
      },
    )) !== JSON.stringify(initialErrorState))) {
      setIsPostingData(false);
      return;
    }

    setIsSubmitting(true);
    const stagesCompleted = dealInfo.stagesCompleted || {};
    // Only set current step as completed if user is trying to complete this step
    if (confirmStepCompletion) {
      stagesCompleted.entityNameSignatureBlock = new Date().toISOString();
    }

    const updatedDealInfo = {
      ...dealInfo,
      stagesCompleted,
      investmentEntityName,
      signatureBlock,
      approvalProcess,
      finalDealScore,
      dateDealApproved,
    };

    await updatePendingInvestment(generateRequest(updatedDealInfo));
    if (confirmStepCompletion && !closeOnConfirm) {
      history.push(`/deal-stages/${id}`);
    } else if (confirmStepCompletion && closeOnConfirm) {
      setCompletionModalOpen(true);
    }
    setIsSubmitting(false);
    setIsPostingData(false);
    setIsSaving(false);
  };

  return (
    <div>
      <Helmet>
        <title>Signature Block</title>
      </Helmet>

      <Container className="med-width-form">
        <Card>
          <CardBody>
            {!dealInfo && (PartialPageLoader())}
            {dealInfo && (
              <>
                <AVSnackbar open={snackbarOpen} setClosed={() => (setSnackbarOpen(false))} />
                <AVSnackbar open={snackbarErrorOpen} status="error" setClosed={() => (setSnackbarErrorOpen(false))} />
                <Row>

                  <Col>
                    <div className="mb-3">
                      <h3>Signature Block</h3>
                    </div>
                    <DealInformation
                      dealInfo={{ dealName: getName(dealInfo), sponsorTeam: getSponsorTeam(dealInfo) }}
                      setDealInfo={() => false}
                      hiddenFields={{ iTeamMemberName: true }}
                    />
                    <TextField
                      sx={{ my: 3 }}
                      fullWidth
                      select
                      label="Approval Process"
                      required
                      value={approvalProcess}
                      onChange={(e) => { setApprovalProcess(e.target.value); }}
                      variant="standard"
                      error={!!errors.approvalProcess}
                      helperText={errors.approvalProcess}
                    >
                      {approvalProcesses.map((reason) => <MenuItem value={reason} key={reason}>{reason}</MenuItem>)}
                    </TextField>
                    <Col sm={6} lg={6} className="float-left pl-0">
                      <DatePicker
                        label="Date Deal Approved *"
                        name="dateDealApproved"
                        emptyLabel=""
                        required
                        value={dateDealApproved}
                        onChange={(date) => setDateDealApproved(date)}
                        renderInput={(params) => (
                          <AvTextField
                            {...params}
                            sx={{ width: '100%' }}
                            error={!!errors.dateDealApproved}
                            helperText={errors.dateDealApproved}
                          />
                        )}
                      />
                    </Col>
                    <Col sm={6} lg={6} className="float-right pr-0">
                      <AvTextField
                        label="Final Deal Score"
                        type="number"
                        name="finalDealScore"
                        required
                        value={finalDealScore}
                        inputProps={{
                          type: 'number',
                        }}
                        onChange={(e) => {
                          if (!e.target.value) {
                            setFinalDealScore('');
                            return;
                          }
                          let score = Number(truncateNDecimals(e.target.value, 1));
                          // Constrain the score between 0 and 120
                          score = Math.min(120, Math.max(0, score));
                          setFinalDealScore(score);
                        }}
                        onBlur={(e) => {
                          e.target.value = finalDealScore;
                        }}
                        variant="standard"
                        error={!!errors.finalDealScore}
                        helperText={errors.finalDealScore}
                      />
                    </Col>
                  </Col>
                </Row>
                <Row className="mt-5">
                  <Col>
                    <h4>
                      Signature Block
                    </h4>
                    <p>{ signatureBlock.name }</p>
                    <p>{ signatureBlock.title }</p>
                    <p>{ signatureBlock.company }</p>
                    <p> {`${signatureBlock.city}, ${signatureBlock.state} ${signatureBlock.zip}`}</p>
                    <p>{ signatureBlock.email }</p>
                  </Col>
                  <Col>
                    {/* <h4>Investment Entity Name</h4> */}
                    <AvTextField
                      label="Investment Entity"
                      type="text"
                      name="investmentEntityName"
                      required
                      value={investmentEntityName}
                      inputProps={{
                        type: 'text',
                      }}
                      onChange={(e) => {
                        setInvestmentEntityName(e.target.value);
                      }}
                      error={!!errors.investmentEntityName}
                      variant="standard"
                      helperText={errors.helperText || 'Only edit this field if the prepopulated name is incorrect'}
                    />
                    {/* <p>{investmentEntityName}</p> */}
                  </Col>
                </Row>
                {
                  (stageStatus.isEditableByRole && !stageStatus.isCompleted) && (
                    <Row>
                      <Col className="mt-5 d-flex justify-content-between w-100">
                        <LoadingButton
                          className="mr-5"
                          variant="outlined"
                          color="secondary"
                          disabled={isSubmitting}
                          loading={isSaving}
                          onClick={() => {
                            setIsSaving(true);
                            setSnackbarErrorOpen(true);
                            createAndPostData(false);
                          }}
                        >Save
                        </LoadingButton>
                        <LoadingButton
                          className="ml-5"
                          variant="contained"
                          color="secondary"
                          disabled={isSubmitting}
                          loading={isPostingData}
                          onClick={() => {
                            setIsPostingData(true);
                            setSnackbarOpen(true);
                            createAndPostData(true);
                          }}
                        >Submit
                        </LoadingButton>
                      </Col>
                    </Row>
                  )
                }
                <StageStatusMessage stageStatus={stageStatus} />
              </>
            )}
          </CardBody>
        </Card>

        <CloseOnConfirmModal modalOpen={completionModalOpen} />
      </Container>
    </div>
  );
}

export default EntityNameSignatureBlock;
