import React, { useEffect, useState } from 'react';
import { Col, Row } from 'reactstrap';
import {
  Checkbox, TextField, FormControlLabel, FormGroup,
} from '@mui/material';
import { ClubResources } from '@alumni-ventures-group/react-shared/lib/services';
import PropTypes from 'prop-types';
import { isWhitespaceOnly } from '../shared/utils/validators';

const { greyColor } = ClubResources;

export function QuestionItem({ question, setAnswer }) {
  const [clickedButton, setClickedButton] = useState(question.value);
  const display = (clickedButton === false) ? '' : 'd-none';

  return (
    <>
      <Row className="mt-md-3">
        <Col className="col-9">
          <p style={{ marginTop: '10px', fontSize: '16px', color: 'rgb(81 84 97)' }}>{question.label}</p>
        </Col>
        <Col className="col-3">
          <FormGroup className="float-right" row>
            <FormControlLabel
              style={{ color: greyColor }}
              control={(
                <Checkbox
                  inputProps={{ 'aria-label': 'controlled' }}
                  checked={clickedButton === true}
                  onClick={() => { setClickedButton(true); setAnswer(question, 'value', true); }}
                />
          )}
              label="Yes"
            />
            <FormControlLabel
              style={{ color: greyColor }}
              control={(
                <Checkbox
                  inputProps={{ 'aria-label': 'controlled' }}
                  checked={clickedButton === false}
                  onClick={() => { setClickedButton(false); setAnswer(question, 'value', false); }}
                />
          )}
              label="No"
            />
          </FormGroup>
        </Col>

      </Row>
      <Row>
        <Col>
          <TextField
            fullWidth
            value={question.exp}
            onChange={(event) => {
              const text = event.target.value;
              if (!isWhitespaceOnly(text) && text.length <= 200) {
                setAnswer(question, 'exp', text);
              }
            }}
            label="Please provide an explanation"
            variant="standard"
            className={display}
            visible=""
            multiline
            required
            style={{
              marginBottom: '20px',
              marginTop: '10px',
            }}
          />
        </Col>
      </Row>
    </>
  );
}

export function Questions({ questionList, setClosingDocsReviewDraft, setAllAnswered }) {
  const [questionMap, setQuestionMap] = useState(questionList);
  useEffect(() => {
    const unansweredQuestions = questionMap.filter((question) => !question.value && !question.exp);
    if (unansweredQuestions.length === 0) {
      setAllAnswered(true);
    } else {
      setAllAnswered(false);
    }
  }, [questionMap]);

  const setAnswer = (question, key, value) => {
    const newQuestion = { ...question };
    newQuestion[key] = value;
    const newQuestionMap = questionMap.map((elem) => {
      if (elem.label === question.label) {
        return newQuestion;
      }
      return elem;
    });
    setQuestionMap(newQuestionMap);
    setClosingDocsReviewDraft(newQuestionMap);
  };

  return questionMap.map((element) => (
    <QuestionItem key={element.label} question={element} answer={element.value} setAnswer={setAnswer} />
  ));
}

QuestionItem.propTypes = {
  question: PropTypes.shape({
    label: PropTypes.string.isRequired,
    exp: PropTypes.string.isRequired,
    value: PropTypes.bool,
  }).isRequired,
  setAnswer: PropTypes.func.isRequired,
};

Questions.propTypes = {
  questionList: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      exp: PropTypes.string.isRequired,
      value: PropTypes.bool,
    }),
  ).isRequired,
  setAllAnswered: PropTypes.func.isRequired,
};
