import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { Helmet } from 'react-helmet';
import {
  CardBody,
  Container,
  Card,
  Row,
  Col,
} from 'reactstrap';
import Button from '@mui/material/Button';
import LoadingButton from '@mui/lab/LoadingButton';
import { styled } from '@mui/material/styles';
import { useHistory, Link } from 'react-router-dom';
import AvDataGrid from '../shared/components/AvDataGrid';
import sortByDate from '../shared/utils/sortAsDate';
import useDDReport from './useDDReport';
import usePendingInvestmentDetail from '../shared/hooks/usePendingInvestmentDetail';
import { getDDReportsByCompanyName, updatePendingInvestment } from '../shared/services/apiGateway';
import generateRequest from '../shared/services/generateRequest';

const customTableStyles = {
  '& .MuiDataGrid-columnHeaderCheckbox .MuiDataGrid-columnHeaderTitleContainer': {
    display: 'none',
  },
};
const createDueDiligenceTableDefinition = () => [
  {
    field: 'dealName',
    headerName: 'Name',
    description: 'The Name of Due Diligence Report',
    flex: 1,
    minWidth: 400,
  },
  {
    field: 'ddDateCreated',
    headerName: 'Date',
    description: 'Date Created',
    flex: 1,
    minWidth: 300,
    sortComparator: sortByDate,
  }];

const ButtonContainer = styled('div')`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

function DDReportSelector({ match }) {
  const pendingInvestmentId = match.params.id;

  const history = useHistory();

  const ddReportSelectorTableDef = createDueDiligenceTableDefinition();
  const [selectionModel, setSelectionModel] = useState([]);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [rows, setRows] = useState(null);

  const pendingInvestmentDetail = usePendingInvestmentDetail(pendingInvestmentId, async (response) => {
    if (!response.verticaObj) {
      return history.push(`/pending-investment/${response.id}/name-verification`);
    }
    const ddReports = await getDDReportsByCompanyName(response.companyName);
    const formattedDDReports = [];
    for (const ddReport of ddReports) {
      const model = { ...ddReport };
      model.id = ddReport.ddId;
      formattedDDReports.push(model);
    }
    if (formattedDDReports.length === 0 && !(new URLSearchParams(window.location.search).has('postEdit'))) {
      response.dueDiligence = {};
      await updatePendingInvestment(generateRequest(response));
      return history.push(`/pending-investment/${response.id}`);
    }
    setRows(formattedDDReports);
    if (response.dueDiligence && response.dueDiligence.ddId) {
      setSelectionModel(response.dueDiligence.ddId);
    }
  });

  return (
    <>
      <Helmet>
        <title>Due Diligence Report Selection Page</title>
      </Helmet>
      <Container className="dashboard" style={{ maxWidth: '100%' }}>
        <Card>
          <CardBody>
            <Row>
              <Col>
                {pendingInvestmentDetail && rows && (
                  <>
                    <h2>{pendingInvestmentDetail.companyName}</h2>
                    <p style={{ fontSize: '16px' }}>
                      Please select the primary Due Diligence Report associated to this investment.
                    </p>
                  </>
                )}

                <div style={{ marginTop: '20px' }}>
                  <AvDataGrid
                    pageSize={5}
                    rows={rows}
                    columns={ddReportSelectorTableDef}
                    loading={!pendingInvestmentDetail || !rows}
                    checkboxSelection
                    disableMultipleSelection
                    onSelectionModelChange={(newSelectionModel) => {
                      if (newSelectionModel.length > 0) {
                        const selectedSelectionModel = newSelectionModel[newSelectionModel.length - 1];
                        setSelectionModel(selectedSelectionModel);
                      }
                    }}
                    selectionModel={selectionModel}
                    sx={customTableStyles}
                    initialState={{
                      sorting: {
                        sortModel: [{ field: 'ddDateCreated', sort: 'desc' }],
                      },
                    }}
                  />
                </div>
                {pendingInvestmentDetail && rows && (
                <div style={{ marginTop: '20px' }}>
                  <ButtonContainer>
                    <Button
                      variant="outlined"
                      color="secondary"
                      onClick={() => {
                        history.goBack();
                      }}
                    >
                      Back
                    </Button>
                    <LoadingButton
                      variant="contained"
                      color="secondary"
                      disabled={!selectionModel || selectionModel.length <= 0}
                      loading={isSubmitting}
                      onClick={async () => {
                        const ddReport = rows.filter((el) => el.id === selectionModel);
                        // add the dd report to the pending investment detail
                        pendingInvestmentDetail.dueDiligence = { ...ddReport[0] };
                        setIsSubmitting(true);
                        await updatePendingInvestment(generateRequest(pendingInvestmentDetail));
                        // navigate back to pending investments
                        history.push(`/pending-investment/${pendingInvestmentId}`);
                      }}
                    >
                      Continue
                    </LoadingButton>
                  </ButtonContainer>
                </div>
                )}
              </Col>
            </Row>
          </CardBody>
        </Card>
      </Container>
    </>
  );
}

DDReportSelector.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.node,
    }).isRequired,
  }).isRequired,
};

export default DDReportSelector;
