import { useState, useEffect } from 'react';
import { getDDReportsByCompanyName } from '../shared/services/apiGateway';

function useDDReport(companyName, onLoad) {
  const [DDReport, setDDReport] = useState([]);

  useEffect(() => {
    const abortController = new AbortController();
    let ignore = false;

    async function fetchData() {
      const response = await getDDReportsByCompanyName(companyName);
      setDDReport(response);
      if (onLoad) {
        onLoad(false);
      }
    }

    fetchData();

    return () => {
      ignore = true;
      abortController.abort();
    };
  }, []);

  return DDReport;
}

export default useDDReport;
