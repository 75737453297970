import { useState, useEffect } from 'react';
import { getPublishedDueDiligenceReport } from '../services/apiGateway';

function useDueDiligenceReportFile(ddReportId, onLoad) {
  const [ddReportS3File, setDdReportS3File] = useState(null);

  useEffect(() => {
    const abortController = new AbortController();
    let ignore = false;

    async function fetchData() {
      const response = await getPublishedDueDiligenceReport(ddReportId);
      setDdReportS3File(response);
      if (onLoad) {
        onLoad(response);
      }
    }

    fetchData();

    return () => {
      ignore = true;
      abortController.abort();
    };
  }, []);

  return ddReportS3File;
}

export default useDueDiligenceReportFile;
