import StringHelper from './StringHelper';

const FormatTableValues = (id, value) => {
  switch (id) {
    case 'totalInvestment':
      return StringHelper.formatMoney(value);
    case 'investableAssets':
      return StringHelper.formatMoney(value);
    // case 'currentValuation':
    //   return StringHelper.formatMoney(value);
    case 'invAmount':
      return StringHelper.formatMoney(value);
    case 'currentPrice':
      return StringHelper.formatMoney(value);
    case 'purchasePrice':
      return StringHelper.formatMoney(value);
    case 'numShares':
      return StringHelper.formatMoney(value, 5, '.', ',', '');
    case 'investedAmount':
      return StringHelper.formatMoney(value);
    case 'interestRate':
      return StringHelper.formatPercent(value);
    case 'percentInvested':
      return StringHelper.formatPercent(value);
    case 'avgAnnualReturn':
      return StringHelper.formatPercent(value);
    case 'ownership':
      return StringHelper.formatPercent(value);
    case 'percentReturn':
      return StringHelper.formatPercent(value);
    case 'realizedGainLoss':
      return StringHelper.formatPercent(value);
    default:
      return value;
  }
};

export default FormatTableValues;
