/* eslint-disable react/forbid-prop-types */
import React, { useState, useEffect } from 'react';
import { useOktaAuth } from '@okta/okta-react';
import PropTypes from 'prop-types';
import DownIcon from 'mdi-react/ChevronDownIcon';
import { Collapse } from 'reactstrap';
import MD5 from 'md5.js';
import { logoutUser } from '@alumni-ventures-group/av-okta-auth';
import ava from 'url:./../../images/ava.png';
import TopbarMenuLink from './TopbarMenuLink';
import RoleSelector from '../../shared/components/RoleSelector';

function TopbarProfile({ user }) {
  const [collapse, setOpen] = useState(false);
  const [username, setUsername] = useState('');
  const [avatar, setAvatar] = useState(ava);
  const { authState } = useOktaAuth();

  const setUser = (updatedUser) => {
    const gravatarHash = updatedUser.email
      ? new MD5().update(updatedUser.email.trim().toLowerCase()).digest('hex') : null;
    const updatedAvatar = updatedUser.avatar ? updatedUser.avatar
      : `https://www.gravatar.com/avatar/${gravatarHash}?s=100&d=${encodeURI(
        'https://la-company-logos.s3.amazonaws.com/avg-avatar.jpg',
      )}`;
    setUsername((
      `${updatedUser.first_name ? updatedUser.first_name
        : updatedUser.firstName} ${updatedUser.last_name ? updatedUser.last_name : updatedUser.lastName}`
    ).toUpperCase());
    setAvatar(updatedAvatar);
  };

  useEffect(() => {
    if (user.email) {
      setUser(user);
    }
  }, [user]);

  if (!authState) {
    return null;
  }

  return (
    <div className="topbar__profile">
      <button className="topbar__avatar" type="button" onClick={() => { setOpen(!collapse); }}>
        <img className="topbar__avatar-img" src={avatar} alt="avatar" />
        <p className="topbar__avatar-name">{username}</p>
        <DownIcon className="topbar__icon" />
      </button>
      {
        collapse
        && (
          <Collapse isOpen={collapse} className="topbar__menu-wrap">
            <div className="topbar__menu">
              <TopbarMenuLink
                title="Logout"
                icon="Logout"
                onClick={() => {
                  logoutUser();
                }}
              />
              <RoleSelector topAdornment={
                <div className="topbar__menu-divider" />
              }
              />
            </div>
          </Collapse>
        )
      }
    </div>
  );
}

TopbarProfile.propTypes = {
  user: PropTypes.any.isRequired,
};

export default TopbarProfile;
