export default function createPendingInvestmentTotalsTableDefinition() {
  return [{
    field: 'avInvestmentATotal',
    headerName: 'Total A Amount',
    description: 'Total A Amount',
    editable: false,
    flex: 1,
    minWidth: 300,
    sortable: false,
    filterable: false,
  },
  {
    field: 'avInvestmentQTotal',
    headerName: 'Total Q Amount',
    description: 'Total Q Amount',
    editable: false,
    flex: 1,
    minWidth: 300,
    sortable: false,
    filterable: false,
  },
  {
    field: 'avInvestmentTotal',
    headerName: 'Total AV Check',
    description: 'Grand Total',
    editable: false,
    flex: 1,
    minWidth: 300,
    sortable: false,
    filterable: false,
  }];
}

export function createAQSplitsTotalsTableDefWithCashAdvances(user) {
  const tableDef = createPendingInvestmentTotalsTableDefinition(user);
  tableDef.push({
    field: 'avCashAdvanceTotal',
    headerName: 'Total Required Cash Advance',
    description: 'The Total Cash Advance Required',
    sortable: true,
    flex: 1,
    minWidth: 200,
    editable: true,
  });
  return tableDef;
}
