import * as React from 'react';
import { Col, Row } from 'reactstrap';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { useEffect } from 'react';
import { NumericFormat } from 'react-number-format';
import InputAdornment from '@mui/material/InputAdornment';
import { spacing } from '../themes/appTheme';
import AvTextField from './AvTextField';
import roundToPrecision from '../utils/roundToPrecision';


const validate = (wireInfo) => {
  const newErrors = {};
  if (!wireInfo.wireAmount || wireInfo.wireAmount < 1) {
    newErrors.wireAmount = 'Exact wire amount must be at least $1';
  }

  return newErrors;
};

const isValidWireAmountInput = (value) => value !== '' && value >= 0 && !Number.isNaN(value);
const formatWireAmount = (wireAmount) => Number(roundToPrecision(Math.abs(wireAmount), 2));
const showWireTargetDate = false; // we are hiding this from the UI per AVG-6524 for now.

export default function WireAmountTargetDate({
  wireInfo, setWireInfo, validationObject = {}, errors = {},
}) {
  const {
    wireAmount, wireTargetDate,
  } = wireInfo;

  useEffect(() => {
    // eslint-disable-next-line no-param-reassign
    validationObject.current = validate;
  }, []);

  return (
    <>
      <h5
        style={{ marginTop: spacing(5) }}
      >
        Wire Amount
      </h5>
      <Row>
        <Col lg={12}>
          <NumericFormat
            value={wireAmount}
            name="wireAmount"
            customInput={AvTextField}
            type="text"
            thousandsGroupStyle="thousand"
            thousandSeparator=","
            variant="standard"
            label="Exact Wire Amount"
            InputProps={{
              startAdornment: <InputAdornment position="start">$</InputAdornment>,
            }}
            onBlur={(e) => {
              const { name, value } = e.target;
              const rawValue = value.replace(/,/g, '');
              setWireInfo((data) => ({ ...data, [name]: isValidWireAmountInput(rawValue) ? formatWireAmount(rawValue) : '' }));
            }}
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                e.target.select();
              }
            }}
            error={!!errors.wireAmount}
            helperText={errors.wireAmount}
          />
        </Col>
        <Col lg={6} hidden={!showWireTargetDate}>
          <DatePicker
            required
            label="Wire Target Date"
            name="wireTargetDate"
            disablePast
            value={wireTargetDate}
            onChange={(date) => setWireInfo({ ...wireInfo, wireTargetDate: date })}
            renderInput={(params) => (
              <AvTextField
                required
                {...params}
                error={!!errors.timeZone}
                helperText={errors.timeZone}
              />
            )}

          />
        </Col>
      </Row>
    </>
  );
}
