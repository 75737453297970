/* eslint-disable max-len */
// defined questions and their default values
const questionList = [
  {
    label: 'Will AV be purchasing digital assets (tokens, NFT’s, etc) as part of this investment, or entering into an agreement to acquire digital assets in the future (SAFT, warrants, etc)?',
    value: null,
  },
  {
    label: 'Is AV required to invest in this deal via digital assets (in full or in part)?',
    value: null,
  },
  {
    label: 'Is AV required to invest in this deal via our offshore entity?',
    value: null,
  },
  {
    label: 'Please explain the details of how AV must invest in this deal (digital assets vs. cash). Please indicate the exact digital asset (i.e. USDC) that will be used to make this investment.',
    value: '',
  },
  {
    label: 'Please enter the portco’s wallet address:',
    value: '',
  },
];

export default questionList;
