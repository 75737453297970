import React, { useState, useEffect } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import LinearProgress from '@material-ui/core/LinearProgress';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { startDownloadSA, terminateDownloadSA, downloadFromDocusign } from '../../services/bifService';

const useStyles = makeStyles({
  summary: {
    margin: '0px',
    padding: '5px',
    paddingLeft: '0px',
    marginTop: '10px',
  },
  summaryParagraph: {
    margin: '0px',
  },
  progressLine: {
    marginTop: '10px',
  },
  scrollView: {
    height: '250px',
    overflow: 'auto',
  },
});

const createStatusMessage = (params) => {
  const { success, message } = params;
  let statusColor = 'green';
  let status = 'Success: ';
  if (success === 'false') {
    statusColor = 'red';
    status = 'Error: ';
  }
  const downLoadStatus = {};
  downLoadStatus.statusStyle = { color: statusColor };
  downLoadStatus.status = status;
  downLoadStatus.message = message;
  return downLoadStatus;
};

const SaDownloadDialog = ({
  fundId = null, open, setOpen, fundName,
}) => {
  const [completed, setCompleted] = useState(0);
  const [downloadStarted, setDownloadStarted] = useState(false);
  const [processed, setProcessed] = useState(0);
  const [closingDocsIds, setClosingDocsIds] = useState([]);
  const [messageBoard, setMessageBoard] = useState([]);

  const closeAndCleanUp = () => {
    setOpen(false);
    setClosingDocsIds([]);
    setDownloadStarted(false);
    setProcessed(0);
    setCompleted(0);
    setMessageBoard([]);
  };

  const updateProgress = () => {
    const percentProcessed = parseInt((processed + 1) * 100 / closingDocsIds.length, 10);
    setCompleted(percentProcessed);
  };

  async function processDocusignDownload() {
    if (processed === closingDocsIds.length) {
      terminateDownloadSA();
      setDownloadStarted(false);
      return;
    }
    const response = await downloadFromDocusign(closingDocsIds[processed]);
    const downLoadStatus = createStatusMessage(response);
    setMessageBoard([...messageBoard, downLoadStatus]);
    setProcessed(processed + 1);
    updateProgress();
  }

  useEffect(() => {
    if (downloadStarted && (completed !== 100)) {
      processDocusignDownload();
    }
  }, [processed, downloadStarted]);

  async function startDownload() {
    const response = await startDownloadSA(fundId);
    const { closingDocs } = response;
    setClosingDocsIds(closingDocs);
    setDownloadStarted(true);
  }

  const classes = useStyles();

  return (
    <div>
      <Dialog
        open={open}
        onClose={closeAndCleanUp}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle id="responsive-dialog-title">Download Subscription Agreement</DialogTitle>
        { completed > 0
            && (
            <DialogContent>
              <DialogContentText>
                {fundName}: Downloading latest Subscription Agreements...
              </DialogContentText>
              <LinearProgress className={classes.progressLine} variant="determinate" value={completed} />
              <h4 className={classes.summary}>Summary:</h4>
              <div className={classes.scrollView}>
                {
                messageBoard.map(m => (
                  <p key={m.message} className={classes.summaryParagraph}>
                    <small><b style={m.statusStyle}>{m.status}</b> {m.message}</small>
                  </p>
                ))
              }
              </div>
            </DialogContent>
            )}
        <DialogActions>
          { (completed === 0 && !downloadStarted)
            && (
            <Button autoFocus onClick={startDownload} variant="contained" color="primary">
              Start Download
            </Button>
            )}
          <Button onClick={closeAndCleanUp} variant="contained">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

SaDownloadDialog.propTypes = {
  setOpen: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  fundId: PropTypes.string.isRequired,
  fundName: PropTypes.string.isRequired,
};

export default SaDownloadDialog;
