export const REQUEST_ALL_COMPANIES = 'REQUEST_ALL_COMPANIES';
export const REQUEST_ALL_COMPANIES_CLEAR_ERROR = 'REQUEST_ALL_COMPANIES_CLEAR_ERROR';
export const RECEIVE_ALL_COMPANIES = 'RECEIVE_ALL_COMPANIES';
export const RECEIVE_ALL_COMPANIES_ERROR = 'RECEIVE_ALL_COMPANIES_ERROR';

const defaultError = 'Unable to get company items';

export function requestAllCompanies() {
  return {
    type: REQUEST_ALL_COMPANIES,
  };
}

export function requestAllCompaniesError(error) {
  return {
    type: RECEIVE_ALL_COMPANIES_ERROR,
    error,
  };
}

export function clearCompaniesErrors() {
  return {
    type: REQUEST_ALL_COMPANIES_CLEAR_ERROR,
    error: null,
  };
}

export function receiveAllCompanies(json) {
  return {
    type: RECEIVE_ALL_COMPANIES,
    companies: json,
    receivedAt: Date.now(),
  };
}

function fetchAllCompanies(auth) {
  return (dispatch) => {
    dispatch(requestAllCompanies());

    const { api } = auth;
    const path = 'api/v1.0/external/companies/list';

    return api.get(path)
      .then((data) => {
        if (data && data.items) {
          dispatch(receiveAllCompanies(data.items));
        } else {
          dispatch(requestAllCompaniesError(data.error ? data.error : defaultError));
        }
      });
  };
}

function shouldFetchAllCompanies(state) {
  const { companies } = state;
  if (!companies) {
    return true;
  }
  if (companies.isFetching) {
    return false;
  }
  return companies;
}

export function fetchAllCompaniesIfNeeded() {
  // eslint-disable-next-line consistent-return
  return (dispatch, getState) => {
    const { auth } = getState();
    if (shouldFetchAllCompanies(getState())) {
      return dispatch(fetchAllCompanies(auth));
    }
  };
}
