export default (sortColumn, sortDirection) => (a, b) => {
  const keyArray = sortColumn.split('.');
  const aNew = keyArray.length === 1
    ? a[keyArray[0]]
    : keyArray.reduce((o, i) => o[i], a);
  const bNew = keyArray.length === 1
    ? b[keyArray[0]]
    : keyArray.reduce((o, i) => o[i], b);

  if (typeof aNew === 'number' && typeof bNew === 'number') {
    if (sortDirection === 'ASC') {
      return aNew - bNew;
    }
    return bNew - aNew;
  }
  const aVal = typeof aNew === 'string' ? aNew.toLowerCase() : aNew;
  const bVal = typeof bNew === 'string' ? bNew.toLowerCase() : bNew;
  if (sortDirection === 'ASC') {
    return aVal > bVal ? 1 : -1;
  }
  return aVal < bVal ? 1 : -1;
};
