import React from 'react';
import Button from '@mui/material/Button';
import formatDateString from '../shared/utils/formatDateString';
import sortByDate from '../shared/utils/sortAsDate';

export default function createPostWireTableDefinition(history) {
  return [
    {
      field: '_',
      headerName: '',
      sortable: false,
      filterable: false,
      renderCell: (params) => (
        <Button
          variant="outlined"
          color="secondary"
          onClick={(e) => {
            e.stopPropagation();
            history.push(`/post-wire/${params.row.id}`);
          }}
        >View
        </Button>
      ),
      maxWidth: 100,
      minWidth: 100,
      flex: 1,
    },
    {
      field: 'companyName',
      headerName: 'Company Name',
      description: 'The name of the Company',
      editable: false,
      flex: 1,
      minWidth: 300,
    },
    {
      field: 'wireDate',
      headerName: 'Wire Date',
      description: 'The Wire date of the Deal',
      sortable: true,
      flex: 1,
      minWidth: 200,
      valueGetter: (params) => `${formatDateString(params.row.wireDate)}`,
      sortComparator: sortByDate,
    },
  ];
}
