import stateMap from './StateMap';

const isVentureCoArray = [
  { label: 'No', value: 0 },
  { label: 'Yes', value: 1 },
];

export default class Company {
  constructor(rawCompany) {
    this.id = {
      label: 'ID', type: null, readOnly: true, value: rawCompany.id,
    };
    this.name = {
      label: 'Name', type: 'text', readOnly: false, value: rawCompany.name,
    };
    this.street = {
      label: 'Street', type: 'text', readOnly: false, value: rawCompany.street,
    };
    this.city = {
      label: 'City', type: 'text', readOnly: false, value: rawCompany.city,
    };
    this.state = {
      label: 'State',
      type: 'select',
      readOnly: false,
      display: stateMap.map(
        state => state.value === rawCompany.state && state.label,
      ),
      value: rawCompany.state,
      options: stateMap,
    };
    this.website = {
      label: 'Website', type: 'text', readOnly: false, value: rawCompany.website,
    };
    this.email = {
      label: 'Email Address',
      type: 'text',
      readOnly: false,
      value: rawCompany.email,
    };
    this.slug = {
      label: 'Slug',
      type: 'text',
      readOnly: false,
      value: rawCompany.slug,
    };
    this.companyCode = {
      label: 'Company Code',
      type: 'text',
      readOnly: false,
      value: rawCompany.companyCode,
    };
    this.isVentureCompany = {
      label: 'Is Venture Company',
      type: 'select',
      readOnly: false,
      value: rawCompany.isVentureCompany,
      options: isVentureCoArray,
    };
    this.logoFilePath = {
      label: 'Logo Link',
      type: 'fileinput',
      readOnly: false,
    };
    this.imageUri = {
      type: null,
      readOnly: true,
      value: rawCompany.logoFilePath,
    };
    this.color = {
      label: 'Color', type: 'text', readOnly: false, value: rawCompany.color,
    };
  }
}
