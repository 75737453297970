/* eslint-disable no-param-reassign */
import React, { useState, useEffect, useCallback } from 'react';
import { Helmet } from 'react-helmet';
import {
  Container, Row, Card, CardBody, Col,
} from 'reactstrap';
import { v4 } from 'uuid';
import { useGridApiRef } from '@mui/x-data-grid-pro';
import {
  Button, Box, Grid, Stack,
} from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { useHistory, useParams } from 'react-router-dom';
import InputAdornment from '@mui/material/InputAdornment';
import { User } from '@alumni-ventures-group/av-okta-auth';
import useDealDetail from '../shared/hooks/usePendingInvestmentDetail';
import createDealsTableDefinition from './DealDetailTableDefinition';
import createDealTotalsTableDefinition from './DealTotalsTableDefinition';
import AvDataGrid from '../shared/components/AvDataGrid';
import ReadOnlyTextField from '../shared/components/ReadOnlyTextField';
import exportGridValues from '../shared/components/AvDataGrid/exportGridValues';
import HorizRecLoader from '../shared/components/Loader/HorizRecLoader';
import getNumberValue from '../shared/utils/getNumberValue';
import formatCurrencyAsUsd from '../shared/utils/formatCurrencyAsUsd';
import roundToPrecision from '../shared/utils/roundToPrecision';
import { updatePendingInvestment, submitPendingInvestment } from '../shared/services/apiGateway';
import generateRequest from '../shared/services/generateRequest';
import ConfirmationModal from '../shared/components/ConfirmationModal';
import SecurityTypeSelector from './SecurityTypeSelector';
import CustomTextField from './PostWireSignOffNetSuiteCustomTextField';
import DocumentViewer from '../shared/components/DocumentViewer';
import {
  createFundConfigurationError,
  assignLocalEditValues,
  securityTypes,
  prepareFundAllocationsForGrid,
  checkPositiveWholeNumber,
  toggleVisibilityOfFieldsBySecurityType,
  checkIfInterestLoanSecurity,
  checkIfNoInterestLoanSecurity,
  checkIfNullOrUndefined,
  checkNaturalNumber,
  checkValidPercentageBounds,
} from './helpers';

function StyledActionButton(props, label) {
  return (
    <LoadingButton
      variant="outlined"
      color="secondary"
      sx={{
        m: 1,
      }}
      {...props}
    >{label}
    </LoadingButton>
  );
}

const getSecurityTypeName = (securityTypeId) => (securityTypes.filter((item) => item.id === securityTypeId))[0].name;

function PostWireSignOffNetsuiteSubmission() {
  const { id: dealId } = useParams();

  const breakdownDGRef = useGridApiRef();
  const [loadingDeal, setDealsDetailLoading] = useState(true);
  const [fundAllocations, setFundAllocations] = useState(null);
  const totalInvestmentsId = v4();
  const [avInvestmentTotals, setAvInvestmentTotals] = useState([{
    id: totalInvestmentsId,
    avInvestmentTotal: 0,
    avInvestmentATotal: 0,
    avInvestmentQTotal: 0,
  }]);
  const [wireTransferDate, setWireTransferDate] = useState(null);
  const [securityType, setSecurityType] = useState(-1);
  const [interestRate, setInterestRate] = useState(roundToPrecision(0));
  const [sharePrice, setSharePrice] = useState(roundToPrecision(1));
  const [roundNumber, setRoundNumber] = useState(1);
  const [numberOfUnits, setNumberOfUnits] = useState(0);
  const [fundConfigError, setFundConfigError] = useState(null);
  const [closingDocuments, setClosingDocuments] = useState([]);
  const [isPushToNSConfirmationModalOpen, setPushToNSConfirmationModalOpen] = useState(false);
  const [wireDateError, setWireDateError] = useState(null);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [isPushingToNS, setIsPushingToNS] = useState(false);
  const user = new User();
  const [userRole] = useState({
    isFinance: user.isFinance(),
  });

  const history = useHistory();
  const calculateTotals = (funds) => {
    const investmentTotals = {
      id: totalInvestmentsId,
      avInvestmentTotal: 0,
      avInvestmentATotal: 0,
      avInvestmentQTotal: 0,
    };
    funds.forEach((fundAllocation) => {
      const aAllocation = getNumberValue(fundAllocation.aAllocation);
      const qAllocation = getNumberValue(fundAllocation.qAllocation);
      investmentTotals.avInvestmentTotal += (aAllocation + qAllocation);
      investmentTotals.avInvestmentATotal += (aAllocation);
      investmentTotals.avInvestmentQTotal += (qAllocation);
    });
    investmentTotals.avInvestmentTotal = formatCurrencyAsUsd(investmentTotals.avInvestmentTotal);
    investmentTotals.avInvestmentATotal = formatCurrencyAsUsd(investmentTotals.avInvestmentATotal);
    investmentTotals.avInvestmentQTotal = formatCurrencyAsUsd(investmentTotals.avInvestmentQTotal);
    return investmentTotals;
  };

  const setTotals = (funds) => {
    const investmentTotals = calculateTotals(funds);
    setAvInvestmentTotals([investmentTotals]);
    return investmentTotals;
  };

  const deal = useDealDetail(dealId, (dealData) => {
    const formattedFundAllocations = prepareFundAllocationsForGrid(dealData.funds);
    setFundAllocations(formattedFundAllocations);
    setDealsDetailLoading(false);
    setClosingDocuments(dealData.closingDocuments || []);
  });

  const autoCalculateNumberOfUnitsforLoanTypes = (securityTypeId) => {
    const filteredSecurityType = securityTypes.filter((st) => st.id === securityTypeId)[0];
    const securityTypeName = filteredSecurityType.name;
    if (checkIfInterestLoanSecurity(securityTypeName) || checkIfNoInterestLoanSecurity(securityTypeName)) {
      if (fundAllocations && fundAllocations[0]) {
        const totals = calculateTotals(exportGridValues(breakdownDGRef.current, Object.keys(fundAllocations[0])));
        return Math.ceil(getNumberValue(totals.avInvestmentTotal));
      }
    }
    return 0;
  };

  useEffect(() => {
    if (breakdownDGRef) {
      if (fundAllocations && fundAllocations[0]) {
        setTotals(exportGridValues(breakdownDGRef.current, Object.keys(fundAllocations[0])));
      }
      if (!loadingDeal) {
        toggleVisibilityOfFieldsBySecurityType(
          securityTypes,
          deal.securityType || securityType,
        );
        if (deal.securityType) {
          setSecurityType(Number(deal.securityType));
        }
        if (deal.interestRate) {
          setInterestRate(Number(deal.interestRate));
        }
        if (deal.sharePrice) {
          setSharePrice(Number((deal.sharePrice)));
        }
        if (deal.roundNumber) {
          setRoundNumber(Number(deal.roundNumber));
        }
        if (deal.numberOfUnits) {
          setNumberOfUnits(Number(deal.numberOfUnits));
        }
        if (deal.wireTransferDate) {
          setWireTransferDate(deal.wireTransferDate);
        }
        if (deal.processStage === 2) {
          history.push('/');
        }

        setFundConfigError(createFundConfigurationError(deal.funds));
        return breakdownDGRef.current.subscribeEvent(
          'cellModeChange',
          (params, event) => {
            event.defaultMuiPrevented = true;
          },
          { isFirst: true },
        );
      }
    }
    return () => { };
  }, [loadingDeal]);

  const handleCellClick = useCallback(
    (params) => {
      if (params.isEditable) {
        breakdownDGRef.current.setCellMode(params.id, params.field, 'edit');
      }
    },
    [breakdownDGRef],
  );

  const dealsTableDef = createDealsTableDefinition(userRole);

  if (!userRole.isFinance) {
    dealsTableDef.forEach((column) => {
      column.editable = false;
    });
  }

  const preparePostPayload = (pendingInvestment) => assignLocalEditValues(
    pendingInvestment,
    exportGridValues(breakdownDGRef.current, Object.keys(fundAllocations[0])),
    {
      roundNumber,
      sharePrice,
      securityType,
      interestRate,
      wireTransferDate,
      numberOfUnits: autoCalculateNumberOfUnitsforLoanTypes(securityType) || numberOfUnits,
    },
  );

  const overwriteAndSaveEdits = async (pendingInvestment) => {
    setIsSubmitting(true);
    await updatePendingInvestment(
      generateRequest(preparePostPayload(pendingInvestment)),
    );
    setIsSubmitting(false);
  };

  const submitToNetsuite = async (pendingInvestment) => {
    setIsSubmitting(true);
    await submitPendingInvestment(
      generateRequest(preparePostPayload(pendingInvestment)),
    );
    setIsSubmitting(false);
  };

  return (
    <>
      <Helmet>
        <title>Finance Wire Sign Off To NetSuite</title>
      </Helmet>
      <Container className="dashboard" style={{ maxWidth: '100%' }}>
        <Card>
          <CardBody>
            <Row>
              <Col>
                {deal && (
                <h2>{deal.companyName}</h2>
                )}
                {!deal && (HorizRecLoader(1, '250px', 30))}
              </Col>
            </Row>
            <Row>
              <Col style={{ marginTop: '30px' }}>
                {deal && (
                  <DatePicker
                    label="Wire Date (investment date)"
                    value={wireTransferDate}
                    renderInput={(params) =>
                    // eslint-disable-next-line react/jsx-props-no-spreading
                      <CustomTextField required style={{ minWidth: '285px' }} {...params} customreadonly={!userRole.isFinance} />}
                    InputProps={{ readOnly: true }}
                    onChange={(date) => setWireTransferDate(date)}
                    onError={(error) => {
                      setWireDateError(error);
                    }}
                  />
                )}
                {
                  deal && (
                    <Grid
                      container
                      direction="row"
                      justifyContent="flex-start"
                      alignItems="baseline"
                      sx={{ mt: userRole.isFinance ? 3 : 0 }}
                    >
                      <Box sx={{
                        p: 1, m: 1, ml: 0, pl: 0,
                      }}
                      >
                        {(userRole.isFinance) && (
                          SecurityTypeSelector(securityTypes, (value) => {
                            setSecurityType(value);
                          }, securityType, userRole)
                        )}
                        {(!userRole.isFinance) && (
                          <ReadOnlyTextField label="Security Type" value={getSecurityTypeName(securityType)} />
                        )}
                      </Box>
                      <Box
                        sx={{ p: 1, m: 1 }}
                      >
                        <CustomTextField
                          id="roundNumberInput"
                          label="Round Number"
                          type="number"
                          required
                          defaultValue={!checkIfNullOrUndefined(deal.roundNumber)
                            ? deal.roundNumber : roundNumber}
                          style={{
                            width: '124px',
                          }}
                          InputLabelProps={{
                            shrink: true,
                          }}
                          onBlur={(event) => {
                            if (!checkNaturalNumber(event.target.value)) {
                              event.target.value = 1;
                            }
                            const newValue = Math.round(event.target.value);
                            event.target.value = newValue;
                            setRoundNumber(newValue);
                          }}
                          customreadonly={!userRole.isFinance}
                        />
                      </Box>
                      <Box
                        id="numberOfUnitsInputContainer"
                        sx={{ p: 1, m: 1 }}
                      >
                        <CustomTextField
                          id="numberOfUnitsInput"
                          label="Number of Units"
                          type="number"
                          required
                          defaultValue={!checkIfNullOrUndefined(deal.numberOfUnits)
                            ? deal.numberOfUnits : numberOfUnits}
                          InputLabelProps={{
                            shrink: true,
                          }}
                          onBlur={(event) => {
                            if (!checkNaturalNumber(event.target.value)) {
                              event.target.value = 1;
                            }
                            const newValue = Math.round(event.target.value);
                            event.target.value = newValue;
                            setNumberOfUnits(newValue);
                          }}
                          customreadonly={!userRole.isFinance}
                        />
                      </Box>
                      <Box
                        id="interestRateInputContainer"
                        sx={{ p: 1, m: 1 }}
                      >
                        <CustomTextField
                          label="Interest Rate %"
                          type="number"
                          required
                          defaultValue={!checkIfNullOrUndefined(deal.interestRate)
                            ? roundToPrecision(deal.interestRate) : interestRate}
                          InputLabelProps={{
                            shrink: true,
                          }}
                          onBlur={(event) => {
                            if (checkValidPercentageBounds(event.target.value)) {
                              const rounded = roundToPrecision(event.target.value);
                              event.target.value = rounded;
                              setInterestRate(rounded);
                            } else {
                              event.target.value = 0;
                              setInterestRate(0);
                            }
                          }}
                          customreadonly={!userRole.isFinance}
                        />
                      </Box>
                      <Box
                        id="sharePriceInputContainer"
                        sx={{ p: 1, m: 1 }}
                      >
                        <CustomTextField
                          label="Share Price"
                          type="number"
                          required
                          defaultValue={!checkIfNullOrUndefined(deal.sharePrice)
                            ? roundToPrecision(deal.sharePrice) : sharePrice}
                          InputLabelProps={{
                            shrink: true,
                          }}
                          InputProps={{
                            startAdornment: <InputAdornment position="start">$</InputAdornment>,
                          }}
                          onBlur={(event) => {
                            if (checkPositiveWholeNumber(event.target.value)) {
                              const rounded = roundToPrecision(event.target.value);
                              event.target.value = rounded;
                              setSharePrice(rounded);
                            } else {
                              event.target.value = 1;
                              setSharePrice(1);
                            }
                          }}
                          customreadonly={!userRole.isFinance}
                        />
                      </Box>
                    </Grid>
                  )
                }
                {
                  !deal && (
                    <>
                      {HorizRecLoader(1, 285)}
                      <Box
                        sx={{
                          display: 'flex', flexDirection: 'row', p: 0, m: 0, borderRadius: 1,
                        }}
                      >
                        {HorizRecLoader(4)}
                      </Box>
                    </>
                  )
                }

                {(userRole.isFinance && fundConfigError) && (
                <h4 className="error red">{fundConfigError}</h4>
                )}
                <div style={{ marginTop: '30px' }}>
                  <AvDataGrid
                    rows={fundAllocations}
                    columns={dealsTableDef}
                    apiRef={breakdownDGRef}
                    loading={loadingDeal}
                    onCellEditStop={() => {
                      setTotals(exportGridValues(breakdownDGRef.current, Object.keys(fundAllocations[0])));
                    }}
                    onCellClick={handleCellClick}
                    initialState={{
                      sorting: {
                        sortModel: [{ field: 'name', sort: 'asc' }],
                      },
                    }}
                  />
                </div>
                <div style={{ marginTop: '20px' }}>
                  <AvDataGrid
                    pageSize={1}
                    rows={avInvestmentTotals}
                    columns={createDealTotalsTableDefinition()}
                    loading={loadingDeal}
                    hideFooter
                    density="compact"
                    disableColumnMenu
                    disableColumnFilter
                    disableColumnSelector
                    sx={{
                      fontSize: '17px',
                    }}
                  />
                </div>
              </Col>
            </Row>
            { deal
              && (
              <Stack>
                <Row className="w-100">
                  <Col>
                    <DocumentViewer id={dealId} documents={closingDocuments} label="Closing Documents" />
                  </Col>
                </Row>
              </Stack>
              )}
            {
              deal && (
                <Stack
                  direction="row"
                  justifyContent="space-between"
                  alignItems="flex-end"
                  spacing={2}
                  mt={2}
                >
                  {(userRole.isFinance) && (
                    StyledActionButton({
                      onClick: async () => {
                        setIsSaving(true);
                        await overwriteAndSaveEdits(deal);
                        setIsSaving(false);
                      },
                      disabled: isSubmitting,
                      loading: isSaving,
                    }, 'Save As Draft'))}

                  {(userRole.isFinance) && (
                    StyledActionButton({
                      onClick: async () => {
                        setIsPushingToNS(true);
                        setPushToNSConfirmationModalOpen(true);
                      },
                      variant: 'contained',
                      disabled: !!fundConfigError || (securityType < 0) || !wireTransferDate || wireDateError || isSubmitting,
                      loading: isPushingToNS,
                    }, 'Push to Netsuite')
                  )}
                </Stack>
              )
            }
            {(deal && !userRole.isFinance) && (
            <h4 style={{ textAlign: 'center' }}>This page is editable by Finance users only</h4>
            )}
            {(!deal && userRole.isFinance) && (
            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="flex-end"
              spacing={2}
              mt={2}
            >
              {HorizRecLoader(2)}
            </Stack>
            )}
          </CardBody>
        </Card>
      </Container>
      <ConfirmationModal
        isOpen={isPushToNSConfirmationModalOpen}
        headerText="Are you sure you want to push changes to NetSuite?"
        bodyText="Please confirm all values are correct as this cannot be easily undone."
        onConfirm={async () => {
          deal.processStage = 2;
          await submitToNetsuite(deal);
          history.push('/');
        }}
        onCancel={() => {
          setPushToNSConfirmationModalOpen(false);
          setIsPushingToNS(false);
        }}
      />
    </>

  );
}

export default PostWireSignOffNetsuiteSubmission;
