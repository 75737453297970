import React from 'react';

export default function NotFound() {
  return (
    <div style={{
      width: '300px',
      backgroundColor: 'white',
      padding: '10px',
      position: 'absolute',
      top: '30%',
      left: '50%',
      margin: '-100px 0 0 -150px',
    }}
    >
      <h2 style={{
        fontSize: '25px',
        textAlign: 'center',
      }}
      > Page Not Found
      </h2>
      <p style={{
        fontSize: '14px',
        textAlign: 'center',
      }}
      > This page either does not exist or you do not have permission to view it. Please contact support for more information.
      </p>
    </div>
  );
}
