import React, { useState } from 'react';
import LoadingButton from '@mui/lab/LoadingButton';
import { Container } from '@mui/material';
import {
  Card, CardBody, Row, Col,
} from 'reactstrap';
import { Helmet } from 'react-helmet';
import { useParams, useHistory } from 'react-router-dom';
import { v4 } from 'uuid';
import DealInformation from '../shared/components/DealInformation';
import formatDateString from '../shared/utils/formatDateString';
import formatCurrencyAsUsd from '../shared/utils/formatCurrencyAsUsd';
import ReadOnlyTextField from '../shared/components/ReadOnlyTextField';
import {
  updatePendingInvestment,
} from '../shared/services/apiGateway';
import usePendingInvestmentDetail from '../shared/hooks/usePendingInvestmentDetail';
import generateRequest from '../shared/services/generateRequest';
import PartialPageLoader from '../shared/components/Loader/PartialPageLoader';
import AvDataGrid from '../shared/components/AvDataGrid';
import getStageStatus from '../shared/utils/checkUserPermissions';
import StageStatusMessage from '../shared/components/StageStatusMessage';
import CloseOnConfirmModal from '../shared/components/CloseOnConfirmModal';
import getSearchParams from '../shared/utils/getSearchParams';

const createFundModels = (deal) => {
  const fundModels = [];
  for (const fund of deal.funds) {
    if (fund.fundId) {
      fundModels.push({
        id: v4(),
        name: fund.name,
        amount: formatCurrencyAsUsd(fund.investmentAmount),
      });
    }
  }
  return fundModels;
};

const createConfirmWireDateTableDefinition = () => [
  {
    field: 'name',
    headerName: 'Fund Name',
    description: 'The name of the Fund',
    editable: false,
    flex: 1,
    minWidth: 350,
  },
  {
    field: 'amount',
    headerName: 'Wire Amount',
    description: 'Wire Amount',
    flex: 1,
    minWidth: 300,
  }];

function ApproveWireExecution() {
  const { id } = useParams();
  const history = useHistory();
  const closeOnConfirm = getSearchParams('closeoncompletion');
  const [dealName, setDealName] = useState('');
  const [iTeamMemberName, setITeamMemberName] = useState('');
  const [sponsorTeam, setSponsorTeam] = useState('');
  const [wireAmount, setWireAmount] = useState(0);
  const [targetWireDate, setTargetWireDate] = useState('');
  const [allocations, setAllocations] = useState([]);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [stageStatus, setStageStatus] = useState({
    isEditableByRole: false,
  });
  const [completionModalOpen, setCompletionModalOpen] = useState(false);

  const dealData = usePendingInvestmentDetail(id, (deal) => {
    let payloadDealName = '';
    if (deal.companyName) {
      payloadDealName = deal.companyName;
    } else if (deal.hsDealObj && deal.hsDealObj.dealName) {
      payloadDealName = deal.hsDealObj.dealName;
    }

    let payloadSponsorTeam = '';
    if (deal.sponsorTeam) {
      payloadSponsorTeam = deal.sponsorTeam;
    } else if (deal.hsDealObj && deal.hsDealObj.sponsorTeam && deal.hsDealObj.sponsorTeam.name) {
      payloadSponsorTeam = deal.hsDealObj.sponsorTeam.name;
    }

    let payloadWireAmount = 0;
    if (deal.wireAmount) {
      payloadWireAmount = deal.wireAmount;
    } else if (deal.hsDealObj && deal.hsDealObj.amount) {
      payloadWireAmount = deal.hsDealObj.amount;
    }

    setStageStatus({
      ...getStageStatus(deal),
    });
    const payloadwireTargetDate = deal.wireTargetDate ? new Date(deal.wireTargetDate) : new Date();
    setDealName(payloadDealName);
    setSponsorTeam(payloadSponsorTeam);
    setWireAmount(formatCurrencyAsUsd(payloadWireAmount));
    setTargetWireDate(formatDateString(payloadwireTargetDate));
    setAllocations(createFundModels(deal));
    setITeamMemberName(deal.iTeamMemberName || '');
  });

  const saveChangesAndNotify = async () => {
    setIsSubmitting(true);
    dealData.stagesCompleted.approveWireExecution = new Date().toISOString();
    await updatePendingInvestment(generateRequest(dealData));
    setIsSubmitting(false);
    if (!closeOnConfirm) {
      history.push(`/deal-stages/${id}`);
    } else {
      setCompletionModalOpen(true);
    }
  };
  return (
    <>
      <Helmet>
        <title>Wire Execution</title>
      </Helmet>
      <Container className="med-width-form">
        <Card>
          {!dealData && (
            PartialPageLoader()
          )}
          {
            dealData && (
              <CardBody>
                <Row>
                  <Col>
                    <h3>Approve Wire Execution for {dealName}</h3>
                  </Col>
                </Row>
                <Row className="w-100">
                  <Col>
                    <DealInformation
                      dealInfo={{ dealName, sponsorTeam, iTeamMemberName }}
                      setDealInfo={() => false}
                    />
                    <ReadOnlyTextField
                      label="Wire Amount"
                      value={wireAmount}
                      fullWidth
                    />
                    <ReadOnlyTextField
                      label="Target Wire Date"
                      value={targetWireDate}
                      fullWidth
                      hidden
                    />
                    <div style={{ marginTop: '20px' }}>
                      <h4>Allocations</h4>
                      <div style={{ marginTop: '20px' }}>
                        <AvDataGrid
                          rows={allocations}
                          columns={createConfirmWireDateTableDefinition()}
                          loading={!dealData}
                          disableMultipleSelection
                          initialState={{
                            sorting: {
                              sortModel: [{ field: 'name', sort: 'desc' }],
                            },
                          }}
                        />
                      </div>
                    </div>
                  </Col>
                </Row>
                {(stageStatus.isEditableByRole && !stageStatus.isCompleted) && (
                <Row>
                  <Col className="mt-md-4 d-flex justify-content-center">
                    <LoadingButton
                      variant="contained"
                      color="secondary"
                      disabled={isSubmitting}
                      loading={isSubmitting}
                      onClick={async () => {
                        await saveChangesAndNotify();
                      }}
                    >
                      Notify Finance to Execute Wire
                    </LoadingButton>
                  </Col>
                </Row>
                )}
                <p className="mt-md-2 p-md-2 text-center">
                  Note that the wires executed before 2PM EST will be sent by finance same-day; wires executed after 2pm
                  EST will be sent next business day. All international wires will require an extra business day to process.
                </p>
                <StageStatusMessage stageStatus={stageStatus} />
              </CardBody>
            )
          }
        </Card>

        <CloseOnConfirmModal modalOpen={completionModalOpen} />
      </Container>
    </>
  );
}

export default ApproveWireExecution;
