export const GET_LI_USER = 'GET_LI_USER';
export const GET_LI_USER_ERROR = 'GET_LI_USER_ERROR';
export const POST_LI_USER = 'POST_LI_USER';
export const POST_LI_USER_ERROR = 'POST_LI_USER_ERROR';
export const SAVE_LI_USER = 'SAVE_LI_USER';


export function updateProfileSettings(settings) {
  return (dispatch, getState) => {
    const state = getState();
    const { api } = state.auth;

    dispatch({ type: POST_LI_USER });

    const path = 'api/v2.0/mobile/fdo-contacts/profile';

    return api.post(path, settings)
      .then((data) => {
        if (data && data.liUser && data.success) {
          dispatch({
            type: SAVE_LI_USER,
            liUser: data.liUser,
          });
        } else {
          dispatch({ type: POST_LI_USER_ERROR });
        }
      });
  };
}

export function getProfileSettings() {
  return (dispatch, getState) => {
    const state = getState();
    const { api } = state.auth;

    dispatch({ type: GET_LI_USER });

    const path = 'api/v2.0/mobile/fdo-contacts/profile';

    return api.get(path)
      .then((data) => {
        if (data && data.liUser && data.success) {
          dispatch({
            type: SAVE_LI_USER,
            liUser: data.liUser,
          });
        } else {
          dispatch({ type: GET_LI_USER_ERROR });
        }
      });
  };
}

export default {
  GET_LI_USER,
  GET_LI_USER_ERROR,
  POST_LI_USER,
  POST_LI_USER_ERROR,
  SAVE_LI_USER,
  updateProfileSettings,
  getProfileSettings,
};
