export const checkValidPercentageBounds = (value) => value >= 0 && value <= 100;
export const checkPositiveWholeNumber = (value) => value >= 0;
export const checkNaturalNumber = (value) => value > 0;
export const checkIfNullOrUndefined = (value) => value === null || value === undefined;

export const preferredStockLabel = 'Preferred Stock';
export const safeLabel = 'SAFE';
export const convertibleNoteLabel = 'Convertible Note';
export const commonStockLabel = 'Common Stock';
export const publicCommonStockLabel = 'Public Common Stock';
export const tokensLabel = 'Token';
export const undefinedSecurityTypeLabel = 'Undetermined';

export const checkIfStockSecurity = (securityTypeName) => [preferredStockLabel,
  commonStockLabel, publicCommonStockLabel].includes(securityTypeName);

export const checkIfNoInterestLoanSecurity = (securityTypeName) => [safeLabel].includes(securityTypeName);

export const checkIfInterestLoanSecurity = (securityTypeName) => [convertibleNoteLabel].includes(securityTypeName);

export const checkIfTokenSecurity = (securityTypeName) => [tokensLabel].includes(securityTypeName);

export const toggleVisibilityOfFieldsBySecurityType = (types, selectedSecurityType) => {
  const filteredSecurityType = types.filter((securityType) => securityType.id === selectedSecurityType)[0];

  const interestRateInputContainer = document.getElementById('interestRateInputContainer');
  const sharePriceInputContainer = document.getElementById('sharePriceInputContainer');
  const numberOfUnitsInputContainer = document.getElementById('numberOfUnitsInputContainer');

  if (filteredSecurityType.name === undefinedSecurityTypeLabel) {
    numberOfUnitsInputContainer.style.display = 'none';
    interestRateInputContainer.style.display = 'none';
    sharePriceInputContainer.style.display = 'none';
  } else if (checkIfStockSecurity(filteredSecurityType.name) || checkIfTokenSecurity(filteredSecurityType.name)) {
    interestRateInputContainer.style.display = 'none';
    sharePriceInputContainer.style.display = '';
    numberOfUnitsInputContainer.style.display = '';
  } else if (checkIfNoInterestLoanSecurity(filteredSecurityType.name)) {
    interestRateInputContainer.style.display = 'none';
    sharePriceInputContainer.style.display = 'none';
    numberOfUnitsInputContainer.style.display = 'none';
  } else if (checkIfInterestLoanSecurity(filteredSecurityType.name)) {
    interestRateInputContainer.style.display = '';
    sharePriceInputContainer.style.display = 'none';
    numberOfUnitsInputContainer.style.display = 'none';
  } else {
    interestRateInputContainer.style.display = '';
    sharePriceInputContainer.style.display = '';
    numberOfUnitsInputContainer.style.display = '';
  }
  return filteredSecurityType;
};

export const createTempTableFundId = (fund) => `${fund.name}_${fund.investmentAmount}`;

export const assignLocalEditValues = (pendingInvestment, tableFundData, otherValues) => {
  let tempPendingInvestment = JSON.parse(JSON.stringify(pendingInvestment));
  tempPendingInvestment = Object.assign(tempPendingInvestment, otherValues);
  for (const fund of tempPendingInvestment.funds) {
    for (const tableFundDataItem of tableFundData) {
      if (createTempTableFundId(fund) === tableFundDataItem.id) {
        Object.keys(fund).forEach((key) => {
          if (Object.prototype.hasOwnProperty.call(tableFundDataItem, key)) {
            fund[key] = tableFundDataItem[key];
          }
        });
        fund.qAmount = tableFundDataItem.qAllocation;
        fund.aAmount = tableFundDataItem.aAllocation;
      }
    }
  }
  return tempPendingInvestment;
};

export const prepareFundAllocationsForGrid = (funds) => {
  const formatted = [];
  for (const fund of funds) {
    formatted.push({
      id: createTempTableFundId(fund),
      name: fund.name,
      qPercent: fund.qPercent || 0,
      aPercent: fund.aPercent || 0,
      aAllocation: 0,
      qAllocation: 0,
      investmentAmount: fund.investmentAmount,
      qFundId: fund.qFundId,
      aFundId: fund.aFundId,
    });
  }
  return formatted;
};

export const createFundConfigurationError = (funds) => {
  let str = null;
  for (const fund of funds) {
    if (!fund.aFundId && !fund.qFundId) {
      if (str) {
        str = 'Some funds are not configured correctly. Please contact support to enable editing of allocations and pushing to netsuite.';
        break;
      }
      str = `${fund.name} is not configured correctly. Please contact support to enable editing of allocations and pushing to netsuite.`;
    }
  }
  return str;
};

export const securityTypes = [
  {
    name: undefinedSecurityTypeLabel,
    id: -1,
  },
  {
    name: preferredStockLabel,
    id: 2,
  },
  {
    name: safeLabel,
    id: 4,
  },
  {
    name: convertibleNoteLabel,
    id: 3,
  },
  {
    name: commonStockLabel,
    id: 1,
  },
  {
    name: publicCommonStockLabel,
    id: 9,
  },
  {
    name: tokensLabel,
    id: 6,
  },
];
