import moment from 'moment';
import clubMemberRoleOptions from './ClubMemberRoleOptions';
import {
  maxLength120, validateUrl, validateEmail,
} from './shared';

export default class AddClubMemberModel {
  constructor(rawClubMember) {
    this.email = (rawClubMember.status === 'new')
      ? {
        label: 'Account Email',
        type: 'text',
        readOnly: false,
        required: true,
        message: rawClubMember.message || null,
      }
      : {
        label: 'Account Email of existing investor',
        type: 'select2',
        readOnly: false,
        value: null,
        options: rawClubMember.investors.map(opt => ({
          label: opt.disabled ? `${opt.title} - already in the club` : opt.title,
          value: (opt.id).toString(),
          secondaryValue: opt.email,
          disabled: opt.disabled,
          avatar: opt.avatar ? opt.avatar : null,
        })),
        required: true,
      };
    this.firstName = {
      label: 'First Name',
      type: rawClubMember.status === 'new' ? 'text' : null,
      required: (rawClubMember.status === 'new'),
      readOnly: false,
      value: rawClubMember.firstName,
    };
    this.lastName = {
      label: 'Last Name',
      type: rawClubMember.status === 'new' ? 'text' : null,
      required: (rawClubMember.status === 'new'),
      readOnly: false,
      value: rawClubMember.lastName,
    };
    this.company_name = {
      label: 'Company',
      type: 'text',
      readOnly: false,
      value: rawClubMember.company_name,
      validators: [maxLength120],
    };
    this.company_url = {
      label: 'Company Website',
      type: 'text',
      readOnly: false,
      value: rawClubMember.company_url,
      validators: [validateUrl],
    };
    this.title = {
      label: 'Title',
      type: 'text',
      readOnly: false,
      value: rawClubMember.title,
      validators: [maxLength120],
    };
    this.contactEmail = {
      label: 'Preferred Contact Email',
      type: 'textPrivate',
      readOnly: false,
      validators: [validateEmail],
      value: rawClubMember.contactEmail === null ? '' : rawClubMember.contactEmail,
      disabled: rawClubMember.contactEmail === '',
    };
    this.linked_in_url = {
      label: 'LinkedIn Url',
      type: 'text',
      readOnly: false,
      validators: [validateUrl],
      value: rawClubMember.linked_in_url === null ? '' : rawClubMember.linked_in_url,
    };
    this.join_date = {
      label: 'Join Date',
      type: 'date',
      readOnly: false,
      value: rawClubMember.join_date,
      placeholder: moment().format('MM/DD/YYYY'),
    };
    this.role = {
      label: 'Member Status',
      type: 'select',
      readOnly: false,
      display: rawClubMember.roleStatus,
      value: rawClubMember.role,
      options: clubMemberRoleOptions,
    };
    this.profile_picture = {
      type: 'fileinput',
      readOnly: false,
    };
    this.imageUri = {
      type: null,
      readOnly: true,
      value: rawClubMember.avatar,
      previewClass: 'logoImgCircle',
      acceptedTypes: 'image/jpeg, image/png, image/gif',
    };
    this.id = {
      label: 'ID',
      type: null,
      readOnly: true,
      value: rawClubMember.id,
    };
    this.clubId = {
      label: 'Club ID',
      type: null,
      readOnly: true,
      value: rawClubMember.clubId,
    };
  }
}
