import React from 'react';
import { Field } from 'redux-form';
import PropTypes from 'prop-types';

const EditField = ({ value, name, onBlur }) => (
  <Field
    name={name}
    component="input"
    type="text"
    value={value}
    onBlur={onBlur}
    autoFocus
  />
);

EditField.propTypes = {
  name: PropTypes.string.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  value: PropTypes.any.isRequired,
  onBlur: PropTypes.func.isRequired,
};

export default EditField;
