import * as applicationActions from './applicationActions';
import * as authActions from './authActions';
import * as errorActions from './errorActions';
import * as fundsTotalActions from './fundsTotalActions';
import * as getCompaniesActions from './getCompaniesActions';
import * as getCurrentUserActions from './getCurrentUserActions';
import * as getDictionaryActions from './getDictionaryActions';
import * as getFundsActions from './getFundsActions';
import * as getInvestmentActions from './getInvestmentsActions';
import * as getInvestorsActions from './getInvestorsActions';
import * as getPortfolioUpdatesActions from './getPortfolioUpdatesActions';
import * as liUserActions from './liUserActions';
import * as modalActions from './modalActions';
import * as portalActions from './portalActions';
import * as sidebarActions from './sidebarActions';
import * as snackbarActions from './snackbarActions';
import * as themeActions from './themeActions';
import * as queryStringActions from './queryStringActions';
import * as managedUserActions from './managedUserActions';
import * as dashboardLabelActions from './dashboardLabelsActions';
import {
  getFundTrackingDataStart,
  getFundTrackingDataSuccess,
  getFundTrackingDataError,
  clearFundTrackingData,
  applyProjectionStatusFilters,
  applyInvestmentMethodsFilters,
  applyInvestorStatusFilters,
  applyAmountFilters,
  resetFiltersState,
  fetchFundTrackingData,
} from '../reducers/fundTrackerReducer';
import {
  fetchPortcoData,
  getPortcoDataStart,
  getPortcoDataSuccess,
  getPortcoDataError,
  clearPortcoData,
} from '../reducers/portcoReducer';

const fundTrackerActions = {
  getFundTrackingDataStart,
  getFundTrackingDataSuccess,
  getFundTrackingDataError,
  clearFundTrackingData,
  applyProjectionStatusFilters,
  applyInvestmentMethodsFilters,
  applyInvestorStatusFilters,
  applyAmountFilters,
  resetFiltersState,
  fetchFundTrackingData,
};

const portcoActions = {
  fetchPortcoData,
  getPortcoDataStart,
  getPortcoDataSuccess,
  getPortcoDataError,
  clearPortcoData,
};

export {
  applicationActions,
  authActions,
  errorActions,
  fundTrackerActions,
  fundsTotalActions,
  getCompaniesActions,
  getCurrentUserActions,
  getDictionaryActions,
  getFundsActions,
  getInvestmentActions,
  getInvestorsActions,
  getPortfolioUpdatesActions,
  liUserActions,
  modalActions,
  portalActions,
  portcoActions,
  sidebarActions,
  snackbarActions,
  themeActions,
  queryStringActions,
  managedUserActions,
  dashboardLabelActions,
};
