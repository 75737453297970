import { useState, useEffect } from 'react';
import { getClosedInvestment } from '../services/apiGateway';

/**
 * @param {*} id the deal id
 * @param {*} onLoad optional callback
 * @returns the deal or an error on fail
 */
function useClosedInvestmentDetail(id, onLoad) {
  const [closedInvestmentDetail, setClosedInvestmentDetail] = useState(null);

  useEffect(() => {
    const abortController = new AbortController();
    let ignore = false;

    async function fetchData() {
      const response = await getClosedInvestment(id);
      setClosedInvestmentDetail(response[0]);
      if (onLoad) {
        onLoad(response[0]);
      }
    }

    fetchData();

    return () => {
      ignore = true;
      abortController.abort();
    };
  }, []);

  return closedInvestmentDetail;
}

export default useClosedInvestmentDetail;
