export const RESET_ERROR_MESSAGE = 'RESET_ERROR_MESSAGE';
export const ADD_ERROR_MESSAGE = 'ADD_ERROR_MESSAGE';

// Resets the currently visible error message.
export const resetErrorMessage = () => ({
  type: RESET_ERROR_MESSAGE,
});

export const addErrorMessage = error => ({
  type: ADD_ERROR_MESSAGE,
  error: error.message,
});
