function getKeyByValue(object, value) {
  return Object.keys(object).find(key => object[key] === value);
}

function getOptions(options) {
  return Object.keys(options).map(opt => ({ label: options[opt], value: opt }));
}

const YESorNO = { 1: 'Yes', 0: 'No' };

export default class FundTrackerModel {
  constructor(rawFundTracker, projectionStatusKey, investorStatusKey, investmentMethodKey) {
    this.id = {
      label: 'ID',
      type: null,
      readOnly: true,
      value: rawFundTracker.id,
    };
    this.contactId = {
      label: 'ID',
      type: null,
      readOnly: true,
      value: rawFundTracker.contactId,
    };
    this.fund_tracking_firstName = {
      label: 'First Name',
      type: 'text',
      readOnly: false,
      value: rawFundTracker.fund_tracking_firstName,
    };
    this.fund_tracking_lastName = {
      label: 'Last Name',
      type: 'text',
      readOnly: false,
      value: rawFundTracker.fund_tracking_lastName,
    };
    this.fund_tracking_email = {
      label: 'Email',
      type: 'text',
      readOnly: false,
      value: rawFundTracker.fund_tracking_email,
    };
    this.amount = {
      label: 'Amount',
      type: 'text',
      readOnly: false,
      value: rawFundTracker.amountRaw,
    };
    this.method_of_investment = {
      label: 'Method of Investment',
      type: 'select',
      readOnly: false,
      value: getKeyByValue(investmentMethodKey, rawFundTracker.method_of_investment),
      display: rawFundTracker.method_of_investment,
      options: getOptions(investmentMethodKey),
    };
    this.accreditation = {
      label: 'Investor Status',
      type: 'select',
      readOnly: false,
      value: getKeyByValue(investorStatusKey, rawFundTracker.accreditation),
      display: rawFundTracker.accreditation,
      options: getOptions(investorStatusKey),
    };
    this.projection_status = {
      label: 'Projection Status',
      type: 'select',
      readOnly: false,
      value: getKeyByValue(projectionStatusKey, rawFundTracker.projection_status),
      display: rawFundTracker.projection_status,
      options: getOptions(projectionStatusKey),
    };
    this.pais_date = {
      label: 'Date of PAIS',
      type: 'date',
      readOnly: false,
      value: rawFundTracker.pais_date_raw,
    };
    this.sa_date = {
      label: 'Date of SA',
      type: 'date',
      readOnly: false,
      value: rawFundTracker.sa_date_raw,
    };
    this.notes = {
      label: 'Notes',
      type: 'text',
      readOnly: false,
      value: rawFundTracker.notes + rawFundTracker.fullNotes,
    };
    this.fund_transfer_date = {
      label: 'Date of FT',
      type: 'date',
      readOnly: false,
      value: rawFundTracker.fund_transfer_date_raw,
    };
    this.is_co_investment = {
      label: 'Coinvestor',
      type: 'select',
      readOnly: false,
      value: rawFundTracker.is_co_investment,
      display: rawFundTracker.projection_status,
      options: Object.keys(YESorNO).map(opt => ({ label: YESorNO[opt], value: opt })),
    };
  }
}
