import { getTotalsAllFunds } from '../../shared/services/bifService';

export const REQUEST_TOTALS_FUNDS = 'REQUEST_TOTALS_FUNDS';
export const RECEIVE_TOTALS_FUNDS = 'RECEIVE_TOTALS_FUNDS';
export const SELECT_DATE = 'SELECT_DATE';
export const INVALIDATE_DATE = 'INVALIDATE_DATE';

export function selectDate(date) {
  return {
    type: SELECT_DATE,
    date,
  };
}

export function invalidateDate(date) {
  return {
    type: INVALIDATE_DATE,
    date,
  };
}

function requestTotalsFunds(date) {
  return {
    type: REQUEST_TOTALS_FUNDS,
    date,
  };
}

function receiveTotalsFunds(date, totals) {
  return {
    type: RECEIVE_TOTALS_FUNDS,
    date,
    totals,
    receivedAt: Date.now(),
  };
}

function fetchTotalsFunds(date) {
  return async (dispatch) => {
    dispatch(requestTotalsFunds(date));
    return dispatch(receiveTotalsFunds(date, await getTotalsAllFunds(date)));
  };
}

function shouldFetchTotalsFunds(state, date) {
  const totals = state.totalsFundsByDate[date];
  if (!totals) {
    return true;
  }
  if (totals.isFetching) {
    return false;
  }
  return totals.didInvalidate;
}

export function fetchTotalsFundsIfNeeded(date) {
  // eslint-disable-next-line consistent-return
  return (dispatch, getState) => {
    if (shouldFetchTotalsFunds(getState(), date)) {
      return dispatch(fetchTotalsFunds(date));
    }
  };
}
