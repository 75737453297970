import { useState, useEffect } from 'react';
import moment from 'moment';
import { getAnnouncement } from '../services/bifService';
import { announcementOptions } from '../helpers/MultiSelectOptions';
import { ANNOUNCEMENT_MOMENT_TIME_FORMAT } from '../services/clubResources';

function useAnnouncement(id, refreshKey = 0) {
  const [announcement, setAnnouncement] = useState(null);

  async function fetchData(ignore, abortController) {
    const response = await getAnnouncement(id, abortController.signal);
    if (!ignore && response !== 'aborted' && response) {
      setAnnouncement({
        ...response,
        id: response.id,
        nameField: response.name || '',
        headline_text: response.headline_text || '',
        display: response.display_status === 1 ? 'show' : 'hide',
        startDate: response.start_date ? moment(response.start_date).format(ANNOUNCEMENT_MOMENT_TIME_FORMAT) : '',
        endDate: response.end_date ? moment(response.end_date).format(ANNOUNCEMENT_MOMENT_TIME_FORMAT) : '',
        typeAsString: announcementOptions.find(item => Number(item.value) === Number(response.type)).label,
      });
    }
  }

  useEffect(() => {
    const abortController = new AbortController();
    let ignore = false;

    fetchData(ignore, abortController);

    return () => {
      ignore = true;
      abortController.abort();
    };
  }, [id, refreshKey]);

  return announcement;
}

export default useAnnouncement;
