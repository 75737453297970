import PropTypes from 'prop-types';

const {
  string, shape,
} = PropTypes;

export const SidebarProps = shape({
  show: PropTypes.bool,
  collapse: PropTypes.bool,
});

export const ThemeProps = shape({
  className: string,
});

export const TotalsFundsProps = shape({
  isFetching: PropTypes.bool,
  didInvalidate: PropTypes.bool,
  // eslint-disable-next-line react/forbid-prop-types
  items: PropTypes.array,
});

export const AllFundsProps = shape({
  isFetching: PropTypes.bool,
  didInvalidate: PropTypes.bool,
  // eslint-disable-next-line react/forbid-prop-types
  fundArray: PropTypes.array,
});
