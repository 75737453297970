import {
  REQUEST_ALL_DICTIONARY,
  RECIVE_ALL_DICTIONARY,
  RECIVE_ALL_DICTIONARY_ERROR,
} from '../actions/getDictionaryActions';
import Dictionary from '../../shared/services/dictionary';

export default function (state = {
  isFetching: false,
  items: {},
  error: null,
}, action) {
  switch (action.type) {
    case REQUEST_ALL_DICTIONARY:
      return Object.assign({}, state, {
        isFetching: true,
        error: action.error,
        dictionary: new Dictionary({}),
      });
    case RECIVE_ALL_DICTIONARY:
      return Object.assign({}, state, {
        isFetching: false,
        items: action.items,
        lastUpdated: action.receivedAt,
        dictionary: new Dictionary(action.items),
      });
    case RECIVE_ALL_DICTIONARY_ERROR:
      return Object.assign({}, state, {
        isFetching: false,
        error: action.error,
        dictionary: new Dictionary({}),
      });

    default:
      return state;
  }
}
