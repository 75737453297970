import React from 'react';
import {
  Button, UncontrolledDropdown, DropdownToggle, DropdownItem, DropdownMenu,
} from 'reactstrap';
import ChevronDownIcon from 'mdi-react/ChevronDownIcon';
import DownloadIcon from 'mdi-react/DownloadIcon';
import PropTypes from 'prop-types';
import SaDownloadDialog from './SaDownloadDialog';
import CsvBulkImportDialog from './CsvBulkImportDialog';

const FundTracking = ({ params }) => {
  const {
    fundId, companyId, refresh, fundName,
  } = params;
  const auditRepHref = `/admin/closing/collections/audit-report?id=${fundId}`;
  const viewRepHref = `/admin/closing/collections/reports?company=${companyId}&fund=${fundId}`;
  const downdCsvHref = `/admin/closing/collections/csv?fund=${fundId}`;
  const [openSaDownload, setOpenSaDownload] = React.useState(false);
  const [openCsvBulkImport, setOpenCsvBulkImport] = React.useState(false);

  return (
    <>
      <Button type="button" outline onClick={refresh}>
        <span className="lnr lnr-redo" />
      </Button>
      <UncontrolledDropdown>
        <DropdownToggle id="FundTrackingEditButton" className="icon icon--right" outline>
          <p><span className="lnr lnr-pencil" /> <ChevronDownIcon /></p>
        </DropdownToggle>
        <DropdownMenu right className="dropdown__menu">
          <DropdownItem tag="a" href={auditRepHref}>Audit Report</DropdownItem>
          <DropdownItem tag="a" href={viewRepHref}>View Report</DropdownItem>
          <DropdownItem tag="a" href={downdCsvHref}><DownloadIcon /> Download CSV Report</DropdownItem>
          <DropdownItem onClick={() => setOpenCsvBulkImport(true)}>
            <DownloadIcon />Download CSV Bulk Import
          </DropdownItem>
          <DropdownItem onClick={() => setOpenSaDownload(true)}>
            <DownloadIcon /> Download Documents
          </DropdownItem>
        </DropdownMenu>
      </UncontrolledDropdown>
      <CsvBulkImportDialog
        fundId={fundId}
        open={openCsvBulkImport}
        setOpen={setOpenCsvBulkImport}
      />
      <SaDownloadDialog
        fundId={fundId}
        open={openSaDownload}
        setOpen={setOpenSaDownload}
        fundName={fundName}
      />
    </>
  );
};

FundTracking.propTypes = {
  params: PropTypes.shape({
    fundId: PropTypes.string,
    fundName: PropTypes.string,
    companyId: PropTypes.number,
    refresh: PropTypes.func,
  }).isRequired,
};

export default FundTracking;
