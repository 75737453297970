import {
  Row, CardBody, Col,
} from 'reactstrap';
import React from 'react';
import HorizRecLoader from './HorizRecLoader';

export default function PartialPageLoader(rowCount = 1) {
  return (
    <CardBody>
      <Row className="w-100">
        <Col>
          {HorizRecLoader(1, '40%', 30)}
        </Col>
        <Col sm={12} className="pr-0">
          {HorizRecLoader(rowCount, '95%')}
        </Col>
      </Row>
    </CardBody>
  );
}
