import React from 'react';
import Button from '@mui/material/Button';
import formatCurrencyAsUsd from '../shared/utils/formatCurrencyAsUsd';
import formatDateString from '../shared/utils/formatDateString';
import sortByDate from '../shared/utils/sortAsDate';
import getNumberValue from '../shared/utils/getNumberValue';

export default function createPendingInvestmentsTableDefinition(history) {
  return [
    {
      field: '_',
      headerName: '',
      sortable: false,
      filterable: false,
      renderCell: (params) => (
        <Button
          variant="outlined"
          color="secondary"
          onClick={(e) => {
            // prevent row from being selected on click
            e.stopPropagation();

            if (params.row.verticaObj && params.row.dueDiligence) {
              history.push(`/pending-investment/${params.row.id}`);
            } else if (params.row.verticaObj && !params.row.dueDiligence) {
              history.push(`/pending-investment/${params.row.id}/dd-report`);
            } else {
              history.push(`/pending-investment/${params.row.id}/name-verification`);
            }
          }}
        >Details
        </Button>
      ),
      minWidth: 100,
      flex: 1,
    },
    {
      field: 'companyName',
      headerName: 'Name',
      description: 'The name of the Company',
      editable: false,
      flex: 1,
      minWidth: 300,
    },
    {
      field: 'sector',
      headerName: 'Sector',
      description: 'The primary industry of the Company',
      editable: false,
      flex: 1,
      minWidth: 250,
    },
    {
      field: 'sponsorFund',
      headerName: 'Sponsor Fund',
      description: 'The sponsor fund.',
      sortable: true,
      flex: 1,
      minWidth: 200,
    },
    {
      field: 'category',
      headerName: 'Status',
      description: 'The status of the investment.',
      sortable: true,
      flex: 1,
      minWidth: 200,
      valueGetter: (params) => params.row.processStage === 1,
      renderCell(params) {
        return (
          <span>{params.row.processStage === 1 ? 'Ready for Finance' : 'In Progress'}</span>
        );
      },
    },
    {
      field: 'totalInvestmentAmount',
      headerName: 'Total Investment Amount',
      description: 'The Total Investment Amount',
      sortable: true,
      flex: 1,
      minWidth: 300,
      valueGetter: (params) => `${formatCurrencyAsUsd((params.row.totalInvestmentAmount))}`,
      sortComparator: (a, b) => (getNumberValue(a) > getNumberValue(b) ? 1 : -1),
    },
  ];
}
