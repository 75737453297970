import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import Helmet from 'react-helmet';
import {
  Container, Row, Card, CardBody, Col,
} from 'reactstrap';
import LoadingButton from '@mui/lab/LoadingButton';
import { Button, Grid } from '@mui/material';

import PropTypes from 'prop-types';
import { Questions } from './Questions';
import QUESTION_LIST from './QuestionList';
import { spacing } from '../shared/themes/appTheme';
import DealInformation from '../shared/components/DealInformation';
import usePendingInvestmentDetail from '../shared/hooks/usePendingInvestmentDetail';
import PartialPageLoader from '../shared/components/Loader/PartialPageLoader';
import ConfirmationModal from '../shared/components/ConfirmationModal';
import StageStatusMessage from '../shared/components/StageStatusMessage';
import { updatePendingInvestment } from '../shared/services/apiGateway';
import generateRequest from '../shared/services/generateRequest';
import getStageStatus from '../shared/utils/checkUserPermissions';
import CloseOnConfirmModal from '../shared/components/CloseOnConfirmModal';
import getSearchParams from '../shared/utils/getSearchParams';

function ClosingDocsReview({ match }) {
  const pendingInvestmentId = match.params.id;
  const closeOnConfirm = getSearchParams('closeoncompletion');
  const history = useHistory();

  const [allAnswered, setAllAnswered] = useState(false);
  const [dealInfo, setDealInfo] = useState({
    dealName: '',
    sponsorTeam: '',
    iTeamMemberName: '',
  });
  const [closingDocsReviewDraft, setClosingDocsReviewDraft] = useState(null);
  const [modalOpen, setModalOpen] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [isPostingAnswers, setIsPostingAnswer] = useState(false);
  const [stageStatus, setStageStatus] = useState({
    isEditableByRole: false,
  });
  const [completionModalOpen, setCompletionModalOpen] = useState(false);

  const dealData = usePendingInvestmentDetail(pendingInvestmentId, (deal) => {
    let dealName = '';
    if (deal.companyName) {
      dealName = deal.companyName;
    } else if (deal.hsDealObj && deal.hsDealObj.dealName) {
      dealName = deal.hsDealObj.dealName;
    }

    let sponsorTeam = '';
    if (deal.sponsorTeam) {
      sponsorTeam = deal.sponsorTeam;
    } else if (deal.hsDealObj && deal.hsDealObj.sponsorTeam && deal.hsDealObj.sponsorTeam.name) {
      sponsorTeam = deal.hsDealObj.sponsorTeam.name;
    }
    setStageStatus({
      ...getStageStatus(deal, '/upload-closing-docs/'),
    });

    setDealInfo({
      dealName,
      sponsorTeam,
      iTeamMemberName: deal.iTeamMemberName || '',
    });

    if (deal.closingDocsReviewQuestions && deal.closingDocsReviewQuestions.length > 0) {
      setClosingDocsReviewDraft(deal.closingDocsReviewQuestions);
    } else {
      setClosingDocsReviewDraft(QUESTION_LIST);
    }
  });

  const saveClosingReviewAnswers = async (draft = true) => {
    try {
      setIsSubmitting(true);
      if (draft) {
        setIsSaving(true);
      } else {
        setIsPostingAnswer(true);
      }
      const dealObjChanges = { closingDocsReviewQuestions: closingDocsReviewDraft };
      const updatedDealData = { ...dealData, ...dealObjChanges };
      if (!draft) {
        updatedDealData.stagesCompleted.closingDocsReview = new Date().toISOString();
      }
      await updatePendingInvestment(generateRequest(updatedDealData));
      setIsSubmitting(false);
      setIsSaving(false);
      setIsPostingAnswer(false);
      setModalOpen(false);
      if (!draft && !closeOnConfirm) {
        history.push(`/deal-stages/${pendingInvestmentId}`);
      } else if (!draft && closeOnConfirm) {
        setCompletionModalOpen(true);
      }
    } catch (error) {
      setIsSubmitting(false);
      setIsSaving(false);
      setIsPostingAnswer(false);
      console.log('An error occurred while saving the answers. Please contact support if this error persists.');
    }
  };

  return (
    <>
      <Helmet>
        <title>Review Deal Docs</title>
      </Helmet>
      <Container className="med-width-form">
        <Card>
          {(!dealData || !closingDocsReviewDraft) && PartialPageLoader(8)}
          {(dealData && closingDocsReviewDraft) && (
            <CardBody>
              <Row>
                <Col>
                  <h3>Closing Docs Review for {dealInfo.dealName}</h3>
                </Col>
              </Row>
              <Row className="w-100">
                <Col
                  style={{
                    marginTop: spacing(2),
                  }}
                >
                  <DealInformation
                    dealInfo={dealInfo}
                    setDealInfo={setDealInfo}
                    rejectionReason={dealData.stagesCompleted && dealData.stagesCompleted.closingDocsReview === false
                      ? dealData.wireSignOffRejectionReason : ''}
                  />
                </Col>
              </Row>
              <Row className="w-100">
                <Col>
                  <h4 style={{ margin: '30px 0 5px 0' }}>Questions</h4>
                  <Questions
                    questionList={closingDocsReviewDraft}
                    setAllAnswered={setAllAnswered}
                    setClosingDocsReviewDraft={setClosingDocsReviewDraft}
                  />
                </Col>
              </Row>
              <Grid
                container
                direction="row"
                justifyContent="space-between"
                alignItems="center"
                spacing={2}
                mt={2}
                style={{ marginTop: spacing(5) }}
              >
                <Button
                  variant="outlined"
                  disabled={isSubmitting}
                  color="secondary"
                  onClick={() => history.goBack()}
                  style={{ marginLeft: spacing(1.75) }}
                >
                  Back
                </Button>
                {(stageStatus.isEditableByRole && !stageStatus.isCompleted) && (
                  <Grid item>
                    <LoadingButton
                      variant="outlined"
                      disabled={isSubmitting}
                      loading={isSaving}
                      color="secondary"
                      onClick={() => { saveClosingReviewAnswers(); }}
                      style={{ marginRight: spacing(2) }}
                    >
                      Save
                    </LoadingButton>
                    <LoadingButton
                      variant="contained"
                      disabled={!allAnswered || isSubmitting}
                      loading={isPostingAnswers}
                      onClick={() => setModalOpen(true)}
                      color="secondary"
                    >Agree and Confirm
                    </LoadingButton>
                  </Grid>
                )}
              </Grid>
              <StageStatusMessage stageStatus={stageStatus} />
            </CardBody>
          )}
        </Card>

        <ConfirmationModal
          isOpen={modalOpen}
          headerText="Agree and Confirm"
          bodyText="I am attesting, as the Managing
          Partner of the Sponsor Team, that I have reviewed the closing documents associated with this investment
          and fully understand the terms under which this investment is being made. I am also attesting to the
          accuracy of the information provided."
          onConfirm={async () => {
            await saveClosingReviewAnswers(false);
          }}
          onCancel={() => { setModalOpen(false); }}
        />

        <CloseOnConfirmModal modalOpen={completionModalOpen} />
      </Container>
    </>
  );
}

ClosingDocsReview.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.node,
    }).isRequired,
  }).isRequired,
};

export default ClosingDocsReview;
