export default (str) => {
  // create html element
  const textarea = document.createElement('textarea');
  // add text to it
  textarea.value = str;
  // make it readonly
  textarea.setAttribute('readonly', '');
  // don't show it
  textarea.style.position = 'absolute';
  textarea.style.left = '-9999px';
  // add it to the document
  document.body.appendChild(textarea);
  // put it in focus
  textarea.select();
  // copy it
  document.execCommand('copy');
  // remove the html element
  document.body.removeChild(textarea);
};
