/* eslint-disable eqeqeq */
import React, { useState } from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import {
  Button, TextField, Grid, IconButton, FormControl, InputLabel, FormControlLabel, Checkbox, FormHelperText,
} from '@material-ui/core';
import makeStyles from '@material-ui/core/styles/makeStyles';
import DateFnsUtils from '@date-io/date-fns';
import {
  MuiPickersUtilsProvider,
  KeyboardDateTimePicker,
} from '@material-ui/pickers';
import { Editor } from '@tinymce/tinymce-react';
import CloseIcon from 'mdi-react/CloseIcon';
import { DropZoneField } from '../../form/DropZone';
import MaterialSelect from '../../form/MaterialSelect';
import { announcementOptions, TYPES } from '../../../helpers/MultiSelectOptions';
import {
  greyColor, DATE_TIME_PICKER_FORMAT,
} from '../../../services/clubResources';

const { TINY_MCE_KEY } = process.env;

const useStyles = makeStyles(theme => ({
  margin: {
    marginBottom: theme.spacing(1),
    marginLeft: theme.spacing(0),
    marginRight: theme.spacing(0),
    marginTop: theme.spacing(2),
  },
  marginButton: {
    marginBottom: theme.spacing(1),
    marginLeft: theme.spacing(0),
    marginRight: theme.spacing(0),
    marginTop: theme.spacing(4),
  },
}));

const AnnouncementForm = ({
  bindName,
  bindHeadline,
  startDate,
  setStartDate,
  isEndDateActive,
  setIsEndDateActive,
  endDate,
  setEndDate,
  members,
  display,
  setDisplay,
  sendEmail,
  setSendEmail,
  announcementBodyPresent,
  setAnnouncementBodyPresent,
  announcement,
  setAnnouncement,
  announcementPreview,
  setAnnouncementPreview,
  image,
  setImage,
  type,
  setType,
  bindUrl,
  bindVideoUrl,
  clubType,
}) => {
  const classes = useStyles();
  const formErrors = ['Announcement body text is required'];
  const [formValErrors, setFormValErrors] = useState({ bodyText: '' });
  const editorToolbarConfig = {
    height: '300px',
    width: '100%',
    toolbar: 'heading | undo | bold italic | bullist | numlist',
    plugins: [
      'link image',
    ],
    default_link_target: '_blank',
  };
  let refToEditorTextArea = null;
  let refToPreviewTextArea = null;
  const handleSetImage = (file) => {
    setImage(file);
  };
  const handleStartDateChange = (date) => {
    setStartDate(date);
    if (new Date() <= date && sendEmail) {
      setSendEmail(false);
    }
  };
  const handleEndDateChange = (date) => {
    setEndDate(date);
  };
  const handleTypeChange = (aType) => {
    setType(aType);
  };
  const calculateAnnouncementTypes = () => {
    if (clubType != 2 && clubType != 3) {
      return announcementOptions.slice(0, 4);
    }
    if (clubType == 3) {
      return [
        { value: '3', label: 'Impact' },
        { value: '6', label: 'Opportunity Fund News' },
        { value: '7', label: 'Sector Deep Dives' },
        { value: '8', label: 'Club Benefits' },
      ];
    }
    return announcementOptions.slice(1).filter((data, idx) => idx !== 2);
  };
  return (
    <>
      <TextField
        id="new-announcement-name"
        label="Name"
        fullWidth
        margin="normal"
        variant="outlined"
        color="secondary"
        {...bindName}
        helperText="Enter the name of the new announcement."
      />
      <TextField
        id="new-announcement-headline"
        label="Headline"
        fullWidth
        margin="normal"
        variant="outlined"
        color="secondary"
        {...bindHeadline}
        helperText="Enter the headline of the new announcement."
      />
      <FormControl style={{ width: '100%', marginTop: '16px' }}>
        <MaterialSelect
          id="announcement-type"
          label="Type"
          inputVal={type}
          values={calculateAnnouncementTypes()}
          handleChange={handleTypeChange}
          error={false}
          helperText="Select Announcement Type"
        />
      </FormControl>
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <Grid
          container
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <KeyboardDateTimePicker
            autoOk
            ampm
            required
            variant="inline"
            format={DATE_TIME_PICKER_FORMAT}
            margin="normal"
            id="new-announcement-start-date"
            label="Start Date"
            value={startDate}
            onChange={handleStartDateChange}
            KeyboardButtonProps={{
              'aria-label': 'change date',
            }}
          />
          {!isEndDateActive && (
            <Button
              id="new-announcement-add-end-date"
              color="primary"
              variant="contained"
              type="button"
              size="small"
              className={classes.margin}
              onClick={() => setIsEndDateActive(true)}
            >
              Add End Date
            </Button>
          )}
          {isEndDateActive && (
            <div>
              <KeyboardDateTimePicker
                autoOk
                ampm
                minDate={startDate}
                variant="inline"
                format={DATE_TIME_PICKER_FORMAT}
                margin="normal"
                id="new-announcement-end-date"
                label="End Date"
                value={endDate}
                onChange={handleEndDateChange}
                KeyboardButtonProps={{
                  'aria-label': 'change date',
                }}
              />
              <IconButton
                aria-label="delete"
                className={classes.marginButton}
                size="small"
                onClick={() => {
                  handleEndDateChange(null);
                  setIsEndDateActive(false);
                }}
              >
                <CloseIcon />
              </IconButton>
            </div>
          )}
        </Grid>
      </MuiPickersUtilsProvider>
      <FormControlLabel
        className={((members.length > 0 && members.length < 100) ? 'm-3 ml-0' : 'w-100 m-3')}
        style={{ marginBottom: 10, color: greyColor }}
        control={(
          <Checkbox
            id="new-announcement-display"
            checked={display}
            onChange={() => { setDisplay(!display); if (display) { setSendEmail(false); } }}
            value="checkedB"
            color="primary"
          />
        )}
        label="Make announcement visible to club"
      />
      {(members.length > 0 && members.length < 100) && (
        <>
          <FormControlLabel
            disabled={
              !display || (new Date() <= startDate) || (endDate && (moment(new Date()).isAfter(moment(endDate))))
            }
            className="m-3"
            style={{ marginBottom: 10, color: greyColor }}
            control={(
              <Checkbox
                id="new-announcement-email"
                checked={sendEmail}
                onChange={() => { setSendEmail(!sendEmail); }}
                value="checkedE"
                color="primary"
              />
            )}
            label="Send email to members about this announcement"
          />
          {(!display
            || (new Date() <= startDate)
            || (endDate && (moment(new Date()).isAfter(moment(endDate)))))
          && (
            <FormHelperText>Can not send email for announcements that are currently hidden</FormHelperText>
          )}
        </>
      )}
      { announcementBodyPresent ? (
        <h4 className="w-100 mt-3">Announcement Body *</h4>
      ) : (
        <h4 className="w-100 mt-3" style={{ color: 'red' }}>
          Announcement body text is required
        </h4>
      )}
      <Editor
        apiKey={TINY_MCE_KEY}
        label="Announcement"
        id="AnnouncementEditor"
        initialValue={announcement}
        init={editorToolbarConfig}
        onEditorChange={(content, editor) => {
          if (!refToEditorTextArea) {
            refToEditorTextArea = editor;
          }
          setAnnouncement(content);
          if (!content) {
            setFormValErrors({ ...formValErrors, bodyText: formErrors[0] });
            setAnnouncementBodyPresent(false);
          } else {
            setFormValErrors({ ...formValErrors, bodyText: '' });
            setAnnouncementBodyPresent(true);
          }
        }}
      />
      <h4 className="w-100 mt-3">
        Announcement Preview Text
      </h4>
      <Editor
        apiKey={TINY_MCE_KEY}
        label="Announcement Preview"
        id="AnnouncementPreview"
        initialValue={announcementPreview}
        init={editorToolbarConfig}
        onEditorChange={(context, editor) => {
          if (!refToPreviewTextArea) {
            refToPreviewTextArea = editor;
          }
          setAnnouncementPreview(context);
        }}
      />
      <FormControl style={{ width: '100%', marginTop: '16px' }}>
        {!image && (
          <InputLabel className="ml-3" htmlFor="new-announcement-image">Image Upload</InputLabel>
        )}
        <DropZoneField
          value={image}
          name="image"
          onChange={handleSetImage}
          id="new-announcement-image"
        />
      </FormControl>
      <TextField
        id="video-url"
        label="Video Url "
        fullWidth
        margin="normal"
        variant="outlined"
        color="secondary"
        {...bindVideoUrl}
        helperText="Enter url for video player."
      />
      {
        Number(type) === TYPES.EVENTS && (
        <TextField
          id="event-url"
          label="Webinar Url "
          fullWidth
          margin="normal"
          variant="outlined"
          color="secondary"
          {...bindUrl}
          helperText="Enter url for the event."
        />
        )}
    </>
  );
};

AnnouncementForm.propTypes = {
  bindName: PropTypes.objectOf(PropTypes.any).isRequired,
  bindHeadline: PropTypes.objectOf(PropTypes.any).isRequired,
  startDate: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.instanceOf(Date),
  ]).isRequired,
  setStartDate: PropTypes.func.isRequired,
  isEndDateActive: PropTypes.bool.isRequired,
  setIsEndDateActive: PropTypes.func.isRequired,
  endDate: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.instanceOf(Date),
  ]),
  setEndDate: PropTypes.func.isRequired,
  members: PropTypes.arrayOf(PropTypes.object).isRequired,
  display: PropTypes.bool.isRequired,
  setDisplay: PropTypes.func.isRequired,
  sendEmail: PropTypes.bool.isRequired,
  setSendEmail: PropTypes.func.isRequired,
  announcementBodyPresent: PropTypes.bool.isRequired,
  setAnnouncementBodyPresent: PropTypes.func.isRequired,
  announcement: PropTypes.string.isRequired,
  setAnnouncement: PropTypes.func.isRequired,
  announcementPreview: PropTypes.string.isRequired,
  setAnnouncementPreview: PropTypes.func.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  image: PropTypes.any.isRequired,
  setImage: PropTypes.func.isRequired,
  type: PropTypes.string.isRequired,
  setType: PropTypes.func.isRequired,
  bindUrl: PropTypes.objectOf(PropTypes.any).isRequired,
  bindVideoUrl: PropTypes.objectOf(PropTypes.any).isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  clubType: PropTypes.any.isRequired,
};

AnnouncementForm.defaultProps = {
  endDate: null,
};

export default AnnouncementForm;
