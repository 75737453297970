import { useState, useEffect } from 'react';
import moment from 'moment';
import { getAnnouncements } from '../services/bifService';

function useAnnouncements(clubId, forTable = false, refreshKey = 0) {
  const [announcements, setAnnouncements] = useState(null);

  async function fetchData(ignore, abortController) {
    const response = await getAnnouncements(clubId, abortController.signal);
    if (!ignore && response !== 'aborted' && response.announcements) {
      if (forTable) {
        setAnnouncements(response.announcements.map(message => ({
          ...message,
          id: message.id,
          nameField: message.name || '',
          headline_text: message.headline_text || '',
          display: message.display_status === 1 ? 'show' : 'hide',
          startDate: message.start_date ? moment(message.start_date).format('MM-DD-YYYY') : '',
          endDate: message.end_date ? moment(message.end_date).format('MM-DD-YYYY') : '',
        })));
      } else {
        setAnnouncements(response.announcements);
      }
    }
  }

  useEffect(() => {
    const abortController = new AbortController();
    let ignore = false;

    fetchData(ignore, abortController);

    return () => {
      ignore = true;
      abortController.abort();
    };
  }, [clubId, refreshKey]);

  return announcements;
}

export default useAnnouncements;
