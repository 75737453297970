import React, { useContext, forwardRef } from 'react';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import { observer } from 'mobx-react-lite';
import AlertTitle from '@mui/material/AlertTitle';
import { NotificationStoreContext } from '../../store/NotificationStore';
import theme from '../../themes/appTheme';

const Alert = forwardRef((
  props,
  ref,
// eslint-disable-next-line react/jsx-props-no-spreading
) => <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />);

const AVSnackbar = observer(() => {
  const notificationContext = useContext(NotificationStoreContext);
  const { status, message, open } = notificationContext.notification;
  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    notificationContext.closeNotification();
  };

  const generateMessage = () => {
    if (!message && status === 'success') {
      return <>Success</>;
    }

    if (!message && status === 'error') {
      return (
        <>
          An error occurred, please contact support.
        </>
      );
    }

    return (message && (
    <>
      <AlertTitle>{ status.charAt(0).toUpperCase() + status.slice(1) }</AlertTitle>
      { message }
    </>
    )
    );
  };

  const backgroundColor = (status === 'success') ? theme.palette.green.dark : theme.palette.red.dark;
  if (status === 'success') {
    setTimeout(() => {
      notificationContext.closeNotification();
    }, 3000);
  }

  const vertical = status === 'success' ? 'bottom' : 'top';

  return (

    <Snackbar
      anchorOrigin={{ horizontal: 'center', vertical }}
      onClose={handleClose}
      open={open}
    >
      <Alert style={{ backgroundColor }} onClose={handleClose} severity={status || 'info'}>
        { generateMessage() }
      </Alert>
    </Snackbar>
  );
});

export default AVSnackbar;
