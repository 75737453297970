import { SET_DASHBOARD_LABELS } from '../actions/dashboardLabelsActions';

export default function (state = '', action) {
  switch (action.type) {
    case SET_DASHBOARD_LABELS:
      return action.labels;
    default:
      return state;
  }
}
