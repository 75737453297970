import { useGridApiContext } from '@mui/x-data-grid';
import React from 'react';
import EditIcon from '@mui/icons-material/Edit';
import formatCurrencyAsUsd from '../shared/utils/formatCurrencyAsUsd';
import getNumberValue from '../shared/utils/getNumberValue';
import roundToPrecision from '../shared/utils/roundToPrecision';

const checkValidBounds = (value) => value >= 0 && value <= 100;

const checkIfQValuesShouldShow = (params) => !!params.row.qFundId;

const checkIfAValuesShouldShow = (params) => !!params.row.aFundId;

const investmentAmountSortComparator = (a, b) => (getNumberValue(a) > getNumberValue(b) ? 1 : -1);

const percentCellStyles = {
  display: 'flex',
  width: '100%',
  height: '100%',
  flexFlow: 'row nowrap',
  placeContent: 'flex-start space-between',
  alignItems: 'center',
};

function CustomNumberField(params, editable = true) {
  const { id, value, field } = params;
  const apiRef = useGridApiContext();

  const handleChange = (event) => {
    const newValue = event.target.value; // The new value entered by the user
    if (checkValidBounds(Number(newValue))) {
      apiRef.current.setEditCellValue({ id, field, value: newValue });
    }
  };
  return (
    <input
      type="number"
      min="0"
      max="100"
      value={value}
      step="0.00001"
      required
      readOnly={!editable}
      onChange={handleChange}
      style={{
        width: '100%',
        height: '100%',
      }}
    />
  );
}

export default function createPendingInvestmentsTableDefinition(user) {
  return [
    {
      field: 'name',
      headerName: 'Fund Name',
      description: 'The name of the Fund',
      editable: false,
      flex: 1,
      minWidth: 350,
    },

    {
      field: 'aPercent',
      headerName: 'A %',
      description: 'Accredited percentage',
      editable: user.isFinance,
      flex: 1,
      minWidth: 250,
      valueGetter: (params) => {
        params.row.qPercent = roundToPrecision(100 - params.row.aPercent);
        const newValue = roundToPrecision(params.row.aPercent);
        return newValue;
      },
      renderEditCell: (params) => CustomNumberField(params, user.isFinance
        && checkIfQValuesShouldShow(params)
        && checkIfAValuesShouldShow(params)),
      renderCell: (params) => {
        const qValuesShouldShow = checkIfQValuesShouldShow(params);
        const isEditable = user.isFinance && qValuesShouldShow;
        return (checkIfAValuesShouldShow(params) ? (
          <div style={percentCellStyles}>
            <span>
              {Number(roundToPrecision(params.row.aPercent))}
            </span>
            {isEditable && (<EditIcon style={{ opacity: 0.2 }} />)}
          </div>
        ) : '');
      },
    },
    {
      field: 'aAllocation',
      headerName: 'A Amount',
      description: 'Accredited amount',
      editable: false,
      flex: 1,
      minWidth: 250,
      valueGetter: (params) => {
        const newValue = `${formatCurrencyAsUsd((getNumberValue(params.row.investmentAmount) * (params.row.aPercent / 100)))}`;
        return newValue;
      },
      sortComparator: investmentAmountSortComparator,
      renderCell: (params) => (checkIfAValuesShouldShow(params) ? (
        <span>
          {params.value}
        </span>
      ) : ''),
    },
    {
      field: 'qPercent',
      headerName: 'Q %',
      description: 'Qualified percentage',
      sortable: true,
      flex: 1,
      minWidth: 200,
      editable: false,
      renderCell: (params) => (checkIfQValuesShouldShow(params) ? (
        <div style={percentCellStyles}>
          <span>
            {Number(roundToPrecision(params.row.qPercent))}
          </span>
        </div>
      ) : ''),
    },
    {
      field: 'qAllocation',
      headerName: 'Q Amount',
      description: 'Qualified allocation',
      editable: false,
      flex: 1,
      minWidth: 150,
      valueGetter: (params) => {
        const newValue = `${formatCurrencyAsUsd((getNumberValue(params.row.investmentAmount) * (params.row.qPercent / 100)))}`;
        return newValue;
      },
      sortComparator: investmentAmountSortComparator,
      renderCell: (params) => (checkIfQValuesShouldShow(params) ? (
        <span>
          {params.value}
        </span>
      ) : ''),
    },

    {
      field: 'investmentAmount',
      headerName: 'Total Investment Amount',
      description: 'The Total Investment Amount',
      sortable: true,
      flex: 1,
      minWidth: 300,
      valueGetter: (params) => `${formatCurrencyAsUsd((params.row.investmentAmount))}`,
      sortComparator: investmentAmountSortComparator,
    },
  ];
}
