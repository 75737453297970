import {
  REQUEST_ALL_INVESTMENT,
  RECEIVE_ALL_INVESTMENT,
  RECEIVE_ALL_INVESTMENT_ERROR,
} from '../actions/getInvestmentsActions';

export default function (state = {
  isFetching: false,
  investmentArray: [],
  error: null,
}, action) {
  switch (action.type) {
    case REQUEST_ALL_INVESTMENT:
      return Object.assign({}, state, {
        isFetching: true,
        error: action.error,
      });
    case RECEIVE_ALL_INVESTMENT:
      return Object.assign({}, state, {
        isFetching: false,
        investmentArray: action.investments,
        lastUpdated: action.receivedAt,
      });
    case RECEIVE_ALL_INVESTMENT_ERROR:
      return Object.assign({}, state, {
        isFetching: false,
        error: action.error,
      });

    default:
      return state;
  }
}
