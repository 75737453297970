class StringHelper {
  static formatMoney(amountInit, decimalCountInit = 2, decimal = '.', thousands = ',', symbol = '$') {
    let decimalCount = Math.abs(decimalCountInit);
    decimalCount = Number.isNaN(decimalCount) ? 2 : decimalCount;

    const negativeSign = amountInit < 0 ? '-' : '';
    const amount = Math.abs(Number(amountInit) || 0).toFixed(decimalCount);
    const i = Number.parseInt(amount, 10).toString();
    const j = (i.length > 3) ? i.length % 3 : 0;
    const numValue = negativeSign + symbol + (j ? i.substr(0, j) + thousands : '') + i.substr(j).replace(/(\d{3})(?=\d)/g, `$1${thousands}`) + (decimalCount ? decimal + Math.abs(amount - i).toFixed(decimalCount).slice(2) : '');
    return this.filterZeroOut(numValue);
  }

  static formatPercent(amountInit, decimalCountInit, symbol = '%') {
    if (amountInit === null) return '';
    let decimalCount = Math.abs(decimalCountInit);
    decimalCount = Number.isNaN(decimalCount) ? 2 : decimalCount;

    const negativeSign = amountInit < 0 ? '-' : '';
    const amount = (Math.abs(Number(amountInit) || 0) * 100).toFixed(decimalCount);
    return negativeSign + amount.toString() + symbol;
  }

  static filterZeroOut(value) {
    const numericRegex = /[^0-9.-]+/g;
    let numValue;

    if (typeof value === 'string' || value instanceof String) {
      numValue = value.replace(numericRegex, '');
    } else {
      numValue = value;
    }

    return (Number(numValue) > 0 ? value : '');
  }

  static getSubstring(stringValue, startIndex, endIndex) {
    if (stringValue == null) {
      return '';
    }
    if (stringValue.length === 0) {
      return '';
    }
    return stringValue.substring(startIndex, endIndex);
  }

  static convertFromCamelCase = title => title.replace(/([A-Z])/g, match => ` ${match}`).replace(/^./, match => match.toUpperCase());
}

export default StringHelper;
