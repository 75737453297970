import React from 'react';
import { Row, Stack } from '@mui/material';
import PropTypes from 'prop-types';
import { spacing } from '../themes/appTheme';
import { getPresignedUrl } from '../services/apiGateway';

function DocumentViewer({
  id, document, documents, label,
}) {
  const documentsExist = documents && documents.length > 0;

  const openFile = async (documentId) => {
    const body = {
      deal: id,
      filekey: documentId,
    };
    const response = await getPresignedUrl(body);
    window.open(response);
  };

  return (
    <>
      {(document || documentsExist)
        && (
        <h6 style={{ marginTop: spacing(2) }}>
          {label}
        </h6>
        )}
      {(documents || documentsExist) && (
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        mt={1}
        sx={{ flexWrap: 'wrap' }}
      >
        {documentsExist && (
          documents.map((doc) => (
            <h5 key={doc.documentKey} className="col-12 pl-0 pr-0 pb-1" style={{ marginBottom: spacing(1) }}>
              {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events */}
              <span
                className="av-false-anchor"
                role="link"
                tabIndex={-1}
                onClick={async () => {
                  await openFile(doc.documentKey);
                }}
              >
                {doc.documentName}
              </span>
            </h5>
          ))
        )}
        {document && (
          <h5 className="pb-1">
            {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events */}
            <span
              className="av-false-anchor"
              role="link"
              tabIndex={-1}
              onClick={async () => {
                await openFile(document.documentKey);
              }}
            >
              {document.documentName}
            </span>
          </h5>
        )}
      </Stack>
      )}
    </>
  );
}

DocumentViewer.propTypes = {
  id: PropTypes.number.isRequired,
  document: PropTypes.shape({
    documentName: PropTypes.string,
    documentKey: PropTypes.string,
  }),
  documents: PropTypes.arrayOf(
    PropTypes.shape({
      documentName: PropTypes.string,
      documentKey: PropTypes.string,
    }),
  ),
  label: PropTypes.string.isRequired,
};

DocumentViewer.defaultProps = {
  document: null,
  documents: [],
};

export default DocumentViewer;
