import React from 'react';
import PropType from 'prop-types';
import {
  FormControl, InputLabel, Select, MenuItem, FormHelperText,
} from '@material-ui/core';

const MaterialSelect = ({
  id, label, values, inputVal, handleChange, minWidth, error, helperText, required,
}) => {
  const inputLabel = React.useRef(null);
  const [labelWidth, setLabelWidth] = React.useState(0);
  React.useEffect(() => {
    setLabelWidth(inputLabel.current.offsetWidth);
  }, []);

  return (
    <FormControl
      color="secondary"
      variant="outlined"
      className="my-3 mr-3 ml-0"
      style={{ minWidth }}
      error={error}
      required={required}
    >
      <InputLabel ref={inputLabel} id={`${id}-label`}>
        {label}
      </InputLabel>
      <Select
        labelId="demo-simple-select-outlined-label"
        id={id}
        value={inputVal}
        onChange={(e) => { handleChange(e.target.value); }}
        labelWidth={labelWidth}
      >
        {values && values.map(value => (
          <MenuItem key={value.value} value={value.value}>{value.label}</MenuItem>
        ))}

      </Select>
      {helperText && <FormHelperText>{helperText}</FormHelperText> }
    </FormControl>
  );
};

MaterialSelect.propTypes = {
  id: PropType.string.isRequired,
  label: PropType.string.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  values: PropType.array.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  inputVal: PropType.any.isRequired,
  handleChange: PropType.func.isRequired,
  minWidth: PropType.number,
  error: PropType.bool.isRequired,
  helperText: PropType.string.isRequired,
  required: PropType.bool,
};

MaterialSelect.defaultProps = {
  minWidth: 200,
  required: false,
};

export default MaterialSelect;
