export const announcementOptions = [
  { value: '0', label: 'Fund News' },
  { value: '1', label: 'Portfolio News' },
  { value: '2', label: 'Events' },
  { value: '3', label: 'Impact' },
  { value: '4', label: 'CEO Hacks: Learn from Your Peers' },
  { value: '5', label: 'AVTV: Meet Other AV CEOs' },
  { value: '6', label: 'Opportunity Fund News' },
  { value: '7', label: 'Sector Deep Dives' },
  { value: '8', label: 'Club Benefits' },
  { value: '9', label: 'CEO Services' },
];

export const TYPES = {
  FUND_NEWS: 0,
  PORTFOLIO: 1,
  EVENTS: 2,
  IMPACT: 3,
  CEO_CONTENT: 4,
  HELP: 5,
  OPPORTUNITY: 6,
  DEEP_DIVE: 7,
  CLUB_BENEFIT: 8,
  CEO_SERVICES: 9,
};

export const DISPLAY_COUNT = 3;
