import { User } from '@alumni-ventures-group/av-okta-auth';

const userCache = new User();

export function checkIfUserIsTreasuryOrOOIAdmin() {
  return userCache.isOOIAdmin() || userCache.isTreasury();
}

export function checkIfStageIsEditableByRole(stage) {
  return (stage.party === 'OOI' && userCache.isOOI())
  || (stage.party === 'Finance' && userCache.isFinance())
  || (stage.party === 'Treasury' && userCache.isTreasury())
  || (stage.party === 'iTeam' && (userCache.isITeam() || userCache.isOOI()));
}

export default function checkUserPermissions(deal, route = window.location.pathname) {
  const { stagesData } = deal;

  const res = {
    isEditableByRole: false,
    isCompleted: false,
    isRejected: false,
    responsibleParty: '',
  };
  let selectedStage = null;
  for (const stage of stagesData) {
    if (route.includes(stage.link)) {
      selectedStage = stage;
      break;
    }
  }

  if (selectedStage) {
    res.isCompleted = !!selectedStage.confirmationProperty;
    res.isRejected = selectedStage.confirmationProperty === false;
    res.isEditableByRole = checkIfStageIsEditableByRole(selectedStage);
    res.responsibleParty = selectedStage.party;
  }
  return res;
}
