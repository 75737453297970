const regexp = /[^0-9.-]+/g;

export default class InvestmentForPortco {
  constructor(rawInvestment) {
    this.id = {
      label: 'ID',
      type: null,
      readOnly: true,
      value: rawInvestment.id,
    };

    this.interest_rate = {
      label: 'Interest Rate',
      type: 'text',
      readOnly: false,
      value: rawInvestment.interestRate ? Number(rawInvestment.interestRate.replace(regexp, '')) : '',
    };

    this.currentPrice = {
      label: 'Current Price Per Share',
      type: 'text',
      readOnly: false,
      value: rawInvestment.currentPrice,
    };

    this.numShares = {
      label: 'Number of Shares',
      type: 'text',
      readOnly: false,
      value: rawInvestment.numShares,
    };

    this.date_price_change = {
      label: 'Date of Price Change',
      type: 'date',
      readOnly: false,
      value: rawInvestment.pricing_date,
    };
  }
}
