import React from 'react';
import {
  TextField,
} from '@mui/material';
import ReadOnlyTextField from '../shared/components/ReadOnlyTextField';

export default function PostWireSignOffNetSuiteCustomTextField(p) {
  const props = { ...p };
  const { customreadonly, label } = props;
  delete props.customreadonly;
  const value = props.inputProps ? props.inputProps.value : props.defaultValue;
  return customreadonly ? (
    <ReadOnlyTextField value={value} label={label} />
  ) : <TextField {...props} />;
}
