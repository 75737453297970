import { ValidateEmail } from '@alumni-ventures-group/react-shared/lib/models';

// Returns true if input is a String and isn't composed solely of whitespace characters.
// Note that an empty string doesn't count as a whitespace only string.
export const isWhitespaceOnly = (text) => {
  if ((typeof text !== 'string') || (text === '')) {
    return false;
  }
  return /^\s+$/.test(text);
};

export const isValidEmail = (email) => ValidateEmail(email) === undefined;

export const isNonEmptyStr = (text) => text && typeof text === 'string' && !/^\s+$/.test(text);
