import React from 'react';
import Button from '@mui/material/Button';
import formatDateString from '../shared/utils/formatDateString';
import formatDateTimeString from '../shared/utils/formatDateTimeString';
import sortByDate from '../shared/utils/sortAsDate';
import sortAlphanumerically from '../shared/utils/sortAlphanumerically';

export default function createPendingInvestmentsTableDefinition(history) {
  return [
    {
      field: '_',
      headerName: '',
      sortable: false,
      filterable: false,
      renderCell: (params) => (
        <Button
          variant="outlined"
          color="secondary"
          onClick={(e) => {
            e.stopPropagation();
            history.push(`/deal-stages/${params.row.id}`);
          }}
        >View
        </Button>
      ),
      maxWidth: 100,
      minWidth: 100,
      flex: 1,
    },
    {
      field: 'companyName',
      headerName: 'Company Name',
      description: 'The name of the Company',
      editable: false,
      flex: 1,
      minWidth: 300,
    },
    {
      field: 'lastCompletedStage',
      headerName: 'Last Completed Stage',
      description: 'The last completed Stage for this Deal',
      sortable: true,
      flex: 1,
      minWidth: 200,
      sortComparator: sortAlphanumerically,
    },
    {
      field: 'lastCompletedStageDate',
      headerName: 'Last Completed Stage Date',
      description: 'The Date of the last completed Stage for this Deal',
      sortable: true,
      flex: 1,
      minWidth: 250,
      valueGetter: (params) => `${formatDateTimeString(params.row.lastCompletedStageDate)}`,
      sortComparator: sortByDate,
    },
    {
      field: 'status',
      headerName: 'Status',
      description: 'The status for this Deal',
      sortable: true,
      flex: 1,
      minWidth: 200,
      sortComparator: sortAlphanumerically,
    },
    {
      field: 'dateCreated',
      headerName: 'Date Created',
      description: 'The creation date of the Deal',
      sortable: true,
      flex: 1,
      minWidth: 200,
      valueGetter: (params) => `${formatDateString(params.row.dateCreated)}`,
      sortComparator: sortByDate,
    },
  ];
}
