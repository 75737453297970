/* eslint-disable react/no-array-index-key */
import React from 'react';
import PropTypes from 'prop-types';
import { Col } from 'reactstrap';

const TotalDisplay = ({
  title,
  value,
  border,
  colSize,
}) => (
  <Col
    md={colSize !== 'auto' && colSize.md ? colSize.md : ''}
    xl={colSize !== 'auto' && colSize.xl ? colSize.xl : ''}
    lg={colSize !== 'auto' && colSize.lg ? colSize.lg : ''}
    xs={colSize !== 'auto' && colSize.xs ? colSize.xs : ''}
    className={border ? 'pinline-border' : ''}
  >
    <div className="total-widget">
      <div className="total-widget__top-line total-widget__top-line">
        <p className="total-widget__total-value">{value}</p>
      </div>
      <div className="total-widget__title">
        <h5>{title}</h5>
      </div>
    </div>
  </Col>
);

TotalDisplay.propTypes = {
  title: PropTypes.string,
  // eslint-disable-next-line react/forbid-prop-types
  value: PropTypes.any,
  border: PropTypes.bool,
  // eslint-disable-next-line react/forbid-prop-types
  colSize: PropTypes.any,
};

TotalDisplay.defaultProps = {
  title: '',
  value: '$0.00',
  border: false,
  colSize: {
    xs: 12,
    md: 12,
    lg: 6,
    xl: 3,
  },
};

export default TotalDisplay;
