import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import ReactModal from 'react-modal';
import PropTypes from 'prop-types';
import ProfileSettingsModal from './ProfileSettingsModal';
import MessagePreviewModal from './MessagePreviewModal';
import TwoStepModal from './TwoStepModal';

const MODAL_COMPONENTS = {
  profileSettingsModal: ProfileSettingsModal,
  messagePreviewModal: MessagePreviewModal,
  twoStepModal: TwoStepModal,
};

const mapStateToProps = state => ({
  ...state.modal,
});

function ModalContainer({ modalProps = null, modalType = null }) {
  const [modalIsOpen, setModalIsOpen] = useState(false);

  useEffect(() => {
    if (modalProps && (modalProps.open !== modalIsOpen)) {
      setModalIsOpen(modalProps.open);
    }
  }, [modalProps]);

  function closeModal() {
    setModalIsOpen(false);
  }

  const SpecifiedModal = MODAL_COMPONENTS[modalType];

  return (
    <>
      {
        modalType
          ? (
            <ReactModal
              isOpen={modalIsOpen}
              onRequestClose={closeModal}
              contentLabel="Example Modal"
              ariaHideApp={false}
              overlayClassName="modal fade show"
              bodyOpenClassName="modal-open"
              className="modal-dialog fdo-modal-dialog modal-dialog-centered fdo-roboto"
            >
              <SpecifiedModal
                closeModal={closeModal}
                initialValues={{ un: modalProps.un, pw: modalProps.pw }}
                {...modalProps}
              />
            </ReactModal>
          ) : null
      }
    </>
  );
}

ModalContainer.propTypes = {
  modalProps: PropTypes.objectOf(PropTypes.any),
  modalType: PropTypes.string,
};

ModalContainer.defaultProps = {
  modalProps: null,
  modalType: '',
};

export default connect(mapStateToProps, null)(ModalContainer);
