import components from './components';
import helpers from './helpers';
import models from './models';
import services from './services';
import { OktaAuth } from '@okta/okta-auth-js';

export class AvOktaAuth {
  constructor(oktaConfig, env) {
    this.oktaAuth = new OktaAuth(oktaConfig);
    this.env = String(env).toLowerCase();
    this.envCache = new models.Cache('env');
    this.envCache.set(this.env);
  };

  getEnv = () => this.env;

  getOktaInstance = () => this.oktaAuth;

  getAuthServicePath = () => {
    if (this.env === 'dev' || this.env === 'local') {
      return 'process.env.AUTH_SERVICE_PATH_DEV';
    } else if (this.env === 'prod') {
      return 'process.env.AUTH_SERVICE_PATH_PROD';
    } else {
      console.error('Invalid AV Okta Initialization');
    }
  }
};

export const {
  Login,
  LoginCallback,
  AvOktaRouter,
  UserLogout
} = components;

export const {
  loginUserWithAccessToken,
  updateAuthentication
} = helpers;

export const {
  Cache,
  User,
  AuthStatus,
  Token
} = models;

export const {
  checkTokenValidity,
  getHeaders,
  logoutUser,
  loginAVAdminUser,
  refreshAuthToken,
  refreshAVAdminUserToken
} = services;
