
export default function GetAnswersAsCsv(scoreCards) {
  if (!scoreCards) return [];

  const headers = [
    'Deal Name',
    'Investor Name',
    'Club Name',
    'Type',
    'Version',
    'Max Score',
    'Total',
    'Deal Sub-Total',
    'Lead Investor Sub-Total',
    'Lead Syndicate Sub-Total',
    'Company Sub-Total',
    'Team Sub-Total',
    'Bonus Sub-Total',
    'Date',
  ];

  const rows = [];
  rows.push(headers);
  const emptyFiller = '-';

  scoreCards.forEach((d) => {
    const temp = [];

    temp.push(d.dealName);
    temp.push(d.userName);
    temp.push(d.clubName);
    temp.push(d.type);
    temp.push(d.version);
    temp.push(d.total);
    temp.push(d.score);

    const answers = Object.values(d.answers);

    const deal = answers.find(i => i.categoryName === 'Deal');
    if (deal) {
      temp.push(deal.totalUserScore);
    } else {
      temp.push(emptyFiller);
    }

    const leadInvestor = answers.find(i => i.categoryName === 'Lead Investor');
    if (leadInvestor) {
      temp.push(leadInvestor.totalUserScore);
    } else {
      temp.push(emptyFiller);
    }

    const leadSyndicate = answers.find(i => i.categoryName === 'Lead Syndicate');
    if (leadSyndicate) {
      temp.push(leadSyndicate.totalUserScore);
    } else {
      temp.push(emptyFiller);
    }

    const company = answers.find(i => i.categoryName === 'Company');
    if (company) {
      temp.push(company.totalUserScore);
    } else {
      temp.push(emptyFiller);
    }

    const team = answers.find(i => i.categoryName === 'Team');
    if (team) {
      temp.push(team.totalUserScore);
    } else {
      temp.push(emptyFiller);
    }

    const bonus = answers.find(i => i.categoryName === 'Bonus');
    if (bonus) {
      temp.push(bonus.totalUserScore);
    } else {
      temp.push(emptyFiller);
    }

    temp.push(d.create_date);
    rows.push(temp);
  });

  return rows;
}
