import React, { PureComponent } from 'react';
import { Col } from 'reactstrap';
import { uid } from 'react-uid';
import PropTypes from 'prop-types';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TablePagination from '@material-ui/core/TablePagination';
import MatTableHead from './MatTableHead';
import FDOContactsMatTableRow from './FDOContactsMatTableRow';
import comparer from '../../../../helpers/FDOCompareHelper';


function createData(item) {
  return { ...item };
}

class FDOContactsMatTable extends PureComponent {
  static propTypes = {
    // eslint-disable-next-line react/forbid-prop-types
    dataArray: PropTypes.array.isRequired,
    // eslint-disable-next-line react/forbid-prop-types
    columnTitles: PropTypes.array.isRequired,
    selectable: PropTypes.bool,
    onContactsSelection: PropTypes.func.isRequired,
    rowsPerPage: PropTypes.number,
    page: PropTypes.number,
  };

  static defaultProps = {
    selectable: false,
    rowsPerPage: 10,
    page: 0,
  };

  constructor(props) {
    super(props);
    const { dataArray, rowsPerPage, page } = this.props;

    const rendRowsPerPage = Number((sessionStorage.getItem('fdo_rowsPerPage') || rowsPerPage));
    const rendPage = Number((sessionStorage.getItem('fdo_page') || page));

    this.state = {
      order: 'asc',
      orderBy: 'id',
      selected: new Map([]),
      data: dataArray.map(dataPoint => createData(dataPoint)),
      page: rendPage,
      rowsPerPage: rendRowsPerPage,
    };
  }

  componentDidUpdate(prevProps) {
    const { dataArray } = this.props;
    if (JSON.stringify(prevProps.dataArray) !== JSON.stringify(dataArray)) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState(prevState => ({
        ...prevState,
        data: dataArray.map(dataPoint => createData(dataPoint)),
      }));
    }
  }

  handleRequestSort = (event, property) => {
    const orderBy = property;
    let order = 'desc';
    const { orderBy: stateOrderBy, order: stateOrder } = this.state;

    if (stateOrderBy === property && stateOrder === 'desc') { order = 'asc'; }

    this.setState({ order, orderBy });
  };

  handleSelectAllClick = (_event, checked) => {
    const { onContactsSelection } = this.props;

    const {
      data, order, orderBy, rowsPerPage, page, selected,
    } = this.state;

    let wasEverythingChecked = true;
    if (!selected || !selected.size) {
      wasEverythingChecked = false;
    }

    data.sort(comparer(orderBy, order))
      .slice(page * rowsPerPage, (page * rowsPerPage) + rowsPerPage)
      // eslint-disable-next-line
      .map((d) => {
        if (!selected || !selected.has(d.id)) {
          wasEverythingChecked = false;
        }
        return d;
      });

    let prChecked = checked;
    if (wasEverythingChecked) {
      prChecked = false;
    }

    if (prChecked) {
      const selectedArray = [];
      const newSelected = new Map();
      data.sort(comparer(orderBy, order))
        .slice(page * rowsPerPage, (page * rowsPerPage) + rowsPerPage)
        // eslint-disable-next-line
        .map((d) => {
          newSelected.set(d.id, true);
          selectedArray.push(d.id);
          return d;
        });
      this.setState({ selected: newSelected });
      onContactsSelection(selectedArray);

      return;
    }
    this.setState({ selected: new Map([]) });
    onContactsSelection([]);
  };

  handleClick = (_event, id) => {
    const { onContactsSelection } = this.props;
    const { selected } = this.state;
    const newSelected = new Map(selected);
    const newSelectedIds = [];
    const value = newSelected.get(id);
    let isActive = true;
    if (value) {
      isActive = false;
    }
    newSelected.set(id, isActive);
    newSelected.forEach((isIdSelected, contactId) => {
      if (isIdSelected === true) {
        newSelectedIds.push(contactId);
      }
    });
    this.setState({ selected: newSelected });
    onContactsSelection(newSelectedIds);
  };

  handleChangePage = (_event, page) => {
    sessionStorage.setItem('fdo_page', page);
    this.setState({ page });
  };

  handleChangeRowsPerPage = (event) => {
    const v = Number(event.target.value);
    sessionStorage.setItem('fdo_rowsPerPage', v);
    this.setState({ rowsPerPage: v });
  };

  isSelected = (id) => {
    const { selected } = this.state;
    return !!selected.get(id);
  };

  render() {
    const {
      columnTitles, selectable,
    } = this.props;
    const {
      data, order, orderBy, selected, rowsPerPage, page,
    } = this.state;

    return (
      <Col
        style={{ padding: '0' }}
      >
        <div className="material-table__wrap fdo-roboto">
          <Table
            className="material-table fdo-contacts-material-table"
          >
            <MatTableHead
              rows={columnTitles}
              numSelected={[...selected].filter(el => el[1]).length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={this.handleSelectAllClick}
              onRequestSort={this.handleRequestSort}
              rowCount={data.length}
              selectable={selectable}
            />
            <TableBody>
              {data
                .sort(comparer(orderBy, order))
                .slice(page * rowsPerPage, (page * rowsPerPage) + rowsPerPage)
                .map((d) => {
                  const isSelected = this.isSelected(d.id);
                  return (
                    <FDOContactsMatTableRow
                      rowData={d}
                      columnTitles={columnTitles}
                      handleClick={this.handleClick}
                      selectable={selectable}
                      key={uid(d)}
                      isSelected={isSelected}
                    />
                  );
                })}
            </TableBody>
          </Table>
        </div>
        <TablePagination
          component="div"
          className="material-table__pagination"
          count={data.length}
          rowsPerPage={rowsPerPage}
          page={page}
          backIconButtonProps={{ 'aria-label': 'Previous Page' }}
          nextIconButtonProps={{ 'aria-label': 'Next Page' }}
          onPageChange={this.handleChangePage}
          onRowsPerPageChange={this.handleChangeRowsPerPage}
          rowsPerPageOptions={[5, 10, 50, 100]}
          dir="ltr"
          SelectProps={{
            inputProps: { 'aria-label': 'rows per page' },
            native: true,
          }}
        />
      </Col>
    );
  }
}

export default FDOContactsMatTable;
