export default function exportGridValues(currentGridRef, modelProperties) {
  const results = [];
  const rowIds = Object.values(currentGridRef.getAllRowIds());
  rowIds.forEach((rowId) => {
    const row = {};
    modelProperties.forEach((prop) => {
      row[prop] = currentGridRef.getCellValue(rowId, prop);
    });
    results.push(row);
  });
  return results;
}
