import React, { useState } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import PropTypes from 'prop-types';
import { CSVLink } from 'react-csv';
import Grid from '@material-ui/core/Grid';
import DateFnsUtils from '@date-io/date-fns';
import CircularProgress from '@material-ui/core/CircularProgress';
import { makeStyles } from '@material-ui/core/styles';
import moment from 'moment';
import Checkbox from '@material-ui/core/Checkbox';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import { downloadCsvBulkImport } from '../../services/bifService';

const useStyles = makeStyles({
  downloadLinkArea: {
    margin: '0px',
    padding: '5px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  datePickersContainer: {
    display: 'flex',
    marginBottom: '10px',
  },
  datePickers: {
    padding: '5px',
  },
});


const CsvBulkImportDialog = ({
  fundId, open, setOpen,
}) => {
  const [csvContent, setCsvContent] = useState(null);
  const [requestSent, setRequestSent] = useState(false);
  const [startDate, setStartDate] = useState(moment().startOf('month'));
  const [endDate, setEndDate] = useState(new Date());
  const [allRecords, setAllRecords] = useState(false);
  const [dateFilter, setDateFilter] = useState('date_created');
  const classes = useStyles();
  const timeFormat = 'MM/dd/yyyy';

  const handleChangeForDateFilter = (event) => {
    setDateFilter(event.target.value);
  };

  const handleCheckBoxChange = (event) => {
    setAllRecords(event.target.checked);
  };

  const handleStartDateChange = (date) => {
    setStartDate(date);
  };

  const handleEndDateChange = (date) => {
    setEndDate(date);
  };

  const closeAndCleanUp = () => {
    setOpen(false);
    setCsvContent(null);
    setRequestSent(false);
    setAllRecords(false);
    setDateFilter('date_created');
  };

  async function startDownload() {
    setRequestSent(true);
    let params = `id=${fundId}`;
    if (!allRecords) {
      const momentTimeFormat = 'YYYY-MM-DD';
      const startD = `&startDate=${encodeURIComponent(moment(startDate).utc().format(momentTimeFormat))}`;
      const endD = `&endDate=${encodeURIComponent(moment(endDate).utc().format(momentTimeFormat))}`;
      const filterD = `&filterDate=${encodeURIComponent(dateFilter)}`;
      params = `${params}${startD}${endD}${filterD}`;
    }
    const response = await downloadCsvBulkImport(params);
    setCsvContent(response);
  }

  return (
    <div>
      <Dialog
        open={open}
        onClose={closeAndCleanUp}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle id="responsive-dialog-title">Report For Bulk Import</DialogTitle>
        <DialogContent>

          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <Grid
              container
              direction="column"
              justifyContent="flex-start"
              alignItems="flex-start"
            >

              {
               !requestSent
               && (
               <div>
                 <Checkbox
                   checked={allRecords}
                   color="primary"
                   onChange={handleCheckBoxChange}
                   inputProps={{ 'aria-label': 'All Records' }}
                   label="All Records"
                 /> <span>All Records</span>
               </div>
               )
             }

              {
                (!allRecords && !requestSent)
                && (
                <>
                  <div className={classes.datePickersContainer}>
                    <div className={classes.datePickers}>
                      <KeyboardDatePicker
                        margin="normal"
                        id="startDatePicker-dialog"
                        label="Start Date"
                        format={timeFormat}
                        value={startDate}
                        onChange={handleStartDateChange}
                        KeyboardButtonProps={{
                          'aria-label': 'Start Date',
                        }}
                      />
                    </div>

                    <div className={classes.datePickers}>
                      <KeyboardDatePicker
                        margin="normal"
                        id="endDatePicker-dialog"
                        label="End Date"
                        format={timeFormat}
                        value={endDate}
                        onChange={handleEndDateChange}
                        KeyboardButtonProps={{
                          'aria-label': 'End Date',
                        }}
                      />
                    </div>
                  </div>
                  <FormControl>
                    <InputLabel id="filter-on-date-select-label">Filter on Date</InputLabel>
                    <Select
                      labelId="filter-on-date-select-label"
                      id="filter-on-select"
                      value={dateFilter}
                      onChange={handleChangeForDateFilter}
                    >
                      <MenuItem value="date_created">Create Date</MenuItem>
                      <MenuItem value="completion_date">Complete Date</MenuItem>
                      <MenuItem value="fund_transfer_date">Fund Transfer Date</MenuItem>
                    </Select>
                  </FormControl>
                </>
                )
            }
            </Grid>
          </MuiPickersUtilsProvider>

          <div className={classes.downloadLinkArea}>
            {csvContent
              ? (
                <CSVLink role="contentinfo" filename="Report for Bulk Import.csv" data={csvContent}>
                  <h3>Download</h3>
                </CSVLink>
              )
              : (requestSent && <CircularProgress />)
            }
          </div>

        </DialogContent>
        <DialogActions>
          {!requestSent
            && (
            <Button autoFocus onClick={startDownload} variant="contained" color="primary">
              Generate
            </Button>
            )
            }
          <Button onClick={closeAndCleanUp} variant="contained">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

CsvBulkImportDialog.propTypes = {
  setOpen: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  fundId: PropTypes.string.isRequired,
};

export default CsvBulkImportDialog;
