import React from 'react';
import DisplayField from './DisplayField';
import EditField from './EditField';

const withEither = (conditionalRenderingFn, EitherComponent) => Component => props => (conditionalRenderingFn(props)
  ? <EitherComponent {...props} />
  : <Component {...props} />);

const isViewConditionFn = props => props.mode === 'view';

const withEditContionalRendering = withEither(isViewConditionFn, DisplayField);

const EditInline = withEditContionalRendering(EditField);

export default EditInline;
