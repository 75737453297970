import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Button, Modal, Backdrop, Fade, Grid,
} from '@material-ui/core';
import makeStyles from '@material-ui/core/styles/makeStyles';

const useStyles = makeStyles(theme => ({
  margin: {
    marginBottom: theme.spacing(1),
    marginLeft: theme.spacing(0),
    marginRight: theme.spacing(0),
    marginTop: theme.spacing(2),
  },
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    color: '#646777',
    '& h2': {
      marginTop: 20,
    },
    '& p': {
      fontSize: 16,
      marginBottom: 20,
      marginTop: 20,
    },
  },
}));

const AnnouncementFormButtonGrid = ({
  fullWidth,
  cancelButton,
  existingAnnouncement,
  handleSkip,
  preview,
  announcement,
  setPreview,
  submitting,
  admin,
}) => {
  const [open, setOpen] = useState(false);
  const classes = useStyles();
  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  return (
    <Grid
      className="mt-2"
      container
      direction="row"
      justifyContent="space-between"
      alignItems="center"
    >
      {(fullWidth && !preview && admin) && ( // fullWidth is used in admin mode. we don't need cancel button there
        <Button
          id="new-announcement-cancel"
          variant="contained"
          type="button"
          size="large"
          className={classes.margin}
          onClick={handleOpen}
        >
          {cancelButton}
        </Button>
      )}
      {!fullWidth && ( // fullWidth is used in admin mode. we don't need cancel button there
        <Button
          id="new-announcement-cancel"
          variant="contained"
          type="button"
          size="large"
          className={classes.margin}
          onClick={handleOpen}
        >
          {cancelButton}
        </Button>
      )}
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <div className={classes.paper}>
            <h2 id="transition-modal-title">Are You Sure?</h2>
            <p id="transition-modal-description">
              {
                existingAnnouncement
                  ? 'Leaving this form cancel changes to the announcement you are working on.'
                  : 'Leaving this form will delete the announcement you are working on.'
              }
            </p>
            <Grid
              container
              direction="row"
              justifyContent="space-between"
              alignItems="center"
            >
              <Button
                variant="contained"
                type="button"
                size="medium"
                // color=""
                className={classes.margin}
                onClick={handleClose}
                id="new-announcement-cancel"
              >
                No
              </Button>
              <Button
                variant="contained"
                type="button"
                size="medium"
                color="primary"
                className={classes.margin}
                onClick={handleSkip}
                id="new-announcement-confirm"
              >
                Yes
              </Button>
            </Grid>
          </div>
        </Fade>
      </Modal>
      {!preview && (
        <Button
          disabled={!announcement || announcement.length === 0}
          variant="contained"
          type="button"
          size="large"
          color="primary"
          className={classes.margin}
          value="preview"
          id="new-announcement-preview"
          onClick={() => setPreview(true)}
        >
          Preview Announcement
        </Button>
      )}
      {preview && (
        <>
          <Button
            variant="outlined"
            type="button"
            size="medium"
            color="primary"
            className={classes.margin}
            value="edit"
            onClick={() => setPreview(false)}
            id="new-announcement-edit"
          >
            Edit
          </Button>
          <Button
            disabled={submitting}
            variant="contained"
            type="submit"
            size="medium"
            color="primary"
            className={classes.margin}
            value="submit"
            id="new-announcement-submit"
          >
            {submitting ? 'Submitting' : 'Submit'}
          </Button>
        </>
      )}
    </Grid>
  );
};

AnnouncementFormButtonGrid.propTypes = {
  fullWidth: PropTypes.bool.isRequired,
  cancelButton: PropTypes.string.isRequired,
  existingAnnouncement: PropTypes.objectOf(PropTypes.any),
  handleSkip: PropTypes.func.isRequired,
  preview: PropTypes.bool.isRequired,
  announcement: PropTypes.string.isRequired,
  setPreview: PropTypes.func.isRequired,
  submitting: PropTypes.bool.isRequired,
  admin: PropTypes.bool.isRequired,
};

AnnouncementFormButtonGrid.defaultProps = {
  existingAnnouncement: null,
};

export default AnnouncementFormButtonGrid;
