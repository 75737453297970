import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { Grid, Button } from '@material-ui/core';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { showSnackbarInfo } from '../../../../redux/actions/snackbarActions';
import { addErrorMessage } from '../../../../redux/actions/errorActions';
import AnnouncementFormWrapper from './AnnouncementFormWrapper';
import DeleteAnnouncementModal from './DeleteAnnouncementModal';
import { deleteAnnouncement, sendAnnouncement } from '../../../services/bifService';
import MaterialTable from '../../table/materialTable';
import EmailAnnouncementModal from './EmailAnnouncementModal';
import copyToClipboard from '../../../helpers/copyToClipboard';

const columnTitles = [
  {
    id: 'nameField',
    label: 'Name',
    alignment: true,
  },
  {
    id: 'headline_text',
    label: 'Headline',
    alignment: true,
  },
  {
    id: 'display',
    label: 'Display Status',
    alignment: true,
  },
  {
    id: 'startDate',
    label: 'Start Date',
  },
  {
    id: 'endDate',
    label: 'End Date',
  },
  {
    id: 'type_as_string',
    label: 'Type',
  },
];

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    marginBottom: theme.spacing(5),
    marginTop: theme.spacing(5),
    color: '#646777',
  },
  textField: {
    marginBottom: theme.spacing(3),
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    width: 200,
  },
  margin: {
    marginBottom: theme.spacing(1),
    marginLeft: theme.spacing(0),
    marginRight: theme.spacing(0),
    marginTop: theme.spacing(2),
  },
  marginButton: {
    marginBottom: theme.spacing(1),
    marginLeft: theme.spacing(0),
    marginRight: theme.spacing(0),
    marginTop: theme.spacing(4),
  },
  chip: {
    margin: theme.spacing(0.5),
  },
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    color: '#646777',
    '& h2': {
      marginTop: 20,
    },
    '& p': {
      fontSize: 16,
      marginBottom: 20,
      marginTop: 20,
    },
  },
}));

const AnnouncementsTable = ({
  isEditing, setIsEditing, announcements, setRefreshKey, handleChange, club, fullWidth, members,
}) => {
  const dispatch = useDispatch();
  const [announcementForEdit, setAnnouncementForEdit] = useState(null);
  const [announcementToEmail, setAnnouncementToEmail] = useState(null);
  const [openDelete, setOpenDelete] = useState(false);
  const [openEmail, setOpenEmail] = useState(false);
  const [loadingEmail, setLoadingEmail] = useState(false);
  const [announcementToDelete, setAnnouncementToDelete] = useState(null);
  const [actionConfirmed, setActionConfirmed] = useState(false);

  const handleCloseDelete = () => {
    setOpenDelete(false);
    setAnnouncementToDelete(null);
  };

  const handleCloseEmail = () => {
    setOpenEmail(false);
    setAnnouncementToEmail(null);
  };

  const handleEdit = (announcementId) => {
    const response = announcements.find(
      obj => announcementId.get(obj.id) && obj,
    );
    setAnnouncementForEdit(response);
    setIsEditing(true);
  };

  const handleDelete = (announcementId) => {
    const deleteArray = announcements.filter(announcement => (
      announcementId.has(announcement.id) && announcementId.get(announcement.id) === true
    ));
    setAnnouncementToDelete(deleteArray.map(message => message.id));
    setOpenDelete(true);
  };

  const handleSelectForEmailSend = (announcementId) => {
    const response = {
      announcement: announcements.find(
        obj => announcementId.get(obj.id) && obj,
      ),
      clubId: club.id,
    };
    setAnnouncementToEmail(response);
    setOpenEmail(true);
  };

  const confirmEmail = async () => {
    if (announcementToEmail) {
      setLoadingEmail(true);
      const res = await sendAnnouncement({
        id: announcementToEmail.announcement.id, clubId: announcementToEmail.clubId,
      });
      setOpenEmail(false);
      if (res) {
        dispatch(showSnackbarInfo('New announcement email sent'));
      } else {
        dispatch(addErrorMessage({ message: 'Unable to send email notification' }));
      }
      setLoadingEmail(false);
    }
  };

  const confirmDelete = async () => {
    if (announcementToDelete) {
      const res = await deleteAnnouncement({ ids: announcementToDelete });
      setOpenDelete(false);
      setActionConfirmed(true);
      if (res && res.success) {
        setRefreshKey(oldkey => oldkey + 1);
        dispatch(showSnackbarInfo('Announcement Deleted', true));
      } else {
        dispatch(addErrorMessage({ message: 'Announcement Not Found' }));
      }
    }
  };

  const handleSetIsEditing = () => {
    setIsEditing(false);
    setAnnouncementForEdit(null);
    setRefreshKey(oldKey => oldKey + 1);
  };

  const viewPublishedAnnouncement = (selected) => {
    const link = `${process.env.AVG_API_PATH}/investor/login/${selected.fk_club_id}?open=${selected.id}`;
    copyToClipboard(link);
    dispatch(showSnackbarInfo(`Announcement Auto-Open Link Copied To Clipboard: ${link}`, true));
  };

  const classes = useStyles();

  return (
    <>
      {((announcements && announcements.length !== 0) && !isEditing) && (
        <MaterialTable
          columns={columnTitles}
          dataArray={announcements}
          title="Announcements"
          selectable
          edit={handleEdit}
          deleteRow={handleDelete}
          sendEmail={(members.length > 0 && members.length < 100) ? handleSelectForEmailSend : () => null}
          preSort={{ field: 'create_date', order: 'desc' }}
          useConfirmModal
          actionConfirmed={actionConfirmed}
          viewPublishedAnnouncement={viewPublishedAnnouncement}
          setActionConfirmed={setActionConfirmed}
        />
      )}
      {(announcements && !announcements.length && !isEditing) && (
        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="center"
        >
          <Grid item>
            <Button className="my-5" color="secondary" variant="contained" onClick={() => handleChange(0)}>
              Add Announcement
            </Button>
          </Grid>
        </Grid>
      )}
      {(announcementForEdit && isEditing) && (
        <AnnouncementFormWrapper
          existingAnnouncement={announcementForEdit}
          handleSetIsEditing={handleSetIsEditing}
          club={club}
          cancelButton="Cancel"
          fullWidth={fullWidth}
          members={members}
        />
      )}
      <DeleteAnnouncementModal
        classes={classes}
        open={openDelete}
        handleClose={handleCloseDelete}
        announcementToDelete={announcementToDelete}
        confirmDelete={confirmDelete}
      />
      {openEmail && (
        <EmailAnnouncementModal
          classes={classes}
          announcement={announcementToEmail.announcement}
          open={openEmail}
          loadingEmail={loadingEmail}
          handleClose={handleCloseEmail}
          confirmEmail={confirmEmail}
        />
      )}
    </>
  );
};

AnnouncementsTable.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  announcements: PropTypes.array.isRequired,
  isEditing: PropTypes.bool.isRequired,
  setIsEditing: PropTypes.func.isRequired,
  setRefreshKey: PropTypes.func.isRequired,
  handleChange: PropTypes.func.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  club: PropTypes.any.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  members: PropTypes.array.isRequired,
  fullWidth: PropTypes.bool.isRequired,
};

export default AnnouncementsTable;
