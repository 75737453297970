import 'bootstrap/dist/css/bootstrap.css';
import '@alumni-ventures-group/react-shared/lib/index.css';
import { createTheme } from '@mui/material/styles';
import { services } from '@alumni-ventures-group/react-shared';
import './app.css';

const baseTheme = createTheme();
const { palette: { augmentColor } } = baseTheme;
const createColor = (mainColor) => augmentColor({ color: { main: mainColor } });

const { blueColor, maroonColor } = services.ClubResources;
maroonColor.light = '#8866a3';

const red = { light: '#A15E62', medium: '#8B383B', dark: '#862027' };
const green = { light: '#8EB38A', medium: '#7BA975', dark: '#468048' };


const createTypographyOverrides = (objValue, properties = []) => {
  const overrides = {};
  // eslint-disable-next-line no-unused-vars
  for (const property of properties) {
    overrides[property] = objValue;
  }
  return overrides;
};

const baseFontFamily = "'Inter', 'Roboto', sans-serif";
const palette = {
  primary: blueColor,
  secondary: maroonColor,
  red,
  green,
  danger: createColor('#AC2929'),
};

const zIndex = {
  modal: 1450,
};

// Typography options below get overridden by react-shared's scss file.
// See react-shared/src/scss/base/scaffolding.scss
const typography = {
  ...createTypographyOverrides({
    fontFamily: "'Archivo', 'Roboto', sans-serif",
  }, ['h1', 'h2', 'h3', 'h4', 'h5', 'h6']),
  ...createTypographyOverrides({
    fontFamily: baseFontFamily,
  }, ['root', 'body2', 'body1', 'caption', 'button', 'subtitle1', 'subtitle2', 'overline']),
};

const MuiButton = {
  styleOverrides: {
    containedSecondary: {
      '&:hover': {
        backgroundColor: maroonColor.light,
      },
    },
  },
};

const MuiAutocomplete = {
  styleOverrides: {
    root: {
      '.MuiAutocomplete-endAdornment': {
        top: 'inherit',
      },
    },
  },
};

const MuiOutlinedInput = {
  styleOverrides: {
    root: {
      width: '100%',
      '&.Mui-focused': {
        '.MuiOutlinedInput-notchedOutline': {
          borderColor: maroonColor.main,
        },
      },
    },
  },
};

const MuiDataGrid = {
  styleOverrides: {
    cell: {
      '&:focus': {
        outline: `solid ${maroonColor.main} 1px`,
      },
    },
  },
};

const MuiCheckbox = {
  styleOverrides: {
    root: {
      '&.MuiCheckbox-colorPrimary.Mui-checked': {
        color: maroonColor.main,
      },
    },
  },
};

const MuiRadio = {
  styleOverrides: {
    colorPrimary: {
      '&.Mui-checked': {
        color: maroonColor.main,
      },
    },
  },
};

const MuiInputLabel = {
  styleOverrides: {
    root: {
      '&.Mui-focused': {
        color: maroonColor.dark,
      },
    },
  },
};

const MuiTextField = {
  styleOverrides: {
    root: {
      '&:hover': {
        '.MuiInputLabel-root': {
          color: 'rgba(0, 0, 0, 0.87)',
          '&.Mui-focused': {
            color: maroonColor.dark,
          },
        },
      },
    },
  },
};

const MuiInput = {
  styleOverrides: {
    underline: {
      '&.Mui-focused:after': {
        borderBottom: `2px solid ${maroonColor.main}`,
      },
    },
  },
};

const MuiPickersDay = {
  styleOverrides: {
    root: {
      '&.Mui-selected': {
        backgroundColor: maroonColor.main,
        '&:hover': {
          backgroundColor: maroonColor.dark,
        },
      },
      '&.MuiPickersDay-today': {
        borderColor: maroonColor.main,
      },
    },
  },
};

const MuiYearPicker = {
  styleOverrides: {
    root: {
      '.PrivatePickersYear-yearButton': {
        '&.Mui-selected': {
          backgroundColor: maroonColor.main,
          '&:hover': {
            backgroundColor: maroonColor.dark,
          },
        },
      },
    },
  },
};

const MuiSvgIcon = {
  styleOverrides: {
    root: ({ theme }) => ({
      color: theme.palette.text.secondary,
    }),
  },
};

const components = {
  MuiAutocomplete,
  MuiButton,
  MuiCheckbox,
  MuiDataGrid,
  MuiInput,
  MuiInputLabel,
  MuiOutlinedInput,
  MuiPickersDay,
  MuiRadio,
  MuiTextField,
  MuiYearPicker,
};

const theme = createTheme(baseTheme, {
  palette,
  zIndex,
  typography,
  components,
});

export default theme;

export const { spacing } = theme;
