import React from 'react';
import PropTypes from 'prop-types';
import {
  Modal, Grid, Button, Fade, Backdrop,
} from '@material-ui/core';

const DeleteAnnouncementModal = ({
  classes, open, handleClose, announcementToDelete, confirmDelete,
}) => (
  <Modal
    aria-labelledby="transition-modal-title"
    aria-describedby="transition-modal-description"
    className={classes.modal}
    open={open}
    onClose={handleClose}
    closeAfterTransition
    BackdropComponent={Backdrop}
    BackdropProps={{
      timeout: 500,
    }}
  >
    <Fade in={open}>
      <div className={classes.paper}>
        <h2 id="transition-modal-title">Are You Sure?</h2>
        {announcementToDelete && (
          <p id="transition-modal-description">
            {(announcementToDelete.length === 1)
              ? 'Are you sure you want to delete this announcement?'
              : `Are you sure you want to delete these ${announcementToDelete.length} announcements?`}
          </p>
        )}
        <Grid
          container
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Button
            variant="contained"
            type="button"
            size="medium"
            // color=""
            className={classes.margin}
            onClick={handleClose}
            id="step-2-skip-cancel"
          >
            No
          </Button>
          <Button
            variant="contained"
            type="button"
            size="medium"
            color="primary"
            className={classes.margin}
            onClick={confirmDelete}
            id="step-2-skip-confirm"
          >
            Yes
          </Button>
        </Grid>
      </div>
    </Fade>
  </Modal>
);

DeleteAnnouncementModal.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  classes: PropTypes.any.isRequired,
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  announcementToDelete: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.array,
  ]),
  confirmDelete: PropTypes.func.isRequired,
};

DeleteAnnouncementModal.defaultProps = {
  announcementToDelete: {},
};


export default DeleteAnnouncementModal;
