import React from 'react';
import {
  Button, ButtonGroup, ButtonToolbar,
} from 'reactstrap';
import PropTypes from 'prop-types';
import FundTracking from './FundTracking';
import Fund from './Fund';

const createToolBarButton = (type, edit, params) => {
  switch (type) {
    case 'fund':
      return <Fund />;
    case 'fundTrecking':
      return <FundTracking params={params} />;
    default:
      return <Button outline onClick={edit}><span className="lnr lnr-pencil" /></Button>;
  }
};

const Toolbar = ({
  edit, settings, type, params,
}) => {
  const toolBarButton = createToolBarButton(type, edit, params);
  return (
    <ButtonToolbar className="pb-4 mt-0 mr-4">
      <ButtonGroup className="btn-group--icons" dir="ltr">
        {toolBarButton}
        <Button outline onClick={settings}><span className="lnr lnr-cog" /></Button>
      </ButtonGroup>
    </ButtonToolbar>
  );
};

Toolbar.propTypes = {
  edit: PropTypes.func.isRequired,
  settings: PropTypes.func.isRequired,
  type: PropTypes.string,
  params: PropTypes.shape({
    fundId: PropTypes.string,
    companyId: PropTypes.number,
    refresh: PropTypes.func,
  }),
};

Toolbar.defaultProps = {
  type: '',
  params: {},
};

export default Toolbar;
