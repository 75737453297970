import * as BifService from './bifService';
import * as ClubResources from './clubResources';
import CompanyService from './companyService';
import Dictionary from './dictionary';
import { fixInvestmentType, changeInvestmentType } from './fundService';
import {
  filterOutFundAndTranchNames, filterOutInvestmentsFromTranches, TotalsCardKeyMapper,
} from './investmentService';
import MultiArrayComparer from './multiArrayComparer';

const FundService = {
  fixInvestmentType,
  changeInvestmentType,
};

const InvestmentService = {
  filterOutFundAndTranchNames,
  filterOutInvestmentsFromTranches,
  TotalsCardKeyMapper,
};

export default {
  BifService,
  ClubResources,
  CompanyService,
  Dictionary,
  FundService,
  InvestmentService,
  MultiArrayComparer,
};
