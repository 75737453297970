/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react';
import PropTypes from 'prop-types';
import AvDataGrid from '../shared/components/AvDataGrid';
import { getPresignedUrl } from '../shared/services/apiGateway';

const customTableStyles = {
  '& .MuiDataGrid-columnHeaderCheckbox .MuiDataGrid-columnHeaderTitleContainer': {
    display: 'none',
  },
};

export default function DocumentsList({
  files, onRowSelection, checkboxSelection, dealId,
}) {
  return (
    <div style={{ marginTop: '20px' }}>
      <AvDataGrid
        pageSize={5}
        rows={files.map((item) => ({
          name: item.name,
          avType: item.documentDescription || item.avType,
          id: item.id,
        }))}
        columns={[
          {
            field: 'name',
            headerName: 'Name',
            description: 'The Name of the Document',
            flex: 1,
            minWidth: 400,
            renderCell: (params) => (
              <span
                className="av-false-anchor"
                role="link"
                tabIndex={-1}
                onClick={async () => {
                  const response = await getPresignedUrl({
                    deal: dealId,
                    filekey: params.row.id,
                  });
                  window.open(response);
                }}
              >
                {params.row.name}
              </span>
            ),
          },
          {
            field: 'avType',
            headerName: 'Type',
            description: 'Document type',
            flex: 1,
            minWidth: 300,
          }]}
        loading={!files}
        checkboxSelection={checkboxSelection}
        onSelectionModelChange={(newSelectionModel) => {
          onRowSelection(newSelectionModel);
        }}
        sx={customTableStyles}
        initialState={{
          sorting: {
            sortModel: [{ field: 'ddDateCreated', sort: 'desc' }],
          },
        }}
      />
    </div>
  );
}

DocumentsList.propTypes = {
  files: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  onRowSelection: PropTypes.func.isRequired,
  dealId: PropTypes.number.isRequired,
  checkboxSelection: PropTypes.bool,
};

DocumentsList.defaultProps = {
  checkboxSelection: true,
};
