import { useEffect, useState, useRef } from 'react';

import {
  useLocation, useParams,
} from 'react-router-dom';
import { User } from '@alumni-ventures-group/av-okta-auth';
import WebsocketService from '../services/websocketService';

const userCache = new User();

export const WSS_CONFIG = {
  SOCKET_URL: process.env.SOCKET_URL,
  SOCKET_RECONNECTION_TIMEOUT: 1000,
};

const websocketConnect = (onOpen, onClose, dataCallback) => new WebsocketService(WSS_CONFIG.SOCKET_URL, onOpen, onClose, dataCallback);

const getDealId = (pathString) => pathString.match(/\d+/g)?.[0] || 0;

export const useWebsocket = () => {
  const [isConnected, setIsConnected] = useState(false);
  const location = useLocation();
  const [wsData, setWsData] = useState({});
  const websocketRef = useRef();
  const userInfo = userCache.get() || {};

  const onClose = () => {
    setTimeout(() => {
      setIsConnected(false);
    }, WSS_CONFIG.SOCKET_RECONNECTION_TIMEOUT);
  };

  const onOpen = () => {
    setIsConnected(true);
  };

  const dataCallback = (event) => {
    const decodedData = JSON.parse(event.data);
    decodedData.isOpen = !!((decodedData.action === 'sendDealNotification')
      && (!decodedData.user || decodedData.user.email !== userInfo.email));
    setWsData({ [decodedData.timestamp]: decodedData });
  };

  const abortController = new AbortController();

  useEffect(() => {
    if (isConnected === false) {
      websocketRef.current = websocketConnect(onOpen, onClose, dataCallback);
    }

    const dealId = getDealId(location.pathname);

    if (dealId > 0 && isConnected && websocketRef.current !== null) {
      websocketRef.current.subscribeDeal(dealId);
    }
    return () => {
      if (isConnected) websocketRef.current?.disconnect();
      abortController.abort();
    };
  }, [isConnected, location.pathname]);

  return {
    wsData,
    setWsData,
  };
};
