import { SELECT_MANAGED_USER } from '../actions/managedUserActions';

export default function (state = '', action) {
  switch (action.type) {
    case SELECT_MANAGED_USER:
      return action.userid;
    default:
      return state;
  }
}
