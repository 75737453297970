import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { Helmet } from 'react-helmet';
import {
  CardBody,
  Container,
  Card,
  Row,
  Col,
} from 'reactstrap';
import { v4 } from 'uuid';
import Stack from '@mui/material/Stack';
import LoadingButton from '@mui/lab/LoadingButton';
import { User } from '@alumni-ventures-group/av-okta-auth';
import AvDataGrid from '../shared/components/AvDataGrid';
import { createDDReportForDeal } from '../shared/services/apiGateway';
import generateRequest from '../shared/services/generateRequest';
import useDueDiligenceReport from '../shared/hooks/useDueDiligenceReport';
import PartialPageLoader from '../shared/components/Loader/PartialPageLoader';

const customTableStyles = {
  '& .MuiDataGrid-columnHeaderCheckbox .MuiDataGrid-columnHeaderTitleContainer': {
    display: 'none',
  },
};
const createDueDiligenceTypeTableDefinition = () => [
  {
    field: 'ddReportType',
    headerName: 'Report Type',
    description: 'The Type of Due Diligence Report',
    flex: 1,
    minWidth: 400,
  },
];

const formatDataForTable = (reportTypes) => {
  const formatted = [];
  for (const reportType of reportTypes) {
    formatted.push({
      id: v4(),
      ddReportType: reportType,
    });
  }
  return formatted;
};

function DDReportTypeSelector({ match }) {
  const ddReportId = match.params.id;
  const isTeamUser = (new User()).isITeam();

  const ddReportTypeSelectorTableDef = createDueDiligenceTypeTableDefinition();
  const [selectionModel, setSelectionModel] = useState([]);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  useDueDiligenceReport(ddReportId, (ddReport) => {
    if (ddReport && ddReport.fileUrl) {
      window.location.href = ddReport.fileUrl;
      return;
    }
    setIsLoading(false);
  });
  const types = [
    'Core',
    'Core Follow-On',
    'Seed',
    'Seed Follow-On',
  ];
  const [rows] = useState(formatDataForTable(types));

  return (
    <>
      <Helmet>
        <title>Due Diligence Report Type Selector</title>
      </Helmet>
      <Container className="med-width-form">
        <Card>
          <CardBody>
            {(isLoading) && (
              PartialPageLoader()
            )}
            {(!isLoading) && (
            <Row>
              <Col>
                <h2>Report Type Selection</h2>
                {
                        isTeamUser && (
                        <p style={{ fontSize: '16px' }}>
                          Please select the primary Due Diligence Report Type associated to this investment.
                        </p>
                        )
                      }

                <div style={{ marginTop: '20px' }}>
                  <AvDataGrid
                    pageSize={5}
                    rows={rows}
                    columns={ddReportTypeSelectorTableDef}
                    loading={!rows}
                    checkboxSelection={isTeamUser}
                    disableMultipleSelection
                    onSelectionModelChange={(newSelectionModel) => {
                      if (newSelectionModel.length > 0) {
                        const selectedSelectionModel = newSelectionModel[newSelectionModel.length - 1];
                        setSelectionModel(selectedSelectionModel);
                      }
                    }}
                    selectionModel={selectionModel}
                    sx={customTableStyles}
                  />
                </div>
                {isTeamUser && (
                <div style={{ marginTop: '20px' }}>
                  <Stack
                    direction="row"
                    justifyContent="end"
                    spacing={2}
                    mt={2}
                  >
                    <LoadingButton
                      variant="contained"
                      color="secondary"
                      disabled={!selectionModel || selectionModel.length <= 0 || isSubmitting}
                      loading={isSubmitting}
                      onClick={async () => {
                        const ddReport = rows.filter((el) => el.id === selectionModel);
                        setIsSubmitting(true);
                        const response = await createDDReportForDeal(generateRequest({
                          dealId: ddReportId,
                          ddReportType: ddReport[0].ddReportType,
                        }));
                        if (response.fileUrl) {
                          window.location.href = response.fileUrl;
                        }
                        setIsSubmitting(false);
                      }}
                    >
                      Create
                    </LoadingButton>

                  </Stack>
                </div>
                )}
                {!isTeamUser && (
                <Stack
                  direction="row"
                  justifyContent="center"
                  spacing={2}
                  mt={2}
                >
                  <h4>This selection can be completed by iTeam users only</h4>
                </Stack>
                )}
              </Col>
            </Row>
            )}
          </CardBody>
        </Card>
      </Container>
    </>
  );
}

DDReportTypeSelector.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.node,
    }).isRequired,
  }).isRequired,
};

export default DDReportTypeSelector;
