import moment from 'moment';

class DateHelper {
  static formatToLocal(date, format) {
    const dateO = moment(`${date} +0000`, 'YYYY-MM-DD HH:mm:ss Z');
    return dateO.format(format);
  }

  static formatToUTC(date, format) {
    if (date === null) return '';
    const dateO = moment(`${date} +0000`, 'YYYY-MM-DD HH:mm:ss Z');
    return dateO.utc().format(format);
  }
}

export default DateHelper;
