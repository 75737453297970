import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import ProfileSettingsForm from './ProfileSettingsForm';


class ProfileSettingsModal extends PureComponent {
  static propTypes = {
    un: PropTypes.string,
    pw: PropTypes.string,
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    isActive: PropTypes.bool,
    signatureFirstDegree: PropTypes.string,
    handleSubmit: PropTypes.func.isRequired,
    onPasswordInputFocus: PropTypes.func.isRequired,
    closeModal: PropTypes.func.isRequired,
  };

  static defaultProps = {
    un: '',
    pw: '',
    firstName: '',
    lastName: '',
    isActive: false,
    signatureFirstDegree: '',
  };

  constructor() {
    super();
    this.state = {
      showPassword: false,
    };
  }

  showPassword = (e) => {
    e.preventDefault();
    this.setState(prevState => ({ showPassword: !prevState.showPassword }));
  };

  render() {
    const {
      un,
      pw,
      firstName,
      lastName,
      isActive,
      signatureFirstDegree,
      handleSubmit,
      onPasswordInputFocus,
      closeModal,
    } = this.props;
    const {
      showPassword,
    } = this.state;

    return (
      <div className="modal-content fdo-roboto">
        <div className="modal-header">
          <h5 className="modal-title">
            <span className="fdo-text-bold-md">
              Profile Settings
            </span>
          </h5>
          <button type="button" className="close" aria-label="Close" onClick={closeModal}>
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div className="modal-body">
          <ProfileSettingsForm
            onSubmit={handleSubmit}
            onPasswordInputFocus={onPasswordInputFocus}
            un={un}
            pw={pw}
            firstName={firstName}
            lastName={lastName}
            isActive={isActive}
            signatureFirstDegree={signatureFirstDegree}
            showPassword={showPassword}
          />
        </div>
      </div>
    );
  }
}

export default ProfileSettingsModal;
