import { useHistory } from 'react-router-dom';
import { useOktaAuth } from '@okta/okta-react';
import AuthStatusCache from '../models/AuthStatus';
import TokenCache from '../models/Token';
import UserCache from '../models/User';

async function logout() {
  const { oktaAuth } = useOktaAuth();
  const history = useHistory();
  const basename = window.location.origin + history.createHref({ pathname: '/' });

  try {
    const userCache = new UserCache();
    const tokenCache = new TokenCache();
    const authStatusCache = new AuthStatusCache();
    userCache.clear();
    tokenCache.clear();
    authStatusCache.clear();
    await oktaAuth.signOut({ postLogoutRedirectUri: basename });
    console.log('User logged out');
  } catch (err) {
    console.err(err);
    throw err;
  }
}

function UserLogout() {
  logout();
  return '';
}

export default UserLogout;
