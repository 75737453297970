/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';
import { getFundTrackingData } from '../../shared/services/bifService';

function createFilterState(obj) {
  const investorProperties = Object.values(obj);
  const propertiesFilterToggleState = {};
  investorProperties.forEach((prop) => {
    propertiesFilterToggleState[prop] = true;
  });
  return propertiesFilterToggleState;
}

/*
  NOTE: createSlice allows us to work with state directly.
  Internally, it does not mute state, but makes a copy.
  https://redux-toolkit.js.org/tutorials/intermediate-tutorial#creating-the-todos-slice
*/
const fundTrackerSlice = createSlice({
  name: 'fundTrackerData',
  initialState: {
    projectionStatusFilters: null,
    investorStatusFilters: null,
    investmentMethodsFilters: null,
    amountFilters: { max: '', min: '' },
    investorPropertyKeys: {},
    investorRows: {},
    loading: false,
    error: null,
    companyId: null,
    fundName: null,
    fundId: null,
    lastFetched: new Date(),
    lastFilteredTime: new Date(),
  },
  reducers: {
    getFundTrackingDataStart(state) {
      state.loading = true;
      state.error = null;
    },
    applyInvestmentMethodsFilters(state, action) {
      state.investmentMethodsFilters = action.payload.data;
      state.lastFilteredTime = new Date();
    },
    applyProjectionStatusFilters(state, action) {
      state.projectionStatusFilters = action.payload.data;
      state.lastFilteredTime = new Date();
    },
    applyInvestorStatusFilters(state, action) {
      state.investorStatusFilters = action.payload.data;
      state.lastFilteredTime = new Date();
    },
    applyAmountFilters(state, action) {
      state.amountFilters = action.payload.data;
      state.lastFilteredTime = new Date();
    },
    resetFiltersState(state) {
      state.amountFilters = createFilterState(state.amountFilters);
      state.investorStatusFilters = createFilterState(state.investorStatusFilters);
      state.projectionStatusFilters = createFilterState(state.projectionStatusFilters);
      state.investmentMethodsFilters = createFilterState(state.investmentMethodsFilters);
    },
    getFundTrackingDataSuccess(state, action) {
      const {
        rows, projectionStatusKey, investorStatusKey,
        investmentMethodKey, fundName, companyId, fundId,
      } = action.payload.data;

      state.loading = false;
      state.error = null;
      state.fundName = fundName;
      state.companyId = companyId;
      state.fundId = fundId;
      state.investorRows = rows;
      state.projectionStatusFilters = state.projectionStatusFilters || createFilterState(projectionStatusKey);
      state.investorStatusFilters = state.investorStatusFilters || createFilterState(investorStatusKey);
      state.investmentMethodsFilters = state.investmentMethodsFilters || createFilterState(investmentMethodKey);
      state.lastFetched = new Date();
      state.investorPropertyKeys = { projectionStatusKey, investorStatusKey, investmentMethodKey };
    },
    getFundTrackingDataError(state, acton) {
      state.loading = false;
      state.error = acton.payload;
    },
    clearFundTrackingData(state) {
      state.loading = false;
      state.error = null;
      state.logoUrl = null;
      state.fundName = null;
      state.companyId = null;
      state.fundId = null;
      state.investorRows = {};
      state.investorPropertyKeys = {};
    },
  },
});

// export all the actions
export const {
  getFundTrackingDataStart,
  getFundTrackingDataSuccess,
  getFundTrackingDataError,
  clearFundTrackingData,
  applyProjectionStatusFilters,
  applyInvestmentMethodsFilters,
  applyInvestorStatusFilters,
  applyAmountFilters,
  resetFiltersState,
} = fundTrackerSlice.actions;

// This is a 'thunk' action for data fetching
export const fetchFundTrackingData = id => async (dispatch) => {
  try {
    dispatch(getFundTrackingDataStart());
    const data = await getFundTrackingData(id);
    dispatch(getFundTrackingDataSuccess({ data }));
  } catch (err) {
    dispatch(getFundTrackingDataError(err));
  }
};

export default fundTrackerSlice.reducer;
