import { showSnackbarError } from './snackbarActions';

export const REQUEST_ALL_INVESTMENT = 'REQUEST_ALL_INVESTMENT';
export const RECEIVE_ALL_INVESTMENT = 'RECEIVE_ALL_INVESTMENT';
export const RECEIVE_ALL_INVESTMENT_ERROR = 'RECEIVE_ALL_INVESTMENT_ERROR';

export const REQUEST_INVESTMENT_DETAILS = 'REQUEST_INVESTMENT_DETAILS';
export const RECEIVE_INVESTMENT_DETAILS = 'RECEIVE_INVESTMENT_DETAILS';
export const RECEIVE_INVESTMENT_DETAILS_ERROR = 'RECEIVE_INVESTMENT_DETAILS_ERROR';

export const REQUEST_INVESTMENT_TOTAL = 'REQUEST_INVESTMENT_TOTAL';
export const RECEIVE_INVESTMENT_TOTAL = 'RECEIVE_INVESTMENT_TOTAL';
export const RECEIVE_INVESTMENT_TOTAL_ERROR = 'RECEIVE_INVESTMENT_TOTAL_ERROR';


const defaultError = 'Unable to get investment items';
export function requestAllInvestments() {
  return {
    type: REQUEST_ALL_INVESTMENT,
    error: null,
  };
}
export function requestAllInvestmentsError(error) {
  return {
    type: RECEIVE_ALL_INVESTMENT_ERROR,
    error,
  };
}

export function receiveAllInvestments(items) {
  return {
    type: RECEIVE_ALL_INVESTMENT,
    investments: items,
    receivedAt: Date.now(),
    error: null,
  };
}

function fetchAllInvestment(auth) {
  return (dispatch) => {
    dispatch(requestAllInvestments());
    const { api } = auth;
    const path = 'api/v2.0/investments/get-all-portco-names';

    return api.get(path)
      .then((data) => {
        if (data && data.items) {
          dispatch(receiveAllInvestments(data.items));
        } else {
          dispatch(showSnackbarError(data.message ? data.message : defaultError));
          dispatch(requestAllInvestmentsError(data.message ? data.message : defaultError));
        }
      });
  };
}

function shouldFetchAllInvestment(state) {
  const { investments } = state;
  if (investments.investmentArray.length === 0) {
    return true;
  }
  return false;
}

export function fetchAllInvestmentsIfNeeded() {
  // eslint-disable-next-line consistent-return
  return (dispatch, getState) => {
    const { auth } = getState();
    if (shouldFetchAllInvestment(getState())) {
      return dispatch(fetchAllInvestment(auth));
    }
  };
}
