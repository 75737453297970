import React, { useState } from 'react';
import { Helmet } from 'react-helmet';
import { useHistory } from 'react-router-dom';
import {
  MenuItem, TextField, Stack,
} from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import {
  Container, Card, CardBody, Col, Row,
} from 'reactstrap';
import PropTypes from 'prop-types';
import { spacing } from '../shared/themes/appTheme';
import DealInformation from '../shared/components/DealInformation';
import usePendingInvestmentDetail from '../shared/hooks/usePendingInvestmentDetail';
import ReadOnlyTextField from '../shared/components/ReadOnlyTextField';
import DocumentViewer from '../shared/components/DocumentViewer';
import { isWhitespaceOnly } from '../shared/utils/validators';
import formatCurrencyAsUsd from '../shared/utils/formatCurrencyAsUsd';
import PartialPageLoader from '../shared/components/Loader/PartialPageLoader';
import { updatePendingInvestment } from '../shared/services/apiGateway';
import generateRequest from '../shared/services/generateRequest';
import StageStatusMessage from '../shared/components/StageStatusMessage';
import getStageStatus from '../shared/utils/checkUserPermissions';
import formatPhoneNumber from '../shared/utils/formatPhoneNumber';
import CloseOnConfirmModal from '../shared/components/CloseOnConfirmModal';
import getSearchParams from '../shared/utils/getSearchParams';

export const cancellationReasons = [
  'Incorrect golden contact',
  'Incomplete wire instructions',
  'Incorrect investment type',
  'Incorrect exact wire amount document',
  'Other',
];

const showWireTargetDate = false; // we are hiding this from the UI per AVG-6524 for now.

const styles = {
  sectionSpacing: { margin: '15px 0 5px 0' }, questionsFontSize: '15px',
};

function VerifyWireInfo({ match }) {
  const pendingInvestmentId = match.params.id;
  const closeOnConfirm = getSearchParams('closeoncompletion');
  const history = useHistory();

  const [dealInfo, setDealInfo] = useState({
    dealName: '',
    sponsorTeam: '',
    commentaryForFinance: '',
    iTeamMemberName: '',
  });
  const [wireInfo, setWireInfo] = useState({
    wireAmount: '',
    wireTargetDate: '',
  });
  const [goldenContact, setGoldenContact] = useState({
    name: '',
    title: '',
    email: '',
    phone: '',
    timeZone: '',
  });
  const [wireAmountDocument, setWireAmountDocument] = useState(null);
  const [wireInstructionsDocument, setWireInstructionsDocument] = useState(null);
  const [teamMemberName, setTeamMemberName] = useState('');
  const [financeNotes, setFinanceNotes] = useState('');
  const [cancellationReason, setCancellationReason] = useState('');
  const [otherReason, setOtherReason] = useState('');
  const [otherReasonSelected, setOtherReasonSelected] = useState(false);
  const [errors, setErrors] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isRejecting, setIsRejecting] = useState(false);
  const [isConfirming, setIsConfirming] = useState(false);
  const [stageStatus, setStageStatus] = useState({
    isEditableByRole: false,
  });
  const [completionModalOpen, setCompletionModalOpen] = useState(false);

  const cancellationRequiredItems = {
    cancellationReason,
    teamMemberName,
  };

  const confirmationRequiredItems = {
    teamMemberName,
  };

  const validateForm = (approval) => {
    // clear validation errors
    const requiredItems = approval ? confirmationRequiredItems : cancellationRequiredItems;
    const itemsToClear = approval ? cancellationRequiredItems : confirmationRequiredItems;

    if (!approval && otherReasonSelected) {
      requiredItems.otherReason = otherReason || '';
    }

    let newErrors = { ...errors };
    Object.keys(itemsToClear).forEach((item) => {
      delete newErrors[item];
    });

    Object.keys(requiredItems).forEach((item) => {
      if (!requiredItems[item]) {
        newErrors = { ...newErrors, [item]: 'Field is required' };
      } else {
        newErrors = { ...newErrors, [item]: null };
      }
    });

    setErrors(newErrors);
    return (Object.values(newErrors).every((error) => error === null));
  };

  const checkAndSetCancellationReason = (reason) => {
    setCancellationReason(reason);

    if (reason === 'Other') {
      setOtherReasonSelected(true);
      setOtherReason('');
      setErrors({ ...errors, otherReason: null });
      return;
    }
    setOtherReasonSelected(false);
  };

  const validateAndSetText = (textFromInput, setterHook, componentName, maxLength = 200) => {
    let text = textFromInput;
    let error = null;
    if (text.length >= maxLength) {
      error = 'Text needs to be 200 or less characters.';
      text = text.substring(0, Math.min(text.length, 200));
    } else if (isWhitespaceOnly(text)) {
      text = '';
      error = 'Text cannot be only whitespace characters.';
    }

    setErrors({
      ...errors,
      [componentName]: error,
    });

    setterHook(text);
  };

  const textFieldStyle = { width: '100%', my: 2 };

  const dealData = usePendingInvestmentDetail(pendingInvestmentId, (deal) => {
    let dealName = '';
    if (deal.companyName) {
      dealName = deal.companyName;
    } else if (deal.hsDealObj && deal.hsDealObj.dealName) {
      dealName = deal.hsDealObj.dealName;
    }

    let sponsorTeam = '';
    if (deal.sponsorTeam) {
      sponsorTeam = deal.sponsorTeam;
    } else if (deal.hsDealObj && deal.hsDealObj.sponsorTeam && deal.hsDealObj.sponsorTeam.name) {
      sponsorTeam = deal.hsDealObj.sponsorTeam.name;
    }

    let payloadWireAmount = 0;
    if (deal.wireAmount) {
      payloadWireAmount = deal.wireAmount;
    } else if (deal.hsDealObj && deal.hsDealObj.amount) {
      payloadWireAmount = deal.hsDealObj.amount;
    }

    const dateSettings = { month: '2-digit', day: '2-digit', year: 'numeric' };
    let wireTargetDate = '';
    if (deal.wireTargetDate) {
      wireTargetDate = new Date(deal.wireTargetDate).toLocaleDateString('en-US', dateSettings);
    }

    setStageStatus({
      ...getStageStatus(deal),
    });

    setDealInfo({
      dealName,
      sponsorTeam,
      commentaryForFinance: deal.wireCommentsForFinance || '',
      iTeamMemberName: deal.iTeamMemberName || '',
    });
    setWireInfo({ wireAmount: formatCurrencyAsUsd(payloadWireAmount), wireTargetDate });
    if (deal.goldenContact) {
      setGoldenContact(deal.goldenContact);
    }
    setWireAmountDocument(deal.wireAmountDocument || null);
    setWireInstructionsDocument(deal.wireInstructionsDocument || null);
    const {
      financeMemberName = '',
      additionalInfo = '',
    } = deal.wireVerificationInfo;
    setTeamMemberName(financeMemberName);
    setFinanceNotes(additionalInfo);
  });

  const rejectWireInfo = async () => {
    try {
      setIsSubmitting(true);
      setIsRejecting(true);
      const dealObjChanges = {
        wireVerificationInfo: {
          financeMemberName: teamMemberName,
          additionalInfo: financeNotes,
          rejectionReason: (otherReason || cancellationReason),
        },
      };
      const stagesCompleted = { ...dealData.stagesCompleted, verifyWireInfo: false, uploadWireInfo: false };
      const updatedDealData = { ...dealData, stagesCompleted, ...dealObjChanges };
      await updatePendingInvestment(generateRequest(updatedDealData, [{
        label: 'rejectDeal',
        value: {
          rejectionReason: otherReason ? 'other' : dealObjChanges.wireVerificationInfo.rejectionReason,
          originStep: '/verify-wire-info/',
        },
      }]));
      setIsSubmitting(false);
      setIsRejecting(false);
      if (!closeOnConfirm) {
        history.push(`/deal-stages/${pendingInvestmentId}`);
      } else {
        setCompletionModalOpen(true);
      }
    } catch (error) {
      setIsSubmitting(false);
      setIsRejecting(false);
      console.log('An error occurred while rejecting wire information. Please contact support if this error persists.');
    }
  };

  const confirmWireInfo = async () => {
    try {
      setIsSubmitting(true);
      setIsConfirming(true);
      const dealObjChanges = {
        wireVerificationInfo: {
          financeMemberName: teamMemberName,
          additionalInfo: financeNotes,
          rejectionReason: (otherReason || cancellationReason),
          verificationDate: new Date().toISOString(),
        },
      };
      const updatedDealData = { ...dealData, ...dealObjChanges };
      updatedDealData.stagesCompleted.verifyWireInfo = new Date().toISOString();
      await updatePendingInvestment(generateRequest(updatedDealData));
      setIsSubmitting(false);
      setIsConfirming(false);
      if (!closeOnConfirm) {
        history.push(`/deal-stages/${pendingInvestmentId}`);
      } else {
        setCompletionModalOpen(true);
      }
    } catch (error) {
      setIsSubmitting(false);
      setIsConfirming(false);
      console.log('An error occurred while confirming wire information. Please contact support if this error persists.');
    }
  };

  return (
    <>
      <Helmet>
        <title>Verify Wire Information</title>
      </Helmet>
      <Container className="med-width-form">
        <Card>
          {!dealData && PartialPageLoader(12)}
          {dealData
            && (
            <CardBody>
              <Row>
                <Col>
                  {/* Deal section - read only */}
                  <h3>Verify Wire Information for {dealInfo.dealName}</h3>
                  <h4 style={{ marginTop: spacing(2) }}>
                    Wire Info from iTeam
                  </h4>
                  <Row className="w-100">
                    <Col
                      style={{
                        marginTop: spacing(2),
                      }}
                    >
                      <DealInformation
                        dealInfo={dealInfo}
                        setDealInfo={setDealInfo}
                        rejectionReason={dealData.stagesCompleted && dealData.stagesCompleted.verifyWireInfo === false
                          ? dealData.wireSignOffRejectionReason : ''}

                      />
                    </Col>
                  </Row>
                  <h5 style={{ marginTop: spacing(4) }}>
                    Wire Information
                  </h5>
                  <Row className="w-100">
                    <Col lg={12}>
                      <ReadOnlyTextField
                        label="Wire Amount"
                        value={wireInfo.wireAmount}
                        fullWidth
                      />
                    </Col>
                    <Col lg={6}>
                      <ReadOnlyTextField
                        label="Wire Target Date"
                        value={wireInfo.wireTargetDate}
                        fullWidth
                        hidden={!showWireTargetDate}
                      />
                    </Col>
                  </Row>
                  {dealData.digitalAssetsQuestions && dealData.digitalAssetsQuestions.length > 0 && (
                    <>
                      <h5 style={{ marginTop: spacing(4) }}>
                        Digital Asset Questions
                      </h5>
                      {dealData.digitalAssetsQuestions.map((question) => {
                        let displayAnswer = question.value;
                        if (question.value === true) {
                          displayAnswer = 'Yes';
                        } else if (question.value === false) {
                          displayAnswer = 'No';
                        }
                        return (
                          <div key={question.label}>
                            <h5 style={{ ...styles.sectionSpacing, fontSize: styles.questionsFontSize }}>{question.label}</h5>
                            <h6>&emsp;<i>Answer:</i>&ensp;“{displayAnswer}”</h6>
                          </div>
                        );
                      })}
                    </>
                  )}
                  <hr />
                  <h5 style={{ marginTop: spacing(4) }}>
                    Golden Contact Information
                  </h5>
                  <Row className="w-100">
                    <Col lg={6}>
                      <ReadOnlyTextField
                        label="Name"
                        value={goldenContact.name}
                        fullWidth
                      />
                      <ReadOnlyTextField
                        label="Title"
                        value={goldenContact.title}
                        fullWidth
                      />
                    </Col>
                    <Col lg={6}>
                      <ReadOnlyTextField
                        label="Phone Number"
                        value={formatPhoneNumber(goldenContact.phone)}
                        fullWidth
                      />
                      <ReadOnlyTextField
                        label="Time Zone"
                        value={goldenContact.timeZone}
                        fullWidth
                      />
                    </Col>
                    <Col lg={12}>
                      <ReadOnlyTextField
                        label="Email Address"
                        value={goldenContact.email}
                        fullWidth
                      />
                    </Col>
                  </Row>
                  <br />

                  {
                    dealInfo.commentaryForFinance && (
                      <Row className="w-100">
                        <Col>
                          <ReadOnlyTextField
                            label="Additional Comments For Finance Team"
                            value={dealInfo.commentaryForFinance}
                            fullWidth
                            multiline
                            variant="outlined"
                            style={{ marginTop: spacing(2) }}
                          />
                        </Col>
                      </Row>
                    )
                  }

                  <Row className="w-100">
                    <Col>
                      <DocumentViewer id={dealData.id} document={wireInstructionsDocument} label="Wire Instructions Document" />
                      <DocumentViewer id={dealData.id} document={wireAmountDocument} label="Wire Amount Document" />
                    </Col>
                  </Row>
                  {/* Finance section - Not read only */}
                  <h4 style={{ marginTop: spacing(6), marginBottom: spacing(-1) }}>
                    <hr />
                    Treasury Input
                  </h4>
                  <Row className="w-100">
                    <Col>
                      <TextField
                        label="Finance member name"
                        variant="standard"
                        required
                        sx={textFieldStyle}
                        value={teamMemberName}
                        onChange={(e) => validateAndSetText(e.target.value, setTeamMemberName, 'teamMemberName')}
                        error={!!errors.teamMemberName}
                        helperText={errors.teamMemberName ? errors.teamMemberName : null}
                      />
                      <TextField
                        sx={textFieldStyle}
                        label="Additional information regarding this deal"
                        multiline
                        rows={4}
                        value={financeNotes}
                        onChange={(e) => validateAndSetText(e.target.value, setFinanceNotes, 'setFinanceNotes')}
                        onBlur={(e) => validateAndSetText(e.target.value.trim(), setFinanceNotes, 'setFinanceNotes')}
                        helperText={errors.setFinanceNotes}
                      />

                      <TextField
                        sx={{ mb: 2 }}
                        fullWidth
                        id="standard-select-currency"
                        select
                        label="Reason for rejection: (required if rejecting wire information step)"
                        required
                        value={cancellationReason}
                        onChange={(e) => { checkAndSetCancellationReason(e.target.value); }}
                        variant="standard"
                        error={!!errors.cancellationReason}
                        helperText={errors.cancellationReason ? 'Required if rejecting the deal' : null}
                      >
                        {cancellationReasons.map((reason) => <MenuItem key={reason} value={reason}>{reason}</MenuItem>)}
                      </TextField>
                      { otherReasonSelected && (
                      <TextField
                        fullWidth
                        size="small"
                        sx={{ mb: 3 }}
                        value={otherReason}
                        onChange={(e) => {
                          validateAndSetText(e.target.value, setOtherReason, 'otherReason');
                        }}
                        label="Please specify cancellation reason within 200 characters"
                        error={!!errors.otherReason}
                        helperText={errors.otherReason || null}
                      />
                      )}
                    </Col>
                  </Row>
                </Col>
              </Row>
              {((stageStatus.isEditableByRole) && !stageStatus.isCompleted) && (

              <Stack
                direction="row"
                justifyContent="space-between"
                alignItems="center"
                spacing={2}
                mt={2}
                style={{ marginTop: spacing(5) }}
              >
                <LoadingButton
                  className="mr-5"
                  variant="outlined"
                  color="danger"
                  disabled={isSubmitting}
                  loading={isRejecting}
                  onClick={async () => {
                    const formValidated = validateForm(false);
                    if (formValidated) {
                      await rejectWireInfo();
                    }
                  }}
                >Reject and request Re-Submission
                </LoadingButton>
                <LoadingButton
                  className="ml-5"
                  variant="contained"
                  color="secondary"
                  disabled={isSubmitting}
                  loading={isConfirming}
                  onClick={async () => {
                    const formValidated = validateForm(true);
                    if (formValidated) {
                      await confirmWireInfo();
                    }
                  }}
                >Confirm Information Provided is Accurate
                </LoadingButton>
              </Stack>
              )}
              <StageStatusMessage stageStatus={stageStatus} />
            </CardBody>
            )}
        </Card>

        <CloseOnConfirmModal modalOpen={completionModalOpen} />
      </Container>
    </>
  );
}

VerifyWireInfo.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.node,
    }).isRequired,
  }).isRequired,
};

export default VerifyWireInfo;
