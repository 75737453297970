import React from 'react';
import {
  Select, MenuItem, FormControl, InputLabel,
} from '@mui/material';
import { User } from '@alumni-ventures-group/av-okta-auth';

export default function createRoleSelector({ topAdornment }) {
  const userCache = new User();
  const roles = User.createExplicitAuthRoles(userCache.get().roles);
  const selectedAuthRole = userCache.getProp('selectedAuthRole');
  return (
    (roles && roles.length > 1) && (
    <>
      {topAdornment || '' }
      <div style={{
        width: '84%',
        margin: 'auto',
        marginTop: '10px',
      }}
      >
        <FormControl style={{
          minWidth: '100%',
        }}
        >
          <InputLabel id="navRolesSelectLabel">Update Security Role</InputLabel>
          <Select
            value={selectedAuthRole || roles[0]}
            labelId="navRolesSelectLabel"
            onChange={(event) => {
              userCache.selectRole(event.target.value);
              window.location.reload();
            }}
          >
            {roles.map((role) => <MenuItem value={role} key={role} style={{ textTransform: 'lowercase' }}>{role}</MenuItem>)}
          </Select>
        </FormControl>
      </div>
    </>
    )
  );
}
