import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import Tooltip from '@material-ui/core/Tooltip';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from 'mdi-react/DeleteIcon';
import EditIcon from 'mdi-react/EditIcon';
import SendIcon from 'mdi-react/SendIcon';
import ClipboardIcon from 'mdi-react/ClipboardOutlineIcon';
import IncognitoIcon from 'mdi-react/IncognitoIcon';
import FileDownloadOutlineIcon from 'mdi-react/FileDownloadOutlineIcon';
import InformationIcon from 'mdi-react/InformationIcon';
import { CSVLink } from 'react-csv';
import MatTableFilterButton from './MatTableFilterButton';

const currentDate = moment().format('L');

const MatTableToolbar = ({
  numSelected,
  selectedData,
  handleDeleteSelected,
  onRequestSort,
  columnTitles,
  handleEditSelected,
  editable,
  handleSendSelected,
  handleSpyLogInSelected,
  downloadCsv,
  handleRowInfo,
  rowInfo,
  handleViewPublishedAnnouncementSelected,
}) => {
  const csvHeaders = columnTitles.map(item => (
    {
      label: item.label,
      key: item.id,
    }
  ));
  return (
    <div className="material-table__toolbar-wrap">
      <Toolbar className="material-table__toolbar">
        <div>
          {numSelected > 0 && (
            <h5 className="material-table__toolbar-selected">{numSelected} <span>selected</span></h5>
          )}
        </div>
        <div>
          {numSelected === 1 && editable && (
            <IconButton
              onClick={handleEditSelected}
              data-testid="editRowButton"
              className="material-table__toolbar-button"
            >
              <EditIcon />
            </IconButton>
          )}
          {(numSelected === 1 && editable && handleSendSelected) && (
            <Tooltip title="Notify Members by Email" aria-label="notify members" arrow>
              <IconButton
                onClick={handleSendSelected}
                data-testid="sendRowButton"
                className="material-table__toolbar-button"
              >
                <SendIcon />
              </IconButton>
            </Tooltip>
          )}
          {(numSelected === 1 && editable && handleSpyLogInSelected) && (
            <Tooltip title="Spy Login" aria-label="spy login" arrow>
              <IconButton
                onClick={handleSpyLogInSelected}
                data-testid="editRowButton"
                className="material-table__toolbar-button"
              >
                <IncognitoIcon />
                {/* <FontAwesomeIcon icon={faUserSecret} style={{ fontSize: '1.2rem' }} /> */}
              </IconButton>
            </Tooltip>
          )}
          {(numSelected > 0 && downloadCsv) && (
            <Tooltip title="Download CSV file" aria-label="download csv" arrow>
              <CSVLink
                data={selectedData}
                headers={csvHeaders}
                filename={`club_scorecards_${currentDate}.csv`}
              >
                <IconButton
                  onClick={() => { }}
                  data-testid="downloadCsv"
                  className="material-table__toolbar-button"
                >
                  <FileDownloadOutlineIcon />
                </IconButton>
              </CSVLink>
            </Tooltip>
          )}
          {(numSelected === 1 && rowInfo) && (
            <IconButton
              onClick={handleRowInfo}
              data-testid="infoRowButton"
              className="material-table__toolbar-button"
            >
              <InformationIcon />
            </IconButton>
          )}
          {(handleDeleteSelected && numSelected > 0) ? (
            <IconButton
              onClick={handleDeleteSelected}
              data-testid="deleteRowButton"
              className="material-table__toolbar-button"
            >
              <DeleteIcon />
            </IconButton>
          ) : (
            <MatTableFilterButton columnTitles={columnTitles} onRequestSort={onRequestSort} />
          )}
          {(numSelected === 1 && editable && handleViewPublishedAnnouncementSelected) && (
            <Tooltip title="Copy Published Announcement Link" aria-label="copy published announcement link" arrow>
              <IconButton
                onClick={() => handleViewPublishedAnnouncementSelected(selectedData)}
                data-testid="viewPublishedAnnouncementButton"
                className="material-table__toolbar-button"
              >
                <ClipboardIcon />
              </IconButton>
            </Tooltip>
          )}
        </div>
      </Toolbar>
    </div>
  );
};

MatTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
  handleDeleteSelected: PropTypes.func,
  handleEditSelected: PropTypes.func.isRequired,
  handleSendSelected: PropTypes.func,
  handleSpyLogInSelected: PropTypes.func,
  editable: PropTypes.bool,
  downloadCsv: PropTypes.bool,
  onRequestSort: PropTypes.func.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  selectedData: PropTypes.array,
  // eslint-disable-next-line react/forbid-prop-types
  columnTitles: PropTypes.array.isRequired,
  handleRowInfo: PropTypes.func,
  rowInfo: PropTypes.func,
  handleViewPublishedAnnouncementSelected: PropTypes.func,
};

MatTableToolbar.defaultProps = {
  editable: false,
  rowInfo: null,
  downloadCsv: false,
  handleSendSelected: null,
  handleDeleteSelected: null,
  handleSpyLogInSelected: null,
  selectedData: [],
  handleRowInfo: null,
  handleViewPublishedAnnouncementSelected: null,
};

export default MatTableToolbar;
