/* eslint-disable camelcase */
import fetch from 'cross-fetch';
import getStore from '../../redux/store/store';
import { addErrorMessage } from '../../redux/actions/errorActions';

export function getRequest(url, abortSignal = null) {
  const { store } = getStore();
  const { dispatch } = store;
  const state = store.getState();
  const authtoken = state.auth.authCode;
  const headers = {
    'Content-Type': 'application/json',
    authtoken,
    isApi: 'true',
  };
  return fetch(`${process.env.AVG_API_PATH}${url}`, {
    method: 'GET',
    headers,
    signal: abortSignal,
  }).then(response => response.json().then((json) => {
    if (!response.ok) {
      dispatch(addErrorMessage(json));
      return Promise.reject(json);
    }
    if (json.errors && json.errors.length > 0 && json.errors[0] === 'BadAuthToken') {
      window.location = window.location; // force page refresh, so login process kicks in
    }
    return json;
  })).catch((e) => {
    // eslint-disable-next-line no-console
    console.error(`Download of ${url} : ${e.message}`);
    return 'aborted';
  });
}

export function postRequest(url, body, abortSignal = null) {
  const { store } = getStore();
  const { dispatch } = store;
  const state = store.getState();
  const authtoken = state.auth.authCode;
  return fetch(`${process.env.AVG_API_PATH}${url}`, {
    method: 'POST',
    body: JSON.stringify(body),
    headers: {
      authtoken,
      'Content-type': 'application/json',
      Accept: 'application/json',
    },
    signal: abortSignal,
  }).then(async res => res.json().then((json) => {
    if (!res.ok) {
      dispatch(addErrorMessage(json));
      return Promise.reject(json);
    }
    return json;
  })).catch((e) => {
    // eslint-disable-next-line no-console
    console.error(`post to ${url} : ${e.message}`);
    return 'aborted';
  });
}

export function postRequestFormData(url, data, abortSignal = null) {
  const { store } = getStore();
  const { dispatch } = store;
  const state = store.getState();
  const authtoken = state.auth.authCode;
  return fetch(`${process.env.AVG_API_PATH}${url}`, {
    method: 'POST',
    body: data,
    headers: {
      authtoken,
      Accept: 'application/json',
    },
    signal: abortSignal,
  }).then(async res => res.json().then((json) => {
    if (!res.ok) {
      dispatch(addErrorMessage(json));
      return Promise.reject(json);
    }
    return json;
  })).catch((e) => {
    // eslint-disable-next-line no-console
    console.error(`post to ${url} : ${e.message}`);
    return 'aborted';
  });
}
