/* eslint-disable react/forbid-prop-types */
import * as React from 'react';
import PropTypes from 'prop-types';
import {
  Radio, RadioGroup, FormControlLabel, FormControl, FormLabel,
} from '@mui/material';

const formControlStyle = {
  m: 0.5,
  border: 'solid',
  borderColor: 'gray',
  borderRadius: 3,
  borderWidth: '1px',
  width: '99%',
  alignItems: 'center',
  marginTop: 1,
};

const RowRadioButtonsGroup = function RowRadioButtonsGroup({ label, valueOne, valueTwo }) {
  return (
    <FormControl
      sx={formControlStyle}
    >
      <RadioGroup
        row
        aria-labelledby="demo-row-radio-buttons-group-label"
        name="row-radio-buttons-group"
      >
        <FormLabel id="demo-controlled-radio-buttons-group" sx={{ m: 3 }}>{label}</FormLabel>
        <FormControlLabel value={valueOne} control={<Radio />} label={valueOne.toUpperCase()} />
        <FormControlLabel value={valueTwo} control={<Radio />} label={valueTwo.toUpperCase()} />
      </RadioGroup>
    </FormControl>
  );
};

RowRadioButtonsGroup.propTypes = {
  label: PropTypes.string.isRequired,
  valueOne: PropTypes.any.isRequired,
  valueTwo: PropTypes.any.isRequired,
};

export default RowRadioButtonsGroup;
