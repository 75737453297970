import React from 'react';
import PropTypes from 'prop-types';
import { Row } from 'reactstrap';
import MatTable from './components/MatTable';

const MaterialTable = ({
  dataArray,
  columns,
  title,
  selectable,
  edit,
  deleteRow,
  preSort,
  sendEmail,
  useConfirmModal,
  actionConfirmed,
  setActionConfirmed,
  doNotSort,
  spyLogIn,
  showWhatsSelected,
  downloadCsv,
  rowInfo,
  rowSize,
  showToolbar,
  wrapHeaders,
  viewPublishedAnnouncement,
  customSort,
  hidePagination,
  customPresort,
  preselectedRows,
  disabledCheckboxRows,
  customStyling,
}) => (
  <Row>
    <MatTable
      dataArray={dataArray}
      columnTitles={columns}
      title={title}
      selectable={selectable}
      edit={edit}
      sendEmail={sendEmail}
      deleteRow={deleteRow}
      preSort={preSort}
      useConfirmModal={useConfirmModal}
      actionConfirmed={actionConfirmed}
      setActionConfirmed={setActionConfirmed}
      doNotSort={doNotSort}
      spyLogIn={spyLogIn}
      showWhatsSelected={showWhatsSelected}
      downloadCsv={downloadCsv}
      rowInfo={rowInfo}
      rowsPerPage={rowSize}
      showToolbar={showToolbar}
      wrapHeaders={wrapHeaders}
      viewPublishedAnnouncement={viewPublishedAnnouncement}
      customSort={customSort}
      hidePagination={hidePagination}
      customPresort={customPresort}
      preselectedRows={preselectedRows}
      disabledCheckboxRows={disabledCheckboxRows}
      customStyling={customStyling}
    />
  </Row>
);

MaterialTable.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  dataArray: PropTypes.array.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  columns: PropTypes.array.isRequired,
  title: PropTypes.string,
  selectable: PropTypes.bool,
  edit: PropTypes.func,
  deleteRow: PropTypes.func,
  setActionConfirmed: PropTypes.func,
  preSort: PropTypes.shape({
    field: PropTypes.string.isRequired,
    order: PropTypes.string.isRequired,
  }),
  sendEmail: PropTypes.func,
  spyLogIn: PropTypes.func,
  useConfirmModal: PropTypes.bool,
  actionConfirmed: PropTypes.bool,
  doNotSort: PropTypes.bool,
  showWhatsSelected: PropTypes.func,
  downloadCsv: PropTypes.bool,
  rowInfo: PropTypes.func,
  rowSize: PropTypes.number,
  showToolbar: PropTypes.bool,
  wrapHeaders: PropTypes.bool,
  viewPublishedAnnouncement: PropTypes.func,
  customSort: PropTypes.func,
  hidePagination: PropTypes.bool,
  customPresort: PropTypes.func,
  // eslint-disable-next-line react/forbid-prop-types
  preselectedRows: PropTypes.array,
  // eslint-disable-next-line react/forbid-prop-types
  disabledCheckboxRows: PropTypes.array,
  // eslint-disable-next-line react/forbid-prop-types
  customStyling: PropTypes.object,
};

MaterialTable.defaultProps = {
  title: null,
  selectable: false,
  edit: null,
  deleteRow: null,
  preSort: null,
  sendEmail: null,
  spyLogIn: null,
  useConfirmModal: false,
  actionConfirmed: false,
  setActionConfirmed: null,
  doNotSort: false,
  showWhatsSelected: null,
  downloadCsv: false,
  rowInfo: null,
  rowSize: 10,
  showToolbar: true,
  wrapHeaders: false,
  viewPublishedAnnouncement: null,
  customSort: null,
  hidePagination: false,
  customPresort: null,
  preselectedRows: [],
  disabledCheckboxRows: [],
  customStyling: null,
};

export default MaterialTable;
