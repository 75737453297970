import React from 'react';
import { Link } from 'react-router-dom';
import { User } from '@alumni-ventures-group/av-okta-auth';
import './topbarNav.css';

function TopbarNav() {
  const user = new User();
  return (
    <nav className="topbar__nav" style={{ textDecorationLine: 'none' }}>
      <Link className="topbar__nav-link" to="/">
        Deal Closing
      </Link>
      {(!user.isITeam()) && (
        <>
          <Link className="topbar__nav-link" to="/pending-investments">
            Pending Investments
          </Link>
          <Link className="topbar__nav-link" to="/post-wire/">
            Post-Wire
          </Link>
          <Link className="topbar__nav-link" to="/closed-investments">
            Closed Investments
          </Link>
        </>
      )}

      {/* <Link className="topbar__nav-link" to="/wire-transfer/VG92YWxhMQ">
        Wire Transfer Example
      </Link>
      <Link className="topbar__nav-link" to="/closing-docs-review">
        Review of Closing Docs
      </Link>
      <Link className="topbar__nav-link" to="/upload-closing-docs">
        Upload Closing Docs
      </Link> */}
    </nav>
  );
}

export default TopbarNav;
