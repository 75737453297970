import { useState, useEffect } from 'react';
import { getPendingInvestment } from '../services/apiGateway';

/**
 * @param {*} id the deal id
 * @param {*} onLoad optional callback
 * @returns the deal or an error on fail
 */
function usePendingInvestmentDetail(id, onLoad) {
  const [pendingInvestmentDetail, setPendingInvestmentDetail] = useState(null);

  useEffect(() => {
    const abortController = new AbortController();
    let ignore = false;

    async function fetchData() {
      const response = await getPendingInvestment(id);
      setPendingInvestmentDetail(response[0]);
      if (onLoad) {
        onLoad(response[0]);
      }
    }

    fetchData();

    return () => {
      ignore = true;
      abortController.abort();
    };
  }, []);

  return pendingInvestmentDetail;
}

export default usePendingInvestmentDetail;
