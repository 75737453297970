import { showSnackbarError } from './snackbarActions';

export const REQUEST_ALL_PORTFOLIO_UPDATE = 'REQUEST_ALL_PORTFOLIO_UPDATE';
export const RECEIVE_ALL_PORTFOLIO_UPDATE = 'RECEIVE_ALL_PORTFOLIO_UPDATE';
export const RECEIVE_ALL_PORTFOLIO_UPDATE_ERROR = 'RECEIVE_ALL_PORTFOLIO_UPDATE_ERROR';

export const REQUEST_PORTFOLIO_UPDATE_DETAILS = 'REQUEST_PORTFOLIO_UPDATE_DETAILS';
export const RECEIVE_PORTFOLIO_UPDATE_DETAILS = 'RECEIVE_PORTFOLIO_UPDATE_DETAILS';
export const RECEIVE_PORTFOLIO_UPDATE_DETAILS_ERROR = 'RECEIVE_PORTFOLIO_UPDATE_DETAILS_ERROR';

export const REQUEST_PORTFOLIO_UPDATE_TOTAL = 'REQUEST_PORTFOLIO_UPDATE_TOTAL';
export const RECEIVE_PORTFOLIO_UPDATE_TOTAL = 'RECEIVE_PORTFOLIO_UPDATE_TOTAL';
export const RECEIVE_PORTFOLIO_UPDATE_TOTAL_ERROR = 'RECEIVE_PORTFOLIO_UPDATE_TOTAL_ERROR';


const defaultError = 'Unable to get portfolio update items';
export function requestAllPortfolioUpdates() {
  return {
    type: REQUEST_ALL_PORTFOLIO_UPDATE,
    error: null,
  };
}
export function requestAllPortfolioUpdatesError(error) {
  return {
    type: RECEIVE_ALL_PORTFOLIO_UPDATE_ERROR,
    error,
  };
}

export function receiveAllPortfolioUpdates(items) {
  return {
    type: RECEIVE_ALL_PORTFOLIO_UPDATE,
    portfolioUpdates: items,
    receivedAt: Date.now(),
    error: null,
  };
}

export function fetchAllPortfolioUpdate(auth) {
  return (dispatch) => {
    dispatch(requestAllPortfolioUpdates());
    const { api } = auth;
    const path = 'api/v2.0/portco-pricing/get-all-portco-with-pricing';

    return api.get(path)
      .then((data) => {
        if (data && data.items) {
          dispatch(receiveAllPortfolioUpdates(data.items));
        } else {
          dispatch(showSnackbarError(data.message ? data.message : defaultError));
          dispatch(requestAllPortfolioUpdatesError(data.message ? data.message : defaultError));
        }
      });
  };
}

function shouldFetchAllPortfolioUpdate(state) {
  const { portfolioUpdates } = state;
  if (portfolioUpdates.portfolioUpdateArray.length === 0) {
    return true;
  }
  return false;
}

export function fetchAllPortfolioUpdatesIfNeeded() {
  // eslint-disable-next-line consistent-return
  return (dispatch, getState) => {
    const { auth } = getState();
    if (shouldFetchAllPortfolioUpdate(getState())) {
      return dispatch(fetchAllPortfolioUpdate(auth));
    }
  };
}
