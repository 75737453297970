import {
  INIT_SESSION,
  INIT_SESSION_SUCCESS,
  INIT_SESSION_FAILURE,
  DO_SIGN_IN,
  AUTHENTICATED,
  DO_LOG_OUT,
  SIGNEDOUT,
  SIGN_IN_ERROR,
  AUTH_TYPE,
} from '../actions/authActions';

import API from '../api/api';

const initialState = {
  apiPath: null,
  api: new API(),
  authType: AUTH_TYPE.USER,
  user: null,
  authCode: null,
  isLoggedIn: false,
  isLoading: false,
};


export default function (state = initialState, action) {
  let api = null;

  switch (action.type) {
    case INIT_SESSION:
      api = new API({ apiPath: action.apiPath });
      return {
        ...state, apiPath: action.apiPath, authType: action.authType, api,
      };
    case INIT_SESSION_SUCCESS:
      api = new API({ apiPath: state.apiPath, authCode: action.authCode });
      return {
        ...state, isLoggedIn: true, user: action.user, authCode: action.authCode, api,
      };
    case INIT_SESSION_FAILURE:
      return {
        ...state, isLoggedIn: false, user: null, authCode: null,
      };
    case AUTHENTICATED:
      api = new API({ apiPath: state.apiPath, authCode: action.authCode });
      return {
        ...state, user: action.user, authCode: action.authCode, isLoggedIn: true, isLoading: false, api,
      };
    case DO_LOG_OUT:
      return {
        ...state, user: null, authCode: null, isLoggedIn: false, isLoading: false,
      };
    case SIGNEDOUT:
      return {
        ...state, user: null, isLoggedIn: false, isLoading: false, authCode: null,
      };
    case DO_SIGN_IN:
      return {
        ...state, user: null, isLoggedIn: false, isLoading: true,
      };
    case SIGN_IN_ERROR:
      return {
        ...state, user: null, isLoggedIn: false, isLoading: false, authCode: null,
      };
    default:
      return state;
  }
}
