import StringHelper from '../helpers/StringHelper';

function totalMap(title, value) {
  switch (title) {
    case 'totalInvestments':
      return { title: StringHelper.convertFromCamelCase(title), value: StringHelper.formatMoney(value) };
    case 'feesReceived':
      return { title: StringHelper.convertFromCamelCase(title), value: StringHelper.formatMoney(value) };
    case 'investableAssets':
      return { title: StringHelper.convertFromCamelCase(title), value: StringHelper.formatMoney(value) };
    case 'percentInvested':
      return { title: StringHelper.convertFromCamelCase(title), value: StringHelper.formatPercent(value) };
    case 'currentValuation':
      return { title: StringHelper.convertFromCamelCase(title), value: StringHelper.formatMoney(value) };
    default:
      return { title: StringHelper.convertFromCamelCase(title), value };
  }
}

function formatCompanyTotals(totals) {
  return Object.keys(totals).map(key => totalMap(key, totals[key]));
}

function formatFunds(funds) {
  return funds.map(fund => ({
    name: fund.fundName,
    id: fund.id,
    link: `/fund/${fund.id}`,
    totalInvestment: fund.totalInvestment,
    investableAssets: fund.investableAssets,
    percentInvested: fund.percentInvested,
    currentValuation: fund.currentValuation,
  }));
}

export default {
  formatCompany: function formatCompany(response) {
    const company = {};
    company.details = response.details;
    company.totals = formatCompanyTotals(response.totals);
    company.fundsDetails = formatFunds(response.fundsDetails);
    return company;
  },
};
