import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Alert } from 'react-bootstrap';

class Snackbar extends Component {
  static propTypes = {
    autoClose: PropTypes.bool.isRequired,
    type: PropTypes.string.isRequired,
    children: PropTypes.string.isRequired,
    hideSnackbar: PropTypes.func.isRequired,
  };


  constructor() {
    super();
    this.state = {
      isShowing: true,
    };
    this.handleClose = this.handleClose.bind(this);
  }

  componentDidMount() {
    const { autoClose, hideSnackbar } = this.props;
    if (autoClose) {
      this.timer = setTimeout(() => {
        this.setState({
          isShowing: false,
        });
        // clearTimeout(this.timer);
        this.timer = setTimeout(hideSnackbar, 500);
      }, 4000);
    }
  }

  componentWillUnmount() {
    if (this.timer) {
      clearTimeout(this.timer);
    }
  }

  handleClose = () => {
    const { hideSnackbar } = this.props;
    this.setState({
      isShowing: false,
    });
    this.timer = setTimeout(hideSnackbar, 500);
  };

  render() {
    const { type, children, autoClose } = this.props;
    const { isShowing } = this.state;
    const isShow = isShowing ? 'show' : 'hide';

    return (
      <div className={isShow} id="snackbar">
        <Alert variant={type} dismissible={!autoClose} onClose={this.handleClose}>
          {children}
        </Alert>
      </div>
    );
  }
}
export default Snackbar;
