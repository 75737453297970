import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import {
  Container, Card, CardBody, Col, Row,
} from 'reactstrap';
import { Button } from '@mui/material';
import PropTypes from 'prop-types';
import AvDataGrid from '../shared/components/AvDataGrid';
import ConfirmationModal from '../shared/components/ConfirmationModal';
import usePendingInvestmentDetail from '../shared/hooks/usePendingInvestmentDetail';
import createDealStageTableDefinition from './DealStageTableDefinition';
import HorizRecLoader from '../shared/components/Loader/HorizRecLoader';
import { allStagesCompleted } from '../PostWireDashboard';
import { checkIfStageIsEditableByRole } from '../shared/utils/checkUserPermissions';
import { getPendingInvestment, updatePendingInvestment } from '../shared/services/apiGateway';
import generateRequest from '../shared/services/generateRequest';

const createTableData = (stagesData) => {
  if (!stagesData || stagesData.length <= 0) {
    return [];
  }

  const tableData = [];
  for (const stage of stagesData) {
    tableData.push({
      id: stage.hsStageProp,
      stageProperty: stage.stageProperty,
      stageName: stage.title,
      stageCompleted: stage.confirmationProperty,
      stageLink: (stage.triggerProperty || stage.confirmationProperty) ? stage.link : '',
      responsibleParty: stage.party,
      isEditableByRole: checkIfStageIsEditableByRole(stage),
    });
  }

  return tableData;
};

function DealStage({ match }) {
  const pendingInvestmentId = match.params.id;
  const history = useHistory();
  const [dealName, setDealName] = useState('');
  const [stagesCompleted, setStagesCompleted] = useState({});
  const [stagesData, setStagesData] = useState([]);
  const [showPostWireButton, setShowPostWireButton] = useState(false);
  const [isUndoStepConfirmationModalOpen, setUndoStepConfirmationModalOpen] = useState(false);
  const [stageToUndo, setStageToUndo] = useState(null);

  const dealStageDef = createDealStageTableDefinition(pendingInvestmentId, setUndoStepConfirmationModalOpen, setStageToUndo);

  const dealData = usePendingInvestmentDetail(pendingInvestmentId, (deal) => {
    if (deal.companyName) {
      setDealName(deal.companyName);
    } else if (deal.hsDealObj && deal.hsDealObj.dealName) {
      setDealName(deal.hsDealObj.dealName);
    }

    setStagesCompleted(deal.stagesCompleted);
    setStagesData(createTableData((deal.stagesData)));
    setShowPostWireButton((allStagesCompleted(deal.stagesData)));
  });

  const cancelStep = async () => {
    if (!stageToUndo || !dealData || !stagesCompleted || !stagesCompleted[stageToUndo]) {
      setUndoStepConfirmationModalOpen(false);
    }

    // copy the stages completed object
    const updatedStagesCompleted = { ...stagesCompleted };
    // delete the timestamp marking the stage as completed
    delete updatedStagesCompleted[stageToUndo];
    // insert the updated stages into a copy of the deal object
    const updatedDealData = { ...dealData, stagesCompleted: updatedStagesCompleted };
    await updatePendingInvestment(generateRequest(updatedDealData));
    // after updating the deal, re-fetch the deal so that we get the updated
    // stagesCompleted object and stagesData array,
    // including the correct confirmationProperty and triggerProperty for each stage
    const [updatedDeal] = await getPendingInvestment(pendingInvestmentId);
    setStagesCompleted(updatedDeal.stagesCompleted);
    setStagesData(createTableData((updatedDeal.stagesData)));
    setShowPostWireButton((allStagesCompleted(updatedDeal.stagesData)));
    setUndoStepConfirmationModalOpen(false);
    setStageToUndo(null);
  };

  return (
    <>
      <Helmet>
        <title>Deal Closing Status</title>
      </Helmet>
      <Container className="med-width-form">
        <Card>
          <CardBody>
            <Row>
              <Col>
                {dealData && (
                  <h3>Deal Closing Status for {dealName}</h3>
                )}
                {!dealData && HorizRecLoader(1, '40%', 30)}
                <div style={{ marginTop: '20px' }}>
                  <AvDataGrid
                    rows={stagesData}
                    columns={dealStageDef}
                    loading={!dealData}
                    hideFooter
                  />
                </div>
              </Col>
            </Row>
            {showPostWireButton && (
            <Row className="mt-lg-5">
              <Col className="d-flex justify-content-end">
                <Button
                  variant="contained"
                  color="secondary"
                  onClick={() => {
                    history.push(`/post-wire/${pendingInvestmentId}`);
                  }}
                >Post Wire
                </Button>
              </Col>
            </Row>
            )}
          </CardBody>
        </Card>
      </Container>
      <ConfirmationModal
        isOpen={isUndoStepConfirmationModalOpen}
        headerText="Would you like to undo this step?"
        bodyText="No data from this step will be lost by completing this action."
        onConfirm={cancelStep}
        onCancel={() => {
          setUndoStepConfirmationModalOpen(false);
          setStageToUndo(null);
        }}
      />
    </>
  );
}

DealStage.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.node,
    }).isRequired,
  }).isRequired,
};

export default DealStage;
