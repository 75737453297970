import React, { useEffect } from 'react';
import { Col, Row } from 'reactstrap';
import {
  Checkbox, TextField, FormControlLabel, FormGroup, FormLabel,
} from '@mui/material';
import { ClubResources } from '@alumni-ventures-group/react-shared/lib/services';
import PropTypes from 'prop-types';
import { isWhitespaceOnly } from '../shared/utils/validators';

const { greyColor } = ClubResources;

export function YesNoQuestion({
  label,
  value,
  index,
  setAnswer,
}) {
  return (
    <Row className="mt-md-3">
      <Col className="col-9">
        <FormLabel required style={{ color: 'rgb(81 84 97)' }}>{label}</FormLabel>
      </Col>
      <Col className="col-3">
        <FormGroup className="float-right" row>
          <FormControlLabel
            style={{ color: greyColor }}
            control={(
              <Checkbox
                inputProps={{ 'aria-label': 'controlled' }}
                checked={value === true}
                onClick={() => setAnswer(true, index)}
              />
            )}
            label="Yes"
          />
          <FormControlLabel
            style={{ color: greyColor }}
            control={(
              <Checkbox
                inputProps={{ 'aria-label': 'controlled' }}
                checked={value === false}
                onClick={() => setAnswer(false, index)}
              />
            )}
            label="No"
          />
        </FormGroup>
      </Col>
    </Row>
  );
}

export function TextQuestion({
  label,
  value,
  inputLabel,
  index,
  setAnswer,
}) {
  return (
    <>
      <Row className="mt-md-3">
        <Col>
          <FormLabel required style={{ color: 'rgb(81 84 97)' }}>{label}</FormLabel>
        </Col>
      </Row>
      <Row>
        <Col>
          <TextField
            fullWidth
            value={value}
            onChange={(event) => {
              const text = event.target.value;
              if (!isWhitespaceOnly(text) && text.length <= 200) {
                setAnswer(text, index);
              }
            }}
            variant="standard"
            label={inputLabel}
            multiline
            required
            style={{
              marginBottom: '20px',
            }}
          />
        </Col>
      </Row>
    </>
  );
}

// defines the logic for when questions are visible to user
export function isQuestionActive(questionAnswers, questionIndex) {
  if (!questionAnswers[questionIndex]) {
    return false;
  }
  if (questionIndex < 2) {
    return true;
  }
  if (questionIndex === 2 && questionAnswers[0] && questionAnswers[0].value === true) {
    return true;
  }
  if (questionAnswers[0] && questionAnswers[0].value === true && questionAnswers[1] && questionAnswers[1].value === true) {
    return true;
  }
  return false;
}

export function Questions({
  title = 'Questions',
  questionAnswers,
  setAnswerList,
  setAllAnswered,
}) {
  useEffect(() => {
    // check if any of the active questions are unanswered
    const someUnansweredActiveQuestions = questionAnswers.some((question, idx) => (
      isQuestionActive(questionAnswers, idx) && (question.value === null || question.value === '')
    ));
    setAllAnswered(!someUnansweredActiveQuestions);
  }, [questionAnswers]);

  const setAnswer = (value, index) => {
    // update question index with new answer
    const newAnswerList = [...questionAnswers];
    newAnswerList[index] = { ...newAnswerList[index], value };
    setAnswerList(newAnswerList);
  };

  return (
    <>
      <h5 className="mt-5">{title}</h5>
      {isQuestionActive(questionAnswers, 0) && (
        <YesNoQuestion
          label={questionAnswers[0].label}
          value={questionAnswers[0] ? questionAnswers[0].value : null}
          index={0}
          setAnswer={setAnswer}
        />
      )}
      {isQuestionActive(questionAnswers, 1) && (
        <YesNoQuestion
          label={questionAnswers[1].label}
          value={questionAnswers[1] ? questionAnswers[1].value : null}
          index={1}
          setAnswer={setAnswer}
        />
      )}
      {isQuestionActive(questionAnswers, 2) && (
        <YesNoQuestion
          label={questionAnswers[2].label}
          value={questionAnswers[2] ? questionAnswers[2].value : null}
          index={2}
          setAnswer={setAnswer}
        />
      )}
      {isQuestionActive(questionAnswers, 3) && (
        <TextQuestion
          label={questionAnswers[3].label}
          value={questionAnswers[3] ? questionAnswers[3].value : ''}
          inputLabel="Explanation"
          index={3}
          setAnswer={setAnswer}
        />
      )}
      {isQuestionActive(questionAnswers, 4) && (
        <TextQuestion
          label={questionAnswers[4].label}
          value={questionAnswers[4] ? questionAnswers[4].value : ''}
          inputLabel="Wallet Address"
          index={4}
          setAnswer={setAnswer}
        />
      )}
    </>
  );
}

YesNoQuestion.propTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.bool,
  index: PropTypes.number.isRequired,
  setAnswer: PropTypes.func.isRequired,
};

TextQuestion.propTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.string,
  inputLabel: PropTypes.string,
  index: PropTypes.number.isRequired,
  setAnswer: PropTypes.func.isRequired,
};

Questions.propTypes = {
  title: PropTypes.string,
  questionAnswers: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.oneOfType([
        PropTypes.bool,
        PropTypes.string,
      ]),
    }),
  ).isRequired,
  setAnswerList: PropTypes.func.isRequired,
  setAllAnswered: PropTypes.func.isRequired,
};

YesNoQuestion.defaultProps = {
  value: null,
};

TextQuestion.defaultProps = {
  value: '',
  inputLabel: null,
};

Questions.defaultProps = {
  title: 'Questions',
};
