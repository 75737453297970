import { getFunds } from '../../shared/services/bifService';

export const REQUEST_ALL_FUNDS = 'REQUEST_ALL_FUNDS';
export const RECEIVE_ALL_FUNDS = 'RECEIVE_ALL_FUNDS';

export function requestAllFunds() {
  return {
    type: REQUEST_ALL_FUNDS,
  };
}

export function receiveAllFunds(json) {
  return {
    type: RECEIVE_ALL_FUNDS,
    funds: json,
    receivedAt: Date.now(),
  };
}

function fetchAllFunds() {
  return async (dispatch) => {
    dispatch(requestAllFunds());
    return dispatch(receiveAllFunds(await getFunds()));
  };
}

function shouldFetchAllFunds(state) {
  const { funds } = state;
  if (!funds) {
    return true;
  }
  if (funds.isFetching) {
    return false;
  }
  return funds;
}

export function fetchAllFundsIfNeeded() {
  // eslint-disable-next-line consistent-return
  return (dispatch, getState) => {
    if (shouldFetchAllFunds(getState())) {
      return dispatch(fetchAllFunds());
    }
  };
}
