import React from 'react';
import PropTypes from 'prop-types';

const DisplayField = ({ value, handleEdit, disabled }) => (
  <button type="button" style={{ border: 'none' }} onClick={handleEdit} disabled={disabled}>
    <p>{ value }</p>
  </button>
);

DisplayField.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  value: PropTypes.any.isRequired,
  handleEdit: PropTypes.func.isRequired,
  disabled: PropTypes.bool.isRequired,
};

export default DisplayField;
