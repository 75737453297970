import { checkTokenValidity, getHeaders } from '@alumni-ventures-group/av-okta-auth';
import {
  getRequest, postRequest, putS3Request, deleteRequest,
} from './request';

export const getClosedInvestments = async (abortSignal = null) => {
  const headers = await getHeaders();
  const res = await getRequest(
    `${process.env.DEAL_API_PATH}/closed-investments`,
    headers,
    abortSignal,
  );
  return res;
};

export const getClosedInvestment = async (id, abortSignal = null) => {
  const headers = await getHeaders();
  const res = await getRequest(
    `${process.env.DEAL_API_PATH}/closed-investment/${id}`,
    headers,
    abortSignal,
  );
  return res;
};

export const getPendingInvestments = async (abortSignal = null) => {
  const headers = await getHeaders();
  const res = await getRequest(
    `${process.env.DEAL_API_PATH}/pending-investments`,
    headers,
    abortSignal,
  );
  return res;
};

export const getPendingInvestment = async (id, abortSignal = null) => {
  const headers = await getHeaders();
  const res = await getRequest(
    `${process.env.DEAL_API_PATH}/pending-investment/${id}`,
    headers,
    abortSignal,
  );
  return res;
};

export const updatePendingInvestment = async (body) => {
  const headers = await getHeaders();
  const res = await postRequest(
    `${process.env.DEAL_API_PATH}/pending-investment`,
    body,
    headers,
  );
  return res;
};

export const submitPendingInvestment = async (body) => {
  const headers = await getHeaders();
  const res = await postRequest(
    `${process.env.DEAL_API_PATH}/submit-pending-investment`,
    body,
    headers,
  );
  return res;
};

export const createDDReportForDeal = async (body) => {
  const headers = await getHeaders();
  const res = await postRequest(
    `${process.env.DEAL_API_PATH}/due-diligence`,
    body,
    headers,
  );
  return res;
};

export const getPresignedUrl = async (body, abortSignal = null) => {
  const headers = await getHeaders();
  const {
    deal, documenttype = null, filekey = null, fileType = null, filename = null, filedescription = null,
  } = body;
  let documentTypeUrlSafe = '';
  let fileTypeUrlSafe = '';
  let fileKeyUrlSafe = '';
  let fileNameUrlSafe = '';
  let filedescriptionUrlSafe = '';

  if (filename) {
    fileNameUrlSafe = `&filename=${
      encodeURIComponent(filename)
    }`;
  }

  if (filekey) {
    fileKeyUrlSafe = `&filekey=${
      encodeURIComponent(filekey)
    }`;
  }

  if (documenttype) {
    documentTypeUrlSafe = `&documenttype=${
      encodeURIComponent(documenttype)
    }`;
  }

  if (fileType) {
    fileTypeUrlSafe = `&filetype=${encodeURIComponent(fileType)}`;
  }

  if (filedescription) {
    filedescriptionUrlSafe = `&filedescription=${encodeURIComponent(filedescription)}`;
  }
  const res = await getRequest(
    `${process.env.DEAL_API_PATH}/resource-url?deal=${deal}${fileKeyUrlSafe}${documentTypeUrlSafe}${fileTypeUrlSafe}${fileNameUrlSafe}${filedescriptionUrlSafe}`,
    headers,
    abortSignal,
  );
  return res;
};

export const postFileToS3 = async (url, data, fileType, abortSignal = null) => {
  try {
    const accessToken = await checkTokenValidity();
    const headers = { 'Content-Type': fileType, Accept: 'application/json' };
    const res = await putS3Request(
      url,
      data,
      headers,
      abortSignal,
    );
    return res;
  } catch (err) {
    console.log(err);
    return err;
  }
};

export const getDDReportsByCompanyName = async (name, abortSignal = null) => {
  const headers = await getHeaders();
  const res = await getRequest(
    `${process.env.DEAL_API_PATH}/due-diligence/${name}`,
    headers,
    abortSignal,
  );
  return res;
};

export const deleteDocuments = async (body, abortSignal = null) => {
  const headers = await getHeaders();
  const {
    deal,
  } = body;

  const res = await deleteRequest(
    `${process.env.DEAL_API_PATH}/resource-url?deal=${deal}`,
    body,
    headers,
    abortSignal,
  );
  return res;
};

export const getDueDiligenceReport = async (id, abortSignal = null, onError = (error) => error && error.statusCode === 404) => {
  const headers = await getHeaders();
  const res = await getRequest(
    `${process.env.DEAL_API_PATH}/dd-report/${id}`,
    headers,
    abortSignal,
    onError,
  );
  return res;
};

export const publishDueDiligenceReport = async (body) => {
  const headers = await getHeaders();
  const res = await postRequest(
    `${process.env.DEAL_API_PATH}/dd-report-document`,
    body,
    headers,
  );
  return res;
};

export const getPublishedDueDiligenceReport = async (ddReportId) => {
  const headers = await getHeaders();
  const res = await getRequest(
    `${process.env.DEAL_API_PATH}/dd-report-document?ddReportId=${ddReportId}`,
    headers,
  );
  return res;
};
