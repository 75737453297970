import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Tooltip } from 'reactstrap';
import formatTableValues from '../../../../helpers/TableHelper';

const aTag = (fieldName, fieldValue, hrefLink) => (
  <a target="_blank" rel="noopener noreferrer" href={hrefLink}>
    {formatTableValues(fieldName, fieldValue)}
  </a>
);

const CustomizedCellValue = ({ fieldName, fieldValue, bag }) => {
  switch (fieldName) {
    case 'name':
      return (
        bag.link
          ? <Link to={bag.link}>{formatTableValues(fieldName, fieldValue)}</Link>
          : formatTableValues(fieldName, fieldValue)
      );
    case 'fund_tracking_name':
    case 'fund_tracking_email':
      return aTag(fieldName, fieldValue, bag.fund_tracking_name_link);
    case 'fund_tracking_dealName':
      return aTag(fieldName, fieldValue, bag.fund_tracking_dealName_link);
    case 'is_co_investment':
      return (fieldValue === 1) ? 'Yes' : 'No';
    case 'notes': {
      const [tooltipOpen, setTooltipOpen] = useState(false);
      const toggle = () => setTooltipOpen(!tooltipOpen);
      const toolTipId = `notesFull-${bag.id}`;

      return (
        <div>
          <span href="#" id={toolTipId}> {fieldValue} </span>
          <Tooltip placement="left" isOpen={tooltipOpen} target={toolTipId} toggle={toggle}>
            {fieldValue}{bag.fullNotes}
          </Tooltip>
        </div>
      );
    }
    default:
      return formatTableValues(fieldName, fieldValue);
  }
};

export default CustomizedCellValue;
