import jwt from 'jsonwebtoken';
import { loginAVAdminUser } from '../services/apiGateway';
import Token from '../models/Token';
import User from '../models/User';

export const loginUserWithAccessToken = async (accessToken) => {
  if (accessToken) {
    const tokenCache = new Token();
    const userCache = new User();
    const payload = {
      authProviderData: { oktaAccessToken: accessToken },
      provider: 'Okta',
    };

    // decrypt the access token from res
    const res = await loginAVAdminUser(payload);
    tokenCache.set(res);
    const serverAccessToken = res.access_token;
    const decodedUserInfo = jwt.decode(serverAccessToken).userInfo;

    if (!decodedUserInfo || (decodedUserInfo.roles && decodedUserInfo.roles.length === 0)) {
      history.push('/page-not-found');
      window.localStorage.clear();
      return;
    }

    const { endpoints, ...publicUserInfo } = decodedUserInfo;
    userCache.setUser(publicUserInfo);

    return publicUserInfo;
  }
};