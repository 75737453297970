import React, { forwardRef } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';


const SearchResult = forwardRef(
  ({
    type, id, closeSearch, className, title, handleKeyDown, index, focused, linkTail,
  }, ref) => {
    const selected = (focused && 'collapse__content__focus');
    return (
      <Link to={`/${type}/${id}${linkTail}`} onClick={closeSearch} ref={ref} onKeyDown={e => handleKeyDown(e, index)}>
        <div className={`collapse__wrapper closed ${className} ${selected} mx-5`} dir="ltr">
          <button className={`collapse__title ${selected}`} type="button">
            <p>{title}</p>
          </button>
        </div>
      </Link>
    );
  },
);

SearchResult.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  id: PropTypes.any.isRequired,
  index: PropTypes.number.isRequired,
  title: PropTypes.string,
  linkTail: PropTypes.string,
  className: PropTypes.string,
  type: PropTypes.string.isRequired,
  closeSearch: PropTypes.func.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  handleKeyDown: PropTypes.func.isRequired,
  focused: PropTypes.bool,
};

SearchResult.defaultProps = {
  title: '',
  className: '',
  focused: false,
  linkTail: '',
};

export default SearchResult;
