import React from 'react';
import {
  Card, CardBody, Col,
} from 'reactstrap';
import { reduxForm } from 'redux-form';
import PropTypes from 'prop-types';
import EditFormInline from './EditFormInline';

const EditForm = ({
  handleSubmit, cancel, data,
}) => (
  <Col md={12} lg={12}>
    <Card>
      <CardBody>
        <EditFormInline handleSubmit={handleSubmit} cancel={cancel} data={data} />
      </CardBody>
    </Card>
  </Col>
);

EditForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  cancel: PropTypes.func.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  data: PropTypes.any.isRequired,
};

export default reduxForm({
  form: 'floating_labels_form', // a unique identifier for this form
})(EditForm);
