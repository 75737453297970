/* eslint-disable max-len */
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import {
  Button, Progress,
} from 'reactstrap';
import Loader from '../loader/Loader';


class TwoStepModal extends PureComponent {
  static propTypes = {
    closeModal: PropTypes.func.isRequired,
    // eslint-disable-next-line react/forbid-prop-types
    liUser: PropTypes.object.isRequired,
    // eslint-disable-next-line react/forbid-prop-types
    auth: PropTypes.object.isRequired,
  };

  static defaultProps = {
  };

  constructor() {
    super();
    this.state = {
      stage: 1,
      remainingTime: null,
    };

    this.pinInput = React.createRef();

    this.initializeSession = this.initializeSession.bind(this);
    this.setPin = this.setPin.bind(this);

    setTimeout(() => {
      this.nextSecond();
    }, 1000);
  }

  setStage(stage) {
    if (stage === 'invalidPin' || stage === 5 || stage === 'timeout') {
      this.setState(prevState => ({ ...prevState, stage, remainingTime: null }));
    } else {
      this.setState(prevState => ({ ...prevState, stage }));
    }
  }

  setPin() {
    const { auth } = this.props;
    // const { pinValue } = this.state;
    const pinValue = this.pinInput.current.value;
    // console.error(pinValue);

    this.setStage(4);
    auth.api.post('api/v2.0/mobile/two-step/pin', { pin: pinValue })
      .then(() => {
        this.waitForConfirmation();
      });
    // this.setStage(4);
    // setTimeout(() => {
    //   this.setStage(5);
    // }, 5000);
  }


  nextSecond() {
    // eslint-disable-next-line arrow-body-style
    this.setState((prevState) => {
      let rtime = prevState.remainingTime;
      if (rtime === null) {
        return prevState;
      }

      // eslint-disable-next-line operator-assignment
      rtime = rtime - 1;

      if (rtime > 0) {
        return { ...prevState, remainingTime: prevState.remainingTime - 1 };
        // eslint-disable-next-line no-else-return
      } else {
        return { ...prevState, remainingTime: null, stage: 'timeout' };
      }
    });

    setTimeout(() => {
      this.nextSecond();
    }, 1000);
  }

  waitForConfirmation() {
    this.confirmationFrom = new Date();
    this.waitForConfirmationLoop();
  }

  waitForConfirmationLoop() {
    const { auth } = this.props;

    auth.api.get('api/v2.0/mobile/two-step/wait-for-error')
      .then((res) => {
        const curTime = new Date();
        let isTimedOut = false;

        if (curTime.getTime() - this.confirmationFrom.getTime() > 120 * 1000) {
          // wait for 2 minutes max
          isTimedOut = true;
        }

        if (res && res.success && res.lastError === '') {
          this.setStage(5);
        } else if (res && res.success && res.lastError === 'Two-step verification required') {
          setTimeout(() => {
            this.waitForConfirmationLoop();
          }, 1000);
        } else if (isTimedOut) {
          this.setStage('invalidPin');
        } else {
          setTimeout(() => {
            this.waitForConfirmationLoop();
          }, 1000);
        }
      });
  }

  initializeSession() {
    const { auth } = this.props;

    this.setStage(2);

    this.setState(prevState => ({ ...prevState, remainingTime: 600 }));

    auth.api.post('api/v2.0/mobile/two-step/initialize')
      .then(() => {
        this.waitFor2x();
      });
  }

  waitFor2x() {
    this.twoStepWaitingFrom = new Date();
    this.waitFor2xLoop();
  }

  waitFor2xLoop() {
    const { auth } = this.props;

    auth.api.get('api/v2.0/mobile/two-step/wait-for-error')
      .then((res) => {
        const curTime = new Date();
        let isTimedOut = false;

        if (curTime.getTime() - this.twoStepWaitingFrom.getTime() > 120 * 1000) {
          // wait for 2 minutes max for 2x verification start
          isTimedOut = true;
        }

        if (res && res.success && res.lastError === 'Two-step verification required') {
          this.setStage(3);
        } else if (isTimedOut) {
          this.setStage(5);
        } else {
          setTimeout(() => {
            this.waitFor2xLoop();
          }, 1000);
        }
      });
  }

  // showPassword = (e) => {
  //   e.preventDefault();
  //   // this.setState(prevState => ({ showPassword: !prevState.showPassword }));
  // };

  render() {
    const {
      closeModal,
      liUser,
    } = this.props;

    const {
      stage,
      remainingTime,
    } = this.state;

    let remainingMinutes = null;
    let remainingSeconds = null;
    let remainingPercent = 100;
    if (remainingTime !== null) {
      remainingMinutes = Math.floor(remainingTime / 60);
      remainingSeconds = remainingTime % 60;
      remainingPercent = Math.floor((remainingTime / 600) * 100);
    }

    let header = 'LinkedIn Scanning Paused';
    if (stage === 'timeout') {
      header = 'Timeout Error';
    }
    if (stage === 'invalidPin') {
      header = 'Invalid Pin Error';
    }
    if (stage === 5) {
      header = 'Success';
    }

    return (
      <div className="modal-content fdo-roboto">
        <div className="modal-header">
          <h5 className="modal-title">
            <span className="fdo-text-bold-md">
              {header}
            </span>
          </h5>
          <button type="button" className="close" aria-label="Close" onClick={closeModal}>
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div className="modal-body theme-light">


          <div className="twoStep-step-1 form" style={{ display: stage === 1 ? 'block' : 'none' }}>

            <p>LinkedIn requires us to re-authenticate your login. This process may take up to 10 minutes to complete.</p>

            <p style={{ textAlign: 'center' }}>When you are ready, please click <strong>Start</strong>.</p>

            <div style={{ textAlign: 'center' }}>

              <Button
                size="sm"
                color="primary"
                className=""
                style={{ margin: '0' }}
                onClick={this.initializeSession}
              >
                Start
              </Button>
            </div>
          </div>

          <div className="twoStep-step-2 form" style={{ display: stage === 2 ? 'block' : 'none' }}>

            <p>LinkedIn requires us to re-authenticate your login. This process may take up to 10 minutes to complete.</p>

            <div style={{ textAlign: 'center', margin: '10px' }}>
              <p>Starting...</p>

              <div className="text-center" style={{ minHeight: '70px', paddingTop: '20px', width: '100%' }}>
                <Loader />
              </div>
            </div>

          </div>

          <div className="twoStep-step-3 form" style={{ display: stage === 3 ? 'block' : 'none' }}>

            <p>LinkedIn requires us to re-authenticate your login. This process may take up to 10 minutes to complete.</p>

            <h4 style={{ marginTop: '15px' }}>Enter LinkedIn PIN sent via email</h4>

            <p>Check your {liUser.un} inbox for a message from LinkedIn. We will wait for up to 10 minutes.</p>
            <div>

              <div style={{ textAlign: 'center', margin: '10px' }}>
                <strong>LinkedIn Pin:&nbsp;&nbsp;</strong>
                <input
                  name="pinInput"
                  type="number"
                  placeholder=""
                  ref={this.pinInput}
                  style={{ borderWidth: '2px', width: '100px' }}
                />
              </div>

              <div style={{ textAlign: 'center' }}>
                <Button
                  size="sm"
                  color="primary"
                  style={{ margin: '0' }}
                  onClick={this.setPin}
                >
                  Submit
                </Button>
              </div>
            </div>


          </div>

          <div className="twoStep-step-4 form" style={{ display: stage === 4 ? 'block' : 'none' }}>
            <p>LinkedIn requires us to re-authenticate your login. This process may take up to 10 minutes to complete.</p>

            <div style={{ textAlign: 'center', margin: '10px' }}>
              <p>Authorizing LinkedIn Pin...</p>

              <div className="text-center" style={{ minHeight: '70px', paddingTop: '20px', width: '100%' }}>
                <Loader />
              </div>
            </div>
          </div>

          <div className="twoStep-step-5 form" style={{ display: stage === 5 ? 'block' : 'none' }}>
            <div>
              <p>LinkedIn scanning will resume. Please check back in later to see progress
              </p>

              <Button
                size="sm"
                color="primary"
                style={{ margin: '0' }}
                onClick={closeModal}
              >
                Close
              </Button>
            </div>
          </div>

          <div className="twoStep-step-invalidPin form" style={{ display: stage === 'invalidPin' ? 'block' : 'none' }}>
            <div>
              <p>The LinkedIn Pin entered was invalid.</p>
              <p>Please click <strong>Restart</strong> to
                restart the process or click <strong>Close</strong> to try again later.
              </p>

              <div style={{ textAlign: 'center', margin: '10px' }}>
                <Button
                  size="sm"
                  color="primary"
                  style={{ margin: '0' }}
                  onClick={this.initializeSession}
                >
                  Restart
                </Button>
                &nbsp;&nbsp;&nbsp;
                <Button
                  size="sm"
                  color="primary"
                  style={{ margin: '0' }}
                  onClick={closeModal}
                >
                  Close
                </Button>
              </div>

            </div>
          </div>


          <div className="twoStep-step-timeout form" style={{ display: stage === 'timeout' ? 'block' : 'none' }}>
            <div>
              <p>LinkedIn Pin was not entered within the time limit.</p>
              <p>Please click <strong>Restart</strong> to
                restart the process or click <strong>Close</strong> to try again later.
              </p>

              <div style={{ textAlign: 'center', margin: '10px' }}>
                <Button
                  size="sm"
                  color="primary"
                  style={{ margin: '0' }}
                  onClick={this.initializeSession}
                >
                  Restart
                </Button>
                &nbsp;&nbsp;&nbsp;
                <Button
                  size="sm"
                  color="primary"
                  style={{ margin: '0' }}
                  onClick={closeModal}
                >
                  Close
                </Button>
              </div>

            </div>
          </div>


          <div className="twoStep-progress" style={{ marginTop: '15px' }}>
            <div style={{ display: remainingTime > 0 ? 'block' : 'none' }}>
              <p>Time Remaining: {remainingMinutes} minute{remainingMinutes === 1 ? '' : 's'} {remainingSeconds} second{remainingSeconds === 1 ? '' : 's'}...</p>
              <div className="progress-wrap">
                <Progress value={remainingPercent} />
              </div>
            </div>
          </div>

        </div>
      </div>
    );
  }
}

export default TwoStepModal;
