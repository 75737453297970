import { useState, useEffect } from 'react';

function useFilteredBySearch(arr, query, closed = false) {
  const [filteredData, setFilteredData] = useState([]);

  useEffect(() => {
    if (Array.isArray(arr)) {
      const data = arr.filter((item) => item && (closed ? item.processStage === 2 : item.processStage !== 2));
      if (!query) {
        setFilteredData(data);
      } else {
        const lowQuery = `${query}`.toLowerCase();
        const filtered = data.filter((el) => `${el.companyName}`.toLowerCase().includes(lowQuery));
        setFilteredData(filtered);
      }
    }
  }, [arr, query]);

  return filteredData;
}

export default useFilteredBySearch;
