/* eslint-disable react/forbid-prop-types */
import { chunk } from 'lodash';
import * as React from 'react';
import PropTypes from 'prop-types';
import { Col, Row } from 'reactstrap';

const GridGenerator = ({ cols, children }) => {
  const colWidth = 12 / cols;
  let increment = 0;
  let incrementCol = 0;
  const rows = chunk(React.Children.toArray(children), cols);
  return (
    <>
      {rows.map((columns) => {
        increment += 1;
        return (
          <Row key={increment}>
            {columns.map((col) => {
              incrementCol += 1;
              return (
                <Col key={incrementCol} sm={12} md={colWidth}>
                  {col}
                </Col>
              );
            })}
          </Row>
        );
      })}
    </>
  );
};

GridGenerator.propTypes = {
  cols: PropTypes.any.isRequired,
  children: PropTypes.any.isRequired,
};

export default GridGenerator;
