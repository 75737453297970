/* eslint-disable eqeqeq */
import stateMap from './StateMap';
import countryMap from './CountryMap';
import {
  // validatePhone,
  validateEmail,
} from './shared';

const k1ConsentArray = [
  { label: 'Paper and Electronic', value: 0 },
  { label: 'Electronic Only', value: 1 },
  { label: 'No preference', value: 2 },
];

export default class ClubsSettings {
  constructor(user) {
    this.accountSettings = {
      label: 'Account Settings',
      type: 'sectionTitle',
    };
    this.email1 = {
      label: 'Primary Email',
      type: 'textOnly',
      value: user.email1,
      validators: [validateEmail],
    };
    this.email2 = {
      label: 'Secondary Email',
      type: 'textOnly',
      value: user.email2,
      validators: [validateEmail],
    };
    this.contactInfo = {
      label: 'Contact Information',
      type: 'sectionTitle',
    };
    this.firstName = {
      label: 'Legal First Name',
      type: 'text',
      readOnly: false,
      value: user.firstName,
      required: true,
    };
    this.middleName = {
      label: 'Legal Middle Name',
      type: 'text',
      readOnly: false,
      value: user.middleName,
    };
    this.lastName = {
      label: 'Legal Last Name',
      type: 'text',
      readOnly: false,
      value: user.lastName,
      required: true,
    };
    this.nameSuffix = {
      label: 'Legal Name Suffix',
      type: 'text',
      readOnly: false,
      value: user.nameSuffix,
    };
    this.homePhone = {
      label: 'Home Phone',
      type: 'text',
      readOnly: false,
      value: user.homePhone,
      // validators: [validatePhone],
    };
    this.mobilePhone = {
      label: 'Mobile Phone',
      type: 'text',
      readOnly: false,
      value: user.mobilePhone,
      // validators: [validatePhone],
    };
    this.taxInfo = {
      label: 'Tax Information',
      type: 'sectionTitle',
    };
    this.k1Consent = {
      label: 'K1 Tax Statement Preference',
      type: 'select',
      readOnly: false,
      value: user.k1Consent,
      options: k1ConsentArray,
    };
    this.primaryAddress = {
      label: 'Primary Residential Address',
      type: 'sectionTitle',
    };
    this.country = {
      label: 'Country',
      type: 'select',
      readOnly: false,
      display: countryMap.map(
        country => country.value === user.country && country.label,
      ),
      value: user.country,
      options: countryMap,
    };
    this.address1 = {
      label: 'Address 1',
      type: 'text',
      readOnly: false,
      value: user.address1,
    };
    this.address2 = {
      label: 'Address 2',
      type: 'text',
      readOnly: false,
      value: user.address2,
    };
    this.city = {
      label: 'City',
      type: 'text',
      readOnly: false,
      value: user.city,
    };
    this.state = {
      hidden: user.country !== 'US',
      label: 'State',
      type: 'select',
      readOnly: false,
      display: stateMap.map(
        state => state.value === user.state && state.label,
      ),
      value: user.state,
      options: stateMap,
    };
    this.province = {
      hidden: user.country === 'US',
      label: 'Province',
      type: 'text',
      readOnly: false,
      value: user.province,
    };
    this.postalCode = {
      label: 'Postal Code',
      type: 'text',
      readOnly: false,
      value: user.postalCode,
    };
    this.profile_picture = {
      type: 'fileinput',
      readOnly: false,
      hidden: true,
    };
    this.imageUri = {
      type: null,
      readOnly: true,
      value: user.avatar,
      previewClass: 'logoImgCircle',
      acceptedTypes: 'image/jpeg, image/png, image/gif',
      hidden: true,
    };
  }
}
