import * as React from 'react';
import {
  Route, Switch,
} from 'react-router-dom';
import { SecureRoute } from '@okta/okta-react';
import { User } from '@alumni-ventures-group/av-okta-auth';
import Layout from '../Layout';
import ClosedInvestments from '../ClosedInvestments';
import ClosedInvestmentSummary from '../ClosedInvestmentSummary';
import PendingInvestments from '../PendingInvestments';
import PendingInvestmentDetail from '../PendingInvestmentDetail';
import NameValidation from '../NameValidation';
import DDReportSelector from '../DueDiligenceReportSelector';
import DataRecPage from '../DataReconciliationPage';
import ApproveWireExecution from '../ApproveWireExecution';
import ClosingDocsReview from '../ClosingDocsReview';
import SignDealDocs from '../SignDealDocs';
import LoginCallback from '../LoginCallback';
import LoginRedirect from '../Login';
import UploadWireInfo from '../UploadWireInfo';
import UploadClosingDocs from '../UploadClosingDocs';
import VerifyWireInfo from '../VerifyWireInfo';
import EditAQSplits from '../AQSplits/AQSplitsEditable';
import NotFound from '../NotFoundPage';
import EntityNameSignatureBlock from '../EntityNameSignatureBlock';
import UserLogout from '../shared/components/UserLogout';
import WireSignOff from '../WireSignOff';
import AVSnackbar from '../shared/components/SnackBar';
import DealDashboard from '../DealDashboard';
import DealStage from '../DealStage';
import ProofOfWire from '../ProofOfWire';
import PostWireDashboard from '../PostWireDashboard';
import PostWireSignOffNetSuiteSubmission from '../PostWireSignOffNetSuiteSubmission';
import DueDiligenceReportTypeSelector from '../DueDiligenceReportTypeSelector';
import DueDiligenceS3FileViewer from '../DueDiligenceS3FileViewer';
import PublishDueDiligenceReport from '../PublishDueDiligenceReport';

function SecureRoutes() {
  const userStorage = new User();
  const user = userStorage.get();
  return (user && (
    <Switch>
      <SecureRoute path="/" exact component={DealDashboard} />
      <SecureRoute path="/closed-investments" exact component={ClosedInvestments} />
      <SecureRoute path="/closed-investment/:id" exact component={ClosedInvestmentSummary} />
      <SecureRoute path="/pending-investments" exact component={PendingInvestments} />
      <SecureRoute path="/deal-stages/:id" exact component={DealStage} />
      <SecureRoute path="/pending-investment/:id" exact component={PendingInvestmentDetail} />
      <SecureRoute path="/pending-investment/:id/name-verification" exact component={NameValidation} />
      <SecureRoute path="/pending-investment/:id/dd-report" exact component={DDReportSelector} />
      <SecureRoute path="/pending-investment/:id/data-rec-selector" exact component={DataRecPage} />
      <SecureRoute path="/entity-name-signature-block/:id" exact component={EntityNameSignatureBlock} />
      <SecureRoute path="/edit-aq-splits/:id" exact component={EditAQSplits} />
      <SecureRoute path="/upload-wire-info/:id" exact component={UploadWireInfo} />
      <SecureRoute path="/verify-wire-info/:id" exact component={VerifyWireInfo} />
      <SecureRoute path="/upload-closing-docs/:id" exact component={UploadClosingDocs} />
      <SecureRoute path="/closing-docs-review/:id" exact component={ClosingDocsReview} />
      <SecureRoute path="/sign-deal-docs/:id" exact component={SignDealDocs} />
      <SecureRoute path="/wire-sign-off/:id" exact component={WireSignOff} />
      <SecureRoute path="/approve-wire-execution/:id" exact component={ApproveWireExecution} />
      <SecureRoute path="/upload-proof-of-wire/:id" exact component={ProofOfWire} />
      <SecureRoute path="/post-wire" exact component={PostWireDashboard} />
      <SecureRoute path="/post-wire/:id" exact component={PostWireSignOffNetSuiteSubmission} />
      <SecureRoute path="/dd-report/:id" exact component={DueDiligenceReportTypeSelector} />
      <SecureRoute path="/dd-report/:id/publish" exact component={PublishDueDiligenceReport} />
      <SecureRoute path="/dd-report/:id/s3-file" exact component={DueDiligenceS3FileViewer} />
      <SecureRoute path="/*" component={NotFound} />
    </Switch>
  ));
}

function Router() {
  const userStorage = new User();
  const user = userStorage.get();
  return (
    <div className="theme-light">
      <div className="wrapper top-navigation">
        <main>
          <div>
            <Layout loading={!user} />
            <AVSnackbar />
            <div className="container__wrap" style={{ padding: '30px' }}>
              <Switch>
                <Route path="/login/callback" component={LoginCallback} />
                <Route path="/login" component={LoginRedirect} />
                <Route path="/logout" exact component={UserLogout} />
                <Route path="/page-not-found" exact component={NotFound} />
                <SecureRoute path="/" component={SecureRoutes} />
              </Switch>
            </div>
          </div>
        </main>
      </div>
    </div>
  );
}

export default Router;
