import { getRequest, postRequest, postRequestFormData } from './request';
import fundService, { formatTrackingFund, reFormatTrackingFund } from './fundService';
import { formatInvestor } from './investorService';
import { breakUpQuestionsByCategory, formatAvailableSyndications } from './scoreCardService';
import investmentService from './investmentService';
import companyService from './companyService';

const fillFormData = (body) => {
  const data = new FormData();
  Object.keys(body).forEach((key) => {
    data.append(key, (body[key] && body[key].file) ? body[key].file : body[key]);
  });
  return data;
};

export const getFunds = async () => getRequest('/api/v2.0/funds/get-search-data');

export const getFundTrackingData = async (id) => {
  const dataPath = `/api/v2.0/collections/get-fund-tracking?id=${id}`;
  const response = await getRequest(dataPath);
  return formatTrackingFund(response);
};

export const postFundTrackingData = async (body) => {
  await postRequest('/api/v2.0/collections/save-attributes', reFormatTrackingFund(body));
};

export const startDownloadSA = async (fundId) => {
  const response = await getRequest(`/api/v2.0/investor-reports-download/document-ids?fund_id=${fundId}`);
  return response;
};

export const terminateDownloadSA = async () => {
  const response = await getRequest('/api/v2.0/investor-reports-download/terminate-sa-download');
  return response;
};

export const downloadFromDocusign = async (closingDocId) => {
  const path = `/api/v2.0/investor-reports-download/download-from-docusign?closing_doc_id=${closingDocId}`;
  const response = await getRequest(path);
  return response;
};

export const downloadCsvBulkImport = async (params) => {
  const response = await getRequest(`/api/v2.0/investor-reports-download/bulk-reports-csv?${params}`);
  return response;
};

export const getTotalsAllFunds = async (date) => {
  try {
    const response = await getRequest(`/api/v2.0/funds/get-grid-totals?set_of_date=${date}`);
    return fundService.formatAllTotals(response);
  } catch (error) {
    return error;
  }
};

export const getFund = async (id, date) => {
  const promiseArray = [
    getRequest(`/api/v2.0/funds/get-details?id=${id}&set_of_date=${date}`),
    // eslint-disable-next-line max-len
    getRequest(`/api/v2.0/funds/get-investors?columns[99][data]=funds_fund_id&columns[0][data]=groups_group_id&columns[99][search][value]=${id}`)];
  try {
    const response = await Promise.all(promiseArray);
    return fundService.formatFundData(response);
  } catch (error) {
    throw error;
  }
};

export const postFund = async (body) => {
  await postRequest('/api/v2.0/funds/save-fund', body);
};

export const getUsersForSearch = async (investorsOnly, abortSignal) => (
  getRequest(`/api/v2.0/investors/search-users?investors=${investorsOnly}`, abortSignal)
);

export const getInvestor = async (id) => {
  const promiseArray = [
    getRequest(`/api/v2.0/investors/get-investor?id=${id}`),
    getRequest(`/api/v2.0/investors/get-investments?id=${id}`),
  ];
  try {
    const response = await Promise.all(promiseArray);
    return formatInvestor(response);
  } catch (error) {
    throw error;
  }
};

export const getInvestmentsByPortcoId = async (id) => {
  const response = await getRequest(`/api/v2.0/investments/get-portco-info?id=${id}`);
  return response;
};

export const getInvestment = async (name) => {
  const promiseArray = [
    getRequest(`/api/v2.0/investments/investment-total-by-name?name=${name}`),
    getRequest(`/api/v2.0/investments/investments-by-name?name=${name}`),
  ];
  try {
    const response = await Promise.all(promiseArray);
    return investmentService.formatInvestment(response);
  } catch (error) {
    throw error;
  }
};

export const getInvestmentById = async (id) => {
  try {
    const response = await getRequest(`/api/v2.0/investments/investment-by-id?id=${id}`);
    return response.investment;
  } catch (error) {
    return error;
  }
};

export const getCompanies = async abortSignal => getRequest('/api/v2.0/companies/get-companies-list', abortSignal);

export const getSponsorFunds = async (abortSignal) => {
  const res = await getRequest('/api/v2.0/club/get-sponsor-funds-list', abortSignal);
  return res;
};

export const getCompany = async id => companyService.formatCompany(
  await getRequest(`/api/v2.0/companies/details?id=${id}`),
);

export const postCompany = async (body) => {
  await postRequestFormData('/api/v2.0/companies/save-company', fillFormData(body));
};

export const postInvestmentForInvestor = async (body) => {
  await postRequest('/api/v2.0/investors/save-investment', body);
};

export const postInvestment = async (body) => {
  await postRequest('/api/v2.0/investments/save-investment', body);
};

export const postSavePortcoInvestment = async (body) => {
  await postRequest('/api/v2.0/investments/save-portco-investment', body);
};

export const getPortcoInvestmentPriceChangeDate = async (id, abortSignal) => {
  const res = await getRequest(`/api/v2.0/investments/get-portco-investment-price-change-date?id=${id}`, abortSignal);
  return res;
};

export const createUser = async body => postRequest('/api/v2.0/club/create-user', body);

export const createMember = async (body, abortSignal) => postRequest('/api/v2.0/club/create-member', body, abortSignal);

export const editClubMember = async body => (postRequestFormData('/api/v2.0/club/edit-member', fillFormData(body)));

export const postSaveSettings = async (body) => {
  await postRequestFormData('/api/v2.0/club/save-settings', fillFormData(body));
};

export const postInvestor = async (body) => {
  await postRequest('/api/v2.0/funds/save-investor', body);
};

export const getClub = async (id, abortSignal) => {
  const res = await getRequest(`/api/v2.0/club/get-club?id=${id}`, abortSignal);
  return res;
};

export const getAllClubDeals = async (abortSignal) => {
  const res = await getRequest('/api/v2.0/club-admin/get-all-club-deals', abortSignal);
  return res;
};

export const getClubs = async abortSignal => getRequest('/api/v2.0/club/get-clubs', abortSignal);

export const getAdminClubs = async abortSignal => getRequest('/api/v2.0/club-admin/get-admin-clubs', abortSignal);

export const postEditAdminClubs = async (body) => {
  await postRequestFormData('/api/v2.0/club-admin/admin-edit-club', fillFormData(body));
};

export const postEditAdminMember = async body => postRequestFormData(
  '/api/v2.0/club-admin/admin-edit-member', fillFormData(body),
);

export const postCreateAdminMember = async (body) => {
  const res = await postRequestFormData('/api/v2.0/club-admin/admin-create-member', fillFormData(body));
  return res;
};

export const postSpyLoginAsMember = async (id) => {
  const res = await postRequest('/api/v2.0/club-admin/spy-login', id);
  return res;
};

export const getPresident = async (id, abortSignal) => {
  const res = await getRequest(`/api/v2.0/club/get-club-president?id=${id}`, abortSignal);
  return res;
};

export const getAvailableSyndications = async (abortSignal) => {
  const res = await getRequest('/api/v2.0/club-admin/get-available-syndications', abortSignal);
  return res;
};

export const getFeaturedSyndications = async (abortSignal) => {
  const res = await getRequest('/api/v2.0/club-admin/get-featured-syndications', abortSignal);
  return res;
};

export const getAvailableSyndicationsById = async (syndIds, abortSignal) => {
  const res = await getRequest(`/api/v2.0/club-admin/get-available-syndications-byid?syndIds=${syndIds}`, abortSignal);
  return res;
};

export const getFeaturedSyndicationsById = async (syndIds, abortSignal) => {
  const res = await getRequest(`/api/v2.0/club-admin/get-featured-syndications-byid?syndIds=${syndIds}`, abortSignal);
  return res;
};

export const getClubAvailableSyndications = async (id, abortSignal) => {
  const res = await getRequest(`/api/v2.0/club/get-club-available-syndications?id=${id}`, abortSignal);
  return formatAvailableSyndications(res);
};

export const getClubFeaturedSyndications = async (id, abortSignal) => {
  const res = await getRequest(`/api/v2.0/club/get-club-featured-syndications?id=${id}`, abortSignal);
  return res;
};

export const getClubName = async (id, abortSignal) => {
  const res = await getRequest(`/api/v2.0/club/get-club-name?id=${id}`, abortSignal);
  return res;
};

export const getMembers = async (id, abortSignal = null) => {
  const res = await getRequest(`/api/v2.0/club/get-all-club-members?id=${id}`, abortSignal);
  return res;
};

export const getAdminClubMembers = async (id, abortSignal = null) => {
  const res = await getRequest(`/api/v2.0/club-admin/get-all-club-members?id=${id}`, abortSignal);
  return res;
};

export const getAdminClubLogo = async (id, abortSignal = null) => {
  const res = await getRequest(`/api/v2.0/club-admin/get-club-logo?id=${id}`, abortSignal);
  return res;
};

export const adminRemoveMemberFromClub = async (body) => {
  await postRequest('/api/v2.0/club-admin/remove-member-from-club', body);
};

export const verifyRecaptcha = async (token) => {
  const res = await getRequest(`/investor/login/get-recaptcha?token=${token}`);
  return res;
};

export const postAdminCreateClub = async (body, abortSignal = null) => {
  const res = await postRequestFormData('/api/v2.0/club-admin/create-club', fillFormData(body), abortSignal);
  return res;
};

export const createClub = async (body, abortSignal = null) => {
  const res = await postRequestFormData('/api/v2.0/club/create-club', body, abortSignal);
  return res;
};

export const updateClub = async body => postRequestFormData('/api/v2.0/club/edit-club', body);

export const sendClubEmail = async body => postRequest('/api/v2.0/club/send-email', body);

export const updateClubsAvalSyndMap = async body => postRequest('/api/v2.0/club-admin/update-club-aval-synd-map', body);

export const updateClubsHighSyndMap = async body => postRequest('/api/v2.0/club-admin/update-club-high-synd-map', body);

export const postAddClubFund = async (body, abortSignal = null) => {
  const res = await postRequest('/api/v2.0/club/add-club-fund', body, abortSignal);
  return res;
};

export const getFundsAssignedToClub = async (clubId, abortSignal) => {
  const res = await getRequest(`/api/v2.0/club/get-funds-assigned-to-club?clubId=${clubId}`, abortSignal);
  return res;
};

export const getInvestorFunds = async (abortSignal) => {
  const res = await getRequest('/api/v2.0/club/get-available-funds', abortSignal);
  return res;
};

export const getAnnouncement = async (id, abortSignal) => {
  const res = await getRequest(`/api/v2.0/club/get-announcement?id=${id}`, abortSignal);
  return res;
};

export const getSyndication = async (id, abortSignal = null) => {
  const res = await getRequest(`/api/v2.0/club/get-syndication?id=${id}`, abortSignal);
  return res;
};

export const getSyndicationName = async (syndId, abortSignal = null) => {
  const res = await getRequest(`/api/v2.0/club/get-syndication-name?syndId=${syndId}`, abortSignal);
  return res;
};

export const getClubResourcePageData = async (id, abortSignal = null) => {
  const res = await getRequest(`/api/v2.0/club/get-club-resource-page-data?id=${id}`, abortSignal);
  return res;
};

export const setClubResourcePageData = async (body) => {
  await postRequest('/api/v2.0/club/set-club-resource-page-text', body);
};

export const uploadClubResourceFile = async (body, abortSignal = null) => {
  const res = await postRequestFormData('/api/v2.0/club/upload-club-resource-file', body, abortSignal);
  return res;
};

export const updateClubResourceFiles = async (body, abortSignal = null) => {
  const res = await postRequest('/api/v2.0/club/update-club-resource-file', body, abortSignal);
  return res;
};

export const deleteClubResourceFile = async (body, abortSignal = null) => {
  const res = await postRequestFormData('/api/v2.0/club/delete-club-resource-file', body, abortSignal);
  return res;
};

export const getAnnouncements = async (id, abortSignal = null) => {
  const res = await getRequest(`/api/v2.0/club/get-all-club-announcements?id=${id}`, abortSignal);
  return res;
};

export const deleteAnnouncement = async body => postRequest('/api/v2.0/club/delete-announcement', body);

export const sendAnnouncement = async body => postRequest('/api/v2.0/club/send-announcement-email', body);

export const createNewAnnouncement = async (body, abortSignal = null) => postRequestFormData(
  '/api/v2.0/club/create-announcement', body, abortSignal,
);

export const createMultiClubAnnouncement = async (body, abortSignal = null) => postRequestFormData(
  '/api/v2.0/club/create-multi-club-announcement', body, abortSignal,
);

export const getDictonaryForClubs = async (id, abortSignal = null) => {
  const res = await getRequest('/api/v2.0/dictionary/get-club-dictionary', abortSignal);
  return res;
};

export const submitClubDeal = async body => postRequest('/api/v2.0/club/submit-club-deal', body);

export const getScorecards = async (id, abortSignal = null) => {
  const res = await getRequest('/api/v2.0/scorecard/get-scorecards', abortSignal);
  return res;
};

export const getClubAnswercards = async (clubId = null, syndId = null, abortSignal = null) => {
  const res = await getRequest(`/api/v2.0/scorecard/get-answercards?clubId=${clubId}&syndId=${syndId}`, abortSignal);
  return res;
};

export const getScoreCardInfo = async (syndId, abortSignal = null) => {
  const res = await getRequest(`/api/v2.0/scorecard/get-deal-info?syndId=${syndId}`, abortSignal);
  return res;
};

export const getIsScoreCardLimiting = async (scoreCardId, abortSignal = null) => {
  const res = await getRequest(`/api/v2.0/scorecard/get-score-card-limiting?scoreCardId=${scoreCardId}`, abortSignal);
  return res;
};

export const getAllScoreCardQuestions = async (syndId, abortSignal = null) => {
  const res = await getRequest(`/api/v2.0/scorecard/get-all-score-card-questions?syndId=${syndId}`, abortSignal);
  return breakUpQuestionsByCategory(res);
};

export const postScoreCardAnswer = async (body, abortSignal = null) => {
  const res = await postRequest('/api/v2.0/scorecard/score-card-answer', body, abortSignal);
  return res;
};

export const getClubsByMember = async (id, abortSignal = null) => {
  const res = await getRequest(`/api/v2.0/club/get-clubs-by-member?id=${id}`, abortSignal);
  return res;
};

export const getClubMemberId = async (clubId, userId, abortSignal = null) => {
  const res = await getRequest(`/api/v2.0/club/get-club-member-id?clubId=${clubId}&userId=${userId}`, abortSignal);
  return res;
};

export const getScoreCardData = async (clubId, fundId, abortSignal = null) => {
  const res = await getRequest(`/api/v2.0/scorecard/get-scorecard-data?clubId=${clubId}&syndId=${fundId}`, abortSignal);
  return res;
};

export const getAllScoreCardsForSynd = async (syndIds, clubId = null, abortSignal = null) => {
  const url = clubId
    ? `/api/v2.0/scorecard/get-all-scorecards-for-synds?synds=${syndIds}&clubId=${clubId}`
    : `/api/v2.0/scorecard/get-all-scorecards-for-synds?synds=${syndIds}`;
  const res = await getRequest(url, abortSignal);
  return res;
};

export const getCategoryMap = async (abortSignal = null) => {
  const res = await getRequest('/api/v2.0/scorecard/get-category-map', abortSignal);
  return res;
};

export const createScorecard = async (body) => {
  const res = await postRequest('/api/v2.0/scorecard/save-scorecard', body);
  return res;
};

export const deleteClubAnswerSheet = async (body) => {
  const res = await postRequest('/api/v2.0/scorecard/delete-club-answersheet', body);
  return res;
};

export const getRequestedPathIfPresent = async (abortSignal = null) => {
  const res = await getRequest('/api/v2.0/club/get-requested-path-if-present', abortSignal);
  return res;
};

export const getUserCollections = async (userId, abortSignal = null) => {
  const res = await getRequest(`/api/v2.0/investor/get-investor-collections?userId=${userId}`, abortSignal);
  return res;
};

export const createPcc = async (body) => {
  const res = await postRequest('/api/v2.0/portco-pricing/pcc-create', body);
  return res;
};

export const editPccStatus = async (body) => {
  const res = await postRequest('/api/v2.0/portco-pricing/update-pcc-status', body);
  return res;
};

export const sendContactEmail = async (body, abortSignal = null) => postRequestFormData(
  '/api/v2.0/contact/send-email', body, abortSignal,
);

export const getAllPccData = async (body, abortSignal = null) => {
  const res = await getRequest('/api/v2.0/portco-pricing/get-all-portco-pricing-data', abortSignal);
  return res;
};

export const getPccData = async (id, abortSignal = null) => {
  const res = await getRequest(`/api/v2.0/portco-pricing/get-portco-pricing-data?id=${id}`, abortSignal);
  return res;
};

export const getClubCustomCard = async (clubId = null, abortSignal = null) => {
  const res = await getRequest(`/api/v2.0/club-admin/get-custom-card?id=${clubId}`, abortSignal);
  return res;
};

export const updateOrCreateClubCustomCard = async (body, abortSignal = null) => postRequestFormData(
  '/api/v2.0/club-admin/custom-card', body, abortSignal,
);

export const forgotPassword = async body => postRequest('/api/v2.0/club/forgot-password', body);

export const resetPasswordProcess = async body => postRequest('/api/v2.0/club/reset-password-process', body);

export const accessResetPassword = async body => postRequest('/api/v2.0/club/access-reset-password', body);

export const getHubspotProfileByEmail = async (email, abortSignal = null) => {
  const res = await getRequest(`/api/v2.0/club-admin/get-hubspot-profile-by-email?email=${email}`, abortSignal);
  return res;
};
