import React, { createContext } from 'react';
import PropTypes from 'prop-types';
import { useWebsocket } from '../../hooks/useWebSocket';

export const WebSocketContext = createContext();

export function WebSocketProvider({ children }) {
  const wssObject = useWebsocket();
  return <WebSocketContext.Provider value={wssObject}>{children}</WebSocketContext.Provider>;
}

WebSocketProvider.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};

WebSocketProvider.defaultProps = {
};
