import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';


export default class MessagePreviewModal extends PureComponent {
  static propTypes = {
    // eslint-disable-next-line react/forbid-prop-types
    messagePreview: PropTypes.object.isRequired,
    closeModal: PropTypes.func.isRequired,
  };

  render() {
    const {
      messagePreview,
      closeModal,
    } = this.props;

    return (
      <div className="modal-content fdo_messages_editor">
        <div className="modal-header">
          <h5 className="modal-title">
            <span className="fdo-text-bold-md" dangerouslySetInnerHTML={{ __html: messagePreview.subject }} />
          </h5>
          <button type="button" className="close" aria-label="Close" onClick={closeModal}>
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div className="modal-body fdo-text-sm" dangerouslySetInnerHTML={{ __html: messagePreview.message }} />
        {(
          messagePreview.subject.includes('data-warning="token-not-defined"')
          || messagePreview.message.includes('data-warning="token-not-defined"')
        ) && (
          <div className="modal-footer fdo-text-sm">
            <p>
              <b>Preview note: </b>
              Text highlighted in red indicates that some of the tokens you want to use in your message are not defined.
              <br />
              Do not worry, we will not send an email if an included token is not defined.
            </p>
          </div>
        )}
      </div>
    );
  }
}
