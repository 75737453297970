import logger from 'redux-logger';
import thunk from 'redux-thunk';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { encryptTransform } from 'redux-persist-transform-encrypt';
import { configureStore } from '@reduxjs/toolkit';
import reducer from '../reducers/index';

const encryptor = encryptTransform({
  secretKey: 'humpty-dumpty-set-on-the-wall',
  onError(error) {
    // eslint-disable-next-line no-console
    console.error(`Failed to encrypt redux state: ${error}`);
  },
});

const persistConfig = {
  key: 'reduxState',
  storage,
  transforms: [encryptor],
  whitelist: ['fundTrackerData'], // list reducers to persist to local storage
};

let store = null;

function configureAvgStore() {
  const middleware = [thunk];
  const inTestMode = process.env.NODE_ENV === 'test'
    || process.env.NODE_ENV === 'development'
    || process.env.NODE_ENV === 'local';
  if (inTestMode) {
    middleware.push(logger);
  }
  const configuredStore = configureStore({
    reducer: persistReducer(persistConfig, reducer),
    middleware,
    devTools: process.env.NODE_ENV === 'development', // Enables redux devtools. Allows to time-travel redux store.
  });
  const persistor = persistStore(configuredStore);
  store = { store: configuredStore, persistor };
}

export default function getStore() {
  if (store == null) {
    configureAvgStore();
  }
  return store;
}
