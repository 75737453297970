import React from 'react';
import PropTypes from 'prop-types';
import ConfirmationModal from './ConfirmationModal';

export default function CloseOnConfirmModal({ modalOpen }) {
  return (
    <ConfirmationModal
      isOpen={modalOpen}
      headerText="Step Completed"
      bodyText="Please close this window, return to HubSpot and refresh page for updates."
      onConfirm={null}
      onCancel={null}
      disableBackdropClick={false}
    />
  );
}

CloseOnConfirmModal.propTypes = {
  modalOpen: PropTypes.bool.isRequired,
};
