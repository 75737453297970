import fetch from 'cross-fetch';

export const REQUEST_CURRENT_USER = 'REQUEST_CURRENT_USER';
export const RECEIVE_CURRENT_USER = 'RECEIVE_CURRENT_USER';

export function requestCurrentUser() {
  return {
    type: REQUEST_CURRENT_USER,
  };
}

export function receiveCurrentUser(json) {
  return {
    type: RECEIVE_CURRENT_USER,
    user: json,
    receivedAt: Date.now(),
  };
}

function fetchCurrentUser() {
  return (dispatch) => {
    dispatch(requestCurrentUser());
    return fetch(`${process.env.AVG_API_PATH}/admin/default/get-admin-user/`)
      .then(response => response.json(),
        // eslint-disable-next-line no-console
        error => console.log('An error occurred.', error))
      .then(json => dispatch(receiveCurrentUser(json)));
  };
}

function shouldFetchCurrentUser(state) {
  const { user } = state;
  if (!user) {
    return true;
  }
  if (user.isFetching) {
    return false;
  }
  return user;
}

export function fetchCurrentUserIfNeeded() {
  // eslint-disable-next-line consistent-return
  return (dispatch, getState) => {
    if (shouldFetchCurrentUser(getState())) {
      return dispatch(fetchCurrentUser());
    }
  };
}
