import { createContext } from 'react';
import { makeAutoObservable } from 'mobx';

const initialNotification = {
  success: () => {},
  error: () => {},
  message: '',
  status: '',
  open: false,
};

class NotificationStore {
  notification = initialNotification;

  constructor() {
    makeAutoObservable(this);
  }

  setNotification = (res) => {
    this.notification = res;
    this.notification.open = true;
  }

  clearNotification = () => {
    this.notification = initialNotification;
  }

  closeNotification = () => {
    this.notification.success = () => {};
    this.notification.error = () => {};
    this.notification.open = false;
  }
}

export const notificationStore = new NotificationStore();

export const NotificationStoreContext = createContext(notificationStore);
