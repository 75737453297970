
const required = value => (value && value.length ? undefined : 'This field is required');
const validateFundIsSelected = value => (
  (!value || !Number(value) || Number(value) < 0) ? 'Please select a Sponsor Fund' : undefined);
const visibility = { 1: 'Public', 0: 'Private' };
const type = {
  0: 'Club', 1: 'Fund Community', 2: 'CEO Club', 3: 'Diamond Club',
};

export default class EditableClub {
  constructor(rawClub) {
    this.id = {
      label: 'ID',
      type: null,
      readOnly: true,
      value: rawClub.id,
    };
    this.name = {
      label: 'Name',
      type: 'text',
      validators: [required],
      readOnly: false,
      value: rawClub.name,
    };
    this.description = {
      label: 'Description',
      type: 'richtext',
      validators: [required],
      rows: 8,
      readOnly: false,
      value: rawClub.description,
    };
    this.logo = {
      type: 'fileinput',
      readOnly: false,
    };
    this.type = {
      label: 'Club Type',
      type: 'select',
      readOnly: false,
      value: rawClub.type,
      display: rawClub.typeLabel,
      options: Object.keys(type).map(opt => ({ label: type[opt], value: opt })),
    };
    this.sponsorFund = {
      label: 'Sponsor Fund',
      type: 'select',
      readOnly: false,
      validators: [validateFundIsSelected],
      value: rawClub.sponsorFund ? rawClub.sponsorFund.id : null,
      display: rawClub.sponsorFund ? rawClub.sponsorFund.name : null,
      options: rawClub.companies.map(opt => ({ label: opt.name, value: opt.id })),
    };
    this.associatedFunds = {
      label: 'Associated Funds',
      type: 'quickselect',
      readOnly: false,
      value: rawClub.funds ? rawClub.funds.map(opt => ({ label: opt.name, value: opt.fund_id })) : [],
      display: rawClub.funds ? rawClub.funds : null,
      // eslint-disable-next-line max-len
      options: rawClub.availableFunds ? rawClub.availableFunds.map(opt => ({ label: opt.name, value: opt.fund_id })) : [],
    };
    this.visibility = {
      label: 'Privacy Setting',
      type: 'select',
      readOnly: false,
      value: rawClub.visibility,
      display: rawClub.visibilityLabel,
      options: Object.keys(visibility).map(opt => ({ label: visibility[opt], value: opt })),
    };
    this.refEmailSubject = {
      label: 'Invite Email Subject',
      type: 'text',
      readOnly: false,
      value: rawClub.refEmailSubject ? rawClub.refEmailSubject : '',
    };
    this.refEmailBody = {
      label: 'Invite Email Body',
      type: 'textarea',
      readOnly: false,
      value: rawClub.refEmailBody ? rawClub.refEmailBody : '',
    };
    this.imageUri = {
      type: null,
      readOnly: true,
      value: rawClub.logoPath,
    };
    this.teamUrl = {
      label: 'Team Url',
      type: 'text',
      readOnly: false,
      value: rawClub.team_url ? rawClub.team_url : '',
    };
    this.supportEmail = {
      label: 'Support Email',
      type: 'text',
      readOnly: false,
      value: rawClub.support_email ? rawClub.support_email : '',
    };
    this.callCalendarUrl = {
      label: 'Calendly Link',
      type: 'text',
      readOnly: false,
      value: rawClub.call_calendar_url ? rawClub.call_calendar_url : '',
    };
    this.zoomLink = {
      label: 'Zoom Link',
      type: 'text',
      readOnly: false,
      value: rawClub.zoom_link ? rawClub.zoom_link : '',
    };
  }
}
