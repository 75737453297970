import { combineReducers } from '@reduxjs/toolkit';
import { reducer as reduxFormReducer } from 'redux-form';
import themeReducer from './themeReducer';
import sidebarReducer from './sidebarReducer';
import totalsFundsByDate from './totalsFundsReducer';
import selectedDate from './dateReducer';
import allFunds from './getFundsReducer';
import allInvestments from './getInvestmentsReducer';
import allPortfolioUpdates from './getPortfolioUpdatesReducer';
import allUsers from './getInvestorsReducer';
import allCompanies from './getCompaniesReducer';
import authReducer from './authReducer';
import currentUser from './getCurrentUserReducer';
import modalReducer from './modalReducer';
import liUserReducer from './liUserReducer';
import applicationReducer from './applicationReducer';
import snackbarReducer from './snackbarReducer';
import dictionaryReducer from './getDictionaryReducer';
import errorReducer from './errorReducer';
import portcoReducer from './portcoReducer';
import fundTrackerReducer from './fundTrackerReducer';
import queryStringReducer from './queryStringReducer';
import managedUserReducer from './managedUserReducer';
import dashboardLabelsReducer from './dashboardLabelsReducer';

const rootReducer = combineReducers({
  auth: authReducer,
  theme: themeReducer,
  sidebar: sidebarReducer,
  totalsFundsByDate,
  selectedDate,
  allFunds,
  errorMessage: errorReducer,
  form: reduxFormReducer, // mounted under "form",
  currentUser,
  modal: modalReducer,
  liUser: liUserReducer,
  application: applicationReducer,
  portfolioUpdates: allPortfolioUpdates,
  investments: allInvestments,
  investors: allUsers,
  companies: allCompanies,
  snackbar: snackbarReducer,
  dictionary: dictionaryReducer,
  portcoData: portcoReducer,
  fundTrackerData: fundTrackerReducer,
  queryString: queryStringReducer,
  currentManagedUser: managedUserReducer,
  dashboardLabels: dashboardLabelsReducer,
});

export default rootReducer;
