import { SET_APPLICATION_ADMIN, SET_APPLICATION_FDO, SET_APPLICATION_INVESTOR } from '../actions/applicationActions';

const initialState = {
  routes: 'admin',
  windowTitle: 'Admin Portal',
  topNavigation: false,
  basePath: '/admin/new',
};

const fdoState = {
  routes: 'fdo',
  windowTitle: 'First Degree Outreach',
  topNavigation: true,
  basePath: '/',
};

const investorState = {
  routes: 'investor',
  windowTitle: 'Venture Clubs',
  topNavigation: true,
  basePath: '/investor',
};

export default function (state = initialState, action) {
  switch (action.type) {
    case SET_APPLICATION_ADMIN:
      return initialState;
    case SET_APPLICATION_FDO:
      return fdoState;
    case SET_APPLICATION_INVESTOR:
      return investorState;
    default:
      return state;
  }
}
