export const SHOW_SNACKBAR_INFO = 'SHOW_SNACKBAR_INFO';
export const SHOW_SNACKBAR_ERROR = 'SHOW_SNACKBAR_ERROR';
export const SHOW_SNACKBAR_WARNING = 'SHOW_SNACKBAR_WARNING';
export const HIDE_SNACKBAR = 'HIDE_SNACKBAR';

export function showSnackbarInfo(message, autoClose = false) {
  return {
    type: SHOW_SNACKBAR_INFO,
    message,
    autoClose,
    designType: 'info',
    isShow: true,
  };
}

export function showSnackbarError(message, autoClose = false) {
  return {
    type: SHOW_SNACKBAR_ERROR,
    message,
    autoClose,
    designType: 'danger',
    isShow: true,
  };
}

export function showSnackbarWarning(message, autoClose = false) {
  return {
    type: SHOW_SNACKBAR_WARNING,
    message,
    autoClose,
    designType: 'warning',
    isShow: true,
  };
}

export function hideSnackbar() {
  return {
    type: HIDE_SNACKBAR,
    isShow: false,
  };
}
