import { User } from '@alumni-ventures-group/av-okta-auth';

const userCache = new User();

// actions stucture { label: 'email', value: 'questionsEmail' }
const generateRequest = (deal, actions = []) => {
  const userInfo = userCache.get() || {};
  const req = {
    deal,
    user: userInfo,
  };

  actions.forEach((action) => {
    req[action.label] = action.value;
  });

  return req;
};

export default generateRequest;
