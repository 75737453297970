import {
  REQUEST_ALL_FUNDS,
  RECEIVE_ALL_FUNDS,
} from '../actions/getFundsActions';

export default function (state = {
  isFetching: false,
  fundArray: [],
}, action) {
  switch (action.type) {
    case REQUEST_ALL_FUNDS:
      return Object.assign({}, state, {
        isFetching: true,
      });
    case RECEIVE_ALL_FUNDS:
      return Object.assign({}, state, {
        isFetching: false,
        fundArray: action.funds,
        lastUpdated: action.receivedAt,
      });
    default:
      return state;
  }
}
