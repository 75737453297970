/* eslint-disable react/no-danger */
import React, { useState } from 'react';
import ReactPlayer from 'react-player';
import PropTypes from 'prop-types';
import moment from 'moment';
import { useParams } from 'react-router-dom';
import { Row, Col } from 'reactstrap';
import { Grid } from '@material-ui/core';
import { TYPES } from '../../helpers/MultiSelectOptions';
import { blueColor, ANNOUNCEMENT_MOMENT_TIME_FORMAT } from '../../services/clubResources';

const determineDisplayType = item => (item.img_src ? 200 : 350);

const displayVideo = (video) => {
  let url = null;
  let videoId = null;
  if (video.indexOf('vimeo') !== -1) {
    url = new URL(video);
    [, videoId] = url.pathname.split('/');
  }

  return (
    <div
      style={{
        backgroundColor: '#1a2e3b',
        backgroundImage: 'linear-gradient(to bottom,rgba(20,21,22,.3),#141516)',
        height: '450px',
        width: '100%',
        margin: '0px 10px 2px 0px',
      }}
    >
      {(video.indexOf('vimeo') === -1) && <ReactPlayer controls height="100%" width="100%" url={video} />}
      {(video.indexOf('vimeo') !== -1) && (
        <iframe
          src={`https://player.vimeo.com/video/${videoId}`}
          width="100%"
          height="100%"
          frameBorder="0"
          allow="autoplay; fullscreen; picture-in-picture"
          allowFullScreen
          title={`${videoId}-video`}
        />
      )}
    </div>
  );
};

const AnnouncementCard = ({ item, showProp = false }) => {
  const [show, setShow] = useState(showProp);
  const { id } = useParams();
  const characterNumber = determineDisplayType(item);

  return (
    <div
      key={item.id}
      style={{
        margin: 'auto',
        width: '100%',
        maxWidth: '800px',
        minWidth: '175px',
        padding: '5px',
        paddingBottom: '15px',
        marginBottom: '15px',
        boxShadow: '0 1px 5px 2px rgba(0, 0, 0, 0.05)',
      }}
    >
      <Grid
        container
        direction="column"
        alignItems="flex-start"
      >
        {(item.img_src && !item.video_url) && (
          <img
            style={{
              maxHeight: '375px',
            }}
            src={item.img_src}
            alt="Club Logo"
          />
        )}
        {item.video_url && displayVideo(item.video_url)}
        <p className="mt-3">
          <strong style={{ fontSize: '16px', overflowWrap: 'anywhere' }}>
            {
              item.id
                ? (
                  <a
                    href={`${process.env.AVG_API_PATH}/investor/club/home/${id}/announcements/${item.id}`}
                    target="_blank"
                    rel="noreferrer"
                  >{item.headline_text}
                  </a>
                ) : (<span>{item.headline_text}</span>)
            }
          </strong>
          {
            item.type === TYPES.EVENTS && (
              <span style={{ fontSize: '11px', marginLeft: '12px' }}>
                {moment(item.start_date).format(ANNOUNCEMENT_MOMENT_TIME_FORMAT)}
              </span>
            )
          }
        </p>
        <p>
          {(item.announcement_text.length <= characterNumber || show)
            ? <span dangerouslySetInnerHTML={{ __html: item.announcement_text }} />
            : <span dangerouslySetInnerHTML={{ __html: `${item.announcement_text.slice(0, characterNumber)}...` }} />
          }
          {(item.announcement_text.length >= characterNumber) && (
            <button
              type="button"
              className="ml-auto"
              style={{
                background: 'none',
                border: 'none',
                color: blueColor.main,
                cursor: 'pointer',
                textDecoration: 'underline',
                float: 'right',
                display: (showProp ? 'none' : ''),
              }}
              onClick={() => {
                setShow(!show);
              }}
            >
              {!show ? '...show more' : '...show less'}
            </button>
          )}
        </p>
      </Grid>

      <Grid>
        {item.webinar_url && (
          <Row style={{ padding: '10px 0px' }}>
            <Col md="3">
              <p><strong>Webinar Url:</strong></p>
            </Col>
            <Col md="9">
              <p>
                <a href={item.webinar_url}>{item.webinar_url}</a>
              </p>
            </Col>
          </Row>
        )}
      </Grid>
    </div>
  );
};

AnnouncementCard.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  item: PropTypes.any.isRequired,
  showProp: PropTypes.bool,
};

AnnouncementCard.defaultProps = {
  showProp: false,
};

export default AnnouncementCard;
