import React from 'react';
import {
  Button, ButtonToolbar,
} from 'reactstrap';
import { Field, reduxForm } from 'redux-form';
import { camelCase } from 'lodash';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import PropTypes from 'prop-types';
import moment from 'moment';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import { makeStyles } from '@material-ui/core/styles';
import DoneIcon from 'mdi-react/DoneIcon';
import CheckBoxOutlineBlankIcon from 'mdi-react/CheckBoxOutlineBlankIcon';
import CheckboxIcon from 'mdi-react/CheckBoxIcon';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { createFilterOptions } from '@material-ui/lab';
import { Editor } from '@tinymce/tinymce-react';
import renderImageInputField from '../form/ImageInput';
import { blueColor } from '../../services/clubResources';

const { TINY_MCE_KEY } = process.env;

const useStyles = makeStyles({
  datePicker: {
    marginBottom: 20,
  },
});

const editorToolbarConfig = {
  height: '300px',
  width: '100%',
  toolbar: 'heading | undo | bold italic | bullist | numlist',
  plugins: [
    'link image',
  ],
  default_link_target: '_blank',
};

const renderTextOnly = ({ input, label }) => (
  <>
    <TextField
      className="material-form__field mb-0"
      id={camelCase(label)}
      label={label}
      value={input.value}
      disabled
      InputProps={{ disableUnderline: true }}
      onChange={(e) => {
        e.preventDefault();
        input.onChange(e.target.value);
      }}
    />
  </>
);

const renderSectionTitle = ({ label }) => (
  <>
    <div>
      <h4 style={{ color: blueColor.main }}>{label}</h4>
      <br />
    </div>
  </>
);

const renderTextField = ({
  input, label, meta: { touched, error }, children, select, disabled, required, helperText,
}) => (
  <>
    <TextField
      className="material-form__field"
      id={camelCase(label)}
      label={label}
      value={input.value}
      disabled={disabled}
      // eslint-disable-next-line react/no-children-prop
      children={children}
      select={select}
      required={required}
      onChange={(e) => {
        e.preventDefault();
        input.onChange(e.target.value);
      }}
    />
    {touched
    && (error && <span style={{ color: '#ac2929' }}>{error}</span>)
    }
    {helperText && (<span style={{ color: 'red' }}>{helperText}</span>)}
  </>
);

const renderTextFieldWithCheckbox = ({
  input, label, meta: { touched, error }, children, select, disabled, required, helperText,
}) => {
  const [isDisabled, setIsDisabled] = React.useState(() => {
    if (input.value === '') {
      input.onChange('');
      return true;
    }
    return disabled;
  });
  const handleChange = (event) => {
    setIsDisabled(event.target.checked);
  };
  return (
    <>
      <TextField
        className="material-form__field mb-0"
        id={camelCase(label)}
        label={label}
        value={input.value}
        disabled={isDisabled}
        // eslint-disable-next-line react/no-children-prop
        children={children}
        select={select}
        required={required}
        onChange={(e) => {
          e.preventDefault();
          input.onChange(e.target.value);
        }}
      />
      <FormControlLabel
        fontSize="small"
        control={(
          <Checkbox
            checked={isDisabled}
            onChange={(e) => { input.onChange(''); handleChange(e); }}
            inputProps={{ 'aria-label': 'primary checkbox' }}
            color="primary"
            icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
            checkedIcon={<CheckboxIcon fontSize="small" />}
          />
        )}
        label={<span style={{ fontSize: '12px' }}>{`Do Not Display ${label}`}</span>}
      />
      {touched
      && (error && <span style={{ color: '#ac2929' }}>{error}</span>)
      }
      {helperText && (<span style={{ color: 'red' }}>{helperText}</span>)}
    </>
  );
};

const renderKeyboardDatePickerField = ({
  input, label, placeholder,
}) => {
  const classes = useStyles();

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <KeyboardDatePicker
        margin="normal"
        className={`${classes.datePicker} editDatePicker-dialog`}
        label={label}
        format="MM/dd/yyyy"
        value={input.value || placeholder || null}
        onChange={value => input.onChange(moment(value).format('YYYY/MM/DD'))}
        KeyboardButtonProps={{
          'aria-label': { label },
        }}
      />
    </MuiPickersUtilsProvider>
  );
};

renderKeyboardDatePickerField.propTypes = {
  input: PropTypes.shape().isRequired,
  label: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
};

renderKeyboardDatePickerField.defaultProps = {
  placeholder: null,
};

const renderSelectField = ({
  input, label, meta: { touched, error }, children, required,
}) => (
  <>
    <TextField
      className="material-form__field"
      id={camelCase(label)}
      label={label}
      value={input.value}
    // eslint-disable-next-line react/no-children-prop
      children={children}
      select
      required={required}
      onChange={(e) => {
        e.preventDefault();
        input.onChange(e.target.value);
      }}
    />
    {touched
    && (error && <span style={{ color: '#ac2929' }}>{error}</span>)
    }
  </>
);

const filterOptions = createFilterOptions({
  limit: 20,
});

const renderAutocompleteField = ({
  input, label, meta: { touched, error }, options, required,
}) => (
  <>
    <Autocomplete
      className="material-form__field"
      id={camelCase(label)}
      getOptionLabel={option => option.label}
      getOptionDisabled={option => option.disabled}
      filterOptions={filterOptions}
      disableClearable
      options={options}
      onChange={(e, value) => {
        e.preventDefault();
        input.onChange(value);
      }}
      renderInput={params => (
        <TextField
          {...params}
          label={label}
          variant="standard"
          required={required}
        />
      )}
    />
    {touched
    && (error && <span style={{ color: '#ac2929' }}>{error}</span>)
    }
  </>
);

const renderQuickSelectField = ({
  input, label, meta: { touched, error }, options, required,
}) => {
  const getOptionSelected = (optionA, optionB) => optionA.value === optionB.value;

  return (
    <>
      <Autocomplete
        className="material-form__field"
        id={camelCase(label)}
        multiple
        disableCloseOnSelect
        onChange={(e, value) => {
          e.preventDefault();
          input.onChange(value);
        }}
        getOptionLabel={option => option.label}
        getOptionSelected={getOptionSelected}
        options={options}
        value={input.value ? input.value : []}
        renderOption={(option, { selected }) => (
          <React.Fragment>
            <DoneIcon
              className="material-form_quickSelectDone"
              style={{ visibility: selected ? 'visible' : 'hidden' }}
            />
            <div>
              {option.label}
            </div>
          </React.Fragment>
        )}
        renderInput={params => (
          <TextField
            {...params}
            label={label}
            variant="standard"
            required={required}
          />
        )}
      />
      {touched
      && (error && <span style={{ color: '#ac2929' }}>{error}</span>)
      }
    </>
  );
};

const renderTextArea = ({
  input, label, rows, meta: { touched, error }, required,
}) => (
  <>
    <TextField
      id={camelCase(label)}
      className="material-form__field"
      label={label}
      fullWidth
      multiline
      value={input.value}
      rows={rows !== null ? rows : 10}
      margin="normal"
      variant="outlined"
      required={required}
      onChange={(e) => {
        e.preventDefault();
        input.onChange(e.target.value);
      }}
    />
    {touched
    && (error && <span style={{ color: '#ac2929' }}>{error}</span>)
    }
  </>
);

const renderRichText = ({
  input, label, meta: { touched, error },
}) => {
  let refToEditorTextArea = null;
  return (
    <>
      <Editor
        apiKey={TINY_MCE_KEY}
        label={label}
        id={camelCase(label)}
        value={input.value}
        init={editorToolbarConfig}
        onEditorChange={(content, editor) => {
          if (!refToEditorTextArea) {
            refToEditorTextArea = editor;
          }
          input.onChange(content);
        }}
      />
      {touched
      && (error && <span style={{ color: '#ac2929' }}>{error}</span>)
      }
    </>
  );
};

const selectFieldForRender = (obj) => {
  switch (obj.fieldType) {
    case 'textOnly':
      return renderTextOnly(obj);
    case 'sectionTitle':
      return renderSectionTitle(obj);
    case 'text':
      return renderTextField(obj);
    case 'textarea':
      return renderTextArea(obj);
    case 'richtext':
      return renderRichText(obj);
    case 'select':
      return renderSelectField(obj);
    case 'select2':
      return renderAutocompleteField(obj);
    case 'quickselect':
      return renderQuickSelectField(obj);
    case 'date':
      return renderKeyboardDatePickerField(obj);
    case 'fileinput':
      return renderImageInputField(obj);
    case 'textPrivate':
      return renderTextFieldWithCheckbox(obj);
    default:
      return (<br />);
  }
};

renderTextArea.propTypes = {
  input: PropTypes.shape().isRequired,
  label: PropTypes.string.isRequired,
  rows: PropTypes.number.isRequired,
  required: PropTypes.bool,
  meta: PropTypes.shape({
    touched: PropTypes.bool,
    error: PropTypes.string,
  }),
};

renderTextArea.defaultProps = {
  meta: null,
  required: false,
};

renderTextField.propTypes = {
  input: PropTypes.shape().isRequired,
  label: PropTypes.string.isRequired,
  meta: PropTypes.shape({
    touched: PropTypes.bool,
    error: PropTypes.string,
  }),
  disabled: PropTypes.bool,
  select: PropTypes.bool,
  children: PropTypes.arrayOf(PropTypes.element),
  required: PropTypes.bool,
  helperText: PropTypes.string,
};

renderTextFieldWithCheckbox.propTypes = {
  input: PropTypes.shape().isRequired,
  label: PropTypes.string.isRequired,
  meta: PropTypes.shape({
    touched: PropTypes.bool,
    error: PropTypes.string,
  }),
  disabled: PropTypes.bool,
  select: PropTypes.bool,
  children: PropTypes.arrayOf(PropTypes.element),
  required: PropTypes.bool,
  helperText: PropTypes.string,
};

renderTextField.defaultProps = {
  meta: null,
  select: false,
  disabled: false,
  children: [],
  required: false,
  helperText: '',
};

renderSelectField.propTypes = {
  input: PropTypes.shape().isRequired,
  label: PropTypes.string.isRequired,
  meta: PropTypes.shape({
    touched: PropTypes.bool,
    error: PropTypes.string,
  }),
  children: PropTypes.arrayOf(PropTypes.element),
  required: PropTypes.bool,
};

renderSelectField.defaultProps = {
  meta: null,
  children: [],
  required: false,
};

renderAutocompleteField.propTypes = {
  input: PropTypes.shape().isRequired,
  label: PropTypes.string.isRequired,
  meta: PropTypes.shape({
    touched: PropTypes.bool,
    error: PropTypes.string,
  }),
  options: PropTypes.arrayOf(PropTypes.element),
  required: PropTypes.bool,
};

renderAutocompleteField.defaultProps = {
  meta: null,
  options: [],
  required: false,
};

renderQuickSelectField.propTypes = {
  input: PropTypes.shape().isRequired,
  label: PropTypes.string.isRequired,
  meta: PropTypes.shape({
    touched: PropTypes.bool,
    error: PropTypes.string,
  }),
  options: PropTypes.arrayOf(PropTypes.element),
  required: PropTypes.bool,
};

renderQuickSelectField.defaultProps = {
  meta: null,
  options: [],
  required: false,
};

renderQuickSelectField.defaultProps = {
  label: PropTypes.string.isRequired,
};

const EditFormInline = ({
  handleSubmit, cancel, data,
}) => (
  <form className="material-form" onSubmit={handleSubmit}>
    { Object.keys(data).map(key => (
      <div key={key} hidden={data[key].hidden ? data[key].hidden : false}>
        <Field
          name={key}
          validate={data[key].validators ? data[key].validators : null}
          fontSize="12px"
          bag={data} // make the whole row available for subcomponents
          component={selectFieldForRender}
          fieldType={data[key].type}
          options={data[key].options ? data[key].options : null}
          rows={data[key].rows ? data[key].rows : null}
          disabled={data[key].readOnly && true}
          required={data[key].required && true}
          display={data[key].display}
          label={data[key].label}
          placeholder={data[key].placeholder ? data[key].placeholder : null}
          helperText={data[key].message ? data[key].message : null}
          className="material-form__field"
        >
          {data[key].options ? data[key].options.map(
            (opt, i) => (
              <MenuItem
                // eslint-disable-next-line react/no-array-index-key
                key={opt.label + i}
                className="material-form__option"
                value={opt.value}
              >
                {opt.label}
              </MenuItem>
            ),
          ) : null}
        </Field>
      </div>
    ))}
    <ButtonToolbar className="form__button-toolbar">
      <Button type="button" onClick={cancel}>
        Cancel
      </Button>
      <Button color="primary" type="submit">Submit</Button>
    </ButtonToolbar>
  </form>
);

EditFormInline.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  cancel: PropTypes.func.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  data: PropTypes.any.isRequired,
};

export default reduxForm({
  form: 'floating_labels_form', // a unique identifier for this form
})(EditFormInline);
