import React, { useEffect } from 'react';
import Autocomplete from '@mui/material/Autocomplete';
import PropTypes from 'prop-types';
import countries from '../utils/countriesList';
import AvTextField from './AvTextField';
import { spacing } from '../themes/appTheme';
import { isWhitespaceOnly } from '../utils/validators';

const corpTypes = [
  'C-Corp',
  'LLC',
  'Partnership',
  'Limited Company (LTD)',
  'Limited Partnership',
];

const companyTaxStatuses = [
  'As a Corporation / Form 1120',
  'As a Partnership / Form 1065',
];

const validate = (companyInfo) => {
  const {
    name, countryIncorp, corpType, companyTaxStatus,
  } = companyInfo;

  const requiredErrorMessage = 'This field is required';
  const newErrors = {};
  if (!name) {
    newErrors.name = requiredErrorMessage;
  } else if (isWhitespaceOnly(name)) {
    newErrors.name = 'This field cannot be whitespace only';
  }

  if (!countryIncorp) {
    newErrors.countryIncorp = requiredErrorMessage;
  }

  if (!corpType) {
    newErrors.corpType = requiredErrorMessage;
  }

  if (corpType !== 'C-Corp' && !companyTaxStatus) {
    newErrors.companyTaxStatus = requiredErrorMessage;
  }

  return newErrors;
};

export default function CompanyInfo({
  companyInfo, setCompanyInfo, validationObject = {}, errors,
}) {
  const {
    name,
    countryIncorp,
    corpType,
    companyTaxStatus,
  } = companyInfo;

  // Check that each required fields exist
  useEffect(() => {
    // eslint-disable-next-line no-param-reassign
    validationObject.current = validate;
  }, []);
  return (
    <>
      <h5
        style={{ marginTop: spacing(3) }}
      >
        Company Information
      </h5>
      <AvTextField
        label="Company Name"
        InputProps={{ readOnly: true }}
        variant="standard"
        disabled
        value={name}
        error={!!errors.name}
        helperText={errors.name}
      />
      <Autocomplete
        options={countries}
        value={countryIncorp}
        onChange={(e, val) => setCompanyInfo({ ...companyInfo, countryIncorp: val })}
        renderInput={(params) => (
          <AvTextField
            required
            {...params}
            name="countryIncorp"
            label="Country of Incorporation"
            variant="standard"
            error={!!errors.countryIncorp}
            helperText={errors.countryIncorp}
          />
        )}
      />
      <Autocomplete
        options={corpTypes}
        value={corpType}
        onChange={(e, val) => setCompanyInfo({
          ...companyInfo,
          corpType: val,
          companyTaxStatus: val === 'C-Corp' ? null : companyInfo.companyTaxStatus,
        })}
        renderInput={(params) => (
          <AvTextField
            required
            {...params}
            name="corpType"
            label="Type of Corporation"
            variant="standard"
            error={!!errors.corpType}
            helperText={errors.corpType}
          />
        )}
      />
      {corpType !== 'C-Corp' && (
        <Autocomplete
          options={companyTaxStatuses}
          value={companyTaxStatus}
          onChange={(e, val) => setCompanyInfo({ ...companyInfo, companyTaxStatus: val })}
          renderInput={(params) => (
            <AvTextField
              required
              {...params}
              name="companyTaxStatus"
              label="How is the entity treated from a tax standpoint?"
              variant="standard"
              error={!!errors.companyTaxStatus}
              helperText={errors.companyTaxStatus}
            />
          )}
        />
      )}
      <br />
    </>
  );
}

CompanyInfo.propTypes = {
  companyInfo: PropTypes.shape({
    name: PropTypes.string,
    countryIncorp: PropTypes.string,
    corpType: PropTypes.string,
    companyTaxStatus: PropTypes.string,
  }).isRequired,
  setCompanyInfo: PropTypes.func.isRequired,
  validationObject: PropTypes.shape({}),
  errors: PropTypes.oneOfType([PropTypes.shape({}), PropTypes.shape({
    name: PropTypes.string,
    countryIncorp: PropTypes.string,
    corpType: PropTypes.string,
    companyTaxStatus: PropTypes.string,
  })]),
};

CompanyInfo.defaultProps = {
  validationObject: {},
  errors: {},
};
