import moment from 'moment';
import { SELECT_DATE } from '../actions/fundsTotalActions';

export default function (state = moment().format('YYYY-MM-DD'), action) {
  switch (action.type) {
    case SELECT_DATE:
      return action.date;
    default:
      return state;
  }
}
