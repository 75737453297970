/* eslint-disable jsx-a11y/label-has-for */
import React, { useState, useEffect } from 'react';
import { useDropzone } from 'react-dropzone';
import PropTypes from 'prop-types';
import { useLocation } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  imageInputContainer: {
    width: '100%',
  },
  logoImg: {
    maxWidth: '150px',
    marginRight: '50px',
  },
  logoImgCircle: {
    width: '150px',
    height: '150px',
    marginRight: '50px',
    borderRadius: '50%',
  },
  logoContainer: {
    width: '100%',
    padding: '0px',
    borderWidth: '1px',
    borderRadius: '4px',
    borderColor: 'lightgrey',
    borderStyle: 'solid',
    color: '#bdbdbd',
    outline: 'none',
    transition: 'border .24s ease-in-out',
    cursor: 'copy',
  },
  logoContainerText: {
    margin: 'auto',
    padding: '15px',
    paddingTop: '60px',
    height: '150px',
    width: '100%',
    textAlign: 'center',
  },
  labelText: {
  },
  labelTextClubCreateView: {
    marginTop: '-7px',
    padding: '0 7px',
    zIndex: '1000',
    backgroundColor: 'white',
    marginLeft: '10px',
    fontSize: '16px',
  },
  labelTextAdminView: {
    margin: '0px',
    padding: '0px',
    zIndex: '1000',
    fontSize: '16px',
  },
  logoInputAndImage: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
  },
  logoImage: {
    margin: '50px',
  },
});

const renderImageInputField = (props) => {
  const { input, meta, bag } = props;
  const { onChange, name } = input;
  const { imageUri } = bag;
  const [imagePreview, setImagePreview] = useState(imageUri ? imageUri.value : null);
  const previewClass = (imageUri && imageUri.previewClass) ? imageUri.previewClass : 'logoImg';
  const acceptedTypes = (imageUri && imageUri.acceptedTypes) ? imageUri.acceptedTypes : 'image/jpeg, image/png';
  const { getRootProps, getInputProps } = useDropzone({
    accept: acceptedTypes,
    onDrop: (acceptedFiles) => {
      const [file] = acceptedFiles;
      setImagePreview(URL.createObjectURL(file));
      onChange({ file, name: file.name });
    },
  });
  const arr = name.split('_');
  let label = '';

  for (let i = 0; i < arr.length; i += 1) {
    if (label) label += ' ';
    label += arr[i].slice(0, 1).toUpperCase() + arr[i].slice(1, arr[i].length);
  }

  useEffect(() => () => {
    // Make sure to revoke the data uris to avoid memory leaks
    if (imagePreview) {
      URL.revokeObjectURL(imagePreview);
    }
  }, []);

  useEffect(() => {
    if (imageUri && !imageUri.value) {
      if (input.value && !input.value.name) {
        setImagePreview(input.value);
      }
      if (!input.value) {
        setImagePreview(null);
      }
    }
  }, [input.value]);

  const location = useLocation();
  const classes = useStyles();
  switch (location.pathname) {
    case '/clubs/step1': // club creation page
      classes.labelText = classes.labelTextClubCreateView;
      break;
    case (location.pathname.match(/clubs\/*[0-9]*/) || {}).input: // admin view of clubs
      classes.labelText = classes.labelTextAdminView;
      break;
    default:
      classes.labelText = classes.labelTextClubCreateView;
  }

  return (
    <div className={`${classes.imageInputContainer} MuiFormControl-root MuiTextField-root material-form__field`}>
      <label
        className={`${classes.labelText} MuiFormLabel-root MuiInputLabel-root MuiInputLabel-formControl
        MuiInputLabel-animated MuiFormLabel-filled`}
        htmlFor={name}
        style={{ top: '-15px' }}
      >
        {label}
      </label>

      <div className="MuiInputBase-root MuiInput-root MuiInput-underline MuiInputBase-formControl MuiInput-formControl">

        <div className={classes.logoInputAndImage}>

          <div className={classes.logoInpunt}>
            <section className={`${classes.logoContainer} form__form-group-file`}>
              <div {...getRootProps({ className: 'dropzone' })}>
                <input {...getInputProps()} />
                <div className={classes.logoContainerText}>
                  <p>Drag and drop the file here, or click to select an image file</p>
                </div>
              </div>
            </section>
          </div>
          <div className={classes.logoImage}>
            <aside>
              { imagePreview && (
                <img
                  src={imagePreview}
                  className={classes[previewClass]}
                  alt="Preview"
                />
              )
              }
            </aside>
          </div>

        </div>

      </div>
      {meta && meta.touched && meta.error && <span className="form__form-group-error">{meta.error}</span>}
    </div>
  );
};

renderImageInputField.propTypes = {
  input: PropTypes.shape({
    onChange: PropTypes.func,
    name: PropTypes.string,
    // eslint-disable-next-line react/forbid-prop-types
    value: PropTypes.any,
  }).isRequired,
  meta: PropTypes.shape({
    touched: PropTypes.bool,
    error: PropTypes.string,
  }),
  bag: PropTypes.shape({
    imageUri: PropTypes.string,
  }),
};

renderImageInputField.defaultProps = {
  meta: null,
  bag: {},
};

export default renderImageInputField;
