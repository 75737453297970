export const SET_PORTAL = 'SET_PORTAL';

export const setPortal = ({ portalName }) => (dispatch) => {
  dispatch({
    type: SET_PORTAL,
    portalName,
  });
};

export default {
  setPortal,
  SET_PORTAL,
};
