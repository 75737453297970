import React from 'react';
import { useHistory } from 'react-router-dom';
import { Button } from '@mui/material';
import { CancelOutlined, CheckCircleOutline } from '@mui/icons-material';
import IconButton from '@mui/material/IconButton';
import theme from '../shared/themes/appTheme';
import { checkIfUserIsTreasuryOrOOIAdmin } from '../shared/utils/checkUserPermissions';

export default function createDealStagesTableDefinition(dealId, setUndoStepConfirmationModalOpen, setStageToUndo) {
  const history = useHistory();
  return [
    {
      field: '_',
      headerName: '',
      sortable: false,
      filterable: false,
      renderCell: (params) => (params.row.stageLink ? (
        <Button
          variant="outlined"
          color="secondary"
          onClick={(e) => {
            e.stopPropagation();
            // below line might need to be altered in the future depending on how stage links are passed back from the API
            history.push(`${params.row.stageLink}${dealId}`);
          }}
        >
          {(params.row.stageCompleted || (!params.row.stageCompleted && !params.row.isEditableByRole)) ? 'View' : 'Edit'}
        </Button>
      ) : ''),
      maxWidth: 100,
      minWidth: 100,
      flex: 1,
    },
    {
      field: 'stageName',
      headerName: 'Stage',
      description: 'The name of the stage',
      sortable: false,
      filterable: false,
      editable: false,
      flex: 2,
      minWidth: 300,
    },
    {
      field: 'responsibleParty',
      headerName: 'Responsible Party',
      description: 'The party responsible for completion of stage',
      sortable: false,
      filterable: false,
      editable: false,
      flex: 1,
      minWidth: 200,
    },
    {
      field: 'stageStatus',
      headerName: 'Stage Status',
      description: 'Status of stage, checkmark indicates completion and crossout mark indicates rejection',
      sortable: false,
      filterable: false,
      editable: false,
      renderCell: (params) => {
        const stageStatus = params.row.stageCompleted;
        if (stageStatus === false) {
          return <CancelOutlined style={{ color: theme.palette.red.dark }} />;
        }
        if (typeof stageStatus === 'string' && Date.parse(stageStatus)) {
          if (checkIfUserIsTreasuryOrOOIAdmin()) {
            return (
              <IconButton
                onClick={() => {
                  setStageToUndo(params.row.stageProperty);
                  setUndoStepConfirmationModalOpen(true);
                }}
                aria-label="Undo step"
              >
                <CheckCircleOutline style={{ color: theme.palette.green.dark }} />
              </IconButton>
            );
          }
          return <CheckCircleOutline style={{ color: theme.palette.green.dark }} />;
        }
        return null;
      },
      maxWidth: 110,
      flex: 1,
      align: 'center',
    },
  ];
}
