import AuthStatus from '../models/AuthStatus';

const authStatusCache = new AuthStatus();

export const updateAuthentication = (oktaAuth, setIsAuthenticated) => {
  oktaAuth.authStateManager.subscribe((authState) => {
    const authenticated = authState.isAuthenticated;
    if (authenticated) {
      oktaAuth.authStateManager.unsubscribe();
      setIsAuthenticated(authenticated);
      authStatusCache.set(authenticated);
    }
  });
};