import clubMemberRoleOptions from './ClubMemberRoleOptions';

import {
  maxLength120, validatePhone, validateUrl, validateEmail,
} from './shared';

export default class EditableClubMember {
  constructor(rawClubMember) {
    this.email = {
      label: 'Account Email',
      type: 'text',
      readOnly: true,
      value: rawClubMember.email,
    };
    this.id = {
      label: 'ID',
      type: null,
      readOnly: true,
      value: rawClubMember.id,
    };
    this.firstName = {
      label: 'First Name',
      type: 'text',
      readOnly: false,
      value: rawClubMember.firstName === null ? '' : rawClubMember.firstName,
    };
    this.lastName = {
      label: 'Last Name',
      type: 'text',
      readOnly: false,
      value: rawClubMember.lastName === null ? '' : rawClubMember.lastName,
    };
    this.company_name = {
      label: 'Company',
      type: 'text',
      readOnly: false,
      value: rawClubMember.company_name === null ? '' : rawClubMember.company_name,
      validators: [maxLength120],
    };
    this.company_url = {
      label: 'Company Website',
      type: 'text',
      readOnly: false,
      value: rawClubMember.company_url === null ? '' : rawClubMember.company_url,
      validators: [validateUrl],
    };
    this.title = {
      label: 'Title',
      type: 'text',
      readOnly: false,
      value: rawClubMember.title === null ? '' : rawClubMember.title,
      validators: [maxLength120],
    };
    this.contactEmail = {
      label: 'Preferred Contact Email',
      type: 'textPrivate',
      readOnly: false,
      validators: [validateEmail],
      value: rawClubMember.contactEmail === null ? '' : rawClubMember.contactEmail,
      disabled: rawClubMember.contactEmail === '',
    };
    this.phone = {
      hidden: true,
      label: 'Phone Number',
      type: 'textPrivate',
      readOnly: false,
      validators: [validatePhone],
      value: rawClubMember.phone === null ? '' : rawClubMember.phone,
      disabled: rawClubMember.phone === '',
    };
    this.linked_in_url = {
      label: 'LinkedIn Url',
      type: 'text',
      readOnly: false,
      validators: [validateUrl],
      value: rawClubMember.linked_in_url === null ? '' : rawClubMember.linked_in_url,
    };
    this.join_date = {
      label: 'Join Date',
      type: 'date',
      readOnly: false,
      value: rawClubMember.join_date,
    };
    this.role = {
      label: 'Member Status',
      type: 'select',
      readOnly: false,
      display: rawClubMember.roleStatus,
      value: rawClubMember.role,
      options: clubMemberRoleOptions,
    };
    this.profile_picture = {
      type: 'fileinput',
      readOnly: false,
    };
    this.imageUri = {
      type: null,
      readOnly: true,
      value: rawClubMember.avatar,
      previewClass: 'logoImgCircle',
      acceptedTypes: 'image/jpeg, image/png, image/gif',
    };
  }
}
