import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import getStore from '../../../../redux/store/store';
import { resetErrorMessage } from '../../../../redux/actions/errorActions';

class FullWideNotification extends PureComponent {
  componentWillUnmount() {
    const { store } = getStore();
    const { dispatch } = store;
    dispatch(resetErrorMessage());
  }

  render() {
    const { color, message, title } = this.props;

    return (
      <div className={`notification notification--full-wide notification--${color}`}>
        <h5 className="notification__title bold-text">{title}</h5>
        <p className="notification__message">{message}</p>
      </div>
    );
  }
}

FullWideNotification.propTypes = {
  color: PropTypes.string,
  title: PropTypes.string.isRequired,
  message: PropTypes.string.isRequired,
};

FullWideNotification.defaultProps = {
  color: '',
};

export default FullWideNotification;
