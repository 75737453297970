import { useState, useEffect } from 'react';
import { getClubName } from '../services/bifService';

function useClubName(clubId) {
  const [data, setData] = useState(null);

  useEffect(() => {
    const abortController = new AbortController();
    let ignore = false;

    async function fetchData() {
      const response = await getClubName(clubId, abortController.signal);
      if (!ignore && response && response.clubName) {
        setData(response.clubName);
      }
    }

    fetchData();

    return () => {
      ignore = true;
      abortController.abort();
    };
  }, [clubId]);

  return data;
}

export default useClubName;
