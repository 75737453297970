import StringHelper from '../helpers/StringHelper';
import DateHelper from '../helpers/DateHelper';

function totalMap(key, title, value) {
  switch (key) {
    case 'totalNumShares':
      return {
        title: StringHelper.convertFromCamelCase(title),
        value: StringHelper.formatMoney(value, 5, '.', ',', ''),
      };
    default:
      return { title: StringHelper.convertFromCamelCase(title), value: StringHelper.formatMoney(value) };
  }
}
function totalsMap(totalArray) {
  const totals = { ...totalArray };
  return [
    totalMap('totalInvestedAmount', 'Total Invested', totals.totalCurrentPrice),
    totalMap('totalCurrentValuation', 'Current valuation', totals.totalCurrentValuation),
    totalMap('totalNumShares', 'Number of Shares', totals.totalNumShares),
    totalMap('totalCurrentPrice', 'Current Price', totals.totalCurrentPrice),
  ];
}

function investmentMap(investment) {
  const copy = { ...investment };
  copy.investmentName = investment.name;
  copy.name = investment.fund.name;
  copy.link = `/fund/${investment.fund.id}`;
  return copy;
}

function formatTotals(totals) {
  return totalsMap(totals);
}

function formatFundInvestments(investments) {
  return investments.map((investment) => {
    const investmentCopy = { ...investment };
    investmentCopy.investments = investmentCopy.investments.map(fund => investmentMap(fund));
    return investmentCopy;
  });
}

export const TotalsCardKeyMapper = {
  originalDate: 'Original Investment Date',
  daysHeld: 'Days Held',
  totalInvestment: 'Total AVG Investment',
  netChange: 'Net Gain/Loss',
  currentValue: 'Current Valuation',
  roi: 'Return Multiple',
  keyNames: [
    'originalDate',
    'daysHeld',
    'totalInvestment',
    'netChange',
    'currentValue',
    'roi',
  ],
};

export const filterOutInvestmentsFromTranches = (tranches) => {
  const investments = [];

  if (tranches && tranches.length > 0) {
    tranches.forEach((tranche) => {
      if (tranche.investments && tranche.investments.length > 0) {
        tranche.investments.forEach((investment) => {
          let fundName = '';
          if (investment.fund != null) {
            fundName = investment.fund.name;
          }
          const inv = {
            id: investment.id,
            fundName,
            dateInvested: DateHelper.formatToUTC(investment.date_invested, 'MM/DD/YYYY'),
            investmentType: investment.investedTypeLabel,
            numShares: investment.numShares,
            investedAmount: investment.investedAmount,
            currentPrice: investment.currentPrice,
            interestRate: investment.interestRate,
            currentValuation: investment.currentValuation,
            returnPercent: StringHelper.formatPercent(investment.percentReturn),
            daysHeld: investment.daysOfHeld,
            trancheName: tranche.name,
            investedTypeLabel: investment.investedTypeLabel,
          };
          investments.push(inv);
        });
      }
    });
  }
  return investments;
};

export const filterOutFundAndTranchNames = (tranches) => {
  const trancheNames = [];
  const fundsArray = [];
  const fundIds = [];

  if (tranches && tranches.length > 0) {
    tranches.forEach((tranche) => {
      trancheNames.push(tranche.name);

      if (tranche.investments && tranche.investments.length > 0) {
        tranche.investments.forEach((investment) => {
          if (investment.fund != null) {
            // don't add dublicate funds
            if (!fundIds.includes(investment.fund.id)) {
              const fundData = {
                id: investment.fund.id,
                name: investment.fund.name,
                url: `/fund/${investment.fund.id}`,
              };
              fundsArray.push(fundData);
              fundIds.push(investment.fund.id);
            }
          }
        });
      }
    });
  }

  fundsArray.sort((a, b) => ((a.name.length > b.name.length) ? 1 : -1));

  return { tranches: trancheNames, funds: fundsArray };
};

export default {
  formatInvestment: function formatInvestment(responseArray) {
    const investment = {};
    investment.totals = formatTotals(responseArray[0].totalItems);
    investment.investments = formatFundInvestments(responseArray[1].investments);
    return investment;
  },
  formatInvestmentsForTable: function formatInvestmentsForTable(investments) {
    return investments.map(investment => investmentMap(investment));
  },
};
