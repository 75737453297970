import fetch from 'cross-fetch';

/* eslint-disable */

class API {
  constructor(params = {}) {
    this.apiPath = params.apiPath;
    if (this.apiPath) {
      this.apiPath = this.apiPath.replace(/\/$/, ''); // normalize trailing slash
      this.apiPath += '/'; // normalize trailing slash
    }
    this.authCode = params.authCode;
  }

  async get(path) {
    const headers = {
      'Content-Type': 'application/json',
      authtoken: this.authCode,
    };

    try {
      if (path.indexOf('?') === -1) {
        path = path + '?auth_token=' + this.authCode;
      } else {
        path = path + '&auth_token=' + this.authCode;
      }
      const response = await fetch(this.apiPath + path, {
        method: 'GET',
        headers,
      });

      const data = await response.json();

      return data;
    } catch (e) {
      return null;
    }
  }

  async post(path, params) {
    const headers = {
      'Content-Type': 'application/json',
      authtoken: this.authCode,
    };

    try {
      if (path.indexOf('?') === -1) {
        path = path + '?auth_token='+this.authCode;
      } else {
        path = path + '&auth_token='+this.authCode;
      }
      const response = await fetch(this.apiPath + path, {
        method: 'POST',
        headers,
        body: JSON.stringify(params),
        // credentials: 'include',
      });

      const data = await response.json();

      return data;
    } catch (e) {
      return null;
    }
  }
}

export default API;
