export const breakUpQuestionsByCategory = (questions) => {
  if (!questions || questions === 'aborted' || questions.length === 0) {
    return [];
  }

  const result = [];
  const max = questions.map(v => v.fk_category_id).reduce(
    (a, b) => Math.max(a, b), 0,
  );

  for (let i = 0; i <= max; i += 1) {
    result.push([]);
  }

  questions.forEach((question) => {
    result[question.fk_category_id].push(question);
  });

  return result.filter(a => a.length > 0);
};

export const formatAvailableSyndications = (synds) => {
  if (!synds || synds === 'aborted' || !synds.list || synds.list === 0) {
    return null;
  }
  const ids = synds.list.map(synd => synd.id);
  const result = { list: [...synds.list], syndIds: ids };
  return result;
};
