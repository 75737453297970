/* eslint-disable react/forbid-prop-types */
import * as React from 'react';
import PropTypes from 'prop-types';
import AvTextField from './AvTextField';
import ReadOnlyTextField from './ReadOnlyTextField';
import { isWhitespaceOnly } from '../utils/validators';

const validate = (dealInfo) => {
  const newErrors = {};
  const {
    iTeamMemberName,
  } = dealInfo;

  if (!iTeamMemberName) {
    newErrors.iTeamMemberName = 'This field is required.';
  } else if (isWhitespaceOnly(iTeamMemberName)) {
    newErrors.iTeamMemberName = 'This field cannot be whitespace only.';
  } else if (iTeamMemberName.length > 200) {
    newErrors.iTeamMemberName = 'iTeam Member Name cannot be longer than 200 characters.';
  }

  return newErrors;
};

export default function DealInformation({
  dealInfo,
  setDealInfo,
  hiddenFields = {},
  disabledFields = { dealName: true, sponsorTeam: true, iTeamMemberName: true },
  validationObject = {},
  errors = {},
  rejectionReason,
}) {
  const {
    dealName, sponsorTeam, iTeamMemberName,
  } = dealInfo;

  React.useEffect(() => {
    // eslint-disable-next-line no-param-reassign
    validationObject.current = validate;
  }, []);

  const renderTextField = (value, label, error, disabled, valueToUpdate) => (!disabled
    ? (
      <AvTextField
        label={label}
        variant="standard"
        value={value || ''}
        onChange={(e) => setDealInfo({ ...dealInfo, [valueToUpdate]: e.target.value })}
        error={error || false}
        helperText={error || ''}
      />
    )
    : (
      <ReadOnlyTextField
        label={label}
        value={value}
        fullWidth
      />
    )
  );

  return (
    <>
      <h5>Deal Information</h5>
      {rejectionReason && renderTextField(rejectionReason, 'Rejection Reason', null, true)}
      {!hiddenFields.dealName && renderTextField(dealName, 'Deal Name', errors.dealName, disabledFields.dealName)}
      {!hiddenFields.sponsorTeam && renderTextField(sponsorTeam, 'Sponsor Team', errors.sponsorTeam, disabledFields.sponsorTeam)}
      {!hiddenFields.iTeamMemberName
      && renderTextField(
        iTeamMemberName || '',
        'iTeam Member Name',
        errors.iTeamMemberName,
        disabledFields.iTeamMemberName,
        'iTeamMemberName',
      )}
    </>
  );
}

DealInformation.propTypes = {
  dealInfo: PropTypes.shape({
    dealName: PropTypes.string,
    sponsorTeam: PropTypes.string,
    iTeamMemberName: PropTypes.string,
  }).isRequired,
  setDealInfo: PropTypes.func.isRequired,
  hiddenFields: PropTypes.shape({}),
  requiredFields: PropTypes.shape({}),
  validationObject: PropTypes.shape({}),
  errors: PropTypes.oneOfType([PropTypes.shape({}), PropTypes.shape({
    dealName: PropTypes.string,
    sponsorTeam: PropTypes.string,
    iTeamMemberName: PropTypes.string,
  })]),
  disabledFields: PropTypes.shape({
    dealName: PropTypes.bool,
    sponsorTeam: PropTypes.bool,
    iTeamMemberName: PropTypes.bool,
  }),
  rejectionReason: PropTypes.string,
};

DealInformation.defaultProps = {
  validationObject: {},
  hiddenFields: {},
  requiredFields: {},
  errors: {},
  disabledFields: { dealName: true, sponsorTeam: true, iTeamMemberName: true },
  rejectionReason: '',
};
