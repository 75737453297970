import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import {
  Radio, RadioGroup, FormControl, FormControlLabel, FormHelperText, Button, Grid, Container, Skeleton,
} from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import { useHistory } from 'react-router-dom';
import {
  Card, CardBody,
} from 'reactstrap';
import usePendingInvestmentDetail from '../shared/hooks/usePendingInvestmentDetail';
import { updatePendingInvestment } from '../shared/services/apiGateway';
import generateRequest from '../shared/services/generateRequest';

export default function NameVerification({ match }) {
  const pendingInvestmentId = match.params.id;
  const history = useHistory();
  const [nsCompanyName, setNsCompanyName] = useState('');
  const [pretext, setPretext] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);

  const pendingInvestmentDetail = usePendingInvestmentDetail(pendingInvestmentId);

  useEffect(() => {
    if (pendingInvestmentDetail) {
      const postEdit = new URLSearchParams(window.location.search).has('postEdit');
      if (pendingInvestmentDetail.verticaObj && !postEdit) {
        if (!pendingInvestmentDetail.dueDiligence) {
          history.push(`/pending-investment/${pendingInvestmentId}/dd-report`);
        } else {
          history.push(`/pending-investment/${pendingInvestmentId}`);
        }
      } else {
        if (pendingInvestmentDetail.netsuiteCompanyId) {
          setPretext('We have found a Company called');
        } else {
          setPretext('We did not find a Company called');
        }
        setNsCompanyName(pendingInvestmentDetail.companyName);
      }
    }
  }, [pendingInvestmentDetail]);

  const [value, setValue] = React.useState('');
  const [error, setError] = React.useState(false);
  const [helperText, setHelperText] = React.useState();

  const handleSubmit = async (event) => {
    event.preventDefault();

    setIsSubmitting(true);
    if (value === 'yes') {
      await updatePendingInvestment(generateRequest(pendingInvestmentDetail));
      history.push(`/pending-investment/${pendingInvestmentId}/dd-report`);
      setError(false);
    } else if (value === 'no') {
      setError(true);
      setHelperText('Please update the Company name externally to continue.');
      const currLoc = window.location.href;
      setTimeout(() => {
        if (currLoc === window.location.href) {
          history.goBack();
        }
      }, 3000);
    } else {
      setHelperText('Please select an option to continue.');
      setError(true);
    }
    setIsSubmitting(false);
  };

  return (
    <>
      <Helmet>
        <title>Company Name Verification</title>
      </Helmet>
      <Container maxWidth="md">
        <Card>
          <CardBody>
            {!pendingInvestmentDetail && (<Skeleton variant="rectangular" sx={{ my: 2, mx: 1 }} height={35} key="verifyNameLoading" />)}
            {pendingInvestmentDetail && (
            <><h4>{pretext} <b>{nsCompanyName}</b>, is this name correct?</h4>
              <form onSubmit={handleSubmit}>
                <FormControl sx={{ m: 3 }} error={error} variant="standard">
                  <RadioGroup
                    name="options"
                    value={value}
                    onChange={(event) => {
                      setValue(event.target.value);
                      setHelperText('');
                      setError(false);
                    }}
                    row
                  >
                    <FormControlLabel value="yes" control={<Radio />} label="Yes" />
                    <FormControlLabel value="no" control={<Radio />} label="No" />
                  </RadioGroup>
                  <FormHelperText style={{ fontSize: '17px' }}>{helperText}</FormHelperText>
                </FormControl>
                <Grid
                  container
                  direction="row"
                  alignItems="flex-end"
                  justifyContent="space-between"
                >
                  <Button
                    color="secondary"
                    variant="outlined"
                    onClick={() => {
                      history.goBack();
                    }}
                  >Cancel
                  </Button>
                  <LoadingButton
                    color="secondary"
                    type="submit"
                    variant="contained"
                    disabled={!value || error}
                    loading={isSubmitting}
                  >Submit
                  </LoadingButton>
                </Grid>
              </form>
            </>
            )}
          </CardBody>
        </Card>
      </Container>
    </>
  );
}

NameVerification.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.node,
    }).isRequired,
  }).isRequired,
};
