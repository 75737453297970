export const INIT_SESSION = 'INIT_SESSION';
export const INIT_SESSION_SUCCESS = 'INIT_SESSION_SUCCESS';
export const INIT_SESSION_FAILURE = 'INIT_SESSION_FAILURE';

export const DO_SIGN_IN = 'DO_SIGN_IN';
export const AUTHENTICATED = 'AUTHENTICATED';
export const SIGN_IN_ERROR = 'SIGN_IN_ERROR';
export const DO_LOG_OUT = 'DO_LOG_OUT';
export const SIGNEDOUT = 'SIGNEDOUT';

export const AUTH_TYPE = { USER: 'USER', ADMIN: 'ADMIN', MANAGED: 'MANAGED' };

function hasAuthCookie() {
  if (document.cookie.replace(/(?:(?:^|.*;\s*)_identity\s*=\s*([^;]*).*$)|^.*$/, '$1')) {
    return true;
  }
  return false;
}

function setAuthCookie(authCookie, rememberMe = true) {
  /* eslint-disable prefer-template */

  if (authCookie) {
    let expires = '';
    if (rememberMe) {
      const date = new Date();
      date.setTime(date.getTime() + (30 * 24 * 60 * 60 * 1000));
      expires = '; expires=' + date.toUTCString();
    }
    document.cookie = '_identity=' + escape(authCookie || '') + expires + '; path=/';
  } else {
    document.cookie = '_identity=;expires=Thu, 01 Jan 1970 00:00:00 GMT';
  }
}

export const loadAuthState = async function loadAuthState(apiPath, authType = AUTH_TYPE.USER) {
  const newState = {};
  // try to get signed in data from localStorage
  let dataFromLocalStorage = {};
  try {
    dataFromLocalStorage = JSON.parse(localStorage.getItem(`au_${apiPath}`)) || {};
  } catch (e) {
    dataFromLocalStorage = {};
  }

  if (!hasAuthCookie()) {
    // do not sign in if there is no cookie for php parts
    dataFromLocalStorage = {};
  }

  newState.user = null;
  newState.authCode = null;
  newState.isLoggedIn = false;

  if (dataFromLocalStorage[authType]) {
    if (dataFromLocalStorage[authType].data && dataFromLocalStorage[authType].authCode) {
      newState.user = dataFromLocalStorage[authType].data;
      newState.authCode = dataFromLocalStorage[authType].authCode;
      newState.isLoggedIn = true;
    }
  }

  newState.isLoading = false;

  return newState;
};


export const persistAuthState = function persistAuthState(authState) {
  const { apiPath } = authState;
  let dataFromLocalStorage = {};
  try {
    dataFromLocalStorage = JSON.parse(localStorage.getItem(`au_${apiPath}`));
  } catch (e) {
    dataFromLocalStorage = {};
  }

  if (!dataFromLocalStorage) {
    dataFromLocalStorage = {};
  }

  dataFromLocalStorage[authState.authType] = { data: authState.user, authCode: authState.authCode };

  localStorage.setItem(`au_${apiPath}`, JSON.stringify(dataFromLocalStorage));

  return true;
};


export function authenticated(user, authCode) {
  return {
    type: AUTHENTICATED,
    user,
    authCode,
  };
}

export function initSession(apiPath, authType = AUTH_TYPE.USER) {
  return (dispatch, getState) => {
    dispatch({ type: INIT_SESSION, apiPath, authType });

    const state = getState();
    const { api } = state.auth; // it's already there and ready to work with public methods (not authCode)

    // const state = getState();
    return loadAuthState(apiPath, authType)
      .then((authState) => {
        if (authState.isLoggedIn) {
          dispatch({ ...authState, type: INIT_SESSION_SUCCESS });
          return true;
        }
        if (authType === AUTH_TYPE.MANAGED) {
          dispatch({ type: INIT_SESSION_FAILURE });
          return getState().auth;
        }

        return api.get('api/v2.0/mobile/identity/details');
        // if (hasAuthCookie()) {
        //   return api.get('api/v2.0/mobile/identity/details');
        // }

        // dispatch({ ...authState, type: INIT_SESSION_FAILURE });
      })
      .then((data) => {
        let success = false;
        if (data) {
          if (data === true || data === false) {
            // resolved before
            success = true;
          }

          if (data.admin && authType === AUTH_TYPE.ADMIN) {
            // signed in as admin on php side
            dispatch({ user: data.admin.data, authCode: data.admin.authCode, type: INIT_SESSION_SUCCESS });
            persistAuthState(getState().auth);

            success = true;
          }
          if (data.user && authType === AUTH_TYPE.USER) {
            // signed in as admin on php side
            dispatch({ user: data.user.data, authCode: data.user.authCode, type: INIT_SESSION_SUCCESS });
            persistAuthState(getState().auth);

            success = true;
          }
        }

        if (!success) {
          dispatch({ type: INIT_SESSION_FAILURE });
        }

        return getState().auth;
      });
  };
}


export function doSignIn(credentials) {
  return (dispatch, getState) => {
    const state = getState();
    const { api } = state.auth; // it's already there and ready to work with public methods (not authCode)

    dispatch({ type: DO_SIGN_IN });

    // credentials = {email: email, password: password};
    let path = 'api/v1.0/mobile/admin/login';
    if (state.auth.authType === AUTH_TYPE.USER) {
      path = 'api/v1.0/mobile/user/login';
    }

    return api.post(path, credentials)
      .then((data) => {
        if (data && data.admin && data.authCode) {
          if (data.authCookie) {
            //  there's identity cookie, set it on the document
            setAuthCookie(data.authCookie);
          }

          dispatch(authenticated(data.admin, data.authCode));
          persistAuthState(getState().auth);

          return true;
        }
        if (data && data.user && data.authCode) {
          if (data.authCookie) {
            //  there's identity cookie, set it on the document
            setAuthCookie(data.authCookie);
          }
          dispatch(authenticated(data.user, data.authCode));
          persistAuthState(getState().auth);
          return data.user;
        }

        dispatch({ type: SIGN_IN_ERROR });

        return false;
      });
  };
}

export function doLogOut() {
  return (dispatch, getState) => {
    const state = getState();
    const { api } = state.auth; // it's already there and ready to work with public methods (not authCode)

    dispatch({ type: DO_LOG_OUT });
    api.get('api/v2.0/mobile/identity/logout');
    // setAuthCookie(null);
    persistAuthState(getState().auth);
  };
}

export function signedout() {
  return { type: SIGNEDOUT };
}


export default {
  initSession,
  doSignIn,
  doLogOut,
  authenticated,
  signedout,
  AUTH_TYPE,
};
