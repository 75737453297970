import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import {
  Modal, Grid, Button, Fade, Backdrop,
} from '@material-ui/core';

const announcementFilter = (announcement) => {
  if (announcement.display_status === 0) {
    return true;
  }
  if (moment(new Date()).isBefore(moment(announcement.start_date))) {
    return true;
  }
  if (announcement.end_date && (moment(new Date()).isAfter(moment(announcement.end_date)))) {
    return true;
  }
  return false;
};

const EmailAnnouncementModal = ({
  classes, open, handleClose, confirmEmail, loadingEmail, announcement,
}) => (
  <Modal
    aria-labelledby="transition-modal-title"
    aria-describedby="transition-modal-description"
    className={classes.modal}
    open={open}
    onClose={handleClose}
    closeAfterTransition
    BackdropComponent={Backdrop}
    BackdropProps={{
      timeout: 500,
    }}
  >
    <Fade in={open}>
      <div className={classes.paper}>
        <h2 id="transition-modal-title">Email club members?</h2>
        { announcementFilter(announcement) ? (
          <p>
            This announcement is currently hidden.
            <br />
            Please change the start date, end date or display status to send an email to members.
          </p>
        ) : (
          <p id="transition-modal-description">
            Are you sure you want to notify all club members of this announcement via email?
          </p>
        )}
        <Grid
          container
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Button
            disabled={loadingEmail}
            variant="contained"
            type="button"
            size="medium"
            // color=""
            className={classes.margin}
            onClick={handleClose}
            id="cancel-email-send"
          >
            {announcementFilter(announcement) ? 'Cancel' : 'No'}
          </Button>
          {loadingEmail ? (
            <div className="ml-auto">
              <svg className="load__icon">
                <path fill="#104866" d="M12,4V2A10,10 0 0,0 2,12H4A8,8 0 0,1 12,4Z" />
              </svg>
            </div>
          ) : (
            <Button
              disabled={loadingEmail || announcementFilter(announcement)}
              variant="contained"
              type="button"
              size="medium"
              color="primary"
              className={classes.margin}
              onClick={confirmEmail}
              id="confirm-email-send"
            >
              Yes
            </Button>
          )}
        </Grid>
      </div>
    </Fade>
  </Modal>
);

EmailAnnouncementModal.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  classes: PropTypes.any.isRequired,
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  confirmEmail: PropTypes.func.isRequired,
  loadingEmail: PropTypes.bool,
  // eslint-disable-next-line react/forbid-prop-types
  announcement: PropTypes.any,
};

EmailAnnouncementModal.defaultProps = {
  loadingEmail: false,
  announcement: null,
};

export default EmailAnnouncementModal;
