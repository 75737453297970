import {
  REQUEST_ALL_COMPANIES,
  RECEIVE_ALL_COMPANIES,
} from '../actions/getCompaniesActions';

export default function (state = {
  isFetching: false,
  companiesArray: [],
}, action) {
  switch (action.type) {
    case REQUEST_ALL_COMPANIES:
      return Object.assign({}, state, {
        isFetching: true,
      });
    case RECEIVE_ALL_COMPANIES:
      return Object.assign({}, state, {
        isFetching: false,
        companiesArray: action.companies,
        lastUpdated: action.receivedAt,
      });
    default:
      return state;
  }
}
