const required = value => (value && value.length ? undefined : 'This field is required');
const URL_REGEXP = /^[A-Za-z][A-Za-z\d.+-]*:\/*(?:\w+(?::\w+)?@)?[^\s/]+(?::\d+)?(?:\/[\w#!:.?+=&%@\-/]*)?$/;
const maxLength = max => value => (value && value.length > max ? `Must be ${max} characters or less` : undefined);
const maxLength120 = maxLength(120);
const maxLength255 = maxLength(255);
const validatePhone = value => (
  value && !/^(?:\+?1\s*(?:[.-]\s*)?)?(?:\(\s*([2-9]1[02-9]|[2-9][02-8]1|[2-9][02-8][02-9])\s*\)|([2-9]1[02-9]|[2-9][02-8]1|[2-9][02-8][02-9]))\s*(?:[.-]\s*)?([2-9]1[02-9]|[2-9][02-9]1|[2-9][02-9]{2})\s*(?:[.-]\s*)?([0-9]{4})(?:\s*(?:#|x\.?|ext\.?|extension)\s*(\d+))?$/.test(value)
    ? 'Invalid phone number, must be 10 digits'
    : undefined
);
const validateUrl = value => (value && !URL_REGEXP.test(value)
  ? 'Invalid Url' : undefined);
const validateEmail = value => (value && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)
  ? 'Invalid email address' : undefined);
export default class EditableMemberCEO {
  constructor(rawClubMember) {
    this.id = {
      label: 'ID',
      type: null,
      readOnly: true,
      value: rawClubMember.id,
    };
    this.first_name = {
      label: 'First Name',
      type: 'text',
      readOnly: false,
      value: rawClubMember.first_name,
      validators: [required, maxLength120],
    };
    this.last_name = {
      label: 'Last Name',
      type: 'text',
      readOnly: false,
      value: rawClubMember.last_name,
      validators: [required, maxLength255],
    };
    this.company_name = {
      label: 'Company',
      type: 'text',
      readOnly: false,
      value: rawClubMember.company_name,
      validators: [required, maxLength120],
    };
    this.company_url = {
      label: 'Company Website',
      type: 'text',
      readOnly: false,
      value: rawClubMember.company_url,
      validators: [required, validateUrl],
    };
    this.title = {
      label: 'Title',
      type: 'text',
      readOnly: false,
      value: rawClubMember.title,
      validators: [required, maxLength120],
    };
    this.email = {
      label: 'Email',
      type: 'textPrivate',
      readOnly: false,
      validators: [validateEmail],
      value: rawClubMember.email === null ? '' : rawClubMember.email,
      disabled: rawClubMember.email === '',
    };
    this.phone = {
      hidden: true,
      label: 'Phone Number',
      type: 'textPrivate',
      readOnly: false,
      validators: [validatePhone],
      value: rawClubMember.phone === null ? '' : rawClubMember.phone,
      disabled: rawClubMember.phone === '',
    };
    this.linked_in_url = {
      label: 'LinkedIn Url',
      type: 'text',
      readOnly: false,
      validators: [validateUrl],
      value: rawClubMember.linked_in_url === null ? '' : rawClubMember.linked_in_url,
    };
    this.profile_picture = {
      type: 'fileinput',
      readOnly: false,
    };
    this.imageUri = {
      type: null,
      readOnly: true,
      value: rawClubMember.avatar,
      previewClass: 'logoImgCircle',
      acceptedTypes: 'image/jpeg, image/png, image/gif',
    };
  }
}
