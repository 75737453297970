/**
 *
 * @param {String} phoneNumber
 * @returns {String}
 * Example:
 *  2123448888 -> (212) - 444 - 8888
 * 18888888888 -> +1 (888) - 888 - 8888
 */
const formatPhoneNumber = (phoneNumber) => {
  let parsedPhoneNumber = '';
  if (phoneNumber.length <= 3) {
    // If less than 3 digits just return the digits.
    // nnn => nnn
    parsedPhoneNumber = phoneNumber;
  } else if (phoneNumber.length <= 6) {
    // If between 4 and 6, add parentheses around the first 3.
    // nnnn => (nnn) - n
    parsedPhoneNumber = `(${phoneNumber.substring(0, 3)}) - ${phoneNumber.substring(3, phoneNumber.length)}`;
  } else if (phoneNumber.length <= 10) {
    // If between 7 and 10, add parenthese around the first group of three and dashes after the 2nd group of three.
    // nnnnnnnnnn => (nnn) - nnn - nnnn
    parsedPhoneNumber = `(${phoneNumber.substring(0, 3)}) - ${phoneNumber.substring(3, 6)} - ${phoneNumber.substring(6)}`;
  } else {
    // For more than 10 digits, take the last 10 digits, parse them using this function
    // and prepend the remaining digits.
    // nnnnnnnnnn => +nn (nnn) - nnn - nnnn
    const countryCode = phoneNumber.substring(0, phoneNumber.length - 10);
    const number = formatPhoneNumber(phoneNumber.substring(countryCode.length));
    parsedPhoneNumber = `+ ${countryCode} ${number}`;
  }

  return parsedPhoneNumber;
};

export default formatPhoneNumber;
