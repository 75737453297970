/* eslint-disable react/forbid-prop-types */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';
import {
  AppBar, Tabs, Tab, Box, Button,
} from '@material-ui/core';
import {
  Col, Container, Row, Card, CardBody,
} from 'reactstrap';
import AnnouncementFormWrapper from './components/AnnouncementFormWrapper';
import AnnouncementsTable from './components/AnnouncementsTable';
import hooks from '../../hooks';

function TabPanel(props) {
  const {
    children, value, index, ...other
  } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          {children}
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  // eslint-disable-next-line react/require-default-props
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const AnnouncementsEditor = ({ fullWidth, admin }) => {
  const { id, tab } = useParams();
  const club = hooks.useClub(id);
  const members = hooks.useMembers(id);
  const [refreshKey, setRefreshKey] = useState(0);
  const announcements = hooks.useAnnouncements(id, true, refreshKey);
  const [isEditing, setIsEditing] = useState(false);

  const setTab = () => {
    if (tab) {
      return parseInt(tab, 10);
    }
    if (fullWidth) {
      return 1;
    }
    return 0;
  };

  const [value, setValue] = useState(setTab);

  // if (announcements && announcements.length === 0) {
  //   setValue(1);
  // }

  const handleChange = (event, newValue) => {
    setIsEditing(false);
    setRefreshKey(oldKey => oldKey + 1);
    setValue(newValue);
  };

  const handleSetIsEditing = (event, set) => {
    if (!set) {
      setRefreshKey(oldKey => oldKey + 1);
    }
    setIsEditing(set);
  };

  return (
    <Container className="dashboard">
      <Row className="justify-content-md-center">
        <Col sm="12" md={!fullWidth ? 10 : 12} xl={!fullWidth ? 8 : 12}>
          <Card>
            <CardBody>
              {!fullWidth && (
                <>
                  <Row>
                    <Col xs="3" md="3" lg="2">
                      <Button color="primary" variant="outlined" href={`/investor/club/home/${id}`}>
                        Back
                      </Button>
                    </Col>
                    <Col>
                      <h3>
                        <b>
                          <span style={{ color: '#104866' }}>Announcements Editor</span>
                        </b>
                      </h3>
                    </Col>
                  </Row>
                  <hr />
                </>
              )}
              <AppBar position="static" className="topbar__border">
                <Tabs
                  value={value}
                  onChange={handleChange}
                  aria-label="simple tabs example"
                  variant="fullWidth"
                >
                  <Tab label="Create Announcement" {...a11yProps(0)} />
                  <Tab label="Edit Announcements" {...a11yProps(1)} />
                </Tabs>
              </AppBar>
              <TabPanel value={value} index={1}>
                {(announcements && club && members) && (
                  <AnnouncementsTable
                    id={id}
                    isEditing={isEditing}
                    setIsEditing={setIsEditing}
                    setRefreshKey={setRefreshKey}
                    announcements={announcements}
                    handleChange={setValue}
                    club={club}
                    members={members}
                    fullWidth={fullWidth}
                  />
                )}
              </TabPanel>
              <TabPanel value={value} index={0}>
                {(handleSetIsEditing && club && members) && (
                  <AnnouncementFormWrapper
                    fullWidth={fullWidth}
                    cancelButton="Cancel"
                    admin={admin}
                    members={members}
                    club={club}
                  />
                )}
              </TabPanel>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

AnnouncementsEditor.propTypes = {
  fullWidth: PropTypes.bool,
  admin: PropTypes.bool,
};

AnnouncementsEditor.defaultProps = {
  fullWidth: false,
  admin: false,
};

export default AnnouncementsEditor;
