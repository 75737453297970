import * as React from 'react';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import SearchIcon from '@mui/icons-material/Search';

export default function SearchBar(params) {
  return (
    <TextField
      InputProps={{
        startAdornment: <InputAdornment position="start"><SearchIcon /></InputAdornment>,
      }}
      variant="outlined"
      sx={{
        '& .MuiOutlinedInput-notchedOutline legend': { width: 0 },
      }}
      {...params}
    />
  );
}
