import { useState, useEffect } from 'react';
import { getMembers } from '../services/bifService';

function useMembers(clubId, updateCounter = null) {
  const [members, setMembers] = useState([]);

  useEffect(() => {
    const abortController = new AbortController();
    let ignore = false;

    async function fetchData() {
      const membersList = await getMembers(clubId, abortController.signal);
      if (!ignore && membersList !== 'aborted' && membersList.members) {
        setMembers(membersList.members);
      }
    }

    fetchData();

    return () => {
      ignore = true;
      abortController.abort();
    };
  }, [clubId, updateCounter]);

  return members;
}

export default useMembers;
