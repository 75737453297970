import React, { useState } from 'react';
import { Helmet } from 'react-helmet';
import {
  Container, Row, Card, CardBody, Col,
} from 'reactstrap';
import { useHistory } from 'react-router-dom';
import DealsSearchBar from '../shared/components/PendingInvestmentsSearchBar';
import AvDataGrid from '../shared/components/AvDataGrid';
import useDeals from '../shared/hooks/usePendingInvestments';
import createPostWireTableDefinition from './PostWireDashboardTableDefinition';
import useFilteredBySearch from '../shared/hooks/useFilteredBySearch';

export const allStagesCompleted = (stagesData) => (stagesData
  .every((stageData) => stageData
  && !!stageData.confirmationProperty));

const createTableData = (dealData) => {
  const tableData = [];
  if (dealData && dealData.length > 0) {
    const postWireDeals = dealData.filter((dd) => allStagesCompleted(dd.stagesData));

    for (const postWireDeal of postWireDeals) {
      tableData.push({
        id: postWireDeal.id,
        companyName: postWireDeal.companyName,
        wireDate: postWireDeal.stagesCompleted.approveWireExecution,
      });
    }
  }
  return tableData;
};

function PostWireDashboard() {
  const [loadingPostWire, setLoadingPostWire] = useState(true);
  const deals = useDeals(setLoadingPostWire);
  const dealsTableDef = createPostWireTableDefinition(useHistory());
  const [companyNameSearchValue, setCompanyNameSearchValue] = useState('');
  const [pageSize, setPageSize] = useState(10);
  const filteredPostWireDeals = createTableData(useFilteredBySearch(deals, companyNameSearchValue));
  return (
    <>
      <Helmet>
        <title>Post Wiring Dashboard</title>
      </Helmet>
      <Container>
        <Card>
          <CardBody>
            <Row>
              <Col>
                <h2>Post Wiring Dashboard</h2>
                <div style={{ marginTop: '20px' }}>
                  <DealsSearchBar
                    placeholder="Search by company name"
                    onChange={(e) => setCompanyNameSearchValue(e.target.value)}
                    value={companyNameSearchValue}
                    disabled={loadingPostWire}
                    fullWidth
                  />
                </div>
                <div style={{ marginTop: '20px' }}>
                  <AvDataGrid
                    pageSize={pageSize}
                    setPageSize={setPageSize}
                    rows={filteredPostWireDeals}
                    columns={dealsTableDef}
                    loading={loadingPostWire}
                    initialState={{
                      sorting: {
                        sortModel: [{ field: 'wireDate', sort: 'asc' }],
                      },
                    }}
                  />
                </div>
              </Col>

            </Row>
          </CardBody>
        </Card>
      </Container>
    </>

  );
}

export default PostWireDashboard;
