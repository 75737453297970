import {
  GET_LI_USER,
  GET_LI_USER_ERROR,
  POST_LI_USER,
  POST_LI_USER_ERROR,
  SAVE_LI_USER,
} from '../actions/liUserActions';

const initialState = {
  un: '',
  pw: '',
  firstName: '',
  lastName: '',
  signatureFirstDegree: '',
  isActive: false,
  fundName: '',
  id: '',
  lastError: '',
  stats: null,
  isLoading: false,
  isLoadingCredentials: false,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_LI_USER:
      return {
        ...state, isLoading: true,
      };
    case GET_LI_USER_ERROR:
      return {
        ...state, un: '', pw: '', isLoading: false,
      };
    case POST_LI_USER:
      // now it is used only for saving credentials
      // so it doesn't need to set isLoading
      // because this will refresh ProfileCard
      return {
        ...state, isLoadingCredentials: true,
      };
    case POST_LI_USER_ERROR:
      return {
        ...state, un: '', pw: '', isLoadingCredentials: false,
      };
    case SAVE_LI_USER:
      return {
        ...state,
        un: action.liUser.un,
        pw: action.liUser.pw,
        firstName: action.liUser.firstName,
        lastName: action.liUser.lastName,
        isActive: action.liUser.isActive,
        signatureFirstDegree: action.liUser.signatureFirstDegree,
        fundName: action.liUser.fundName,
        id: action.liUser.id,
        lastError: action.liUser.lastError,
        stats: action.liUser.stats,
        isLoading: false,
        isLoadingCredentials: false,
      };
    default:
      return state;
  }
}
