import {
  REQUEST_ALL_INVESTORS,
  RECEIVE_ALL_INVESTORS,
  RECEIVE_ALL_INVESTORS_ERROR,
} from '../actions/getInvestorsActions';

export default function (state = {
  isFetching: false,
  investorArray: [],
  error: null,
}, action) {
  switch (action.type) {
    case REQUEST_ALL_INVESTORS:
      return Object.assign({}, state, {
        isFetching: true,
        error: action.error,
      });
    case RECEIVE_ALL_INVESTORS:
      return Object.assign({}, state, {
        isFetching: false,
        investorArray: action.investors,
        lastUpdated: action.receivedAt,
      });
    case RECEIVE_ALL_INVESTORS_ERROR:
      return Object.assign({}, state, {
        isFetching: false,
        error: action.error,
      });
    default:
      return state;
  }
}
