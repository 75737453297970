import {
  INVALIDATE_DATE,
  REQUEST_TOTALS_FUNDS,
  RECEIVE_TOTALS_FUNDS,
} from '../actions/fundsTotalActions';

function totals(
  state = {
    isFetching: false,
    didInvalidate: false,
    items: [],
  },
  action,
) {
  switch (action.type) {
    case INVALIDATE_DATE:
      return Object.assign({}, state, {
        didInvalidate: true,
      });
    case REQUEST_TOTALS_FUNDS:
      return Object.assign({}, state, {
        isFetching: true,
        didInvalidate: false,
      });
    case RECEIVE_TOTALS_FUNDS:
      return Object.assign({}, state, {
        isFetching: false,
        didInvalidate: false,
        items: action.totals,
        lastUpdated: action.receivedAt,
      });
    default:
      return state;
  }
}

export default function (state = {}, action) {
  switch (action.type) {
    case INVALIDATE_DATE:
    case RECEIVE_TOTALS_FUNDS:
    case REQUEST_TOTALS_FUNDS:
      return Object.assign({}, state, {
        [action.date]: totals(state[action.date], action),
      });
    default:
      return state;
  }
}
