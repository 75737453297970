import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import {
  CardBody,
  Container,
  Card,
} from 'reactstrap';
import PartialPageLoader from '../shared/components/Loader/PartialPageLoader';
import useDueDiligenceReportFile from '../shared/hooks/useDueDiligenceReportFile';

function DDReportS3FileViewer({ match }) {
  const history = useHistory();
  const ddReportId = match.params.id;
  if (!ddReportId) {
    history.push('/page-not-found');
  }

  const ddReportS3File = useDueDiligenceReportFile(ddReportId, (s3File) => {
    if (!s3File || !s3File.publishedFileSignedUrl) {
      history.push('/page-not-found');
    } else {
      window.location.href = s3File.publishedFileSignedUrl;
    }
  });

  return (
    <>
      <Helmet>
        <title>Due Diligence Report File Viewer</title>
      </Helmet>
      <Container className="med-width-form">
        <Card>
          <CardBody>
            {PartialPageLoader(4)}
          </CardBody>
        </Card>
      </Container>
    </>
  );
}

DDReportS3FileViewer.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.node,
    }).isRequired,
  }).isRequired,
};

export default DDReportS3FileViewer;
