import {
  SHOW_SNACKBAR_INFO,
  SHOW_SNACKBAR_ERROR,
  SHOW_SNACKBAR_WARNING,
  HIDE_SNACKBAR,
} from '../actions/snackbarActions';


export default function (state = {
  message: '',
  autoClose: false,
}, action) {
  switch (action.type) {
    case SHOW_SNACKBAR_INFO:
      return Object.assign({}, state, {
        message: action.message,
        isShow: true,
        designType: 'success',
        autoClose: action.autoClose,
      });
    case SHOW_SNACKBAR_ERROR:
      return Object.assign({}, state, {
        message: action.message,
        isShow: true,
        designType: 'danger',
        autoClose: action.autoClose,
      });
    case SHOW_SNACKBAR_WARNING:
      return Object.assign({}, state, {
        message: action.message,
        isShow: true,
        designType: 'warning',
        autoClose: action.autoClose,
      });
    case HIDE_SNACKBAR:
      return Object.assign({}, state, {
        message: '',
        isShow: false,
      });
    default:
      return state;
  }
}
