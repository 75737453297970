import { useState, useEffect } from 'react';
import { getDictonaryForClubs } from '../services/bifService';

function useClubDictionary() {
  const [data, setData] = useState([]);

  useEffect(() => {
    const abortController = new AbortController();
    let ignore = false;

    async function fetchData() {
      const response = await getDictonaryForClubs(abortController.signal);
      if (!ignore && response !== 'aborted' && response) {
        setData(response);
      }
    }

    fetchData();

    return () => {
      ignore = true;
      abortController.abort();
    };
  }, []);

  return data;
}

export default useClubDictionary;
