import getStore from '../../redux/store/store';
import StringHelper from '../helpers/StringHelper';

export const getAccreditationLabel = function getAccreditationLabel(accreditation) {
  const { store } = getStore();
  const state = store.getState();
  const { dictionary } = state;
  return dictionary.dictionary.get('userModelAccreditaion', accreditation, '-');
};

export const getMoiLabel = function getMoiLabel(moi) {
  const { store } = getStore();
  const state = store.getState();
  const { dictionary } = state;
  return dictionary.dictionary.get('userModelMOI', moi);
};

function getNameLabel(investor) {
  return `${investor.firstName} ${(investor.middleName ? `${investor.middleName} ` : '')}${investor.lastName}`;
}

function totalMap(title, value) {
  switch (title) {
    case 'investmentsAtCost':
      return { title: StringHelper.convertFromCamelCase(title), value: StringHelper.formatMoney(value) };
    case 'investmentsValue':
      return { title: StringHelper.convertFromCamelCase(title), value: StringHelper.formatMoney(value) };
    case 'gainVsLoss':
      return { title: StringHelper.convertFromCamelCase(title), value: StringHelper.formatPercent(value) };
    default:
      return { title: StringHelper.convertFromCamelCase(title), value };
  }
}

function formatInvestorTotals(investor) {
  const { totals } = investor;
  totals.accreditationLevel = getAccreditationLabel(investor.accreditation);
  return Object.keys(totals).map(key => totalMap(key, totals[key]));
}

function formatInvestments(investments) {
  return investments.map(investment => ({
    fund: investment.fund.name,
    id: investment.id,
    invAmount: investment.invAmount,
    moi: getMoiLabel(investment.moi),
    ownership: investment.ownership,
    percentReturn: investment.percentReturn,
    link: `/fund/${investment.fund.id}`,
    name: investment.fund.name,
  }));
}

export const formatInvestor = function formatInvestor(responseArray) {
  const { investor } = { ...responseArray[0] };
  investor.totals = formatInvestorTotals(investor);
  investor.investments = responseArray[1].investmentsDetails;
  investor.tableInvestments = formatInvestments(responseArray[1].investmentsDetails);
  return investor;
};

export const formatInvestorSearchArray = function formatInvestorSearchArray(responseArray) {
  return responseArray.map((investor) => {
    const resArray = { ...investor };
    const name = getNameLabel(investor);
    resArray.title = `${investor.id}: ${name} (${investor.email})`;
    return resArray;
  });
};
