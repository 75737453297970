import PropTypes from 'prop-types';
import React from 'react';

export default function StageStatusMessage({ stageStatus }) {
  return (
    <div style={{
      display: 'flex',
      justifyContent: 'center',
      marginTop: '24px',
    }}
    >
      {(!stageStatus.isEditableByRole && !stageStatus.isCompleted) && (
        <h4>This step is editable by {stageStatus.responsibleParty} users only</h4>
      )}
      {(stageStatus.isCompleted) && (
        <h4>Step completed</h4>
      )}
    </div>
  );
}

StageStatusMessage.propTypes = {
  stageStatus: PropTypes.shape({
    isEditableByRole: PropTypes.bool,
    isCompleted: PropTypes.bool,
    responsibleParty: PropTypes.string,
  }),
};

StageStatusMessage.defaultProps = {
  stageStatus: {},
};
