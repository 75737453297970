import React, { useState } from 'react';
import { Helmet } from 'react-helmet';
import {
  Container, Row, Card, CardBody, Col,
} from 'reactstrap';
import { v4 } from 'uuid';
import { useParams } from 'react-router-dom';
import DealInformation from '../shared/components/DealInformation';
import useClosedInvestmentDetail from '../shared/hooks/useClosedInvestmentDetail';
import PartialPageLoader from '../shared/components/Loader/PartialPageLoader';
import ReadOnlyTextField from '../shared/components/ReadOnlyTextField';
import getNumberValue from '../shared/utils/getNumberValue';
import formatCurrencyAsUsd from '../shared/utils/formatCurrencyAsUsd';
import { spacing } from '../shared/themes/appTheme';
import { createAQSplitsTotalsTableDefWithCashAdvances } from '../AQSplits/AQSplitsTotalsTableDefinition';
import { createAQSplitsTableDefWithCashAdvances } from '../AQSplits/AQSplitsTableDefinition';
import AvDataGrid from '../shared/components/AvDataGrid';
import {
  prepareFundAllocationsForGrid,
} from '../AQSplits/helpers';
import DocumentViewer from '../shared/components/DocumentViewer';

const createCustomFormatDateStr = (strOrDate) => {
  if (strOrDate) {
    return new Date(strOrDate).toLocaleDateString('en-US', { month: '2-digit', day: '2-digit', year: 'numeric' });
  }
  return '';
};

const styles = {
  sectionSpacing: { margin: '15px 0 5px 0' }, questionsFontSize: '15px',
};

function ClosedInvestmentSummary() {
  const { id: dealId } = useParams();

  const [dealName, setDealName] = useState('');
  const [wireVerificationInfo, setWireVerificationInfo] = useState({});
  const [fundAllocations, setFundAllocations] = useState(null);
  const [wireAmountDoc, setWireAmountDoc] = useState(null);
  const [wireInstructionsDoc, setWireInstructionsDoc] = useState(null);
  const [signedDealDocs, setSignedDealDocs] = useState([]);
  const [closingDocs, setClosingDocs] = useState([]);
  const [proofOfBankTransferDocument, setProofOfBankTransferDocument] = useState(null);
  const [advanceAgreementDocuments, setAdvanceAgreementDocuments] = useState([]);

  const totalInvestmentsId = v4();
  const [avInvestmentTotals, setAvInvestmentTotals] = useState([{
    id: totalInvestmentsId,
    avInvestmentTotal: 0,
    avInvestmentATotal: 0,
    avInvestmentQTotal: 0,
    avCashAdvanceTotal: 0,
  }]);

  const calculateTotals = (funds) => {
    const investmentTotals = {
      id: totalInvestmentsId,
      avInvestmentTotal: 0,
      avInvestmentATotal: 0,
      avInvestmentQTotal: 0,
      avCashAdvanceTotal: 0,
    };
    if (funds) {
      for (const fund of funds) {
        investmentTotals.avInvestmentTotal += getNumberValue(fund.investmentAmount);
        investmentTotals.avInvestmentATotal += getNumberValue(fund.aAmount);
        investmentTotals.avInvestmentQTotal += getNumberValue(fund.qAmount);
        investmentTotals.avCashAdvanceTotal += getNumberValue(fund.cashAdvanceAmount);
      }
    }

    investmentTotals.avInvestmentTotal = formatCurrencyAsUsd(investmentTotals.avInvestmentTotal);
    investmentTotals.avInvestmentATotal = formatCurrencyAsUsd(investmentTotals.avInvestmentATotal);
    investmentTotals.avInvestmentQTotal = formatCurrencyAsUsd(investmentTotals.avInvestmentQTotal);
    investmentTotals.avCashAdvanceTotal = formatCurrencyAsUsd(investmentTotals.avCashAdvanceTotal);
    return investmentTotals;
  };

  const deal = useClosedInvestmentDetail(dealId, (dealData) => {
    let responseDealName = '';
    if (dealData.companyName) {
      responseDealName = dealData.companyName;
    } else if (dealData.hsDealObj && dealData.hsDealObj.dealName) {
      responseDealName = dealData.hsDealObj.dealName;
    }
    setDealName(responseDealName);
    setWireVerificationInfo(dealData.wireVerificationInfo || {});
    setFundAllocations(prepareFundAllocationsForGrid(dealData.funds));
    setAvInvestmentTotals([calculateTotals(dealData.funds)]);

    setWireAmountDoc(dealData.wireAmountDocument || null);
    setWireInstructionsDoc(dealData.wireInstructionsDocument || null);
    setSignedDealDocs(dealData.signedDealDocuments || []);
    setClosingDocs(dealData.closingDocuments || []);
    setAdvanceAgreementDocuments(dealData.advanceAgreementDocuments || []);
    setProofOfBankTransferDocument(dealData.proofOfBankTransferDocument || null);
  });

  return (
    <>
      <Helmet>
        <title>Investment Summary for {dealName}</title>
      </Helmet>
      <Container>
        <Card>
          {!deal && (
            PartialPageLoader()
          )}
          <CardBody>
            {deal && (
              <>
                <Row>
                  <Col>
                    <h3>Investment Summary for {dealName}</h3>
                  </Col>
                </Row>
                <Row className="w-100">
                  <Col
                    lg={12}
                    style={{
                      marginTop: spacing(2),
                    }}
                  >
                    <DealInformation
                      dealInfo={{ dealName, sponsorTeam: deal.sponsorTeam, iTeamMemberName: deal.iTeamMemberName }}
                      setDealInfo={() => false}
                    />
                    <ReadOnlyTextField
                      label="Wire Amount"
                      value={formatCurrencyAsUsd(deal.wireAmount)}
                      fullWidth
                    />
                    <hr />
                    <h5 style={styles.sectionSpacing}> Wire Info Verification</h5>
                    <ReadOnlyTextField
                      label="Name"
                      value={wireVerificationInfo.financeMemberName || ''}
                      fullWidth
                    />
                    <ReadOnlyTextField
                      label="Date"
                      value={createCustomFormatDateStr(wireVerificationInfo.verificationDate || '')}
                      fullWidth
                    />
                    {
                      wireVerificationInfo.additionalInfo && (
                        <ReadOnlyTextField
                          label="Finance Notes"
                          value={wireVerificationInfo.additionalInfo}
                          fullWidth
                          multiline
                        />
                      )
                    }
                    <hr />
                    <h5 style={styles.sectionSpacing}>iTeam Sign Off Of Closing Docs</h5>
                    <ReadOnlyTextField
                      label="Name"
                      value={deal.iTeamMemberName}
                      fullWidth
                    />
                    <ReadOnlyTextField
                      label="Date"
                      value={createCustomFormatDateStr(deal.stagesCompleted.closingDocsReview)}
                      fullWidth
                    />
                    <hr />
                    {
                      (deal.closingDocsReviewQuestions && deal.closingDocsReviewQuestions.length > 0)
                        && (
                        <>
                          <h5 style={{ ...styles.sectionSpacing, fontSize: styles.questionsFontSize }}>Questions answered “No”:</h5>
                          {deal.closingDocsReviewQuestions && deal.closingDocsReviewQuestions.filter((item) => !item.value).map((item) => (
                            <div key={item.label}>
                              <h5 style={{ ...styles.sectionSpacing, fontSize: styles.questionsFontSize }}>{item.label}</h5>
                              <h6>&emsp;<i>Reason:</i>&ensp;“{item.exp}”</h6>
                            </div>
                          ))}
                        </>
                        )
                    }
                    <hr />
                    <div style={{ marginTop: '20px' }}>
                      <h5 style={styles.sectionSpacing}>Uploaded Documents</h5>
                      <DocumentViewer id={deal.id} document={wireAmountDoc} label="Wire Amount Document" />
                      <DocumentViewer id={deal.id} document={wireInstructionsDoc} label="Wire Instructions Document" />
                      <DocumentViewer id={deal.id} documents={signedDealDocs} label="Signed Deal Documents" />
                      <DocumentViewer id={deal.id} documents={closingDocs} label="Closing Documents" />
                      <DocumentViewer id={deal.id} document={proofOfBankTransferDocument} label="Proof Of Bank Transfer Document" />
                      { !!advanceAgreementDocuments.length && (
                      <DocumentViewer
                        id={deal.id}
                        documents={advanceAgreementDocuments}
                        label="Advance Agreement Documents"
                      />
                      ) }
                    </div>
                    <hr />
                    <h4 style={styles.sectionSpacing}>Detailed Allocations</h4>
                    <div style={{ marginTop: '10px' }}>
                      <AvDataGrid
                        rows={fundAllocations}
                        columns={createAQSplitsTableDefWithCashAdvances()}
                        loading={!fundAllocations}
                        initialState={{
                          pinnedColumns: { left: ['name'] },
                        }}
                      />
                    </div>
                    <div style={{ marginTop: '20px' }}>
                      <AvDataGrid
                        pageSize={1}
                        rows={avInvestmentTotals}
                        columns={createAQSplitsTotalsTableDefWithCashAdvances()}
                        loading={!deal}
                        hideFooter
                        density="compact"
                        disableColumnMenu
                        disableColumnFilter
                        disableColumnSelector
                        sx={{
                          fontSize: '17px',
                        }}
                      />
                    </div>
                  </Col>
                </Row>
              </>
            )}
          </CardBody>
        </Card>
      </Container>
    </>
  );
}

export default ClosedInvestmentSummary;
