import moment from 'moment';
import StringHelper from '../helpers/StringHelper';
import DateHelper from '../helpers/DateHelper';

function changeTotalTitle(title) {
  switch (title) {
    case 'inv_amount':
      return 'Total Investment';
    case 'fees_received':
      return 'Fees Reserved';
    case 'investable_assets':
      return 'Investable Assets';
    case 'current_valuation':
      return 'Current Valuation';
    default:
      return title;
  }
}

export const changeInvestmentType = function changeInvestmentType(type) {
  switch (type) {
    case 0:
      return 'Equity Purchase';
    case 1:
      return 'Convertable Note';
    case 2:
      return 'SAFE';
    case 3:
      return 'Liquidated';
    case 4:
      return 'Publicly Traded';
    default:
      return 'Other';
  }
};

export const fixInvestmentType = function fixInvestmentType(type) {
  switch (type) {
    case 'Equity Purchase':
      return 0;
    case 'Convertable Note':
      return 1;
    case 'SAFE':
      return 2;
    case 'Liquidated':
      return 3;
    case 'Publicly Traded':
      return 4;
    default:
      return 0;
  }
};

function formatFundTotals(totals) {
  const totalsCopy = { ...totals };
  totalsCopy.percentInvested = `${(totalsCopy.percentInvested * 100).toFixed(2)}%`;
  // eslint-disable-next-line max-len
  totalsCopy.percentGainLoss = `${(((Number(totalsCopy.investmentsAtCost.replace(/[^0-9.-]+/g, '')) - Number(totalsCopy.currentValuation.replace(/[^0-9.-]+/g, ''))) / Number(totalsCopy.currentValuation.replace(/[^0-9.-]+/g, ''))) * 100).toFixed(2)}%`;
  return Object.keys(totalsCopy).map(key => (
    { title: StringHelper.convertFromCamelCase(key), value: totalsCopy[key] }
  ));
}

function formatInvestments(investments) {
  return investments.map((originalItem) => {
    const item = { ...originalItem };
    item.dateInvested = item.date_invested;
    delete item.date_invested;
    item.dateInvested = item.dateInvested ? moment(item.dateInvested).format('YYYY-MM-DD') : '';
    item.numShares = item.numShares ? parseInt(item.numShares, 10) : '';
    item.investedType = changeInvestmentType(item.investedType);
    return item;
  });
}

export function formatTrackingFund(fundTrackingData) {
  const {
    data, invMethods, investorStatuses, projectionStatuses,
    companyId, fundName, fundId,
  } = fundTrackingData;

  const projectionStatusKey = {
    ...projectionStatuses,
    null: 'Not Set',
  };

  const investorStatusKey = {
    ...investorStatuses,
    null: 'Not Set',
  };

  const investmentMethodKey = {
    ...invMethods,
    null: 'Not Set',
  };

  function hrefToHubspot(hsPortalId, hsContactId, to) {
    return `https://app.hubspot.com/contacts/${hsPortalId}/${to}/${hsContactId}/`;
  }

  const dateFormat = 'MM-DD-YYYY';

  const result = {};
  result.projectionStatusKey = projectionStatusKey;
  result.investorStatusKey = investorStatusKey;
  result.investmentMethodKey = investmentMethodKey;
  result.companyId = companyId;
  result.fundName = fundName;
  result.fundId = fundId;

  const rows = data.map((i) => {
    const item = {};
    item.id = i.id;
    item.fk_fund_id = i.fk_fund_id;
    item.contactId = i.contact.id;
    item.fund_tracking_firstName = i.contact.first_name;
    item.fund_tracking_lastName = i.contact.last_name;
    item.fund_tracking_name = `${i.contact.first_name} ${i.contact.last_name}`;
    item.fund_tracking_name_link = hrefToHubspot(i.contact.hs_portal_id, i.contact.hs_contact_id, 'contact');
    item.fund_tracking_dealName = i.deal.deal_name;
    item.fund_tracking_dealName_link = hrefToHubspot(i.contact.hs_portal_id, i.contact.hs_contact_id, 'deal');
    item.fund_tracking_email = i.contact.email;
    item.amount = StringHelper.formatMoney(i.amount);
    item.amountRaw = i.amount;
    item.method_of_investment = investmentMethodKey[i.method_of_investment];
    item.accreditation = investorStatusKey[i.accreditation];
    item.projection_status = projectionStatusKey[i.projection_status];
    item.pais_date = DateHelper.formatToUTC(i.pais_date, dateFormat);
    item.pais_date_raw = i.pais_date;
    item.sa_date = DateHelper.formatToUTC(i.sa_date, dateFormat);
    item.sa_date_raw = i.sa_date;
    item.fund_transfer_date = DateHelper.formatToUTC(i.fund_transfer_date, dateFormat);
    item.fund_transfer_date_raw = i.fund_transfer_date;
    item.notes = StringHelper.getSubstring(i.notes, 0, 100);
    item.fullNotes = StringHelper.getSubstring(i.notes, 100, Number.MAX_VALUE);
    item.is_co_investment = i.is_co_investment;
    item.days_to_close = i.days_to_close;
    return item;
  });

  result.rows = rows;
  return result;
}

export function reFormatTrackingFund(data) {
  const item = {};
  item.id = data.id;
  item.contact = {};
  item.contact.id = data.contactId;
  item.contact.first_name = data.fund_tracking_firstName;
  item.contact.last_name = data.fund_tracking_lastName;
  item.contact.email = data.fund_tracking_email;
  item.amount = data.amount;
  item.method_of_investment = (data.method_of_investment === 'null') ? null : data.method_of_investment;
  item.accreditation = (data.accreditation === 'null') ? null : data.accreditation;
  item.projection_status = (data.projection_status === 'null') ? null : data.projection_status;
  item.pais_date = data.pais_date;
  item.sa_date = data.sa_date;
  item.fund_transfer_date = data.fund_transfer_date;
  item.notes = data.notes + StringHelper.getSubstring(data.fullNotes, 0, Number.MAX_VALUE);
  item.is_co_investment = data.is_co_investment;
  return item;
}

function formatInvestors(investors) {
  return investors.map((investor) => {
    const investorCopy = { ...investor };
    delete investorCopy.select;
    delete investorCopy.notes;
    investorCopy.user_name = investorCopy.user_name.replace(/<\/?[^>]+(>|$)/g, '');
    investorCopy.user_name = investorCopy.user_name.indexOf('>') !== -1
      ? investorCopy.user_name.split('>').pop()
      : investorCopy.user_name;
    investorCopy.groups_funds_mapping_id = investor.id;
    investorCopy.id = investor.user_id;
    investorCopy.link = `/investor/${investor.user_id}`;
    investorCopy.name = investorCopy.user_name;
    return investorCopy;
  });
}

export default {
  formatAllTotals: function formatAllTotals(response) {
    return Object.keys(response.data[0]).map(key => ({ title: changeTotalTitle(key), value: response.data[0][key].replace(/<\/?[^>]+(>|$)/g, '') }));
  },
  formatFundData: function formatFundData(responseArray) {
    const fund = {};
    fund.details = responseArray[0].fund;
    fund.investors = formatInvestors(responseArray[1].data);
    fund.totals = formatFundTotals(responseArray[0].totals);
    fund.allInvestments = formatInvestments(responseArray[0].investments);
    fund.currentInvestments = fund.allInvestments.filter(item => item.investedType !== 3);
    fund.liquidatedInvestments = fund.allInvestments.filter(item => item.investedType === 3);
    return fund;
  },
};
