/* eslint-disable import/named */
import AddClubMemberModel from './AddClubMember.model';
import ClubMemberRoleOptions from './ClubMemberRoleOptions';
import ClubsSettings from './ClubsSettings.model';
import Company from './Company.model';
import CountryArray from './CountryMap';
import CurrentFutureSyndication from './CurrentFutureSyndication.model';
import EditableClub from './EditableClub.model';
import EditableClubMemberModel from './EditableClubMember.model';
import EditableMemberCEO from './EditableMemberCEO.model';
import FeaturedSyndication from './FeaturedSyndication.model';
import Fund from './Fund.model';
import FundTrackerModel from './FundTracker.model';
import InvestmentForFund from './InvestmentForFund.model';
import InvestmentForInvestor from './InvestmentForInvestor.model';
import InvestmentForPortco from './InvestmentForPortco.model';
import StateArray from './StateMap';
import { validateEmail } from './shared';

export default {
  AddClubMemberModel,
  ClubMemberRoleOptions,
  ClubsSettings,
  Company,
  CountryArray,
  CurrentFutureSyndication,
  EditableClub,
  EditableClubMemberModel,
  EditableMemberCEO,
  FeaturedSyndication,
  Fund,
  FundTrackerModel,
  InvestmentForFund,
  InvestmentForInvestor,
  InvestmentForPortco,
  StateArray,
  ValidateEmail: validateEmail,
};
