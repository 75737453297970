import { useState, useEffect } from 'react';
import { getClub } from '../services/bifService';

function useClub(clubId) {
  const [club, setClub] = useState(null);

  useEffect(() => {
    const abortController = new AbortController();
    let ignore = false;

    async function fetchData() {
      const response = await getClub(clubId, abortController.signal);
      if (!ignore && response !== 'aborted' && response.club) {
        setClub(response.club);
      }
    }

    fetchData();

    return () => {
      ignore = true;
      abortController.abort();
    };
  }, [clubId]);

  return club;
}

export default useClub;
