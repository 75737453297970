import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Col, Row } from 'reactstrap';
import Autocomplete from '@mui/material/Autocomplete';
import { styled } from '@mui/material/styles';
import TextField from '@mui/material/TextField';
import parsePhoneNumber from 'libphonenumber-js';
import { spacing } from '../themes/appTheme';
import { isValidEmail, isWhitespaceOnly } from '../utils/validators';
import formatPhoneNumber from '../utils/formatPhoneNumber';
import timeZones from '../utils/timeZones';

export const AvTextField = styled(TextField)(
  ({ theme }) => `
    margin-top: ${theme.spacing(2)};
    width: 100%;
  `,
);

export default function GoldenContactInformation({
  goldenContactInfo, setGoldenContactInfo, validationObject = {}, errors = {},
}) {
  const {
    name, title, email, phone, timeZone,
  } = goldenContactInfo;

  // Get the time zone object from the timeZone array to pass into autocomplete
  const timeZoneObject = timeZones.find((tz) => tz.label === timeZone);

  useEffect(() => {
    // eslint-disable-next-line no-param-reassign,no-use-before-define
    validationObject.current = validate;
  }, []);

  return (
    <>
      <h5
        style={{ marginTop: spacing(3) }}
      >
        Golden Contact Information
      </h5>
      <Row>
        <Col lg={6}>
          <AvTextField
            label="Name"
            type="text"
            name="goldName"
            required
            value={name}
            onChange={(e) => { setGoldenContactInfo({ ...goldenContactInfo, name: e.target.value }); }}
            variant="standard"
            error={!!errors.name}
            helperText={errors.name}
          />
          <AvTextField
            label="Title"
            type="text"
            name="goldTitle"
            required
            value={title}
            onChange={(e) => { setGoldenContactInfo({ ...goldenContactInfo, title: e.target.value }); }}
            variant="standard"
            error={!!errors.title}
            helperText={errors.title}
          />
          <AvTextField
            label="Email Address"
            type="email"
            name="goldEmail"
            required
            value={email}
            onChange={(e) => { setGoldenContactInfo({ ...goldenContactInfo, email: e.target.value }); }}
            variant="standard"
            error={!!errors.email}
            helperText={errors.email}
          />
        </Col>
        <Col lg={6}>
          <AvTextField
            label="Phone Number"
            type="tel"
            name="goldPhone"
            required
            value={formatPhoneNumber(phone)}
            onChange={(e) => {
              const rawPhoneNumber = e.target.value.replace(/\D/g, '');
              setGoldenContactInfo({ ...goldenContactInfo, phone: rawPhoneNumber });
            }}
            variant="standard"
            error={!!errors.phone}
            helperText={errors.phone}
          />
          <Autocomplete
            options={timeZones}
            value={timeZoneObject || null}
            onChange={(e, val) => { setGoldenContactInfo({ ...goldenContactInfo, timeZone: val.label }); }}
            groupBy={(option) => option.type}
            getOptionLabel={(option) => option.label}
            renderInput={(params) => (
              <AvTextField
                required
                {...params}
                name="goldTimeZone"
                label="Time Zone"
                variant="standard"
                error={!!errors.timeZone}
                helperText={errors.timeZone}
              />
            )}
          />
        </Col>
      </Row>
    </>
  );
}

const validate = (goldenContactInfo) => {
  const textLength = (text) => text.length > 200;
  const nameValidations = {
    isWhitespaceOnly: { fn: isWhitespaceOnly, message: 'Text cannot be whitespace only' },
    textLength: { fn: textLength, message: 'Length cannot be greater than 200 characters' },
    empty: { fn: (text) => !text, message: 'This field is required' },
  };

  const emailValidations = {
    isWhitespaceOnly: { fn: isWhitespaceOnly, message: 'Email cannot be whitespace only' },
    isValidEmail: { fn: (email) => !isValidEmail(email), message: 'Not a valid email address' },
    empty: { fn: (text) => !text, message: 'This field is required' },
  };

  const validatePhoneNumber = (phone) => {
    const parsedPhoneNumber = (phone.length === 10) ? parsePhoneNumber(phone, 'US') : parsePhoneNumber(`+${phone}`);
    if (!parsedPhoneNumber) return true;
    return !parsedPhoneNumber.isValid();
  };

  const phoneValidations = {
    validatePhoneNumber: { fn: validatePhoneNumber, message: 'Phone number is not valid' },
    empty: { fn: (text) => !text, message: 'This field is required' },
  };

  const timeZoneValidations = {
    empty: { fn: (value) => !value, message: 'This field is required' },
  };

  const errors = {};
  const validateField = (fieldName, validationFunction) => {
    for (const item of Object.values(validationFunction)) {
      if (item.fn(goldenContactInfo[fieldName])) {
        errors[fieldName] = item.message;
      }
    }
  };

  validateField('name', nameValidations);
  validateField('title', nameValidations);
  validateField('email', emailValidations);
  validateField('phone', phoneValidations);
  validateField('timeZone', timeZoneValidations);
  return errors;
};

GoldenContactInformation.propTypes = {
  goldenContactInfo: PropTypes.shape({
    name: PropTypes.string,
    title: PropTypes.string,
    email: PropTypes.string,
    phone: PropTypes.string,
    timeZone: PropTypes.string,
  }).isRequired,
  setGoldenContactInfo: PropTypes.func.isRequired,
  validationObject: PropTypes.shape({}),
  errors: PropTypes.oneOfType([PropTypes.shape({}), PropTypes.shape({
    name: PropTypes.string,
    title: PropTypes.string,
    email: PropTypes.string,
    phone: PropTypes.string,
    timeZone: PropTypes.string,
  })]),
};

GoldenContactInformation.defaultProps = {
  validationObject: {},
  errors: {},
};
