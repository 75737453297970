/* eslint-disable no-param-reassign */
import React, { useState, useEffect, useCallback } from 'react';
import { Helmet } from 'react-helmet';
import {
  Container, Row, Card, CardBody, Col,
} from 'reactstrap';
import { v4 } from 'uuid';
import { useGridApiRef } from '@mui/x-data-grid-pro';
import LoadingButton from '@mui/lab/LoadingButton';
import { Stack, Button } from '@mui/material';
import { useParams, useHistory } from 'react-router-dom';
import usePendingInvestmentDetail from '../shared/hooks/usePendingInvestmentDetail';
import { createAQSplitsTableDefWithCashAdvances } from './AQSplitsTableDefinition';
import { createAQSplitsTotalsTableDefWithCashAdvances } from './AQSplitsTotalsTableDefinition';
import ReadOnlyTextField from '../shared/components/ReadOnlyTextField';
import AvDataGrid from '../shared/components/AvDataGrid';
import exportGridValues from '../shared/components/AvDataGrid/exportGridValues';
import HorizRecLoader from '../shared/components/Loader/HorizRecLoader';
import {
  deleteDocuments, getPresignedUrl, postFileToS3, updatePendingInvestment,
} from '../shared/services/apiGateway';
import generateRequest from '../shared/services/generateRequest';
import getStageStatus from '../shared/utils/checkUserPermissions';
import formatCurrencyAsUsd from '../shared/utils/formatCurrencyAsUsd';
import getNumberValue from '../shared/utils/getNumberValue';
import {
  assignLocalEditValues, prepareFundAllocationsForGrid, calculateTotals, initTotalsAndOnCellClickHandler,
} from './helpers';
import StageStatusMessage from '../shared/components/StageStatusMessage';
import CloseOnConfirmModal from '../shared/components/CloseOnConfirmModal';
import getSearchParams from '../shared/utils/getSearchParams';
import AvDropzone from '../shared/components/AvDropzone';
import { spacing } from '../shared/themes/appTheme';

const MAX_ADVANCED_AGREEMENT_UPLOAD_COUNT = 10;

function AQSplitsEditable() {
  const { id: dealId } = useParams();
  const closeOnConfirm = getSearchParams('closeoncompletion');
  const history = useHistory();

  const breakdownDGRef = useGridApiRef();
  const [loadingPendingInvestments, setPendingInvestmentsDetailLoading] = useState(true);
  const [fundAllocations, setFundAllocations] = useState(null);
  const totalInvestmentsId = v4();
  const [avInvestmentTotals, setAvInvestmentTotals] = useState([{
    id: totalInvestmentsId,
    avInvestmentTotal: 0,
    avInvestmentATotal: 0,
    avInvestmentQTotal: 0,
    avCashAdvanceTotal: 0,
  }]);
  const [dealName, setDealName] = useState('');
  const [wireAmount, setWireAmount] = useState('');
  const [avCheckAmountIsValid, setAvCheckAmountIsValid] = useState(false);
  const [stageStatus, setStageStatus] = useState({
    isEditableByRole: false,
  });
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [isPostingAQSplits, setIsPostingAQSplits] = useState(false);
  const [completionModalOpen, setCompletionModalOpen] = useState(false);
  const [dzProofOfBankTransferDocument, setDzProofOfBankTransferDocument] = useState(null);
  const [dzProofOfBankTransferDocumentError, setDzProofOfBankTransferDocumentError] = useState(null);
  const [dzAdvanceAgreementDocuments, setDZAdvanceAgreementDocuments] = useState(null);
  const [dzAdvanceAgreementsDocumentError, setDZAdvanceAgreementsDocumentError] = useState(null);
  const [uploadedAdvanceAgreementDocuments, setUploadedAdvanceAgreementDocuments] = useState([]);
  const [remainingAdvancedAgreementUploadMax, setRemainingAdvanceAgreementUploadMax] = useState(0);
  const [cashAdvanceTotal, setCashAdvanceTotal] = useState(0);
  const [filesToRemove, setFilesToRemove] = useState([]);
  const [errorText, setErrorText] = useState('');

  const deal = usePendingInvestmentDetail(dealId, (updatedDeal) => {
    const formattedFundAllocations = prepareFundAllocationsForGrid(updatedDeal.funds);
    setFundAllocations(formattedFundAllocations);
    setPendingInvestmentsDetailLoading(false);
    let responseDealName = '';
    if (updatedDeal.companyName) {
      responseDealName = updatedDeal.companyName;
    } else if (updatedDeal.hsDealObj && updatedDeal.hsDealObj.dealName) {
      responseDealName = updatedDeal.hsDealObj.dealName;
    }
    setDealName(responseDealName);
    let payloadWireAmount = 0;
    if (updatedDeal.wireAmount) {
      payloadWireAmount = updatedDeal.wireAmount;
    } else if (updatedDeal.hsDealObj && updatedDeal.hsDealObj.amount) {
      payloadWireAmount = updatedDeal.hsDealObj.amount;
    }
    setWireAmount(formatCurrencyAsUsd(payloadWireAmount));
    setStageStatus({
      ...getStageStatus(updatedDeal),
    });
    const advanceAgreementDocuments = updatedDeal.advanceAgreementDocuments || [];
    setUploadedAdvanceAgreementDocuments(advanceAgreementDocuments);
    setRemainingAdvanceAgreementUploadMax(MAX_ADVANCED_AGREEMENT_UPLOAD_COUNT - advanceAgreementDocuments.length);
  });

  const validateAvCheckAmountEqualsWireAmount = (avCheckAmount) => {
    const isValid = getNumberValue(avCheckAmount) === getNumberValue(wireAmount);
    setAvCheckAmountIsValid(isValid);
    setErrorText(!isValid ? `Total AV Check Amount must match Wire Amount at the top of the page.
                            Please update the lead fund investment amount to submit.` : '');
  };

  useEffect(() => {
    initTotalsAndOnCellClickHandler(breakdownDGRef, fundAllocations, loadingPendingInvestments, (funds) => {
      const investmentTotals = calculateTotals(funds, totalInvestmentsId);
      setAvInvestmentTotals([investmentTotals]);
      return investmentTotals;
    });
    return () => { };
  }, [loadingPendingInvestments]);

  useEffect(() => {
    const [{ avInvestmentTotal, avCashAdvanceTotal }] = avInvestmentTotals;
    setCashAdvanceTotal(getNumberValue(avCashAdvanceTotal));
    validateAvCheckAmountEqualsWireAmount(avInvestmentTotal);
    return () => { };
  }, [avInvestmentTotals, wireAmount]);

  const pendingInvestmentsTableDef = createAQSplitsTableDefWithCashAdvances(stageStatus);

  const handleCellClick = useCallback(
    (params) => {
      if (params.isEditable) {
        breakdownDGRef.current.setCellMode(params.id, params.field, 'edit');
      }
    },
    [breakdownDGRef],
  );
  if (!stageStatus.isEditableByRole) {
    pendingInvestmentsTableDef.forEach((column) => {
      column.editable = false;
    });
  }
  const setTotals = (funds) => {
    const investmentTotals = calculateTotals(funds, totalInvestmentsId);
    setAvInvestmentTotals([investmentTotals]);
    return investmentTotals;
  };

  const preparePostPayload = (pendingInvestment) => assignLocalEditValues(
    pendingInvestment,
    exportGridValues(breakdownDGRef.current, Object.keys(fundAllocations[0])),
    {},
  );

  const uploadFiles = async () => {
    const upload = async (file) => {
      const sanitizeRegex = /[^a-z\d ._-]/gi;
      const body = {
        deal: dealId,
        documenttype: file.avType,
        fileType: file.type,
        filename: `${file.name}`.replace(sanitizeRegex, ''),
        filedescription: '',
      };
      const response = await getPresignedUrl(body);
      if (!response) {
        setErrorText(`Failed to upload: ${body.filename}. Please contact support if this error persists.`);
        return null;
      }

      const { url, filekey } = response;
      const formData = new FormData();
      formData.append('file', file);
      formData.append('Content-Type', file.type);

      try {
        await postFileToS3(url, file, file.type);
        setErrorText('');
        return {
          documentKey: filekey,
          documentType: file.avType,
          documentName: body.filename,
        };
      } catch (error) {
        setErrorText(`An error occurred while uploading: ${body.filename}. Please contact support if this error persists.`);
        console.log(error);
      }
      return null;
    };

    if (dzProofOfBankTransferDocument && dzProofOfBankTransferDocument[0] && dzProofOfBankTransferDocument[0].file) {
      dzProofOfBankTransferDocument[0].file.avType = 'proofOfBankTransferDocument';
      const document = await upload(dzProofOfBankTransferDocument[0].file);
      deal.proofOfBankTransferDocument = document;
      setDzProofOfBankTransferDocument(null);
    }

    let quotaRemaining = MAX_ADVANCED_AGREEMENT_UPLOAD_COUNT - uploadedAdvanceAgreementDocuments.length;

    if (dzAdvanceAgreementDocuments && dzAdvanceAgreementDocuments[0] && dzAdvanceAgreementDocuments[0].file) {
      const newUploadedAdvanceAgreementDocuments = [];
      for (const dzAdvanceAgreementDocument of dzAdvanceAgreementDocuments) {
        if (quotaRemaining > 0) {
          dzAdvanceAgreementDocument.file.avType = 'advanceAgreementDocument';
          const uploadResponse = await upload(dzAdvanceAgreementDocument.file);
          newUploadedAdvanceAgreementDocuments.push(uploadResponse);
          quotaRemaining -= 1;
          setUploadedAdvanceAgreementDocuments([...uploadedAdvanceAgreementDocuments, ...newUploadedAdvanceAgreementDocuments]);
        }
      }
    }
    setRemainingAdvanceAgreementUploadMax(quotaRemaining);
    setDZAdvanceAgreementDocuments([]);
  };

  const removeFiles = async () => {
    if (filesToRemove.length > 0) {
      await deleteDocuments({
        deal: dealId,
        documents: filesToRemove,
      });
      setFilesToRemove([]);
    }
  };

  const openFile = async (documentId) => {
    const body = {
      deal: dealId,
      filekey: documentId,
    };
    const response = await getPresignedUrl(body);
    window.open(response);
  };

  const overwriteAndSaveEdits = async (pendingInvestment) => {
    await removeFiles();
    await uploadFiles();
    return updatePendingInvestment(
      generateRequest(preparePostPayload(pendingInvestment)),
    );
  };

  return (
    <>
      <Helmet>
        <title>Edit Allocations</title>
      </Helmet>
      <Container className="dashboard" style={{ maxWidth: '100%' }}>
        <Card>
          <CardBody>
            {
              deal && (
              <Row>
                <Col>
                  <h3>Review Allocations for {dealName}</h3>
                </Col>
              </Row>
              )
            }
            {
              !deal && (
              <Row>
                <Col>
                  { HorizRecLoader(1, '40%', 30) }
                  { HorizRecLoader(1, '98%', 30) }
                </Col>
              </Row>
              )
            }
            <Row>
              <Col>
                {wireAmount && (
                  <ReadOnlyTextField
                    label="Wire Amount"
                    value={wireAmount}
                    fullWidth
                  />
                )}
              </Col>
            </Row>
            <Row>
              <Col>
                <div style={{ marginTop: '30px' }}>
                  <AvDataGrid
                    rows={fundAllocations}
                    columns={pendingInvestmentsTableDef}
                    apiRef={breakdownDGRef}
                    loading={loadingPendingInvestments}
                    initialState={{
                      sorting: {
                        sortModel: [{ field: 'name', sort: 'asc' }],
                      },
                    }}
                    onCellEditStop={() => {
                      setTotals(exportGridValues(breakdownDGRef.current, Object.keys(fundAllocations[0])));
                    }}
                    onCellClick={handleCellClick}
                  />
                </div>
                <div style={{ marginTop: '20px' }}>
                  <AvDataGrid
                    pageSize={1}
                    rows={avInvestmentTotals}
                    columns={createAQSplitsTotalsTableDefWithCashAdvances()}
                    loading={loadingPendingInvestments}
                    hideFooter
                    density="compact"
                    disableColumnMenu
                    disableColumnFilter
                    disableColumnSelector
                    sx={{
                      fontSize: '17px',
                    }}
                  />
                </div>
                <div style={{ width: '100%', minWidth: '300px', marginBottom: '15px' }}>
                  { deal && (
                  <h5 style={{ marginTop: spacing(4), width: '50%' }}>
                    Proof Of Bank Transfer Document
                  </h5>
                  )}
                  {
                    deal && !!deal.proofOfBankTransferDocument && (
                      <Stack
                        direction="row"
                        justifyContent="space-between"
                        alignItems="center"
                        spacing={2}
                        mt={1}
                        style={{ maxWidth: '500px' }}
                      >
                        <h5>
                          {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events */}
                          <span
                            className="av-false-anchor"
                            role="link"
                            tabIndex={-1}
                            onClick={async () => {
                              await openFile(deal.proofOfBankTransferDocument.documentKey);
                            }}
                          >
                            {deal.proofOfBankTransferDocument.documentName}
                          </span>
                        </h5>
                        {
                          (stageStatus.isEditableByRole && !stageStatus.isCompleted)
                          && (
                          <Button
                            variant="outlined"
                            color="secondary"
                            onClick={async () => {
                              setFilesToRemove([...filesToRemove, deal.proofOfBankTransferDocument]);
                              deal.proofOfBankTransferDocument = null;
                            }}
                          >Remove
                          </Button>
                          )
                        }
                      </Stack>
                    )
                  }
                  {
                    (stageStatus.isEditableByRole && !stageStatus.isCompleted && !deal.proofOfBankTransferDocument) && (
                    <AvDropzone
                      maxFiles={1}
                      value={dzProofOfBankTransferDocument}
                      onFilesChange={(e) => {
                        setDzProofOfBankTransferDocument(e);
                        setDzProofOfBankTransferDocumentError(null);
                      }}
                      label={(
                        <>
                          { !!dzProofOfBankTransferDocumentError && (
                          <h4 className="error red">
                            {dzProofOfBankTransferDocumentError}
                          </h4>
                          ) }
                          <h5 className="text-left">Click to upload documents showing proof of bank transfer * </h5>
                        </>
                    )}
                    />
                    )
                  }
                </div>
                {
                   (cashAdvanceTotal > 0) && (
                   <div style={{ width: '100%', minWidth: '300px', marginBottom: '15px' }}>
                     {deal && (
                     <h5 style={{ marginTop: spacing(4), width: '50%' }}>
                       Advance Agreement Documents
                     </h5>
                     )}
                     {
                      deal && (
                        <>
                          {
                            uploadedAdvanceAgreementDocuments.map((doc) => (
                              <Stack
                                direction="row"
                                justifyContent="space-between"
                                alignItems="center"
                                spacing={2}
                                mt={1}
                                key={doc.documentKey}
                                style={{ maxWidth: '500px' }}
                              >
                                <h5 key={doc.documentKey} style={{ marginBottom: spacing(1) }}>
                                  {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events */}
                                  <span
                                    className="av-false-anchor"
                                    role="link"
                                    tabIndex={-1}
                                    onClick={async () => {
                                      await openFile(doc.documentKey);
                                    }}
                                  >
                                    {doc.documentName}
                                  </span>
                                </h5>
                                {
                                    (stageStatus.isEditableByRole && !stageStatus.isCompleted) && (
                                      <LoadingButton
                                        variant="outlined"
                                        color="secondary"
                                        onClick={async () => {
                                          setFilesToRemove([...filesToRemove, doc]);
                                          setUploadedAdvanceAgreementDocuments(
                                            uploadedAdvanceAgreementDocuments.filter((element) => element.documentKey !== doc.documentKey),
                                          );
                                          let currentRemainingQuota = remainingAdvancedAgreementUploadMax;
                                          currentRemainingQuota += 1;
                                          setRemainingAdvanceAgreementUploadMax(currentRemainingQuota);
                                        }}
                                      >Remove
                                      </LoadingButton>
                                    )
                                  }
                              </Stack>
                            ))
                          }
                        </>
                      )
                    }
                     {
                      (stageStatus.isEditableByRole && !stageStatus.isCompleted && !!remainingAdvancedAgreementUploadMax) && (
                      <AvDropzone
                        header={false}
                        maxFiles={remainingAdvancedAgreementUploadMax}
                        onFilesChange={(e) => {
                          setDZAdvanceAgreementDocuments(e);
                          setDZAdvanceAgreementsDocumentError(null);
                        }}
                        value={dzAdvanceAgreementDocuments}
                        label={(
                          <div className="av-dropzone-label">
                            {dzAdvanceAgreementsDocumentError && <h4 className="error red">{dzAdvanceAgreementsDocumentError}</h4>}
                            <h5>Click to upload documents showing advance agreement information * </h5>
                          </div>
                      )}
                      />
                      )
                    }
                   </div>
                   )
                }

              </Col>
            </Row>
            <Row>
              <Col><h4 className="error red">{errorText}</h4></Col>
            </Row>
            {
              deal && (
              <Row>
                <Col>
                  {((stageStatus.isEditableByRole) && !stageStatus.isCompleted) && (
                  <Stack
                    direction="row"
                    justifyContent="space-between"
                    alignItems="flex-end"
                    spacing={2}
                    mt={2}
                  >
                    <LoadingButton
                      variant="outlined"
                      disabled={isSubmitting}
                      loading={isSaving}
                      color="secondary"
                      onClick={async () => {
                        setIsSubmitting(true);
                        setIsSaving(true);
                        await overwriteAndSaveEdits(deal);
                        setIsSubmitting(false);
                        setIsSaving(false);
                      }}
                    >Save
                    </LoadingButton>
                    <LoadingButton
                      variant="contained"
                      disabled={isSubmitting || !avCheckAmountIsValid}
                      loading={isPostingAQSplits}
                      onClick={async () => {
                        const validProofOfBankTransferDocument = (!(dzProofOfBankTransferDocument === null
                          || dzProofOfBankTransferDocument.length === 0))
                          || deal.proofOfBankTransferDocument;
                        const validDZAdvanceAgreementDocuments = (!(dzAdvanceAgreementDocuments === null
                          || dzAdvanceAgreementDocuments.length === 0))
                          || (uploadedAdvanceAgreementDocuments && uploadedAdvanceAgreementDocuments.length > 0);
                        if (!validProofOfBankTransferDocument) {
                          setDzProofOfBankTransferDocumentError('Proof of Bank Transfer is required.');
                          return;
                        }
                        if (!validDZAdvanceAgreementDocuments && cashAdvanceTotal > 0) {
                          setDZAdvanceAgreementsDocumentError('Advanced Agreement Documents are required.');
                          return;
                        }

                        deal.stagesCompleted.reviewedAQSplits = new Date().toISOString();
                        setIsSubmitting(true);
                        setIsPostingAQSplits(true);
                        await overwriteAndSaveEdits(deal);
                        if (!closeOnConfirm) {
                          history.push(`/deal-stages/${dealId}`);
                        } else {
                          setCompletionModalOpen(true);
                        }
                      }}
                      color="secondary"
                    >Confirm and Submit Allocations
                    </LoadingButton>
                  </Stack>
                  )}
                </Col>
              </Row>
              )
            }
            { deal && (
            <StageStatusMessage stageStatus={stageStatus} />
            )}
          </CardBody>
        </Card>
        <CloseOnConfirmModal modalOpen={completionModalOpen} />
      </Container>
    </>
  );
}

export default AQSplitsEditable;
