import React, { createRef, useRef, useState, useReducer } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import Helmet from 'react-helmet';
import {
  Card, CardBody, Col, Container, Row,
} from 'reactstrap';
import Button from '@mui/material/Button';
import LoadingButton from '@mui/lab/LoadingButton';
import Stack from '@mui/material/Stack';
import { User } from '@alumni-ventures-group/av-okta-auth';
import usePendingInvestmentDetail from '../shared/hooks/usePendingInvestmentDetail';
import GoldenContactInformation from '../shared/components/GoldenContactInformation';
import DealInformation from '../shared/components/DealInformation';
import { Questions, isQuestionActive } from './Questions';
import QUESTION_LIST from './QuestionList';
import WireAmountTargetDate from '../shared/components/WireAmountTargetDate';
import AvTextField from '../shared/components/AvTextField';
import { spacing } from '../shared/themes/appTheme';
import AvDropzone from '../shared/components/AvDropzone';
import {
  deleteDocuments, getPresignedUrl, postFileToS3, updatePendingInvestment,
} from '../shared/services/apiGateway';
import getStageStatus from '../shared/utils/checkUserPermissions';
import generateRequest from '../shared/services/generateRequest';
import PartialPageLoader from '../shared/components/Loader/PartialPageLoader';
import StageStatusMessage from '../shared/components/StageStatusMessage';
import CloseOnConfirmModal from '../shared/components/CloseOnConfirmModal';
import getSearchParams from '../shared/utils/getSearchParams';

function UploadWireInfo() {
  const { id } = useParams();
  const closeOnConfirm = getSearchParams('closeoncompletion');
  const userCache = new User();
  const history = useHistory();

  const [dealInfo, setDealInfo] = useState({
    dealName: '',
    sponsorTeam: '',
    iTeamMemberName: '',
  });

  // whenever the questions are updated, sync them with the list of defined questions
  // carry over any user answers to the updated list
  const [digitalAssetsQuestions, setDigitalAssetsQuestions] = useReducer((prev, questions) => (
    QUESTION_LIST.map((question, index) => {
      if (questions && questions[index]) {
        return { ...question, value: questions[index].value };
      }
      return question;
    })
  ), []);
  const [allAnswered, setAllAnswered] = useState(false);

  const [wireInfo, setWireInfo] = useState({
    wireAmount: '',
    wireTargetDate: null,
  });

  const [goldenContactInfo, setGoldenContactInfo] = useState({
    name: '',
    title: '',
    email: '',
    phone: '',
    timeZone: null,
  });
  const [stageStatus, setStageStatus] = useState({
    isEditableByRole: false,
  });

  const [errorText, setErrorText] = useState('');

  const [financeComment, setFinanceComment] = useState('');
  const [dzWireInstructionsDocument, setDZWireInstructionsDocument] = useState(null);
  const [dzWireAmountDocument, setDZWireAmountDocument] = useState(null);
  const [dzWireAmountDocumentError, setDzWireAmountDocumentError] = useState(null);
  const [dzWireInstructionsDocumentError, setDzWireInstructionsDocumentError] = useState(null);

  const [filesToRemove, setFilesToRemove] = useState([]);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [isPostingData, setIsPostingData] = useState(false);
  const [goldenContactInfoComponentErrors, setGoldenContactInfoComponentErrors] = useState({});
  const [wireInfoComponentErrors, setWireInfoComponentErrors] = useState({});
  const [dealInfoComponentErrors, setDealInfoComponentErrors] = useState({});
  const [completionModalOpen, setCompletionModalOpen] = useState(false);

  const goldenContactValidationObject = useRef(null);
  const wireInfoValidationObject = useRef(null);
  const dealInfoValidationObject = useRef(null);

  const dealData = usePendingInvestmentDetail(id, (deal) => {
    let dealName = '';
    if (deal.companyName) {
      dealName = deal.companyName;
    } else if (deal.hsDealObj && deal.hsDealObj.dealName) {
      dealName = deal.hsDealObj.dealName;
    }

    let sponsorTeam = '';
    if (deal.sponsorTeam) {
      sponsorTeam = deal.sponsorTeam;
    } else if (deal.hsDealObj && deal.hsDealObj.sponsorTeam && deal.hsDealObj.sponsorTeam.name) {
      sponsorTeam = deal.hsDealObj.sponsorTeam.name;
    }
    let iTeamMemberName = '';
    if (deal.iTeamMemberName) {
      iTeamMemberName = deal.iTeamMemberName;
    } else if (userCache.isITeam()) {
      const { firstName, lastName } = userCache.get();
      iTeamMemberName = `${firstName} ${lastName}`;
    }

    setDealInfo({
      dealName,
      sponsorTeam,
      iTeamMemberName,
    });

    setGoldenContactInfo({
      name: (deal.goldenContact && deal.goldenContact.name) ? deal.goldenContact.name : '',
      title: (deal.goldenContact && deal.goldenContact.title) ? deal.goldenContact.title : '',
      email: (deal.goldenContact && deal.goldenContact.email) ? deal.goldenContact.email : '',
      phone: (deal.goldenContact && deal.goldenContact.phone) ? deal.goldenContact.phone : '',
      timeZone: (deal.goldenContact && deal.goldenContact.timeZone) ? deal.goldenContact.timeZone : null,
    });

    setDigitalAssetsQuestions(deal.digitalAssetsQuestions);

    let wireAmount = '';
    if (deal.wireAmount) {
      wireAmount = deal.wireAmount;
    }
    setWireInfo({
      wireAmount,
      wireTargetDate: deal.wireTargetDate ? new Date(deal.wireTargetDate) : null,
    });
    setFinanceComment(deal.wireCommentsForFinance || '');

    setStageStatus({
      ...getStageStatus(deal),
    });
  });

  const dealInfoRef = createRef();
  const questionsRef = createRef();
  const wireInfoRef = createRef();
  const goldenContactInfoRef = createRef();

  const isFormValid = () => {
    const goldenContactErrors = goldenContactValidationObject.current(goldenContactInfo);
    setGoldenContactInfoComponentErrors(goldenContactErrors);

    const dealInfoErrors = dealInfoValidationObject.current(dealInfo);
    setDealInfoComponentErrors(dealInfoErrors);

    const wireInfoErrors = wireInfoValidationObject.current(wireInfo);
    setWireInfoComponentErrors(wireInfoErrors);

    const validWireAmountDocument = (!(dzWireAmountDocument === null || dzWireAmountDocument.length === 0)) || dealData.wireAmountDocument;
    const validWireInstructionsDocument = (!(dzWireInstructionsDocument === null || dzWireInstructionsDocument.length === 0))
    || dealData.wireInstructionsDocument;

    if (!validWireAmountDocument) {
      setDzWireAmountDocumentError('A Wire Amount document is required.');
    }

    if (!validWireInstructionsDocument) {
      setDzWireInstructionsDocumentError('A Wire Instruction document is required.');
    }
    console.log({
      goldenContactErrors,
      dealInfoErrors,
      wireInfoErrors,
      validWireAmountDocument,
      validWireInstructionsDocument,
      allAnswered,
    });

    if (Object.keys(dealInfoErrors).length > 0) {
      dealInfoRef.current.scrollIntoView();
    } else if (!allAnswered) {
      questionsRef.current.scrollIntoView();
    } else if (Object.keys(wireInfoErrors).length > 0 || dzWireAmountDocumentError || dzWireInstructionsDocumentError) {
      wireInfoRef.current.scrollIntoView();
    } else if (Object.keys(goldenContactErrors).length > 0) {
      goldenContactInfoRef.current.scrollIntoView();
    }

    return Object.keys(goldenContactErrors).length <= 0
      && Object.keys(dealInfoErrors).length <= 0
      && Object.keys(wireInfoErrors).length <= 0
      && validWireAmountDocument
      && validWireInstructionsDocument
      && allAnswered;
  };

  const isFormFilled = () => {
    const goldenContactInfoFilled = !!(goldenContactInfo && goldenContactInfo.name
      && goldenContactInfo.title
      && goldenContactInfo.email
      && goldenContactInfo.phone
      && goldenContactInfo.timeZone);

    const dealInfoFilled = !!dealInfo.iTeamMemberName;

    // Check if the wire instruction and wire transfer documents are uploaded.
    const documentsUploaded = ((dealData.wireInstructionsDocument
      || (dzWireInstructionsDocument && dzWireInstructionsDocument.length))
      && (dealData.wireAmountDocument || (dzWireAmountDocument && dzWireAmountDocument.length)));

    return goldenContactInfoFilled && dealInfoFilled && documentsUploaded && wireInfo.wireAmount;
  };

  const uploadFiles = async () => {
    const upload = async (file) => {
      const sanitizeRegex = /[^a-z\d ._-]/gi;
      const body = {
        deal: id,
        documenttype: file.avType,
        fileType: file.type,
        filename: `${file.name}`.replace(sanitizeRegex, ''),
        filedescription: '',
      };
      const response = await getPresignedUrl(body);
      if (!response) {
        setErrorText(`Failed to upload: ${body.filename}. Please contact support if this error persists.`);
        return null;
      }

      const { url, filekey } = response;
      const formData = new FormData();
      formData.append('file', file);
      formData.append('Content-Type', file.type);

      try {
        const uploadResponse = await postFileToS3(url, file, file.type);
        console.log(uploadResponse);
        setErrorText('');
        // in case the user uploads the file, but does not refresh the page
        // force the uploaded state of the file upload to show to avoid any
        // issues with the user trying to the delete the file from the dropzone
        // then saving after uploading without refreshing
        return {
          documentKey: filekey,
          documentType: file.avType,
          documentName: body.filename,
        };
      } catch (error) {
        setErrorText(`An error occurred while uploading: ${body.filename}. Please contact support if this error persists.`);
        console.log(error);
      }
      return null;
    };

    if (dzWireInstructionsDocument && dzWireInstructionsDocument[0] && dzWireInstructionsDocument[0].file) {
      dzWireInstructionsDocument[0].file.avType = 'wireInstructionsDocument';
      const document = await upload(dzWireInstructionsDocument[0].file);
      dealData.wireInstructionsDocument = document;
      setDZWireInstructionsDocument(null);
    }

    if (dzWireAmountDocument && dzWireAmountDocument[0] && dzWireAmountDocument[0].file) {
      dzWireAmountDocument[0].file.avType = 'wireAmountDocument';
      dealData.wireAmountDocument = await upload(dzWireAmountDocument[0].file);
      setDZWireAmountDocument(null);
    }
  };

  const removeFiles = async () => {
    if (filesToRemove.length > 0) {
      await deleteDocuments({
        deal: id,
        documents: filesToRemove,
      });
      setFilesToRemove([]);
    }
  };

  const openFile = async (documentId) => {
    const body = {
      deal: id,
      filekey: documentId,
    };
    const response = await getPresignedUrl(body);
    window.open(response);
  };

  const saveChanges = async (submitToFinance) => {
    if (submitToFinance && !isFormValid()) {
      setIsSubmitting(false);
      setIsPostingData(false);
      setIsSaving(false);
      return;
    }
    dealData.iTeamMemberName = dealInfo.iTeamMemberName;
    dealData.goldenContact = {
      ...goldenContactInfo,
    };

    // filter down all the questions and answers to just those that are "active"
    // e.g. they are displayed on the UI for the user
    const activeQuestions = digitalAssetsQuestions.filter((q, idx) => isQuestionActive(digitalAssetsQuestions, idx));
    // Update the questions state so that the user knows only the active questions were saved
    setDigitalAssetsQuestions(activeQuestions);
    dealData.digitalAssetsQuestions = activeQuestions;

    let { wireTargetDate } = wireInfo;
    if (typeof wireTargetDate === 'object' && wireTargetDate !== null) {
      // for type safety convert this from a (moment object or date object) to a date object to
      // make sure it has toISOString which is our format of choice for dates
      wireTargetDate = new Date(wireInfo.wireTargetDate.toString()).toISOString();
    }

    dealData.wireAmount = wireInfo.wireAmount;
    dealData.wireTargetDate = wireTargetDate;
    dealData.wireCommentsForFinance = financeComment;
    if (submitToFinance) {
      if (dealData && dealData.stagesCompleted) {
        dealData.stagesCompleted.uploadWireInfo = new Date().toISOString();
      } else {
        dealData.stagesCompleted = { uploadWireInfo: new Date().toISOString() };
      }
    }
    await removeFiles();
    await uploadFiles();
    await updatePendingInvestment(generateRequest(dealData));
    setIsSubmitting(false);
    setIsSaving(false);
    setIsPostingData(false);
    if (submitToFinance && !closeOnConfirm) {
      history.push(`/deal-stages/${id}`);
    } else if (submitToFinance && closeOnConfirm) {
      setCompletionModalOpen(true);
    }
  };

  const saveChangesAndNotify = async (submitToFinance) => {
    setIsSubmitting(true);
    if (submitToFinance) {
      setIsPostingData(true);
    } else {
      setIsSaving(true);
    }
    await saveChanges(submitToFinance);
  };

  const rejectionReason = (dealData && dealData.wireVerificationInfo) ? dealData.wireVerificationInfo.rejectionReason : null;
  const wireSignOffRejectionReason = (dealData
                                      && dealData.stagesCompleted
                                      && dealData.stagesCompleted.uploadWireInfo === false
  )
    ? dealData.wireSignOffRejectionReason
    : null;

  return (
    <>
      <Helmet>
        <title>Upload Wire Information</title>
      </Helmet>
      <Container className="med-width-form">
        <Card>
          {!dealData && (
            PartialPageLoader()
          )}
          {dealData && (
            <CardBody>
              <Row>
                <Col>
                  <h3>Upload Wire Information for {dealInfo.dealName}</h3>
                </Col>
              </Row>
              <Row>
                <Col
                  md={12}
                  style={{
                    marginTop: spacing(2),
                  }}
                >
                  <div ref={dealInfoRef}>
                    <DealInformation
                      dealInfo={dealInfo}
                      setDealInfo={setDealInfo}
                      disabledFields={{ dealName: true, sponsorTeam: true, iTeamMemberName: false }}
                      setErrors={setDealInfoComponentErrors}
                      validationObject={dealInfoValidationObject}
                      errors={dealInfoComponentErrors}
                      rejectionReason={wireSignOffRejectionReason || rejectionReason}
                    />
                  </div>
                  <br />
                  <div ref={questionsRef}>
                    <Questions
                      title="Digital Asset Questions"
                      questionAnswers={digitalAssetsQuestions}
                      setAnswerList={setDigitalAssetsQuestions}
                      setAllAnswered={setAllAnswered}
                    />
                  </div>
                  <br />
                  <div ref={wireInfoRef}>
                    <WireAmountTargetDate
                      wireInfo={wireInfo}
                      setWireInfo={setWireInfo}
                      setErrors={setWireInfoComponentErrors}
                      validationObject={wireInfoValidationObject}
                      errors={wireInfoComponentErrors}
                    />
                  </div>
                  <br />
                  <h5 style={{ marginTop: spacing(3) }}>
                    Wire Amount Documentation
                  </h5>
                  {
                    dealData.wireAmountDocument && (
                    <Stack
                      direction="row"
                      justifyContent="space-between"
                      alignItems="center"
                      spacing={2}
                      mt={1}
                    >
                      <h5>
                        {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events */}
                        <span
                          className="av-false-anchor"
                          role="link"
                          tabIndex={-1}
                          onClick={async () => {
                            await openFile(dealData.wireAmountDocument.documentKey);
                          }}
                        >
                          {dealData.wireAmountDocument.documentName}
                        </span>
                      </h5>
                      {
                        (stageStatus.isEditableByRole && !stageStatus.isCompleted) && (
                          <Button
                            variant="outlined"
                            color="secondary"
                            onClick={async () => {
                              setFilesToRemove([...filesToRemove, dealData.wireAmountDocument]);
                              dealData.wireAmountDocument = null;
                            }}
                          >Remove
                          </Button>
                        )
                      }
                    </Stack>
                    )
                  }
                  {
                    (stageStatus.isEditableByRole && !stageStatus.isCompleted && !dealData.wireAmountDocument) && (
                    <AvDropzone
                      header={false}
                      maxFiles={1}
                      style={{ width: '100%', marginTop: spacing(2) }}
                      onFilesChange={(e) => {
                        setDZWireAmountDocument(e);
                        setDzWireAmountDocumentError(null);
                      }}
                      value={dzWireAmountDocument}
                      label={(
                        <div className="av-dropzone-label">
                          { dzWireAmountDocumentError && <h4 style={{ color: '#d32f2f' }}> {dzWireAmountDocumentError} </h4> }
                          <h5>Click to upload documents showing exact wire amount *</h5>
                          <br />
                          <h5 className="text-left">Acceptable documents:</h5>
                          <ol>
                            <li>SAFE / Conv. Note showing exact amount</li>
                            <li>Pro-Forma Cap Table from Co. showing exact amount</li>
                            <li>SPA from Co. showing exact amount</li>
                            <li>Email from portco counsel or CEO / founder showing exact amount</li>
                          </ol>
                        </div>
                    )}
                    />
                    )
                  }

                  <h5 style={{ marginTop: spacing(3) }}>
                    Wire Instructions
                  </h5>
                  {
                    dealData.wireInstructionsDocument && (
                      <Stack
                        direction="row"
                        justifyContent="space-between"
                        alignItems="center"
                        spacing={2}
                        mt={1}
                      >
                        <h5>
                          {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events */}
                          <span
                            className="av-false-anchor"
                            role="link"
                            tabIndex={-1}
                            onClick={async () => {
                              await openFile(dealData.wireInstructionsDocument.documentKey);
                            }}
                          >
                            {dealData.wireInstructionsDocument.documentName}
                          </span>
                        </h5>
                        {
                          (stageStatus.isEditableByRole && !stageStatus.isCompleted)
                          && (
                          <Button
                            variant="outlined"
                            color="secondary"
                            onClick={async () => {
                              setFilesToRemove([...filesToRemove, dealData.wireInstructionsDocument]);
                              dealData.wireInstructionsDocument = null;
                            }}
                          >Remove
                          </Button>
                          )
                        }
                      </Stack>
                    )
                  }
                  {
                    (stageStatus.isEditableByRole && !stageStatus.isCompleted && !dealData.wireInstructionsDocument) && (
                    <AvDropzone
                      maxFiles={1}
                      value={dzWireInstructionsDocument}
                      onFilesChange={(e) => {
                        setDZWireInstructionsDocument(e);
                        setDzWireInstructionsDocumentError(null);
                      }}
                      label={(
                        <div className="av-dropzone-label">
                          {dzWireInstructionsDocumentError && <h4 style={{ color: '#d32f2f' }}>{dzWireInstructionsDocumentError}</h4>}
                          <h5>Click to upload documents showing wire instructions *</h5>
                          <br />
                          <h5 className="text-left">Document must include the following:</h5>
                          <ol>
                            <li>Bank account name</li>
                            <li>Account number</li>
                            <li>Routing number or SWIFT number</li>
                          </ol>
                        </div>
                    )}
                    />
                    )
                  }
                  <div ref={goldenContactInfoRef}>
                    <GoldenContactInformation
                      goldenContactInfo={goldenContactInfo}
                      setGoldenContactInfo={setGoldenContactInfo}
                      setErrors={setGoldenContactInfoComponentErrors}
                      errors={goldenContactInfoComponentErrors}
                      validationObject={goldenContactValidationObject}
                    />
                  </div>
                  <br />
                  <h5
                    style={{ marginTop: spacing(3) }}
                  >
                    Additional Comments For Finance Team
                  </h5>
                  <AvTextField
                    label="Comments"
                    type="text"
                    name="comments"
                    multiline
                    fullWidth
                    minRows={5}
                    value={financeComment}
                    onChange={(e) => setFinanceComment(e.target.value)}
                  />
                  {
                    (stageStatus.isEditableByRole && !stageStatus.isCompleted) && (
                      <>
                        <Stack
                          direction="row"
                          justifyContent="space-between"
                          alignItems="center"
                          spacing={2}
                          mt={2}
                        >
                          <LoadingButton
                            variant="outlined"
                            color="secondary"
                            disabled={isSubmitting}
                            loading={isSaving}
                            onClick={async () => saveChangesAndNotify()}
                          >
                            Save
                          </LoadingButton>
                          <LoadingButton
                            variant="contained"
                            color="secondary"
                            disabled={isSubmitting || !isFormFilled()}
                            loading={isPostingData}
                            onClick={async () => {
                              await saveChangesAndNotify(true);
                            }}
                          >
                            Submit To Finance
                          </LoadingButton>
                        </Stack>
                        <p className="mt-md-2 p-md-2 text-center">
                          Note that upon completion of this step, it will take 48 hours to confirm wire instructions for
                          domestic deals and 72 hours for international deals.
                        </p>
                      </>
                    )
                  }
                  <StageStatusMessage stageStatus={stageStatus} />
                </Col>
                <h4 className="error red">{errorText}</h4>
              </Row>
            </CardBody>
          )}
        </Card>

        <CloseOnConfirmModal modalOpen={completionModalOpen} />
      </Container>
    </>
  );
}

export default UploadWireInfo;
