import {
  Skeleton,
} from '@mui/material';
import React from 'react';
import { v4 } from 'uuid';

export default function HorizRecLoader(count = 1, width = 120, height = 40, className = '') {
  return (
    <>
      {Array.from(Array(count)
        .keys())
        .map(() => <Skeleton variant="rectangular" sx={{ my: 2, mx: 1 }} height={height} width={width} key={v4()} className={className} />)}
    </>
  );
}
