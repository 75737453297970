import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import {
  Card, CardHeader, CardMedia, CardContent, CardActions,
} from '@material-ui/core';
import screenfull from 'screenfull';
import ReactPlayer from 'react-player';
import { Link } from 'react-router-dom';
import moment from 'moment';
import { TYPES } from '../../helpers/MultiSelectOptions';

const useStyles = makeStyles(theme => ({
  root: {
    width: '400px',
    height: '100%',
    backgroundSize: 'contain',
  },
  media: {
    height: 0,
    paddingTop: '56.25%', // 16:9
    backgroundSize: 'contain',
  },
  expand: {
    transform: 'rotate(0deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: 'rotate(180deg)',
  },
  dateCard: {
    width: '200px',
    padding: '10px',
    display: 'flex',
    margin: 'auto',
    alignItems: 'center',
    textAlign: 'center',
    color: '#7B569B',
  },
  day: {
    width: '50%',
    height: 0,
    textAlign: 'center',
    lineHeight: 0,
    padding: '25% 0',
    borderRadius: '50%',
    margin: 'auto',
    color: '#fff',
    background: '#7B569B',
    fontSize: '28px',
    // margin: '0px 10px',
  },
  month: {
    fontSize: '16px',
    fontWeight: 'bold',
  },
  actions: {
    marginTop: 'auto',
  },
}));

const videoPlayerComponent = ({ video }) => {
  const player = useRef(null);
  return (
    <div
      style={{
        width: '100%',
        margin: '0px 10px 2px 0px',
      }}
    >
      <ReactPlayer
        controls
        height="100%"
        width="100%"
        url={video}
        ref={player}
        onPlay={() => screenfull.request(screenfull.request(player.current.player.player.player.element))}
      />
    </div>
  );
};

const displayImage = (image, headline, classes) => (
  <CardMedia image={image} title={headline} className={classes.media} />
);

const displayDate = (date, classes) => (
  <div className={classes.dateCard}>
    <div className="w-100 text-center">
      <div className={classes.month}>{moment(date).format('MMMM')}</div>
      <div className="text-center">
        <div className={classes.day}>{moment(date).format('DD')}</div>
      </div>
    </div>
  </div>
);

const displayVideo = video => (
  <CardMedia video={video} component={videoPlayerComponent} />
);

const AnnouncementCarouselInfo = ({
  announcement,
  clubId,
  isPreview = false,
}) => {
  const {
    headline_text: headline,
    img_src: image,
    start_date: date,
    type,
    announcement_text: text,
    id,
    preview_text: previewText,
    video_url: video,
  } = announcement;
  const classes = useStyles();
  const textLength = image ? 100 : 150;
  const textToDisplay = previewText || text.slice(0, textLength);

  if ((announcement.type === TYPES.EVENTS) && moment(new Date()).isAfter(moment(date))) {
    return null;
  }

  return (
    <Card key={id} className={classes.root} elevation={3}>
      { (video && displayVideo(video))
        || ((!video && image) && displayImage(image, headline, classes))
        || (type === 2 && displayDate(date, classes))}
      <CardHeader title={headline} />
      <CardContent>
        <div>
          <span dangerouslySetInnerHTML={{ __html: textToDisplay }} />
        </div>
      </CardContent>
      <CardActions disableSpacing>
        {(!previewText && !isPreview && text.length >= textLength) && (
          <Link to={`/club/home/${clubId}/announcements/${id}`}>
            <span className={classes.linkText}>
              More
            </span>
          </Link>
        )}
      </CardActions>
    </Card>
  );
};

AnnouncementCarouselInfo.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  announcement: PropTypes.object,
  clubId: PropTypes.number,
  isPreview: PropTypes.bool,
};

AnnouncementCarouselInfo.defaultProps = {
  announcement: {},
  isPreview: false,
  clubId: null,
};

export default AnnouncementCarouselInfo;
