import React, { PureComponent } from 'react';
import './ProfileSettingsForm.css';
import { Editor } from '@tinymce/tinymce-react';

import {
  Field,
  reduxForm,
  propTypes,
  change,
} from 'redux-form';
import { Button } from 'reactstrap';
import KeyVariantIcon from 'mdi-react/KeyVariantIcon';
import AccountOutlineIcon from 'mdi-react/AccountOutlineIcon';
import EyeIcon from 'mdi-react/EyeIcon';
import renderCheckBoxField from '../form/CheckBox';

const { TINY_MCE_KEY } = process.env;

class ProfileSettingsForm extends PureComponent {
  static propTypes = {
    ...propTypes,
  };

  constructor(props) {
    super(props);
    this.state = {
      showPassword: false,
      signatureFirstDegree: props.signatureFirstDegree,
    };
  }

  componentDidMount() {
    const {
      un,
      pw,
      firstName,
      lastName,
      signatureFirstDegree,
      dispatch,
    } = this.props;
    dispatch(change('li_settings_form', 'un', un));
    dispatch(change('li_settings_form', 'pw', pw));
    dispatch(change('li_settings_form', 'firstName', firstName));
    dispatch(change('li_settings_form', 'lastName', lastName));
    dispatch(change('li_settings_form', 'signatureFirstDegree', signatureFirstDegree));
  }

  showPassword = (e) => {
    e.preventDefault();
    this.setState(prevState => ({ showPassword: !prevState.showPassword }));
  };

  handleSignatureFirstDegreeEditorChange = (signatureFirstDegree) => {
    const {
      dispatch,
    } = this.props;
    this.setState(prevState => ({ ...prevState, signatureFirstDegree }));
    dispatch(change('li_settings_form', 'signatureFirstDegree', signatureFirstDegree));
  };

  render() {
    const {
      handleSubmit,
      onPasswordInputFocus,
      isActive,
    } = this.props;
    const { showPassword, signatureFirstDegree } = this.state;
    return (
      <form className="form" onSubmit={handleSubmit} autoComplete="new-password">
        <input type="text" style={{ display: 'none' }} />
        <input type="password" style={{ display: 'none' }} />
        <div className="form__form-group">
          <span className="form__form-group-label">First Name</span>
          <div className="form__form-group-field">
            <div className="form__form-group-icon">
              <AccountOutlineIcon />
            </div>
            <Field
              name="firstName"
              component="input"
              type="text"
              placeholder="Your First Name"
            />
          </div>
        </div>
        <div className="form__form-group">
          <span className="form__form-group-label">Last Name</span>
          <div className="form__form-group-field">
            <div className="form__form-group-icon">
              <AccountOutlineIcon />
            </div>
            <Field
              name="lastName"
              component="input"
              type="text"
              placeholder="Your Last Name"
            />
          </div>
        </div>
        <div className="form__form-group">
          <span className="form__form-group-label">LinkedIn Email</span>
          <div className="form__form-group-field">
            <div className="form__form-group-icon">
              <AccountOutlineIcon />
            </div>
            <Field
              name="un"
              component="input"
              type="text"
              placeholder="Your LinkedIn Email"
              autoComplete="new-password"
            />
          </div>
        </div>
        <div className="form__form-group">
          <span className="form__form-group-label">Password</span>
          <div className="form__form-group-field">
            <div className="form__form-group-icon">
              <KeyVariantIcon />
            </div>
            <Field
              id="pwInputLI"
              name="pw"
              component="input"
              type={showPassword ? 'text' : 'password'}
              onFocus={onPasswordInputFocus}
              placeholder="Your LinkedIn Password"
              autoComplete="new-password"
            />
            <button
              className={`form__form-group-button${showPassword ? ' active' : ''}`}
              onClick={e => this.showPassword(e)}
              type="button"
            ><EyeIcon />
            </button>
          </div>
        </div>
        <div
          className="helper-div-for-checkbox-in-modal"
          style={{ padding: '0 0 0 7px', marginBottom: '12px' }}
        >
          <Field
            name="isActive"
            component={renderCheckBoxField}
            defaultChecked={isActive}
            label="Run Scan"
            style={{ color: 'black', zIndex: '1910' }}
          />
        </div>
        <div className="form__form-group">
          <span className="form__form-group-label">Email Signature</span>
          <div className="form__form-group-field">
            <Editor
              apiKey={TINY_MCE_KEY}
              label="signatureFirstDegree"
              id="signatureFirstDegree"
              initialValue={signatureFirstDegree}
              init={{
                width: '100%',
                toolbar: 'heading | undo | bold italic | bullist | numlist',
                plugins: [
                  'link image',
                ],
                default_link_target: '_blank',
              }}
              onEditorChange={(content) => {
                this.handleSignatureFirstDegreeEditorChange(content);
              }}
              style={{ width: '100%', height: '100px' }}
            />
          </div>
        </div>
        <Button
          type="submit"
          size="sm"
          color="primary"
          className="w-100"
        >
          Authorize and Save
        </Button>
      </form>
    );
  }
}

export default reduxForm({
  form: 'li_settings_form',
})(ProfileSettingsForm);
