import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import {
  CardBody,
  Container,
  Card,
  Row,
  Col,
} from 'reactstrap';
import { Stack } from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import { User } from '@alumni-ventures-group/av-okta-auth';
import {
  publishDueDiligenceReport,
} from '../shared/services/apiGateway';
import generateRequest from '../shared/services/generateRequest';
import useDueDiligenceReport from '../shared/hooks/useDueDiligenceReport';
import PartialPageLoader from '../shared/components/Loader/PartialPageLoader';
import formatDateTimeString from '../shared/utils/formatDateTimeString';

function PublishDDReport({ match }) {
  const ddReportId = match.params.id;
  const user = new User();
  const userFirstName = user.getProp('firstName');

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const ddReport = useDueDiligenceReport(ddReportId, () => {
    setIsLoading(false);
  });

  return (
    <>
      <Helmet>
        <title>Publish Due Diligence Report</title>
      </Helmet>
      <Container className="med-width-form">
        <Card>
          <CardBody>
            {(isLoading) && (
              PartialPageLoader()
            )}
            {(!isLoading) && (
              <>
                <Row>
                  <Col>
                    <h3>{userFirstName}, are you sure you want to publish the report?</h3>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <p style={{ fontSize: '16px', paddingTop: '14px' }}>
                      By publishing the report, you are confirming that this is the final version of the DD Report.
                    </p>
                  </Col>
                </Row>
                <Stack
                  direction="row-reverse"
                  justifyContent="space-between"
                  alignItems="center"
                  flexWrap="wrap"
                  spacing={2}
                  mt={2}
                >
                  <LoadingButton
                    variant="contained"
                    color="secondary"
                    disabled={isSubmitting}
                    loading={isSubmitting}
                    onClick={async () => {
                      setIsSubmitting(true);
                      await publishDueDiligenceReport(generateRequest({
                        ddReportId,
                      }));
                      window.dueDiligenceReportPublicationRequested = ddReportId;
                    }}
                  >
                    Publish
                  </LoadingButton>
                  {(ddReport.lastPublishedDate) && (
                    <h5>Report last published at {formatDateTimeString(ddReport.lastPublishedDate)}</h5>
                  )}
                </Stack>
              </>
            )}
          </CardBody>
        </Card>
      </Container>
    </>
  );
}

PublishDDReport.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.node,
    }).isRequired,
  }).isRequired,
};

export default PublishDDReport;
