import React from 'react';
import Select from '@mui/material/Select';
import {
  InputLabel, MenuItem, FormControl,
} from '@mui/material';
import {
  toggleVisibilityOfFieldsBySecurityType,
} from './helpers';

function SecurityTypeSelector(types, onChange, securityType) {
  return (
    <FormControl style={{ minWidth: '100px' }} required>
      <InputLabel id="securityTypeLabel">Security Type</InputLabel>
      <Select
        labelId="securityTypeLabel"
        id="securityTypeSelect"
        value={securityType}
        label="Security Type"
        onChange={(event) => {
          if (onChange) {
            onChange(event.target.value);
            toggleVisibilityOfFieldsBySecurityType(types, event.target.value);
          }
        }}
      >
        {types.map((item) => (
          <MenuItem key={item.id} value={item.id}>{item.name}</MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}

export default SecurityTypeSelector;
