import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { ThemeProvider } from '@mui/material/styles';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { toRelativeUrl } from '@okta/okta-auth-js';
import { Security } from '@okta/okta-react';
import {
  AvOktaAuth, User, AuthStatus, loginUserWithAccessToken, updateAuthentication,
} from '@alumni-ventures-group/av-okta-auth';

import appTheme from './shared/themes/appTheme';
import Router from './Router';
import oktaConfig from './oktaConfig';
import { WebSocketProvider } from './shared/components/WebsocketContext';
import Websocket from './shared/components/WebSocket';

const avOktaAuth = new AvOktaAuth(oktaConfig.oidc, process.env.ENV);
const oktaAuth = avOktaAuth.getOktaInstance();

function App() {
  const userCache = new User();
  const authStatusCache = new AuthStatus();
  const history = useHistory();

  const [isAuthenticated, setIsAuthenticated] = useState(authStatusCache.get() || false);
  const [userInfo, setUserInfo] = useState(userCache.get() || {});
  const [userInfoLoading, setUserInfoLoading] = useState(false);

  const restoreOriginalUri = React.useCallback((_oktaAuth, originalUri) => {
    history.replace(toRelativeUrl(originalUri || '/', window.location.origin));
  }, []);

  const loginAndInitializeUser = async (accessToken) => {
    if (accessToken) {
      const decodedUserInfo = await loginUserWithAccessToken(accessToken);
      setUserInfo(decodedUserInfo);
      setUserInfoLoading(false);
    }
  };

  useEffect(() => {
    if (isAuthenticated) {
      const accessToken = oktaAuth.getAccessToken();
      if ((!userInfo || !userInfo.email) && !userInfoLoading && accessToken) {
        setUserInfoLoading(true);
        loginAndInitializeUser(accessToken);
      }
    } else {
      updateAuthentication(oktaAuth, setIsAuthenticated);
    }
  }, [isAuthenticated]);

  return (
    <Security
      oktaAuth={oktaAuth}
      restoreOriginalUri={restoreOriginalUri}
    >
      <ThemeProvider theme={appTheme}>
        <LocalizationProvider dateAdapter={AdapterMoment}>
          <WebSocketProvider>
            <Websocket />
          </WebSocketProvider>
          <Router />
        </LocalizationProvider>
      </ThemeProvider>
    </Security>
  );
}

export default App;
