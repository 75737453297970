
const fundTypeArray = [
  { label: 'Managed', value: 0 },
  { label: 'Systematic', value: 1 },
  { label: 'TAF', value: 2 },
  { label: 'Systematic2', value: 3 },
  { label: 'Syndication Fund', value: 4 },
  { label: 'Basecamp Managed', value: 5 },
  { label: 'AVG Fundraise', value: 6 },
  { label: 'AVG Buyback', value: 7 },
];
const fundStatusArray = [
  { label: 'Closed', value: 0 },
  { label: 'Open', value: 1 },
  { label: 'Open and Processing Collections', value: 2 },
];

export default class Fund {
  constructor(rawFund) {
    this.fund_id = {
      label: 'Fund ID', type: null, readOnly: true, value: rawFund.fund_id,
    };
    this.fk_company_id = {
      label: 'Company ID', type: null, readOnly: true, value: rawFund.fk_company_id,
    };
    this.name = {
      label: 'Fund Name',
      type: 'text',
      readOnly: false,
      value: rawFund.name,
    };
    this.base_fund_code = {
      label: 'Base Fund Code',
      type: 'text',
      readOnly: false,
      value: rawFund.base_fund_code,
    };
    this.fund_code = {
      label: 'Fund Code',
      type: 'text',
      readOnly: false,
      value: rawFund.fund_code,
    };
    this.min_investment_amount = {
      label: 'Minimum Investment Amount', type: 'text', readOnly: false, value: rawFund.min_investment_amount,
    };
    this.type = {
      label: 'Fund Type',
      type: 'select',
      readOnly: false,
      value: rawFund.type,
      // display: changeFundType(rawFund.type),
      options: fundTypeArray,
    };
    this.fee = {
      label: 'Fee',
      type: 'text',
      readOnly: false,
      value: rawFund.fee,
    };
    this.fund_status = {
      label: 'Fund Status',
      type: 'select',
      readOnly: false,
      value: rawFund.fund_status,
      // display: changeFundStatus(rawFund.fund_status),
      options: fundStatusArray,
    };
    this.legal_docs_url = {
      label: 'Legal Docs Url',
      type: 'text',
      readOnly: false,
      value: rawFund.legal_docs_url,
    };
    this.num_deals = {
      label: 'Number of Deals',
      type: null,
      readOnly: true,
      value: rawFund.num_deals,
    };
  }
}
