export default class Cache {
  constructor(key) {
    this.key = key;
  }

  /**
   * @returns returns saved values for the object or null if no object saved
   */
  get() {
    const obj = localStorage.getItem(this.key);
    if (!obj) {
      return null;
    }
    return JSON.parse(obj).data;
  }

  set(obj) {
    return localStorage.setItem(this.key, JSON.stringify({
      data: obj,
    }));
  }

  clear() {
    return localStorage.clear(this.key);
  }

  getProp(propName) {
    const item = this.get();
    if (item) {
      return item[propName];
    }
    return undefined;
  }

  setProp(propName, propValue) {
    const item = this.get();
    if (item) {
      item[propName] = propValue;
      this.set(item);
    }
    return item;
  }
}
