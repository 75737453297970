export default (sortColumn, sortDirection) => (a, b) => {
  if (sortColumn === 'scanDate') {
    if (
      a[sortColumn] === 'In Progress'
      || a[sortColumn] === null
      || a[sortColumn] === ''
      || typeof a[sortColumn] !== 'string'
    ) return 1;
    if (
      b[sortColumn] === 'In Progress'
      || b[sortColumn] === null
      || b[sortColumn] === ''
      || typeof b[sortColumn] !== 'string'
    ) return -1;
    const aComps = a[sortColumn].split('/');
    const bComps = b[sortColumn].split('/');
    const aVal = parseInt(
      aComps[2]
      + (aComps[0].length === 1 ? `0${aComps[0]}` : aComps[0])
      + (aComps[1].length === 1 ? `0${aComps[1]}` : aComps[1]),
      10,
    );
    const bVal = parseInt(
      bComps[2]
      + (bComps[0].length === 1 ? `0${bComps[0]}` : bComps[0])
      + (bComps[1].length === 1 ? `0${bComps[1]}` : bComps[1]),
      10,
    );
    if (typeof aVal === 'number' && typeof bVal === 'number') {
      if (aVal === bVal) return 0;
      if (sortDirection === 'ASC' || sortDirection === 'asc') {
        return aVal > bVal ? 1 : -1;
      }
      return aVal < bVal ? 1 : -1;
    }
  }

  if (typeof a[sortColumn] === 'number' && typeof b[sortColumn] === 'number') {
    if (sortDirection === 'ASC' || sortDirection === 'asc') {
      return a[sortColumn] - b[sortColumn];
    }
    return b[sortColumn] - a[sortColumn];
  }

  if ((a[sortColumn] && b[sortColumn]) && (a[sortColumn].indexOf('$') !== -1 && b[sortColumn].indexOf('$') !== -1)) {
    const aVal = Number(a[sortColumn].replace(/[^0-9.-]+/g, ''));
    const bVal = Number(b[sortColumn].replace(/[^0-9.-]+/g, ''));
    if (sortDirection === 'ASC' || sortDirection === 'asc') {
      return aVal - bVal;
    }
    return bVal - aVal;
  }

  const aVal = typeof a[sortColumn] === 'string' ? a[sortColumn].toLowerCase() : a[sortColumn];
  const bVal = typeof b[sortColumn] === 'string' ? b[sortColumn].toLowerCase() : b[sortColumn];
  if (sortDirection === 'ASC' || sortDirection === 'asc') {
    if (aVal === '' || aVal === null) return 1;
    if (bVal === '' || bVal === null) return -1;
    if (aVal === bVal) return 0;
    return aVal > bVal ? 1 : -1;
  }
  if (aVal === '' || aVal === null) return 1;
  if (bVal === '' || bVal === null) return -1;
  if (aVal === bVal) return 0;
  return aVal < bVal ? 1 : -1;
};
