import React from 'react';
import Button from '@mui/material/Button';
import formatCurrencyAsUsd from '../shared/utils/formatCurrencyAsUsd';
import formatDateString from '../shared/utils/formatDateString';
import getNumberValue from '../shared/utils/getNumberValue';
import sortByDate from '../shared/utils/sortAsDate';
import sortAlphanumerically from '../shared/utils/sortAlphanumerically';

export default function createClosedInvestmentsTableDefinition(history) {
  return [
    {
      field: '_',
      headerName: '',
      sortable: false,
      filterable: false,
      renderCell: (params) => (
        <Button
          variant="outlined"
          color="secondary"
          onClick={(e) => {
            // prevent row from being selected on click
            e.stopPropagation();
            history.push(`/closed-investment/${params.row.id}`);
          }}
        >Details
        </Button>
      ),
      minWidth: 100,
      flex: 1,
    },
    {
      field: 'companyName',
      headerName: 'Name',
      description: 'The name of the Company',
      editable: false,
      flex: 1,
      minWidth: 300,
      sortComparator: sortAlphanumerically,
    },
    {
      field: 'sponsorFund',
      headerName: 'Sponsor Fund',
      description: 'The sponsor fund.',
      sortable: true,
      flex: 1,
      minWidth: 200,
    },
    {
      field: 'totalInvestmentAmount',
      headerName: 'Total Investment Amount',
      description: 'The Total Investment Amount',
      sortable: true,
      flex: 1,
      minWidth: 300,
      valueGetter: (params) => `${formatCurrencyAsUsd((params.row.totalInvestmentAmount))}`,
      sortComparator: (a, b) => (getNumberValue(a) > getNumberValue(b) ? 1 : -1),
    },
    {
      field: 'roundNumber',
      headerName: 'Round',
      description: 'AVs investment round',
      editable: false,
      flex: 1,
      minWidth: 250,
    },
    {
      field: 'dateInvested',
      headerName: 'Investment Date',
      description: 'Date of investment transfer into Netsuite',
      editable: false,
      flex: 1,
      minWidth: 250,
    },
    {
      field: 'wireTransferDate',
      headerName: 'Wire Transfer Date',
      description: 'Date of wire transfer',
      editable: false,
      flex: 1,
      minWidth: 250,
      valueGetter: (params) => `${formatDateString(params.row.wireTransferDate)}`,
      sortComparator: sortByDate,
    },
  ];
}
