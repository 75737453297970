import React from 'react';
import PropTypes from 'prop-types';
import AnnouncementInfo from '../../announcementInfo/AnnouncementInfo';
import AnnouncementCard from '../../announcementCard/AnnouncementCard';

const AnnouncementFormPreview = ({
  existingAnnouncement,
  sendEmail,
  name,
  headline,
  announcement,
  announcementPreview,
  club,
  setVisibility,
  startDate,
  endDate,
  image,
  type,
  url,
  videoUrl,
}) => (
  <div className="container">
    <hr />
    <h4 className="w-100">Club Dashboard Preview:</h4>
    <br />
    {(existingAnnouncement) && (
      <div>
        <h5>
          Auto-open Url: &nbsp;&nbsp;
          <a
            href={`${process.env.AVG_API_PATH}/investor/login/${club.id}?open=${existingAnnouncement.id}`}
            target="_blank"
            rel="noreferrer"
          >
            {`${process.env.AVG_API_PATH}/investor/login/${club.id}?open=${existingAnnouncement.id}`}
          </a>
        </h5>
        <br />
      </div>
    )}
    <div className="container" style={{ maxWidth: '500px' }}>
      <AnnouncementInfo
        announcement={{
          id: existingAnnouncement ? existingAnnouncement.id : '',
          sendEmail,
          name,
          headline_text: headline,
          announcement_text: announcement,
          preview_text: announcementPreview,
          clubId: club.id,
          display: setVisibility(),
          start_date: startDate,
          end_date: endDate || null,
          img_src: (image && image[0] ? image[0].preview : null),
          type,
          webinar_url: url,
          video_url: videoUrl,
        }}
        isPreview
        clubId={club.id}
      />
    </div>
    <h4 className="w-100">Full Page Preview:</h4>
    <AnnouncementCard item={{
      id: existingAnnouncement ? existingAnnouncement.id : '',
      sendEmail,
      name,
      headline_text: headline,
      announcement_text: announcement,
      announcementPreview,
      clubId: club.id,
      display: setVisibility(),
      start_date: startDate,
      end_date: endDate || null,
      img_src: (image && image[0] ? image[0].preview : null),
      type,
      webinar_url: url,
      video_url: videoUrl,
    }}
    />
  </div>
);

AnnouncementFormPreview.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  image: PropTypes.any.isRequired,
  existingAnnouncement: PropTypes.objectOf(PropTypes.any),
  sendEmail: PropTypes.bool.isRequired,
  name: PropTypes.string.isRequired,
  headline: PropTypes.string.isRequired,
  announcement: PropTypes.string.isRequired,
  announcementPreview: PropTypes.string.isRequired,
  club: PropTypes.objectOf(PropTypes.any),
  setVisibility: PropTypes.func.isRequired,
  startDate: PropTypes.instanceOf(Date).isRequired,
  endDate: PropTypes.instanceOf(Date),
  type: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
  videoUrl: PropTypes.string.isRequired,
};

AnnouncementFormPreview.defaultProps = {
  existingAnnouncement: null,
  endDate: null,
  club: {
    id: null,
  },
};

export default AnnouncementFormPreview;
