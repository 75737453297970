import React, { useState } from 'react';
import moment from 'moment';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { Form } from 'reactstrap';
import {
  ANNOUNCEMENT_MOMENT_TIME_FORMAT,
} from '../../../services/clubResources';
import { showSnackbarInfo } from '../../../../redux/actions/snackbarActions';
import useInput from '../../../hooks/input-hook';
import { createNewAnnouncement } from '../../../services/bifService';
import AnnouncementFormButtonGrid from './AnnouncementFormButtonGrid';
import AnnouncementFormPreview from './AnnouncementFormPreview';
import AnnouncementForm from './AnnouncementForm';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    marginBottom: theme.spacing(2),
    marginTop: theme.spacing(2),
    color: '#646777',
  },
  textField: {
    marginBottom: theme.spacing(3),
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    width: 200,
  },
  chip: {
    margin: theme.spacing(0.5),
  },
}));
const setDisplayBool = display => (!!display);

const AnnouncementFormWrapper = ({
  existingAnnouncement, handleSetIsEditing, cancelButton, club, fullWidth, members, admin,
}) => {
  const [submitting, setSubmitting] = useState(false);
  const [preview, setPreview] = useState(false);
  const dispatch = useDispatch();
  const history = useHistory();
  const [image, setImage] = useState(
    (existingAnnouncement && existingAnnouncement.img_src) ? [{
      preview: existingAnnouncement.img_src,
      name: existingAnnouncement.img_src,
    }] : '',
  );
  const [sendEmail, setSendEmail] = useState(false);
  const [announcementBodyPresent, setAnnouncementBodyPresent] = useState(true);
  const {
    value: name, bind: bindName, reset: resetName,
  } = useInput(((existingAnnouncement && existingAnnouncement.name) || ''), true);
  const {
    value: headline, bind: bindHeadline, reset: resetHeadline,
  } = useInput(((existingAnnouncement && existingAnnouncement.headline_text) || ''), true);
  const {
    value: announcement, setValue: setAnnouncement, reset: resetAnnouncement,
  } = useInput(((existingAnnouncement && existingAnnouncement.announcement_text) || ''), true);
  const {
    value: announcementPreview, setValue: setAnnouncementPreview, reset: resetAnnouncementPreview,
  } = useInput(((existingAnnouncement && existingAnnouncement.preview_text) || ''), true);
  const [isEndDateActive, setIsEndDateActive] = useState(
    // eslint-disable-next-line no-unneeded-ternary
    (existingAnnouncement && existingAnnouncement.end_date) ? true : false,
  );
  const [startDate, setStartDate] = React.useState(
    (existingAnnouncement && existingAnnouncement.start_date) ? existingAnnouncement.start_date : new Date(),
  );
  const [endDate, setEndDate] = React.useState(
    (existingAnnouncement && existingAnnouncement.end_date) ? existingAnnouncement.end_date : null,
  );
  const [display, setDisplay] = useState(
    (existingAnnouncement)
      ? setDisplayBool(existingAnnouncement.display_status)
      : true,
  );
  const determineDefaultType = () => (club.type == 3 ? '3' : '1');

  const [type, setType] = useState(
    (existingAnnouncement && existingAnnouncement.type)
      ? existingAnnouncement.type : determineDefaultType(),
  );
  const {
    value: url, bind: bindUrl, setValue: setUrl,
  } = useInput(((existingAnnouncement && existingAnnouncement.webinar_url)
    ? existingAnnouncement.webinar_url : ''), false);

  const {
    value: videoUrl, bind: bindVideoUrl,
  } = useInput(((existingAnnouncement && existingAnnouncement.video_url)
    ? existingAnnouncement.video_url : ''), false);

  const clearAllFields = () => {
    resetName();
    resetHeadline();
    resetAnnouncement();
    resetAnnouncementPreview();
    setImage('');
    setDisplay(true);
    setStartDate(new Date());
    setEndDate(null);
    setUrl('');
    // setVideoUrl('');
    setAnnouncement('');
    setAnnouncementPreview('');
  };
  const setVisibility = () => (display ? 1 : 0);
  const handleSubmit = async (evt) => {
    evt.preventDefault();
    const postData = new FormData();
    if (existingAnnouncement && existingAnnouncement.id) {
      postData.append('id', existingAnnouncement.id);
    }
    if (sendEmail && display) {
      postData.append('sendEmail', sendEmail.toString());
    }
    postData.append('name', name);
    postData.append('headline', headline);
    if (!announcement || announcement.length === 0) {
      setAnnouncementBodyPresent(false);
      return false;
    }
    setAnnouncementBodyPresent(true);
    setSubmitting(true);
    postData.append('announcement', announcement);
    postData.append('announcementPreview', announcementPreview);
    postData.append('clubId', club.id);
    postData.append('display', setVisibility().toString());
    postData.append('startDate', moment(startDate).format(ANNOUNCEMENT_MOMENT_TIME_FORMAT));
    if (endDate) { postData.append('endDate', moment(endDate).format(ANNOUNCEMENT_MOMENT_TIME_FORMAT)); }
    if (image && image[0]) { postData.append('image', image ? image[0] : null); }
    postData.append('type', type);
    postData.append('url', url);
    postData.append('video_url', videoUrl);
    const res = await createNewAnnouncement(postData);
    if (res && res.clubAnnouncement) {
      dispatch(showSnackbarInfo(existingAnnouncement ? 'Announcement Updated!' : 'New Announcement Created!', true));
      clearAllFields();
      setSubmitting(false);
      setPreview(false);
      if (existingAnnouncement) {
        handleSetIsEditing(false);
        if (!fullWidth) {
          history.push(`/club/home/${club.id}/announcements-edit/1`);
        }
      } else if (!fullWidth) {
        history.push(`/club/home/${club.id}/announcements`);
      }
    }
    return true;
  };
  const handleSkip = async (evt) => {
    evt.preventDefault();
    history.push(`/club/home/${club.id}`);
  };
  const classes = useStyles();
  return (
    club && (
      <Form className={classes.root} onSubmit={handleSubmit} id="new-announcement-form">
        <div className="w-100">
          <h4>Announcement:</h4>
        </div>
        {!preview && (
          <AnnouncementForm
            bindName={bindName}
            bindHeadline={bindHeadline}
            startDate={startDate}
            setStartDate={setStartDate}
            isEndDateActive={isEndDateActive}
            setIsEndDateActive={setIsEndDateActive}
            endDate={endDate}
            setEndDate={setEndDate}
            members={members}
            display={display}
            setDisplay={setDisplay}
            sendEmail={sendEmail}
            setSendEmail={setSendEmail}
            announcementBodyPresent={announcementBodyPresent}
            setAnnouncementBodyPresent={setAnnouncementBodyPresent}
            announcement={announcement}
            setAnnouncement={setAnnouncement}
            announcementPreview={announcementPreview}
            setAnnouncementPreview={setAnnouncementPreview}
            image={image}
            setImage={setImage}
            type={type}
            setType={setType}
            bindUrl={bindUrl}
            bindVideoUrl={bindVideoUrl}
            clubType={club.type}
          />
        )}
        {preview && (
          <AnnouncementFormPreview
            existingAnnouncement={existingAnnouncement}
            sendEmail={sendEmail}
            name={name}
            headline={headline}
            announcement={announcement}
            announcementPreview={announcementPreview}
            club={club}
            setVisibility={setVisibility}
            startDate={new Date(startDate)}
            endDate={new Date(endDate)}
            image={image}
            type={type}
            url={url}
            videoUrl={videoUrl}
          />
        )}
        <AnnouncementFormButtonGrid
          fullWidth={fullWidth}
          cancelButton={cancelButton}
          admin={admin}
          existingAnnouncement={existingAnnouncement}
          handleSkip={handleSkip}
          preview={preview}
          announcement={announcement}
          setPreview={setPreview}
          submitting={submitting}
        />
      </Form>
    )
  );
};

AnnouncementFormWrapper.propTypes = {
  cancelButton: PropTypes.string,
  // eslint-disable-next-line react/forbid-prop-types
  existingAnnouncement: PropTypes.any,
  handleSetIsEditing: PropTypes.func,
  // eslint-disable-next-line react/forbid-prop-types
  club: PropTypes.any.isRequired,
  fullWidth: PropTypes.bool.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  members: PropTypes.array,
  admin: PropTypes.bool,
};

AnnouncementFormWrapper.defaultProps = {
  cancelButton: 'Skip',
  existingAnnouncement: null,
  handleSetIsEditing: (() => null),
  members: [],
  admin: false,
};

export default AnnouncementFormWrapper;
