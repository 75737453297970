/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useRef } from 'react';
import ReactPlayer from 'react-player';
import PropTypes from 'prop-types';
import screenfull from 'screenfull';
import { Link } from 'react-router-dom';
import { Col, Row } from 'reactstrap';
import moment from 'moment';
import makeStyles from '@material-ui/core/styles/makeStyles';
// import { useDispatch } from 'react-redux';
// import { TYPES } from '../../helpers/MultiSelectOptions';
// import { setQueryString } from '../../../redux/actions/queryStringActions';

const useStyles = makeStyles(() => ({
  centerImage: {
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap',
  },
  headLineText: {
    fontSize: '16px',
    fontWeight: 'bold',
    marginBottom: '5px',
    marginTop: '10px',
  },
  linkText: {
    textDecoration: 'underline',
    float: 'right',
  },
  dateCard: {
    width: '200px',
    padding: '10px',
    display: 'flex',
    margin: 'auto',
    alignItems: 'center',
    textAlign: 'center',
    color: '#7B569B',
  },
  day: {
    width: '50%',
    height: 0,
    textAlign: 'center',
    lineHeight: 0,
    padding: '25% 0',
    borderRadius: '50%',
    margin: 'auto',
    color: '#fff',
    background: '#7B569B',
    fontSize: '28px',
    // margin: '0px 10px',
  },
  month: {
    fontSize: '16px',
    fontWeight: 'bold',
  },
}));

const displayImage = image => (
  <img
    style={{
      width: '100%',
      margin: '0px 10px 2px 0px',
    }}
    src={image}
    alt="Club Logo"
  />
);

const displayVideo = (video) => {
  const player = useRef(null);
  return (
    <div
      style={{
        width: '100%',
        margin: '0px 10px 2px 0px',
      }}
    >
      <ReactPlayer
        controls
        height="100%"
        width="100%"
        url={video}
        ref={player}
        onPlay={() => screenfull.request(screenfull.request(player.current.player.player.player.element))}
      />
    </div>
  );
};

const displayDate = (date, classes) => (
  <div className={classes.dateCard}>
    <div className="w-100 text-center">
      <div className={classes.month}>{moment(date).format('MMMM')}</div>
      <div className="text-center">
        <div className={classes.day}>{moment(date).format('DD')}</div>
      </div>
    </div>
  </div>
);

const AnnouncementInfo = ({
  announcement, clubId, isPreview = false,
}) => {
  const classes = useStyles();
  // const dispatch = useDispatch();
  const {
    headline_text: headline,
    img_src: image,
    start_date: date,
    type,
    announcement_text: text,
    id,
    preview_text: previewText,
    video_url: video,
  } = announcement;
  const textLength = image ? 100 : 150;

  // if ((announcement.type === TYPES.EVENTS) && moment(new Date()).isAfter(moment(date))) {
  //   return null;
  // }

  // const openVideoModal = () => {
  //   if (video) {
  //     dispatch(setQueryString(`open=${id}`));
  //   }
  // };

  const textToDisplay = previewText || text.slice(0, textLength);
  return (
    <>
      <Row>
        <Col>
          {announcement.video_url ? (
            <Link to={`/club/home/${clubId}/announcements/${id}`}>
              <h5 className={classes.headLineText}>
                {headline}
              </h5>
            </Link>
          ) : (
            <h5 className={classes.headLineText}>
              {headline}
            </h5>
          )}
        </Col>
      </Row>
      <Row>
        {(video || image || date) && (
          <Col xl={4} lg={3} md={12} className={classes.centerImage}>
            { (video && displayVideo(video))
            || ((!video && image) && displayImage(image))
            || (type === 2 && displayDate(date, classes))}
          </Col>
        )}
        <Col xl={(video || image || type === 2) ? 8 : 12} lg={(video || image || type === 2) ? 9 : 12} md={12}>
          <div>
            <span dangerouslySetInnerHTML={{ __html: textToDisplay }} />
          </div>
        </Col>
        {(!previewText && !isPreview && text.length >= textLength) && (
          <Col sm={12}>
            <Link to={`/club/home/${clubId}/announcements/${id}`}>
              <span className={classes.linkText}>
                More
              </span>
            </Link>
          </Col>
        )}
      </Row>
      <hr />
    </>
  );
};

AnnouncementInfo.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  announcement: PropTypes.object,
  clubId: PropTypes.number,
  isPreview: PropTypes.bool,
};

AnnouncementInfo.defaultProps = {
  announcement: {},
  isPreview: false,
  clubId: null,
};

export default AnnouncementInfo;
