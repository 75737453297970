import React, { createRef, useRef, useState } from 'react';
import {
  Container, MenuItem, TextField, Button,
} from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import Stack from '@mui/material/Stack';
import {
  Card, CardBody, Row, Col,
} from 'reactstrap';
import { useParams, useHistory } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { isWhitespaceOnly } from '../shared/utils/validators';
import usePendingInvestmentDetail from '../shared/hooks/usePendingInvestmentDetail';
import {
  getPresignedUrl, postFileToS3, deleteDocuments, updatePendingInvestment,
} from '../shared/services/apiGateway';
import generateRequest from '../shared/services/generateRequest';
import HorizRecLoader from '../shared/components/Loader/HorizRecLoader';
import CompanyInfo from '../shared/components/CompanyInfo';
import StageStatusMessage from '../shared/components/StageStatusMessage';
import getStageStatus from '../shared/utils/checkUserPermissions';
import getSearchParams from '../shared/utils/getSearchParams';
import DocumentsList from './DocumentsList';

const docTypes = [
  'Stock Purchase Agreement',
  'Right of First Refusal & Co-Sale Agreement',
  'Certificate of Incorporation',
  'Investor Rights Agreement',
  'Voting Agreement',
  'Term Sheet',
  'SAFE',
  'Convertible Note',
  'Warrant',
  'SAFT',
  'Other Closing Document',
];

function UploadClosingDocs() {
  const { id } = useParams();
  const closeOnConfirm = getSearchParams('closeoncompletion');
  const history = useHistory();

  const [fileType, setFileType] = useState('');
  const [otherReasonSelected, setOtherReasonSelection] = useState(false);
  const [otherFileType, setOtherFileType] = useState('');
  const [files, setFiles] = useState([]);
  const [dealDetailLoading, setDealDetailLoading] = useState(true);
  const [uploadClosingDocsTableSelectionModel, setUploadClosingDocsTableSelectionModel] = useState([]);
  const [errorText, setErrorText] = useState('');
  const [stageStatus, setStageStatus] = useState({
    isEditableByRole: false,
  });
  const [isUploadingFile, setIsUploadingFile] = useState(false);
  const [isRemovingFile, setIsRemovingFile] = useState(false);
  const [isSaving, setIsSaving] = useState(false);

  const [companyInfo, setCompanyInfo] = useState({
    name: '',
    countryIncorp: null,
    corpType: null,
    companyTaxStatus: null,
  });
  const [companyInfoComponentErrors, setCompanyInfoComponentErrors] = useState({});

  const companyInfoValidationObject = useRef(null);

  const dealData = usePendingInvestmentDetail(id, (deal) => {
    let companyName = '';
    if (deal.companyName) {
      companyName = deal.companyName;
    } else if (deal?.hsDealObj?.company?.name) {
      companyName = deal.hsDealObj.company.name;
    }
    let countryIncorp = null;
    if (deal.companyCountryIncorp) {
      countryIncorp = deal.companyCountryIncorp;
    } else if (deal?.hsDealObj?.country) {
      countryIncorp = deal.hsDealObj.country;
    }

    setCompanyInfo({
      name: companyName,
      countryIncorp,
      corpType: deal.companyCorpType || null,
      companyTaxStatus: deal.companyTaxStatus || null,
    });

    setStageStatus({
      ...getStageStatus(deal),
    });
    if (deal.closingDocuments) {
      setFiles(deal.closingDocuments.map((item) => ({
        name: item.documentName,
        avType: item.documentType,
        documentDescription: item.documentDescription,
        id: item.documentKey,
      })));
    }
    setDealDetailLoading(false);
  });

  const checkAndSetFileType = (dropdownFileType) => {
    setFileType(dropdownFileType);

    if (dropdownFileType === 'Other Closing Document') {
      setOtherReasonSelection(true);
      setOtherFileType('');
      return;
    }
    setOtherReasonSelection(false);
  };

  const removeSelectedFiles = async () => {
    setIsRemovingFile(true);
    const filesToDelete = files.filter((item) => uploadClosingDocsTableSelectionModel.includes(item.id)).map((item) => ({
      documentName: item.name,
      documentType: item.avType,
      documentKey: item.id,
      documentDescription: item.documentDescription,
    }));
    const deletedDocuments = await deleteDocuments({
      deal: id,
      documents: filesToDelete,
    });
    const remainingFiles = files.filter((item) => !deletedDocuments.includes(item.id));

    setFiles(remainingFiles);
    setUploadClosingDocsTableSelectionModel([]);
    setIsRemovingFile(false);
  };

  const handleFileUpload = async (e) => {
    if (!e.target.files) {
      return;
    }
    setIsUploadingFile(true);
    const file = e.target.files[0];
    if (fileType === 'Other Closing Document') {
      setOtherFileType('');
    }
    file.avType = fileType;

    const sanitizeRegex = /[^a-z0-9 ._-]/gi;
    const body = {
      deal: id,
      documenttype: file.avType,
      fileType: file.type,
      filename: `${file.name}`.replace(sanitizeRegex, ''),
      filedescription: otherFileType ? `${otherFileType}`.replace(sanitizeRegex, '') : '',
    };
    const response = await getPresignedUrl(body);

    if (!response) {
      setErrorText(`Failed to upload: ${body.filename}. Please contact support if this error persists.`);
      setIsUploadingFile(false);
      return;
    }

    const { url, filekey } = response;
    const formData = new FormData();
    formData.append('file', file);
    formData.append('Content-Type', file.type);
    try {
      const uploadResponse = await postFileToS3(url, file, file.type);
      console.log(uploadResponse);
      file.id = filekey;
      if (fileType === 'Other Closing Document') {
        file.documentDescription = body.filedescription;
      }

      setFiles([...files, file]);
      setErrorText('');
      setIsUploadingFile(false);
    } catch (error) {
      setErrorText(`An error occurred while uploading: ${body.filename}. Please contact support if this error persists.`);
      setIsUploadingFile(false);
      console.log(error);
    }
  };

  const companyInfoRef = createRef();

  const isFormValid = () => {
    const companyInfoErrors = companyInfoValidationObject.current(companyInfo);
    setCompanyInfoComponentErrors(companyInfoErrors);

    if (Object.keys(companyInfoErrors).length > 0) {
      companyInfoRef.current.scrollIntoView();
    }

    return Object.keys(companyInfoErrors).length <= 0;
  };

  const companyInfoFilled = !!(companyInfo && companyInfo.name
    && companyInfo.corpType
    && companyInfo.countryIncorp
    && ((companyInfo.corpType !== 'C-Corp' && companyInfo.companyTaxStatus)
    || companyInfo.corpType === 'C-Corp'));

  const navigateToClosingDocsReview = () => {
    const closingDocsReviewURL = `/closing-docs-review/${dealData.id}`;
    history.push(closeOnConfirm ? `${closingDocsReviewURL}?closeoncompletion=${closeOnConfirm}` : closingDocsReviewURL);
  };

  const saveChanges = async (shouldContinue) => {
    if (shouldContinue && !isFormValid()) {
      return;
    }
    setIsSaving(true);
    dealData.companyCorpType = companyInfo.corpType;
    dealData.companyCountryIncorp = companyInfo.countryIncorp;
    dealData.companyTaxStatus = companyInfo.companyTaxStatus;
    await updatePendingInvestment(generateRequest(dealData));
    setIsSaving(false);
    if (shouldContinue) navigateToClosingDocsReview();
  };

  return (
    <>
      <Helmet>
        <title>Upload Closing Docs</title>
      </Helmet>
      <Container maxWidth="md">
        <Card>
          {dealDetailLoading && (
          <CardBody>
            <Row className="w-100">
              <Col>
                {HorizRecLoader(1, '40%', 30)}
              </Col>
              <Col sm={12} className="pr-0">
                {HorizRecLoader(1, '90%', 35)}
              </Col>
            </Row>
            <Row className="w-100">
              <Col className="d-flex justify-content-start">
                {HorizRecLoader(2)}
              </Col>
            </Row>
            <Row className="w-100">
              <Col sm={12} className="pr-0">
                {HorizRecLoader(3, '90%', 35)}
              </Col>
            </Row>
          </CardBody>
          )}
          {!dealDetailLoading && (
            <CardBody>
              <Row className="w-100">
                <Col>
                  <h3>Upload Closing Docs for {dealData.companyName}</h3>
                  <p>Please upload ALL finalized closing documents associated with this deal.  For each document
                    uploaded, please indicate the type of document by selecting the appropriate category from the
                    dropdown values.  If you cannot find the appropriate category in the dropdown, select “Other
                    Closing Document” and enter your own category description.  The purpose of this categorization is
                    to help our finance team navigate between the uploaded documents.
                  </p>
                </Col>
                {(stageStatus.isEditableByRole && !stageStatus.isCompleted) && (
                <>
                  <Col sm={12} className="pr-0">
                    <TextField
                      sx={{ my: 3 }}
                      fullWidth
                      id="documentTypeSelect"
                      select
                      label="Document Type"
                      value={fileType}
                      onChange={(e) => { checkAndSetFileType(e.target.value); }}
                      helperText="Please select type of document to upload"
                      variant="standard"
                    >
                      {docTypes.sort().map((corpType) => <MenuItem key={corpType} value={corpType}>{corpType}</MenuItem>)}
                    </TextField>
                    {otherReasonSelected && (
                    <TextField
                      fullWidth
                      size="small"
                      sx={{ mb: 3 }}
                      value={otherFileType}
                      onChange={(e) => {
                        const text = e.target.value;
                        if (!isWhitespaceOnly(text) && text.length <= 200) {
                          setOtherFileType(text);
                        }
                      }}
                      label="Please specify file type"
                    />
                    )}
                  </Col>
                  <Col>
                    <LoadingButton
                      variant="contained"
                      component="label"
                      color="secondary"
                      sx={{ marginRight: '1rem' }}
                      disabled={fileType === '' || (otherReasonSelected && (!otherFileType))}
                      loading={isUploadingFile}
                    >
                      Upload Files
                      <input
                        type="file"
                        accept=".csv, .txt, .pdf, .png, .jpg, .jpeg, .xlsx, .xls, .docx, .doc"
                        hidden
                        onChange={handleFileUpload}
                      />
                    </LoadingButton>
                    <LoadingButton
                      variant="outlined"
                      color="secondary"
                      sx={{ marginRight: '1rem' }}
                      disabled={uploadClosingDocsTableSelectionModel.length === 0}
                      loading={isRemovingFile}
                      onClick={removeSelectedFiles}
                    >
                      Remove Files
                    </LoadingButton>
                  </Col>
                </>
                )}
              </Row>
              <Row
                className="w-100"
                style={{
                  marginTop: '20px',
                }}
              >
                <Col>
                  <h4 className="error red">{errorText}</h4>
                </Col>
              </Row>
              <Row
                className="w-100"
                style={{
                  marginTop: '20px',
                }}
              >
                {(files && files.length > 0) && (
                  <Col>
                    <h4>Uploaded documents:</h4>
                    <DocumentsList
                      files={files}
                      onRowSelection={setUploadClosingDocsTableSelectionModel}
                      checkboxSelection={stageStatus.isEditableByRole}
                      dealId={dealData.id}
                    />
                  </Col>
                )}
              </Row>
              {(files.length === 0 && !stageStatus.isEditableByRole) && (
                <Row className="mt-lg-5">
                  <Col>
                    <h4 style={{ textAlign: 'center' }}>There are no documents uploaded.</h4>
                  </Col>
                </Row>
              )}
              <Row
                className="w-100"
                style={{
                  marginTop: '20px',
                }}
              >
                <Col>
                  <div ref={companyInfoRef}>
                    <CompanyInfo
                      companyInfo={companyInfo}
                      setCompanyInfo={setCompanyInfo}
                      setErrors={setCompanyInfoComponentErrors}
                      validationObject={companyInfoValidationObject}
                      errors={companyInfoComponentErrors}
                    />
                  </div>
                </Col>
              </Row>
              <Row>
                {(stageStatus.isEditableByRole && !stageStatus.isCompleted) ? (
                  <Col>
                    <Stack
                      direction="row"
                      justifyContent="space-between"
                      alignItems="center"
                      spacing={2}
                    >
                      <LoadingButton
                        variant="outlined"
                        color="secondary"
                        disabled={isSaving}
                        loading={isSaving}
                        onClick={() => saveChanges()}
                      >
                        Save
                      </LoadingButton>
                      <LoadingButton
                        variant="contained"
                        color="secondary"
                        disabled={files.length === 0 || !companyInfoFilled || isSaving}
                        loading={isSaving}
                        onClick={() => saveChanges(true)}
                      >
                        Save and Continue
                      </LoadingButton>
                    </Stack>
                  </Col>
                ) : (
                  <Col className="d-flex justify-content-end">
                    <Button
                      variant="contained"
                      color="secondary"
                      disabled={files.length === 0}
                      onClick={navigateToClosingDocsReview}
                    >
                      Continue
                    </Button>
                  </Col>
                )}
              </Row>
              <StageStatusMessage stageStatus={stageStatus} />
            </CardBody>
          )}
        </Card>
      </Container>
    </>
  );
}

export default UploadClosingDocs;
