import React, { Component, Fragment } from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import { Button } from 'reactstrap';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import CalendarBlankIcon from 'mdi-react/CalendarBlankIcon';


export default class TotalDatePicker extends Component {
  render() {
    const {
      handleChange, handleRefreshClick, selectedDate, changeDate,
    } = this.props;
    const openDate = () => {
      this.datePicker.setOpen(this.focus);
      this.focus = !this.focus;
    };
    return (
      <form className="form form-inline">
        <div className="form-row ml-auto pb-4">
          {changeDate && (
            <Fragment>
              <div className="date-picker mr-0 no-card">
                <DatePicker
                  ref={(r) => {
                    this.datePicker = r;
                  }}
                  className="form__form-group-datepicker"
                  selected={moment(selectedDate).valueOf()}
                  maxDate={new Date()}
                  onChange={date => handleChange(date)}
                  dateFormat="yyyy-MM-dd"
                  dropDownMode="select"
                  todayButton="Today"
                />
              </div>
              <button
                type="button"
                onClick={() => openDate()}
                className="form__form-group-button icon no-card"
              >
                <CalendarBlankIcon />
              </button>
            </Fragment>
          )}
          <Button
            type="button"
            outline
            className="btn icon btn-sm mb-0 px-3 mx-2"
            onClick={handleRefreshClick}
          >
            <span className="lnr lnr-redo" />
          </Button>
        </div>
      </form>
    );
  }
}

TotalDatePicker.propTypes = {
  handleChange: PropTypes.func.isRequired,
  handleRefreshClick: PropTypes.func.isRequired,
  selectedDate: PropTypes.string.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  changeDate: PropTypes.any,
};

TotalDatePicker.defaultProps = {
  changeDate: undefined,
};
