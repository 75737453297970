import { useOktaAuth } from '@okta/okta-react';
import { AuthStatus, Token, User } from '@alumni-ventures-group/av-okta-auth';

function logout() {
  const { oktaAuth } = useOktaAuth();
  const dashboardPath = 'https://alumniventuresgroup.okta.com/';

  try {
    const userCache = new User();
    const tokenCache = new Token();
    const authStatusCache = new AuthStatus();
    userCache.clear();
    tokenCache.clear();
    authStatusCache.clear();
    oktaAuth.tokenManager.clear();
    window.location.href = dashboardPath;
    console.log('User logged out');
  } catch (err) {
    console.error(err);
    throw err;
  }
}

function UserLogout() {
  logout();
  return '';
}

export default UserLogout;
