import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import TableHead from '@material-ui/core/TableHead';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import Checkbox from '@material-ui/core/Checkbox';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import InfoCircleIcon from 'mdi-react/InfoCircleIcon';

class MatTableHead extends PureComponent {
  static propTypes = {
    numSelected: PropTypes.number.isRequired,
    onRequestSort: PropTypes.func.isRequired,
    onSelectAllClick: PropTypes.func.isRequired,
    order: PropTypes.string.isRequired,
    orderBy: PropTypes.string.isRequired,
    rowCount: PropTypes.number.isRequired,
    // eslint-disable-next-line react/forbid-prop-types
    rows: PropTypes.array.isRequired,
    selectable: PropTypes.bool.isRequired,
    wrapHeaders: PropTypes.bool.isRequired,
    // eslint-disable-next-line react/forbid-prop-types
    customStyling: PropTypes.object,
  };

  static defaultProps = {
    customStyling: null,
  }

  createSortHandler = property => (event) => {
    const { onRequestSort } = this.props;
    onRequestSort(event, property);
  };

  render() {
    const {
      onSelectAllClick, order, orderBy, numSelected, rowCount, rows, selectable, wrapHeaders, customStyling,
    } = this.props;

    const calculateWhitespace = () => {
      if (wrapHeaders) {
        return 'material-table__wrap-text';
      }
      return '';
    };

    return (
      <TableHead>
        <TableRow>
          {selectable && (
            <TableCell padding="checkbox">
              <Checkbox
                className={
                  `material-table__checkbox ${numSelected === rowCount && 'material-table__checkbox--checked'}`
                }
                indeterminate={numSelected > 0 && numSelected < rowCount}
                checked={numSelected === rowCount}
                onChange={onSelectAllClick}
                style={customStyling && customStyling.checkboxColor ? { color: customStyling.checkboxColor } : null}
              />
            </TableCell>
          )}
          {rows.map(row => (
            <TableCell
              className={
                `material-table__cell material-table__cell--sort material-table__cell-right ${calculateWhitespace()}`
              }
              key={row.id}
              align={row.alignment ? 'left' : 'right'}
              padding={row.disablePadding ? 'none' : 'normal'}
              sortDirection={orderBy === row.id ? order : false}
            >
              <TableSortLabel
                active={orderBy === row.id}
                direction={order}
                onClick={this.createSortHandler(row.id)}
                className="material-table__sort-label"
                dir="ltr"
              >
                {row.toolTip && (
                  <Tooltip title={row.toolTip}>
                    <IconButton aria-label={row.toolTip} size="small">
                      <InfoCircleIcon />
                    </IconButton>
                  </Tooltip>
                )}
                {row.label}
              </TableSortLabel>
            </TableCell>
          ), this)}
        </TableRow>
      </TableHead>
    );
  }
}

export default MatTableHead;
