/* eslint-disable react/forbid-prop-types */
import * as React from 'react';
import { withRouter } from 'react-router-dom';
import classNames from 'classnames';
import TopbarInvestor from './topbar/Topbar';
import SidebarMobile from './topbar/sidebarMobile/SidebarMobile';

function Layout(props) {
  // eslint-disable-next-line react/prop-types
  const { loading } = props;
  const [sidebar, setSidebar] = React.useState({
    show: false,
    collapse: false,
    hidden: false,
  });

  const theme = { className: 'theme-light' };
  const application = { topNavigation: '' };

  const classes = {
    layout: true,
    'layout--collapse': sidebar.collapse,
    'layout--top-navigation': application.topNavigation,
  };

  classes[theme.className] = true;

  const layoutClass = classNames(classes);
  const changeMobileSidebarVisibility = () => {
    setSidebar({ ...sidebar, show: !sidebar.show });
  };

  return (
    <div className={layoutClass}>
      <TopbarInvestor changeMobileSidebarVisibility={changeMobileSidebarVisibility} loading={loading} />
      <SidebarMobile
        sidebar={sidebar}
        changeMobileSidebarVisibility={changeMobileSidebarVisibility}
      />
    </div>
  );
}

export default withRouter(Layout);
