import React, { PureComponent } from 'react';
import { uid } from 'react-uid';
import { Tooltip } from 'reactstrap';
import PropTypes from 'prop-types';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import Checkbox from '@material-ui/core/Checkbox';

export default class FDOContactsMatTableRow extends PureComponent {
  static propTypes = {
    // eslint-disable-next-line react/forbid-prop-types
    rowData: PropTypes.object.isRequired,
    // eslint-disable-next-line react/forbid-prop-types
    columnTitles: PropTypes.array.isRequired,
    selectable: PropTypes.bool,
    handleClick: PropTypes.func.isRequired,
    isSelected: PropTypes.bool.isRequired,
  }

  static defaultProps = {
    selectable: false,
  }

  constructor(props) {
    super(props);
    this.state = {
      tooltipOpen: false,
    };
    this.toggle = this.toggle.bind(this);
  }

  toggle = () => {
    this.setState(prevState => ({
      tooltipOpen: !prevState.tooltipOpen,
    }));
  };

  handleCellClick = (event, rowData, key) => {
    if ((key === 'linkedInName' && typeof rowData.linkedInUrl === 'string')
      || (key === 'hubSpotContactName' && rowData.hubSpotContactUrl && typeof rowData.hubSpotContactUrl === 'string')) {
      // event.preventDefault();
      event.stopPropagation();
    }
  };

  tableCell = (rowData, key) => {
    switch (key) {
      case 'linkedInName':
        return (typeof rowData.linkedInUrl === 'string')
          ? (
            <TableCell
              key={uid(rowData[key] + key)}
              component={key === 'name' ? 'th' : undefined}
              scope={key === 'name' ? 'row' : undefined}
              className="material-table__cell material-table__cell-right"
              padding={rowData.disablePadding ? 'none' : 'normal'}
              align={rowData ? 'left' : 'right'}
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
              }}
            >
              {this.cellValue(rowData, key)}
            </TableCell>
          )
          : (
            <TableCell
              key={uid(rowData[key] + key)}
              component={key === 'name' ? 'th' : undefined}
              scope={key === 'name' ? 'row' : undefined}
              className="material-table__cell material-table__cell-right"
              padding={rowData.disablePadding ? 'none' : 'normal'}
              align={rowData ? 'left' : 'right'}
            >
              {this.cellValue(rowData, key)}
            </TableCell>
          );
      case 'hubSpotContactName':
        return (rowData.hubSpotContactUrl && typeof rowData.hubSpotContactUrl === 'string')
          ? (
            <TableCell
              key={uid(rowData[key] + key)}
              component={key === 'name' ? 'th' : undefined}
              scope={key === 'name' ? 'row' : undefined}
              className="material-table__cell material-table__cell-right"
              padding={rowData.disablePadding ? 'none' : 'normal'}
              align={rowData ? 'left' : 'right'}
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
              }}
            >
              {this.cellValue(rowData, key)}
            </TableCell>
          )
          : (
            <TableCell
              key={uid(rowData[key] + key)}
              component={key === 'name' ? 'th' : undefined}
              scope={key === 'name' ? 'row' : undefined}
              className="material-table__cell material-table__cell-right"
              padding={rowData.disablePadding ? 'none' : 'normal'}
              align={rowData ? 'left' : 'right'}
            >
              {this.cellValue(rowData, key)}
            </TableCell>
          );
      default:
        return (
          <TableCell
            key={uid(rowData[key] + key)}
            component={key === 'name' ? 'th' : undefined}
            scope={key === 'name' ? 'row' : undefined}
            className="material-table__cell material-table__cell-right"
            padding={rowData.disablePadding ? 'none' : 'normal'}
            align={rowData ? 'left' : 'right'}
          >
            {this.cellValue(rowData, key)}
          </TableCell>
        );
    }
  };

  cellValue = (rowData, key) => {
    switch (key) {
      case 'linkedInName':
        return (typeof rowData.linkedInUrl === 'string')
          ? (
            <a
              href={rowData.linkedInUrl}
              rel="noopener noreferrer"
              target="_blank"
              className="avg-color fdo-text-bold-sm"
              title={`View profile of ${rowData.linkedInName} on LinkedIn`}
            >
              {rowData.linkedInName}
            </a>
          )
          : rowData.linkedInName;
      case 'hubSpotContactName':
        return (rowData.hubSpotContactUrl && typeof rowData.hubSpotContactUrl === 'string')
          ? (
            <a
              href={rowData.hubSpotContactUrl}
              rel="noopener noreferrer"
              target="_blank"
              className="avg-color fdo-text-bold-sm"
              title={`View profile of ${rowData.hubSpotContactName} on HubSpot`}
            >
              {rowData.hubSpotContactName}
            </a>
          )
          : '';
      default:
        return rowData[key];
    }
  };

  render() {
    const {
      columnTitles,
      selectable,
      rowData,
      isSelected,
      handleClick,
    } = this.props;
    const { tooltipOpen } = this.state;
    const containerId = `row-container-${rowData.id}`;
    let className = 'material-table__row';

    if ((rowData.duplicates && rowData.duplicates.length > 1) || (rowData.alreadyOnHubSpot === true)) {
      let tooltipContent = 'This is a shared contact.';
      className = `${className} row-duplicate-contact`;

      if (rowData.duplicates && rowData.duplicates.length > 1) {
        const tooltipContentBaseDuplicate = 'This contact is shared between ';
        const userNames = [];
        rowData.duplicates.forEach((user) => {
          if (user.name) {
            userNames.push(user.name);
          }
        });

        if (userNames.length > 1) {
          switch (userNames.length) {
            case 2:
              tooltipContent = tooltipContentBaseDuplicate
                .concat(`${userNames[0]} and ${userNames[1]}.`);
              break;
            case 3:
              tooltipContent = tooltipContentBaseDuplicate
                .concat(`${userNames[0]}, ${userNames[1]} and ${userNames[2]}.`);
              break;
            default:
              tooltipContent = tooltipContentBaseDuplicate
                .concat(`${userNames[0]}, ${userNames[1]} and ${userNames.length - 2} others.`);
          }
        }
      } else if (rowData.alreadyOnHubSpot) {
        tooltipContent = 'This contact is already in HubSpot';
      }

      return (
        <TableRow
          id={containerId}
          className={className}
          role="checkbox"
          onClick={event => handleClick(event, rowData.id)}
          aria-checked={isSelected}
          tabIndex={-1}
          key={uid(rowData)}
          selected={isSelected}
        >
          {selectable && (
            <TableCell className="material-table__cell" padding="checkbox">
              <Checkbox checked={isSelected} className="material-table__checkbox" />
            </TableCell>
          )}
          {columnTitles.map(col => (
            <TableCell
              key={uid(rowData[col.id] + col.id)}
              component={col.id === 'name' ? 'th' : undefined}
              scope={col.id === 'name' ? 'row' : undefined}
              className="material-table__cell material-table__cell-right"
              padding={col.disablePadding ? 'none' : 'normal'}
              align={col.alignment ? 'left' : 'right'}
              onClick={(e) => { this.handleCellClick(e, rowData, col.id); }}
            >
              {this.cellValue(rowData, col.id)}
            </TableCell>
          ))}
          <Tooltip
            placement="top"
            isOpen={tooltipOpen}
            target={containerId}
            toggle={this.toggle}
          >
            {tooltipContent}
          </Tooltip>
        </TableRow>
      );
    }

    return (
      <TableRow
        className={className}
        role="checkbox"
        onClick={event => handleClick(event, rowData.id)}
        aria-checked={isSelected}
        tabIndex={-1}
        selected={isSelected}
      >
        {selectable && (
          <TableCell className="material-table__cell" padding="checkbox">
            <Checkbox checked={isSelected} className="material-table__checkbox" />
          </TableCell>
        )}
        {columnTitles.map(col => (
          <TableCell
            key={uid(rowData[col.id] + col.id)}
            component={col.id === 'name' ? 'th' : undefined}
            scope={col.id === 'name' ? 'row' : undefined}
            className="material-table__cell material-table__cell-right"
            padding={col.disablePadding ? 'none' : 'normal'}
            align={col.alignment ? 'left' : 'right'}
          >
            {this.cellValue(rowData, col.id)}
          </TableCell>
        ))}
      </TableRow>
    );
  }
}
