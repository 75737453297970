export default class WebsocketService {
    ws;

    /** *
 * singleton.
 * reconnect if there is no existing connection
 * or connection is closed
 */
    constructor(url, onOpenCallback, onCloseCallback, dataCallback) {
      if (this.ws === undefined
        || this.ws.readyState === WebSocket.CLOSED) {
        this.ws = new WebSocket(url);
        this.removeMessageEventListener(dataCallback);
        this.addMessageEventListener(dataCallback);
        this.removeErrorEventListener();
        this.addErrorEventListener();
        this.removeOpenEventListener(onOpenCallback);
        this.addOpenEventListener(onOpenCallback);
        this.removeCloseEventListener(onCloseCallback);
        this.addCloseEventListener(onCloseCallback);
      }
    }

    get isConnected() {
      return this.ws !== undefined && this.ws.readyState === WebSocket.OPEN;
    }

    onOpen = (event) => {
      console.log('websocket opened: ', event);
    };

    onError = (event) => {
      console.log('event: ', event);
    };

    subscribeDeal = (dealId) => {
      if (this.isConnected) {
        this.ws.send(JSON.stringify({
          action: 'subscribeDeal',
          dealId,
        }));
      }
    };

    sendDealNotification = (dealId, message) => {
      if (this.isConnected) {
        this.ws.send(JSON.stringify({
          action: 'sendDealNotification',
          dealId,
          message,
        }));
      }
    };

    addCloseEventListener = (onCloseCallback) => {
      this.ws.addEventListener('close', onCloseCallback);
    };

    removeCloseEventListener = (onCloseCallback) => {
      this.ws.removeEventListener('close', onCloseCallback);
    };

    addMessageEventListener = (dataCallback) => {
      this.ws.addEventListener('message', dataCallback);
    };

    removeMessageEventListener = (dataCallback) => {
      this.ws.removeEventListener('message', dataCallback);
    };

    addErrorEventListener = () => {
      this.ws.addEventListener('error', this.onError);
    };

    removeErrorEventListener = () => {
      this.ws.removeEventListener('error', this.onError);
    };

    addOpenEventListener = (onOpenCallback) => {
      this.ws.addEventListener('open', onOpenCallback);
    };

    removeOpenEventListener = (onOpenCallback) => {
      this.ws.removeEventListener('open', onOpenCallback);
    };

    disconnect = () => {
      if (this.isConnected) {
        this.ws.close();
      }
    };
}
