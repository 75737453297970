import React, { useState } from 'react';
import { Helmet } from 'react-helmet';
import {
  Container, Row, Card, CardBody, Col,
} from 'reactstrap';
import { useHistory } from 'react-router-dom';
import DealsSearchBar from '../shared/components/PendingInvestmentsSearchBar';
import AvDataGrid from '../shared/components/AvDataGrid';
import useDeals from '../shared/hooks/usePendingInvestments';
import createDealsTableDefinition from './DealsDashboardTableDefinition';
import useFilteredBySearch from '../shared/hooks/useFilteredBySearch';
import sortByDate from '../shared/utils/sortAsDate';
import { cancellationReasons } from '../VerifyWireInfo';

const isStageCompleted = (stageMetadata) => !!stageMetadata.confirmationProperty;

const validWireSignOffRejectionReason = {
  'Incomplete Signed Deal Docs': (stagesCompleted) => (
    stagesCompleted.signedDealDocumentsUpload === false
    || stagesCompleted.wireSignOff === false),
  'Incomplete Legal Docs': (stagesCompleted) => (
    stagesCompleted.closingDocsReview === false
    || stagesCompleted.wireSignOff === false),
  'Incorrect Wire Amount': (stagesCompleted) => (
    stagesCompleted.uploadWireInfo === false
    || stagesCompleted.verifyWireInfo === false
    || stagesCompleted.wireSignOff === false),
  // Cancelled Deal archives the deal so the deal should never show under this rejection reason
};

const isOther = (rejectionReason) => cancellationReasons.every((cr) => cr !== rejectionReason);

const getStatus = (dealData) => {
  let rejectionReason = null;

  if (dealData.wireVerificationInfo
    && dealData.wireVerificationInfo.rejectionReason
    && (dealData.stagesCompleted.verifyWireInfo === false
      || dealData.stagesCompleted.uploadWireInfo === false)) {
    rejectionReason = isOther(dealData.wireVerificationInfo.rejectionReason)
      ? 'Other' : dealData.wireVerificationInfo.rejectionReason;
  }

  const wireSignOffRejectionReason = !!validWireSignOffRejectionReason[dealData.wireSignOffRejectionReason]
                                    && validWireSignOffRejectionReason[dealData.wireSignOffRejectionReason](dealData.stagesCompleted)
    ? dealData.wireSignOffRejectionReason
    : null;

  const rReason = rejectionReason || wireSignOffRejectionReason;
  return rReason ? `Rejected: ${rReason}` : '';
};

const sortCompletedStages = (stagesCompleted) => stagesCompleted.filter((stage) => isStageCompleted(stage))
  .sort((item1, item2) => sortByDate(item2.confirmationProperty, item1.confirmationProperty));

const isNonEmptyArray = (array) => array && array.length > 0;

const getLastCompletedStage = (stagesCompleted) => {
  const completedStages = sortCompletedStages(stagesCompleted);

  return isNonEmptyArray(completedStages) && completedStages[0] ? completedStages[0].title : null;
};

const getLastCompletedStageDate = (stagesCompleted) => {
  const completedStages = sortCompletedStages(stagesCompleted);

  return isNonEmptyArray(completedStages) && completedStages[0] ? completedStages[0].confirmationProperty : null;
};

const createTableData = (dealData) => {
  const tableData = [];
  if (dealData) {
    for (const investment of dealData) {
      tableData.push({
        id: investment.id,
        companyName: investment.companyName,
        dateCreated: investment.dateCreated,
        status: getStatus(investment),
        lastCompletedStage: getLastCompletedStage(investment.stagesData) || 'None',
        lastCompletedStageDate: getLastCompletedStageDate(investment.stagesData),
      });
    }
  }
  return tableData;
};

function LandingPage() {
  const [loadingDeals, setDealsLoading] = useState(true);
  const deals = useDeals(setDealsLoading);
  const dealsTableDef = createDealsTableDefinition(useHistory());
  const [companyNameSearchValue, setCompanyNameSearchValue] = useState('');
  const [pageSize, setPageSize] = useState(10);
  const filteredDeals = createTableData(useFilteredBySearch(deals, companyNameSearchValue));

  return (
    <>
      <Helmet>
        <title>Dashboard - Deal Portal</title>
      </Helmet>
      <Container>
        <Card>
          <CardBody>
            <Row>
              <Col>
                <h2>Deals Dashboard</h2>
                <div style={{ marginTop: '20px' }}>
                  <DealsSearchBar
                    placeholder="Search by company name"
                    onChange={(e) => setCompanyNameSearchValue(e.target.value)}
                    value={companyNameSearchValue}
                    disabled={loadingDeals}
                    fullWidth
                  />
                </div>
                <div style={{ marginTop: '20px' }}>
                  <AvDataGrid
                    pageSize={pageSize}
                    setPageSize={setPageSize}
                    rows={filteredDeals}
                    columns={dealsTableDef}
                    loading={loadingDeals}
                    initialState={{
                      sorting: {
                        sortModel: [
                          { field: 'status', sort: 'desc' },
                        ],
                      },
                    }}
                  />
                </div>
              </Col>

            </Row>
          </CardBody>
        </Card>
      </Container>
    </>

  );
}

export default LandingPage;
