import React from 'react';
import { Helmet } from 'react-helmet';
import {
  Grid, Container,
} from '@mui/material';
import { Card, CardBody } from 'reactstrap';
import RowRadioButtonsGroup from '../shared/components/RowRadioButtonsGroup';
import LinkButton from '../shared/components/LinkButton';

export default function DataRecPage() {
  const values = [{
    label: 'Company Name',
    verticaValue: 'Caspersky',
    dynamoValue: 'Casperski',
  },
  {
    label: 'Date',
    verticaValue: '12/04/2021',
    dynamoValue: '15/05/2020',
  },
  {
    label: 'Value',
    verticaValue: '$300,000',
    dynamoValue: '$400,000',
  }];

  return (
    <>
      <Helmet>
        <title> Data Reconciliation Page </title>
      </Helmet>
      <Container maxWidth="md">
        <Card>
          <CardBody sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            <h4> We found out of sync data in your record, please select the correct options in the list below: </h4>
            {values && values.map((val) => (
              <RowRadioButtonsGroup key={val.label} label={val.label} valueOne={val.verticaValue} valueTwo={val.dynamoValue} />
            ))}
            <Grid
              container
              direction="row"
              alignItems="flex-end"
              justifyContent="space-between"
              sx={{ mt: 2 }}
            >
              <LinkButton to="/" variant="outlined">Cancel</LinkButton>
              <LinkButton to="/pending-investment/:id" variant="contained">Submit</LinkButton>
            </Grid>
          </CardBody>
        </Card>
      </Container>
    </>
  );
}
