import { styled } from '@mui/material/styles';
import TextField from '@mui/material/TextField';

const AvTextField = styled(TextField)(
  ({ theme }) => `
    margin-top: ${theme.spacing(2)};
    width: 100%;
  `,
);

export default AvTextField;
