import React from 'react';
import PropTypes from 'prop-types';
import Backdrop from '@material-ui/core/Backdrop';
import Button from '@material-ui/core/Button';
import Fade from '@material-ui/core/Fade';
import Grid from '@material-ui/core/Grid';
import Modal from '@material-ui/core/Modal';
import makeStyles from '@material-ui/core/styles/makeStyles';

const useStyles = makeStyles(theme => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    color: '#646777',
    '& h2': {
      marginTop: 20,
    },
    '& p': {
      fontSize: 16,
      marginBottom: 20,
      marginTop: 20,
    },
  },
}));

const MaterialModal = ({
  id, title, body, open, close, confirm, confirmText, cancelText, disabled,
}) => {
  const classes = useStyles();

  return (
    <Modal
      aria-labelledby={`${id}-title`}
      aria-describedby={`${id}-description`}
      className={classes.modal}
      open={open}
      onClose={close}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={open}>
        <div className={classes.paper}>
          <h2 id={`${id}-title`}>{title || 'Are You Sure?'}</h2>
          <p id={`${id}-description`}>
            {body}
          </p>
          <Grid
            container
            direction="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <Button
              variant="contained"
              type="button"
              size="medium"
              // color=""
              className={classes.margin}
              onClick={close}
              id={`${id}-cancel`}
            >
              {cancelText || 'No'}
            </Button>
            <Button
              variant="contained"
              type="button"
              size="medium"
              color="primary"
              className={classes.margin}
              onClick={confirm}
              id={`${id}-confirm`}
              disabled={disabled}
            >
              {confirmText || 'Yes'}
            </Button>
          </Grid>
        </div>
      </Fade>
    </Modal>
  );
};

MaterialModal.propTypes = {
  id: PropTypes.string,
  title: PropTypes.string,
  body: PropTypes.string.isRequired,
  open: PropTypes.bool,
  close: PropTypes.func.isRequired,
  confirm: PropTypes.func.isRequired,
  confirmText: PropTypes.string,
  cancelText: PropTypes.string,
  disabled: PropTypes.bool,
};

MaterialModal.defaultProps = {
  id: 'transition-modal',
  title: null,
  open: false,
  confirmText: null,
  cancelText: null,
  disabled: false,
};

export default MaterialModal;
