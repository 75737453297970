import { showSnackbarError } from './snackbarActions';

export const REQUEST_ALL_DICTIONARY = 'REQUEST_ALL_DICTIONARY';
export const RECIVE_ALL_DICTIONARY = 'RECIVE_ALL_DICTIONARY';
export const RECIVE_ALL_DICTIONARY_ERROR = 'RECIVE_ALL_DICTIONARY_ERROR';

export function requestAllDictionary() {
  return {
    type: REQUEST_ALL_DICTIONARY,
    error: null,
  };
}

export function receiveAllDictionaryError(error) {
  return {
    type: RECIVE_ALL_DICTIONARY_ERROR,
    error,
  };
}

export function receiveAllDictionary(items) {
  return {
    type: RECIVE_ALL_DICTIONARY,
    items,
    receivedAt: Date.now(),
    error: null,
  };
}

function fetchAllDictionaryItems(auth) {
  return (dispatch) => {
    dispatch(requestAllDictionary());
    const { api } = auth;
    const path = 'api/v2.0/dictionary/get-full-dictionary';

    return api.get(path)
      .then((data) => {
        if (data && data.items) {
          dispatch(receiveAllDictionary(data.items));
        } else {
          dispatch(showSnackbarError(data.message ? data.message : 'Unable to load Dictionary'));
          dispatch(receiveAllDictionaryError(data.message ? data.message : 'Unable to load Dictionary'));
        }
      });
  };
}

function shouldFetchDictionary(state) {
  const { items } = state;
  if (!items) {
    return true;
  }
  if (items.isFetching) {
    return false;
  }
  return items;
}
export function fetchAllDictionaryItemsIfNeeded() {
  // eslint-disable-next-line consistent-return
  return (dispatch, getState) => {
    const { auth } = getState();
    if (shouldFetchDictionary(getState())) {
      return dispatch(fetchAllDictionaryItems(auth));
    }
  };
}
