import components from './shared/components';
import recaptchaConfig from './shared/config/recaptchaConfig';
import helperFunctions from './shared/helpers';
import hooks from './shared/hooks';
import models from './shared/models';
import {
  SidebarProps,
  ThemeProps,
  TotalsFundsProps,
  AllFundsProps,
} from './shared/prop-types/ReducerProps';
import services from './shared/services';
import store from './redux/store/store';
import rootReducer from './redux/reducers';
import {
  applicationActions,
  authActions,
  errorActions,
  fundTrackerActions,
  fundsTotalActions,
  getCompaniesActions,
  getCurrentUserActions,
  getDictionaryActions,
  getFundsActions,
  getInvestmentActions,
  getInvestorsActions,
  getPortfolioUpdatesActions,
  liUserActions,
  modalActions,
  portalActions,
  sidebarActions,
  snackbarActions,
  themeActions,
  queryStringActions,
  managedUserActions,
  dashboardLabelActions,
} from './redux/actions';
import api from './redux/api/api';

import './scss/app.scss';

const config = {
  recaptchaConfig,
};

const propTypes = {
  SidebarProps,
  ThemeProps,
  TotalsFundsProps,
  AllFundsProps,
};

const actions = {
  applicationActions,
  authActions,
  errorActions,
  fundTrackerActions,
  fundsTotalActions,
  getCompaniesActions,
  getCurrentUserActions,
  getDictionaryActions,
  getFundsActions,
  getInvestmentActions,
  getInvestorsActions,
  getPortfolioUpdatesActions,
  liUserActions,
  modalActions,
  portalActions,
  sidebarActions,
  snackbarActions,
  themeActions,
  queryStringActions,
  managedUserActions,
  dashboardLabelActions,
};

export {
  actions,
  api,
  components,
  config,
  helperFunctions,
  hooks,
  models,
  propTypes,
  rootReducer,
  services,
  store,
};
