
export default class CurrentFutureSyndication {
  constructor(syndication) {
    this.id = {
      label: 'ID',
      type: null,
      readOnly: true,
      value: syndication.id,
    };
    this.fk_fund_id = {
      label: 'ID',
      type: null,
      readOnly: true,
      value: syndication.fk_fund_id,
    };
    this.synd_name = {
      label: 'Syndication Name',
      type: 'text',
      readOnly: false,
      value: syndication.synd_name,
    };
    this.start_date = {
      label: 'Start Date',
      type: 'date',
      readOnly: false,
      value: syndication.start_date,
    };
    this.close_date = {
      label: 'Close Date',
      type: 'date',
      readOnly: false,
      value: syndication.close_date,
    };
  }
}
