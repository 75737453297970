// import { showSnackbarError } from './snackbarActions';
import { getUsersForSearch } from '../../shared/services/bifService';
import { formatInvestorSearchArray } from '../../shared/services/investorService';

export const REQUEST_ALL_INVESTORS = 'REQUEST_ALL_INVESTORS';
export const RECEIVE_ALL_INVESTORS = 'RECEIVE_ALL_INVESTORS';
export const RECEIVE_ALL_INVESTORS_ERROR = 'RECEIVE_ALL_INVESTORS_ERROR';


export function requestAllUsers() {
  return {
    type: REQUEST_ALL_INVESTORS,
    error: null,
  };
}

export function requestAllUsersError(error) {
  return {
    type: RECEIVE_ALL_INVESTORS_ERROR,
    error,
  };
}

export function receiveallUsers(json) {
  return {
    type: RECEIVE_ALL_INVESTORS,
    investors: json,
    receivedAt: Date.now(),
    error: null,
  };
}

function fetchAllUsers(investorsOnly) {
  return async (dispatch) => {
    dispatch(requestAllUsers());
    const res = await getUsersForSearch(investorsOnly);
    return dispatch(receiveallUsers(formatInvestorSearchArray(res)));
  };
}

function shouldfetchAllUsers(state) {
  const { investors } = state;
  if (investors.investorArray.length === 0) {
    return true;
  }

  return false;
}

export function fetchAllUsersIfNeeded(investorsOnly = false) {
  // eslint-disable-next-line consistent-return
  return (dispatch, getState) => {
    const { auth } = getState();
    if (shouldfetchAllUsers(getState())) {
      return dispatch(fetchAllUsers(investorsOnly, auth));
    }
  };
}
