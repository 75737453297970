import React, { useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { WebSocketContext } from '../WebsocketContext';
import ConfirmationModal from '../ConfirmationModal';

export default function Websocket() {
  const history = useHistory();
  const { wsData: modalData = {}, setWsData } = useContext(WebSocketContext);

  const webSocketOnConfirmClickHandler = (eventType, key) => {
    setWsData({ [key]: { isOpen: false } });
    switch (eventType) {
      case 'POST_PENDING_INVESTMENT':
        window.location.reload();
        break;
      case 'POST_PENDING_INVESTMENT_NETSUITE':
        history.push('/');
        break;
      default:
    }
  };

  for (const key in modalData) {
    const wssEvent = modalData[key];
    if (wssEvent && wssEvent.eventType === 'POST_PUBLISH_DUE_DILIGENCE') {
      if (window.dueDiligenceReportPublicationRequested && wssEvent.message.redirectUrl) {
        delete window.dueDiligenceReportPublicationRequested;
        window.location.href = wssEvent.message.redirectUrl;
      }
      return null;
    }
  }

  if (modalData === {}) return null;
  return Object.keys(modalData).map((key) => (modalData[key]) && (
    <ConfirmationModal
      key={key}
      isOpen={modalData[key].isOpen}
      headerText={modalData[key].status ? 'Deal Updated' : 'Error'}
      bodyText={`${modalData[key].status ? modalData[key].message : modalData[key].error}`}
      onConfirm={() => {
        const eventType = modalData[key].eventType ? modalData[key].eventType : '';
        webSocketOnConfirmClickHandler(eventType, key);
      }}
    />
  ));
}
