export default function roundToPrecision(num, decimalPlaces = 5) {
  let negative = false;
  let value = num;
  if (value < 0) {
    negative = true;
    value *= -1;
  }
  const multiplicator = 10 ** decimalPlaces; // 10^decimalPlaces
  value = parseFloat((value * multiplicator).toFixed(11)); // 11 being the max supported reg js number decimal places
  value = (Math.round(value) / multiplicator).toFixed(decimalPlaces);
  if (negative) {
    value = (value * -1).toFixed(decimalPlaces);
  }
  return value;
}
