import { useState, useEffect } from 'react';
import { getPendingInvestments } from '../services/apiGateway';

function usePendingInvestments(setLoading) {
  const [pendingInvestments, setPendingInvestments] = useState([]);

  useEffect(() => {
    const abortController = new AbortController();
    let ignore = false;

    async function fetchData() {
      const response = await getPendingInvestments();
      setPendingInvestments(response);
      if (setLoading) {
        setLoading(false);
      }
    }

    fetchData();

    return () => {
      ignore = true;
      abortController.abort();
    };
  }, []);

  return pendingInvestments;
}

export default usePendingInvestments;
