import { RESET_ERROR_MESSAGE, ADD_ERROR_MESSAGE } from '../actions/errorActions';
// Updates error message to notify about the failed fetches.
export default function (state = null, action) {
  switch (action.type) {
    case ADD_ERROR_MESSAGE:
      return action.error;
    case RESET_ERROR_MESSAGE:
      return null;
    default:
      return state;
  }
}
