import moment from 'moment';

const DATE_FORMATS = ['MM/DD/YYYY', 'MM-DD-YYYY'];

export default (sortColumn, sortDirection) => (a, b) => {
  if (Number(a[sortColumn]) >= 0 && Number(b[sortColumn]) >= 0) {
    if (sortDirection === 'ASC' || sortDirection === 'asc') {
      return a[sortColumn] - b[sortColumn];
    }
    return b[sortColumn] - a[sortColumn];
  }

  if ((a[sortColumn] && b[sortColumn]) && (a[sortColumn].indexOf('$') !== -1 && b[sortColumn].indexOf('$') !== -1)) {
    const aVal = Number(a[sortColumn].replace(/[^0-9.-]+/g, ''));
    const bVal = Number(b[sortColumn].replace(/[^0-9.-]+/g, ''));
    if (sortDirection === 'ASC' || sortDirection === 'asc') {
      return aVal - bVal;
    }
    return bVal - aVal;
  }

  const isDateA = moment(a[sortColumn], DATE_FORMATS);
  const isDateB = moment(b[sortColumn], DATE_FORMATS);
  if (isDateA.isValid() && isDateB.isValid()) {
    if (sortDirection.toLowerCase() === 'asc') {
      return isDateA < isDateB ? 1 : -1;
    }

    return isDateA > isDateB ? 1 : -1;
  }

  const aVal = typeof a[sortColumn] === 'string' ? a[sortColumn].toLowerCase() : a[sortColumn];
  const bVal = typeof b[sortColumn] === 'string' ? b[sortColumn].toLowerCase() : b[sortColumn];
  if (sortDirection === 'ASC' || sortDirection === 'asc') {
    return aVal > bVal ? 1 : -1;
  }
  return aVal < bVal ? 1 : -1;
};
