export const SET_APPLICATION_ADMIN = 'SET_APPLICATION_ADMIN';
export const SET_APPLICATION_FDO = 'SET_APPLICATION_FDO';
export const SET_APPLICATION_INVESTOR = 'SET_APPLICATION_INVESTOR';

export function setApplicationAdmin() {
  return {
    type: SET_APPLICATION_ADMIN,
  };
}

export function setApplicationFdo() {
  return {
    type: SET_APPLICATION_FDO,
  };
}

export function setApplicationInvestor() {
  return {
    type: SET_APPLICATION_INVESTOR,
  };
}
