import React from 'react';
import TextField from '@mui/material/TextField';

function ReadOnlyTextField(props) {
  return (
    <TextField
      label=""
      InputProps={{ readOnly: true }}
      variant="standard"
      disabled
      value=""
      sx={{
        '& .MuiFormLabel-root.Mui-disabled': {
          color: 'rgba(0, 0, 0, 0.6)', // (default alpha is 0.38)
        },
        '& .MuiInputBase-input.Mui-disabled': {
          WebkitTextFillColor: 'rgba(0, 0, 0, 0.6)',
        },
        margin: '10px 0 10px 0',
      }}
      {...props}
    />
  );
}

export default ReadOnlyTextField;
