import {
  REQUEST_CURRENT_USER,
  RECEIVE_CURRENT_USER,
} from '../actions/getCurrentUserActions';

export default function (state = {
  isFetching: false,
  user: {},
}, action) {
  switch (action.type) {
    case REQUEST_CURRENT_USER:
      return Object.assign({}, state, {
        isFetching: true,
      });
    case RECEIVE_CURRENT_USER:
      return Object.assign({}, state, {
        isFetching: false,
        user: action.user,
        lastUpdated: action.receivedAt,
      });
    default:
      return state;
  }
}
