const clubMemberRoleOptions = [
  { label: 'President', value: 0 },
  { label: 'Vice President', value: 7 },
  { label: 'Managing Partner', value: 10 },
  { label: 'Investing Partner', value: 14 },
  { label: 'Partner', value: 15 },
  { label: 'Senior Principal', value: 13 },
  { label: 'Principal', value: 12 },
  { label: 'Analyst', value: 11 },
  { label: 'Senior Partner', value: 9 },
  { label: 'General Manager', value: 16 },
  { label: 'Investor Relations Manager', value: 8 },
  { label: 'Member', value: 1 },
  { label: 'Pending', value: 2 },
  { label: 'Founding Member', value: 3 },
  { label: 'Associate Member', value: 4 },
  { label: 'Advisor', value: 5 },
  { label: 'Trial Member', value: 6 },
];

export default clubMemberRoleOptions;
