export const signatureBlock = {
  name: 'Mark Edwards',
  title: 'Trustee',
  company: 'Alumni Ventures Group',
  address1: '70 Federal Street',
  address2: '6th Floor',
  city: 'Boston',
  state: 'MA',
  zip: '02110',
  email: 'cio@av.vc',
};

export const approvalProcesses = [
  'Core',
  'Turbo',
  'Follow-On',
  'SAP',
];

export const getName = (deal) => {
  // If deal has company name on the root level return company name
  // else get the name from the hsDealObj.
  // If hsDealObj doesn't have it either, return an empty string.
  if (deal && deal.companyName) {
    return deal.companyName;
  }

  if (deal
    && deal.hsDealObj
    && deal.hsDealObj.company) {
    return deal.hsDealObj.company.name || '';
  }

  return '';
};

export const getSponsorTeam = (deal) => {
  let sponsorTeam;
  if (deal) {
    if (deal.sponsorTeam) {
      sponsorTeam = deal.sponsorTeam;
    } else if (deal.hsDealObj && deal.hsDealObj.sponsorTeam && deal.hsDealObj.sponsorTeam.name) {
      sponsorTeam = deal.hsDealObj.sponsorTeam.name;
    }
  }

  return sponsorTeam || '';
};

export const validateForm = (initialErrorState, setErrors, formData) => {
  const {
    approvalProcess, finalDealScore, dateDealApproved, investmentEntityName,
  } = formData;
  const newErrors = { ...initialErrorState };
  if (!approvalProcess) {
    newErrors.approvalProcess = 'This field is required';
  }

  if (!finalDealScore) {
    newErrors.finalDealScore = 'This field is required';
  }

  if (!dateDealApproved) {
    newErrors.dateDealApproved = 'This field is required';
  }

  if (!investmentEntityName) {
    newErrors.investmentEntityName = 'This field is required';
  }

  setErrors(newErrors);
  return newErrors;
};
