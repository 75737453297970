import React from 'react';
import PropTypes from 'prop-types';

const style = {
  maxWidth: '28px',
  position: 'relative',
  top: '-4px',
};
const FundIcon = ({ url }) => (
  <span className="media-left mr-2">
    <img
      src={`https://avg-wordpress-prod.s3.amazonaws.com/uploads/${url}.png`}
      alt=""
      style={style}
    />
  </span>
);

FundIcon.propTypes = {
  url: PropTypes.string.isRequired,
};

export default FundIcon;
