/* eslint-disable no-param-reassign */
import React, { useState, useEffect, useCallback } from 'react';
import { Helmet } from 'react-helmet';
import {
  Container, Row, Card, CardBody, Col,
} from 'reactstrap';
import { v4 } from 'uuid';
import { useGridApiRef } from '@mui/x-data-grid-pro';
import {
  Box, InputLabel, MenuItem, TextField, FormControl, Grid,
} from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import Select from '@mui/material/Select';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { useHistory, useParams } from 'react-router-dom';
import InputAdornment from '@mui/material/InputAdornment';
import { User } from '@alumni-ventures-group/av-okta-auth';
import usePendingInvestmentDetail from '../shared/hooks/usePendingInvestmentDetail';
import createPendingInvestmentsTableDefinition from './PendingInvestmentDetailTableDefinition';
import createPendingInvestmentTotalsTableDefinition from './PendingInvestmentTotalsTableDefinition';
import AvDataGrid from '../shared/components/AvDataGrid';
import exportGridValues from '../shared/components/AvDataGrid/exportGridValues';
import HorizRecLoader from '../shared/components/Loader/HorizRecLoader';
import getNumberValue from '../shared/utils/getNumberValue';
import formatCurrencyAsUsd from '../shared/utils/formatCurrencyAsUsd';
import roundToPrecision from '../shared/utils/roundToPrecision';
import { updatePendingInvestment, submitPendingInvestment } from '../shared/services/apiGateway';
import generateRequest from '../shared/services/generateRequest';
import ConfirmationModal from '../shared/components/ConfirmationModal';

const checkValidPercentageBounds = (value) => value >= 0 && value <= 100;
const checkPositiveWholeNumber = (value) => value >= 0;
const checkNaturalNumber = (value) => value > 0;
const checkIfNullOrUndefined = (value) => value === null || value === undefined;

const preferredStockLabel = 'Preferred Stock';
const safeLabel = 'SAFE';
const convertibleNoteLabel = 'Convertible Note';
const commonStockLabel = 'Common Stock';
const publicCommonStockLabel = 'Public Common Stock';
const tokensLabel = 'Token';
const undefinedSecurityTypeLabel = 'Undetermined';

const checkIfStockSecurity = (securityTypeName) => [preferredStockLabel,
  commonStockLabel, publicCommonStockLabel].includes(securityTypeName);

const checkIfNoInterestLoanSecurity = (securityTypeName) => [safeLabel].includes(securityTypeName);

const checkIfInterestLoanSecurity = (securityTypeName) => [convertibleNoteLabel].includes(securityTypeName);

const checkIfTokenSecurity = (securityTypeName) => [tokensLabel].includes(securityTypeName);

const toggleVisibilityOfFieldsBySecurityType = (types, selectedSecurityType) => {
  const filteredSecurityType = types.filter((securityType) => securityType.id === selectedSecurityType)[0];

  const interestRateInputContainer = document.getElementById('interestRateInputContainer');
  const sharePriceInputContainer = document.getElementById('sharePriceInputContainer');
  const numberOfUnitsInputContainer = document.getElementById('numberOfUnitsInputContainer');

  if (filteredSecurityType.name === undefinedSecurityTypeLabel) {
    numberOfUnitsInputContainer.style.display = 'none';
    interestRateInputContainer.style.display = 'none';
    sharePriceInputContainer.style.display = 'none';
  } else if (checkIfStockSecurity(filteredSecurityType.name) || checkIfTokenSecurity(filteredSecurityType.name)) {
    interestRateInputContainer.style.display = 'none';
    sharePriceInputContainer.style.display = '';
    numberOfUnitsInputContainer.style.display = '';
  } else if (checkIfNoInterestLoanSecurity(filteredSecurityType.name)) {
    interestRateInputContainer.style.display = 'none';
    sharePriceInputContainer.style.display = 'none';
    numberOfUnitsInputContainer.style.display = 'none';
  } else if (checkIfInterestLoanSecurity(filteredSecurityType.name)) {
    interestRateInputContainer.style.display = '';
    sharePriceInputContainer.style.display = 'none';
    numberOfUnitsInputContainer.style.display = 'none';
  } else {
    interestRateInputContainer.style.display = '';
    sharePriceInputContainer.style.display = '';
    numberOfUnitsInputContainer.style.display = '';
  }
  return filteredSecurityType;
};

const createTempTableFundId = (fund) => `${fund.name}_${fund.investmentAmount}`;

const assignLocalEditValues = (pendingInvestment, tableFundData, otherValues) => {
  let tempPendingInvestment = JSON.parse(JSON.stringify(pendingInvestment));
  tempPendingInvestment = Object.assign(tempPendingInvestment, otherValues);
  for (const fund of tempPendingInvestment.funds) {
    for (const tableFundDataItem of tableFundData) {
      if (createTempTableFundId(fund) === tableFundDataItem.id) {
        Object.keys(fund).forEach((key) => {
          if (Object.prototype.hasOwnProperty.call(tableFundDataItem, key)) {
            fund[key] = tableFundDataItem[key];
          }
        });
        fund.qAmount = tableFundDataItem.qAllocation;
        fund.aAmount = tableFundDataItem.aAllocation;
      }
    }
  }
  return tempPendingInvestment;
};

function SecurityTypeSelector(types, onChange, securityType, userRole) {
  return (
    <FormControl style={{ minWidth: '100px' }}>
      <InputLabel id="securityTypeLabel">Security Type</InputLabel>
      <Select
        labelId="securityTypeLabel"
        id="securityTypeSelect"
        value={securityType}
        label="Security Type"
        onChange={(event) => {
          if (onChange) {
            onChange(event.target.value);
            toggleVisibilityOfFieldsBySecurityType(types, event.target.value);
          }
        }}
      >
        {types.map((item) => (
          <MenuItem key={item.id} value={item.id}>{item.name}</MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}

const prepareFundAllocationsForGrid = (funds) => {
  const formatted = [];
  for (const fund of funds) {
    formatted.push({
      id: createTempTableFundId(fund),
      name: fund.name,
      qPercent: fund.qPercent || 0,
      aPercent: fund.aPercent || 0,
      aAllocation: 0,
      qAllocation: 0,
      investmentAmount: fund.investmentAmount,
      qFundId: fund.qFundId,
      aFundId: fund.aFundId,
    });
  }
  return formatted;
};

const createFundConfigurationError = (funds) => {
  let str = null;
  for (const fund of funds) {
    if (!fund.aFundId && !fund.qFundId) {
      if (str) {
        str = 'Some funds are not configured correctly. Please contact support to enable editing of allocations and pushing to netsuite.';
        break;
      }
      str = `${fund.name} is not configured correctly. Please contact support to enable editing of allocations and pushing to netsuite.`;
    }
  }
  return str;
};

function PendingInvestmentDetailActionButton(props, label) {
  return (
    <LoadingButton
      variant="outlined"
      color="secondary"
      sx={{
        m: 1,
      }}
      {...props}
    >{label}
    </LoadingButton>
  );
}

function PendingInvestmentDetail() {
  const { id: pendingInvestmentId } = useParams();

  const breakdownDGRef = useGridApiRef();
  const [loadingPendingInvestments, setPendingInvestmentsDetailLoading] = useState(true);
  const [fundAllocations, setFundAllocations] = useState(null);
  const totalInvestmentsId = v4();
  const [avInvestmentTotals, setAvInvestmentTotals] = useState([{
    id: totalInvestmentsId,
    avInvestmentTotal: 0,
    avInvestmentATotal: 0,
    avInvestmentQTotal: 0,
  }]);
  const [wireTransferDate, setWireTransferDate] = useState(null);
  const [securityType, setSecurityType] = useState(-1);
  const [interestRate, setInterestRate] = useState(roundToPrecision(0));
  const [sharePrice, setSharePrice] = useState(roundToPrecision(1));
  const [roundNumber, setRoundNumber] = useState(1);
  const [numberOfUnits, setNumberOfUnits] = useState(0);
  const [fundConfigError, setFundConfigError] = useState(null);
  const [isPushToNSConfirmationModalOpen, setPushToNSConfirmationModalOpen] = useState(false);
  const [isReturnToOOIConfirmationModalOpen, setReturnToOOIConfirmationModalOpen] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isPushingToNS, setIsPushingToNS] = useState(false);
  const user = new User();
  const [userRole, setUserRole] = useState({
    isOOI: user.isOOI(),
    isFinance: user.isFinance(),
  });

  const history = useHistory();

  const calculateTotals = (funds) => {
    const investmentTotals = {
      id: totalInvestmentsId,
      avInvestmentTotal: 0,
      avInvestmentATotal: 0,
      avInvestmentQTotal: 0,
    };
    funds.forEach((fundAllocation) => {
      const aAllocation = getNumberValue(fundAllocation.aAllocation);
      const qAllocation = getNumberValue(fundAllocation.qAllocation);
      investmentTotals.avInvestmentTotal += (aAllocation + qAllocation);
      investmentTotals.avInvestmentATotal += (aAllocation);
      investmentTotals.avInvestmentQTotal += (qAllocation);
    });
    investmentTotals.avInvestmentTotal = formatCurrencyAsUsd(investmentTotals.avInvestmentTotal);
    investmentTotals.avInvestmentATotal = formatCurrencyAsUsd(investmentTotals.avInvestmentATotal);
    investmentTotals.avInvestmentQTotal = formatCurrencyAsUsd(investmentTotals.avInvestmentQTotal);
    return investmentTotals;
  };

  const setTotals = (funds) => {
    const investmentTotals = calculateTotals(funds);
    setAvInvestmentTotals([investmentTotals]);
    return investmentTotals;
  };

  const pendingInvestmentDetail = usePendingInvestmentDetail(pendingInvestmentId, (updatedPendingInvestments) => {
    const formattedFundAllocations = prepareFundAllocationsForGrid(updatedPendingInvestments.funds);
    setFundAllocations(formattedFundAllocations);
    setPendingInvestmentsDetailLoading(false);
  });

  const securityTypes = [
    {
      name: undefinedSecurityTypeLabel,
      id: -1,
    },
    {
      name: preferredStockLabel,
      id: 2,
    },
    {
      name: safeLabel,
      id: 4,
    },
    {
      name: convertibleNoteLabel,
      id: 3,
    },
    {
      name: commonStockLabel,
      id: 1,
    },
    {
      name: publicCommonStockLabel,
      id: 9,
    },
    {
      name: tokensLabel,
      id: 6,
    },
  ];

  const autoCalculateNumberOfUnitsforLoanTypes = (securityTypeId) => {
    const filteredSecurityType = securityTypes.filter((st) => st.id === securityTypeId)[0];
    const securityTypeName = filteredSecurityType.name;
    if (checkIfInterestLoanSecurity(securityTypeName) || checkIfNoInterestLoanSecurity(securityTypeName)) {
      if (fundAllocations && fundAllocations[0]) {
        const totals = calculateTotals(exportGridValues(breakdownDGRef.current, Object.keys(fundAllocations[0])));
        return Math.ceil(getNumberValue(totals.avInvestmentTotal));
      }
    }
    return 0;
  };

  useEffect(() => {
    if (breakdownDGRef) {
      if (fundAllocations && fundAllocations[0]) {
        setTotals(exportGridValues(breakdownDGRef.current, Object.keys(fundAllocations[0])));
      }
      if (!loadingPendingInvestments) {
        if (!pendingInvestmentDetail.verticaObj) {
          return history.push(`/pending-investment/${pendingInvestmentDetail.id}/name-verification`);
        }
        if (pendingInvestmentDetail.verticaObj && !pendingInvestmentDetail.dueDiligence) {
          return history.push(`/pending-investment/${pendingInvestmentDetail.id}/dd-report`);
        }

        if (pendingInvestmentDetail.stagesCompleted && Object.keys(pendingInvestmentDetail.stagesCompleted).length >= 1) {
          history.push('/');
        }
        toggleVisibilityOfFieldsBySecurityType(
          securityTypes,
          pendingInvestmentDetail.securityType || securityType,
        );
        if (pendingInvestmentDetail.securityType) {
          setSecurityType(Number(pendingInvestmentDetail.securityType));
        }
        if (pendingInvestmentDetail.interestRate) {
          setInterestRate(Number(pendingInvestmentDetail.interestRate));
        }
        if (pendingInvestmentDetail.sharePrice) {
          setSharePrice(Number((pendingInvestmentDetail.sharePrice)));
        }
        if (pendingInvestmentDetail.roundNumber) {
          setRoundNumber(Number(pendingInvestmentDetail.roundNumber));
        }
        if (pendingInvestmentDetail.numberOfUnits) {
          setNumberOfUnits(Number(pendingInvestmentDetail.numberOfUnits));
        }
        if (pendingInvestmentDetail.wireTransferDate) {
          setWireTransferDate(pendingInvestmentDetail.wireTransferDate);
        }
        if (pendingInvestmentDetail.processStage === 2) {
          history.push('/');
        }

        setFundConfigError(createFundConfigurationError(pendingInvestmentDetail.funds));
        return breakdownDGRef.current.subscribeEvent(
          'cellModeChange',
          (params, event) => {
            event.defaultMuiPrevented = true;
          },
          { isFirst: true },
        );
      }
    }
    return () => { };
  }, [loadingPendingInvestments]);

  const handleCellClick = useCallback(
    (params) => {
      if (params.isEditable) {
        breakdownDGRef.current.setCellMode(params.id, params.field, 'edit');
      }
    },
    [breakdownDGRef],
  );

  const pendingInvestmentsTableDef = createPendingInvestmentsTableDefinition(userRole);

  if (userRole.isOOI) {
    pendingInvestmentsTableDef.forEach((column) => {
      column.editable = false;
    });
  }

  const preparePostPayload = (pendingInvestment) => assignLocalEditValues(
    pendingInvestment,
    exportGridValues(breakdownDGRef.current, Object.keys(fundAllocations[0])),
    {
      roundNumber,
      sharePrice,
      securityType,
      interestRate,
      wireTransferDate,
      numberOfUnits: autoCalculateNumberOfUnitsforLoanTypes(securityType) || numberOfUnits,
    },
  );

  const overwriteAndSaveEdits = async (pendingInvestment) => updatePendingInvestment(
    generateRequest(preparePostPayload(pendingInvestment)),
  );

  const submitToNetsuite = async (pendingInvestment) => submitPendingInvestment(
    generateRequest(preparePostPayload(pendingInvestment)),
  );

  return (
    <>
      <Helmet>
        <title>Pending Investment</title>
      </Helmet>
      <Container className="dashboard" style={{ maxWidth: '100%' }}>
        <Card>
          <CardBody>
            <Row>
              <Col>
                {
                  pendingInvestmentDetail && (
                    <h2>{pendingInvestmentDetail.companyName}</h2>
                  )
                }
                {!pendingInvestmentDetail && (HorizRecLoader(1, 300))}
              </Col>
              {userRole.isOOI && (
                <Col md={4} sm={5} className="float-right">
                  {
                    pendingInvestmentDetail && (
                      <>
                        {PendingInvestmentDetailActionButton({
                          onClick: () => {
                            history.push(`/pending-investment/${pendingInvestmentId}/name-verification?postEdit=1`);
                          },
                          loading: false,
                          style: {
                            display: 'none',
                          },
                        }, 'Update PortCo')}
                        {PendingInvestmentDetailActionButton({
                          onClick: () => {
                            history.push(`/pending-investment/${pendingInvestmentId}/dd-report?postEdit=1`);
                          },
                          loading: false,
                          style: {
                            float: 'right',
                          },
                        }, 'Update DD')}
                      </>
                    )
                  }
                  {!pendingInvestmentDetail && (HorizRecLoader(1, 100, 40, 'float-right'))}
                </Col>
              )}
            </Row>
            <Row>
              <Col style={{ marginTop: '30px' }}>
                {/* {
                    !pendingInvestmentDetail && (
                      HorizRecLoader(1, 200)
                    )
                  } */}
                {pendingInvestmentDetail && (
                  <DatePicker
                    label="Wire Date (investment date)"
                    value={wireTransferDate}
                    renderInput={(params) => <TextField {...params} style={{ width: '275px' }} />}
                    InputProps={{ readOnly: true }}
                    onChange={(date) => setWireTransferDate(date)}

                  />
                )}
                {
                  pendingInvestmentDetail && (
                    <Grid
                      container
                      direction="row"
                      justifyContent="flex-start"
                      alignItems="baseline"
                      sx={{ mt: 3 }}
                    >
                      <Box sx={{
                        p: 1,
                        m: 1,
                        ml: 0,
                        pl: 0,
                      }}
                      >
                        {SecurityTypeSelector(securityTypes, (value) => {
                          setSecurityType(value);
                        }, securityType, userRole)}
                      </Box>
                      <Box sx={{
                        p: 1,
                        m: 1,
                      }}
                      >
                        <TextField
                          id="roundNumberInput"
                          label="Round Number"
                          type="number"
                          defaultValue={!checkIfNullOrUndefined(pendingInvestmentDetail.roundNumber)
                            ? pendingInvestmentDetail.roundNumber : roundNumber}
                          style={{
                            width: '124px',
                          }}
                          InputLabelProps={{
                            shrink: true,
                          }}
                          onBlur={(event) => {
                            if (!checkNaturalNumber(event.target.value)) {
                              event.target.value = 1;
                            }
                            const newValue = Math.round(event.target.value);
                            event.target.value = newValue;
                            setRoundNumber(newValue);
                          }}
                        />
                      </Box>
                      <Box
                        id="numberOfUnitsInputContainer"
                        sx={{
                          p: 1,
                          m: 1,
                        }}
                      >
                        <TextField
                          id="numberOfUnitsInput"
                          label="Number of Units"
                          type="number"
                          defaultValue={!checkIfNullOrUndefined(pendingInvestmentDetail.numberOfUnits)
                            ? pendingInvestmentDetail.numberOfUnits : numberOfUnits}
                          InputLabelProps={{
                            shrink: true,
                          }}
                          onBlur={(event) => {
                            if (!checkNaturalNumber(event.target.value)) {
                              event.target.value = 1;
                            }
                            const newValue = Math.round(event.target.value);
                            event.target.value = newValue;
                            setNumberOfUnits(newValue);
                          }}
                        />
                      </Box>
                      <Box
                        id="interestRateInputContainer"
                        sx={{
                          p: 1,
                          m: 1,
                        }}
                      >
                        <TextField
                          label="Interest Rate %"
                          type="number"
                          defaultValue={!checkIfNullOrUndefined(pendingInvestmentDetail.interestRate)
                            ? roundToPrecision(pendingInvestmentDetail.interestRate) : interestRate}
                          InputLabelProps={{
                            shrink: true,
                          }}
                          onBlur={(event) => {
                            if (checkValidPercentageBounds(event.target.value)) {
                              const rounded = roundToPrecision(event.target.value);
                              event.target.value = rounded;
                              setInterestRate(rounded);
                            } else {
                              event.target.value = 0;
                              setInterestRate(0);
                            }
                          }}
                        />
                      </Box>
                      <Box
                        id="sharePriceInputContainer"
                        sx={{
                          p: 1,
                          m: 1,
                        }}
                      >
                        <TextField
                          label="Share Price"
                          type="number"
                          defaultValue={!checkIfNullOrUndefined(pendingInvestmentDetail.sharePrice)
                            ? roundToPrecision(pendingInvestmentDetail.sharePrice) : sharePrice}
                          InputLabelProps={{
                            shrink: true,
                          }}
                          InputProps={{
                            startAdornment: <InputAdornment position="start">$</InputAdornment>,
                          }}
                          onBlur={(event) => {
                            if (checkPositiveWholeNumber(event.target.value)) {
                              const rounded = roundToPrecision(event.target.value);
                              event.target.value = rounded;
                              setSharePrice(rounded);
                            } else {
                              event.target.value = 1;
                              setSharePrice(1);
                            }
                          }}
                        />
                      </Box>
                    </Grid>
                  )
                }
                {
                  !pendingInvestmentDetail && (
                    <Box
                      sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        p: 0,
                        m: 0,
                        borderRadius: 1,
                      }}
                    >
                      {HorizRecLoader(4)}
                    </Box>
                  )
                }

                {(userRole.isFinance && fundConfigError) && (
                <h4 className="error red">{fundConfigError}</h4>
                )}
                <div style={{ marginTop: '30px' }}>
                  <AvDataGrid
                    rows={fundAllocations}
                    columns={pendingInvestmentsTableDef}
                    apiRef={breakdownDGRef}
                    loading={loadingPendingInvestments}
                    onCellEditStop={() => {
                      setTotals(exportGridValues(breakdownDGRef.current, Object.keys(fundAllocations[0])));
                    }}
                    onCellClick={handleCellClick}
                    initialState={{
                      sorting: {
                        sortModel: [{ field: 'name', sort: 'asc' }],
                      },
                    }}
                  />
                </div>
                <div style={{ marginTop: '20px' }}>
                  <AvDataGrid
                    pageSize={1}
                    rows={avInvestmentTotals}
                    columns={createPendingInvestmentTotalsTableDefinition()}
                    loading={loadingPendingInvestments}
                    hideFooter
                    density="compact"
                    disableColumnMenu
                    disableColumnFilter
                    disableColumnSelector
                    sx={{
                      fontSize: '17px',
                    }}
                  />
                </div>
              </Col>
            </Row>
            {
              pendingInvestmentDetail && (
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  m: 3,
                }}
              >
                {(userRole.isFinance && !!pendingInvestmentDetail.processStage) && (
                  PendingInvestmentDetailActionButton({
                    onClick: async () => {
                      setReturnToOOIConfirmationModalOpen(true);
                    },
                    disabled: isReturnToOOIConfirmationModalOpen,
                    loading: isReturnToOOIConfirmationModalOpen,
                    color: 'danger',
                  }, 'Return To OOI')
                )}

                {((userRole.isOOI && !pendingInvestmentDetail.processStage) || (userRole.isFinance && !!pendingInvestmentDetail.processStage))
                && (
                  PendingInvestmentDetailActionButton({
                    onClick: async () => {
                      setIsSaving(true);
                      await overwriteAndSaveEdits(pendingInvestmentDetail);
                      setIsSaving(false);
                    },
                    disabled: isSaving || isSubmitting,
                    loading: isSaving,
                  }, 'Save As Draft'))}

                {(userRole.isOOI && !pendingInvestmentDetail.processStage) && (
                  PendingInvestmentDetailActionButton({
                    onClick: async () => {
                      // call service
                      setIsSubmitting(true);
                      pendingInvestmentDetail.processStage = 1; // "Ready for Finance"
                      await overwriteAndSaveEdits(pendingInvestmentDetail);
                      history.push('/');
                    },
                    disabled: isSaving || isSubmitting,
                    loading: isSubmitting,
                    variant: 'contained',
                  }, 'Submit To Finance')
                )}
                {(userRole.isOOI && !!pendingInvestmentDetail.processStage) && (
                <h4>Investment submitted to Finance for review.</h4>
                )}
                {(userRole.isFinance && !pendingInvestmentDetail.processStage) && (
                <h4>Investment is being reviewed by OOI.</h4>
                )}

                {(userRole.isFinance && !!pendingInvestmentDetail.processStage) && (
                  PendingInvestmentDetailActionButton({
                    onClick: async () => {
                      setIsPushingToNS(true);
                      setPushToNSConfirmationModalOpen(true);
                    },
                    loading: isPushingToNS,
                    variant: 'contained',
                    disabled: !!fundConfigError || (securityType < 0) || !wireTransferDate,
                  }, 'Push to Netsuite')
                )}
              </Box>
              )

            }
            {
              !pendingInvestmentDetail && (
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    m: 3,
                  }}
                >
                  {HorizRecLoader(2)}
                </Box>
              )
            }
          </CardBody>
        </Card>
      </Container>
      <ConfirmationModal
        isOpen={isPushToNSConfirmationModalOpen}
        headerText="Are you sure you want to push changes to NetSuite?"
        bodyText="Please confirm all values as this cannot be easily undone."
        onConfirm={async () => {
          // call service
          pendingInvestmentDetail.processStage = 2; // "Pushed To Netsuite"
          await submitToNetsuite(pendingInvestmentDetail);
          history.push('/');
        }}
        onCancel={() => {
          setPushToNSConfirmationModalOpen(false);
          setIsPushingToNS(false);
        }}
      />
      <ConfirmationModal
        isOpen={isReturnToOOIConfirmationModalOpen}
        headerText="Are you sure you want to send deal back to OOI?"
        // eslint-disable-next-line max-len
        bodyText="This will update investment amounts to match the pending investments spreadsheet and clear any edits to the allocations made via this application."
        onConfirm={async () => {
          // call service
          pendingInvestmentDetail.processStage = 0;// "Ready For OOI" i.e. in progress
          pendingInvestmentDetail.funds = [];
          await overwriteAndSaveEdits(pendingInvestmentDetail);
          history.push('/');
        }}
        onCancel={() => { setReturnToOOIConfirmationModalOpen(false); }}
      />
    </>

  );
}

export default PendingInvestmentDetail;
