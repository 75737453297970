import React from 'react';
import SidebarLink from './SidebarLink';
import SidebarCategory from './SidebarCategory';

function SidebarContent({ onClick }) {
  const hideSidebar = () => {
    onClick();
  };

  return (
    <div className="sidebar__content">
      <ul className="sidebar__block">
        <SidebarCategory title="Portals" icon="list">
          <SidebarLink title="Dashboard" route="/" onClick={hideSidebar} />
          <SidebarLink title="Wire Transfer Example" route="/wire-transfer/VG92YWxhMQ" onClick={hideSidebar} />
          <SidebarLink title="Upload Closing Docs" route="/upload-closing-docs" onClick={hideSidebar} />
        </SidebarCategory>
      </ul>
    </div>
  );
}

export default SidebarContent;
