import moment from 'moment';
import clubMembersSortOrder from '../helpers/ClubMemberSortOrder';

export const avgLogoUrl = 'https://s3.amazonaws.com/la-company-logos/avg_logo2.png';
export const avgAvatarUrl = 'https://la-company-logos.s3.amazonaws.com/avg-avatar.jpg';

export const blueColor = { light: '#4f7ecc', main: '#104866', dark: '#002b6c' };
export const maroonColor = { light: '#c27dff', main: '#7B569B', dark: '#55029e' }; // was green 
export const greyColor = '#646777';
export const redColor = '#FF4861';
export const goldColor = { light: '#fcdf38', main: '#CFB72E', dark: '#ba9f04' }; // was maroon

export const ANNOUNCEMENT_MOMENT_TIME_FORMAT = 'YYYY-MM-DD HH:mm';
export const DATE_TIME_PICKER_FORMAT = 'MM/dd/yyyy HH:mm';
export const announcementFilter = type => (element) => {
  if (element.display_status !== 1) {
    return false;
  }
  if (element.type !== 2 && moment(element.start_date).isAfter()) {
    return false;
  }
  if (element.end_date && moment(element.end_date).isBefore()) {
    return false;
  }
  if (type !== null && element.type !== type) {
    return false;
  }
  return true;
};


export const sortClubMembers = (members) => {
  if (!members) return [];
  const sorted = members.sort((a, b) => {
    const a2 = clubMembersSortOrder.find(v => v.label === a.roleStatus);
    const b2 = clubMembersSortOrder.find(v => v.label === b.roleStatus);

    if (!a2 || !b2) return -1;

    if (a2 === b2) return moment(a.join_date) > moment(b.join_date) ? 1 : -1;

    return (a2.value > b2.value) ? 1 : -1;
  });
  return sorted;
};

export const filterClubMembers = (element) => {
  if (element.role === 1) {
    return false;
  }
  if (element.role === 2) {
    return false;
  }
  if (element.role === 3) {
    return false;
  }
  if (element.role === 4) {
    return false;
  }
  if (element.role === 5) {
    return false;
  }
  if (element.role === 6) {
    return false;
  }
  return true;
};
