import fetch from 'cross-fetch';

export const postRequest = (url, body, headers, abortSignal = null, showSuccessNotification = true) => fetch(url, {
  method: 'POST',
  body: JSON.stringify(body),
  headers,
  signal: abortSignal,
}).then(async (res) => res.json().then((json) => {
  if (!res.ok) {
    return Promise.reject(json);
  }

  if (showSuccessNotification) {
    setNotification('success');
  }
  return json;
})).catch((e) => {
  showErrorMessage(e);
  return Promise.reject(e);
});
