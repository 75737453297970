/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';
import { getInvestmentsByPortcoId } from '../../shared/services/bifService';

/*
  NOTE: createSlice allows us to work with state directly.
  Internally, it does not mute state, but makes a copy.
  https://redux-toolkit.js.org/tutorials/intermediate-tutorial#creating-the-todos-slice
*/
const portcoSlice = createSlice({
  name: 'portcoData',
  initialState: {
    logoUrl: '',
    totals: null,
    portcoName: '',
    portco: null,
    loading: false,
    error: null,
    lastFetched: new Date(),
  },
  reducers: {
    getPortcoDataStart(state) {
      state.loading = true;
      state.error = null;
    },
    getPortcoDataSuccess(state, action) {
      const {
        companyLogo, name, totals, portco,
      } = action.payload.data;
      state.loading = false;
      state.error = null;
      state.logoUrl = companyLogo;
      state.totals = totals;
      state.portcoName = name;
      state.portco = portco;
      state.lastFetched = new Date();
    },
    getPortcoDataError(state, acton) {
      state.loading = false;
      state.error = acton.payload;
    },
    clearPortcoData(state) {
      state.loading = false;
      state.error = null;
      state.logoUrl = '';
      state.totals = null;
      state.portcoName = null;
      state.portco = null;
    },
  },
});

// export all the actions
export const {
  getPortcoDataStart, getPortcoDataSuccess, getPortcoDataError, clearPortcoData,
} = portcoSlice.actions;

// This is a 'thunk' action for data fetching
export const fetchPortcoData = id => async (dispatch) => {
  try {
    dispatch(getPortcoDataStart());
    const data = await getInvestmentsByPortcoId(id);
    dispatch(getPortcoDataSuccess({ data }));
  } catch (err) {
    dispatch(getPortcoDataError(err));
  }
};

export default portcoSlice.reducer;
