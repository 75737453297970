import React, { useState } from 'react';
import { Helmet } from 'react-helmet';
import {
  Container, Row, Card, CardBody, Col,
} from 'reactstrap';
import { useHistory } from 'react-router-dom';
import PendingInvestmentsSearchBar from '../shared/components/PendingInvestmentsSearchBar';
import AvDataGrid from '../shared/components/AvDataGrid';
import useClosedInvestments from '../shared/hooks/useClosedInvestments';
import createClosedInvestmentsTableDefinition from './ClosedInvestmentsTableDefinition';
import useFilteredBySearch from '../shared/hooks/useFilteredBySearch';

const closed = true;

function LandingPage() {
  const [loadingPendingInvestments, setPendingInvestmentsLoading] = useState(true);
  const pendingInvestments = useClosedInvestments(setPendingInvestmentsLoading);
  const pendingInvestmentsTableDef = createClosedInvestmentsTableDefinition(useHistory());
  const [companyNameSearchValue, setCompanyNameSearchValue] = useState('');
  const [pageSize, setPageSize] = useState(10);
  const filteredPendingInvestments = useFilteredBySearch(pendingInvestments, companyNameSearchValue, closed);

  return (
    <>
      <Helmet>
        <title>Closed Investments - Deal Portal</title>
      </Helmet>
      <Container className="dashboard" style={{ maxWidth: '100%' }}>
        <Card>
          <CardBody>
            <Row>
              <Col>
                <h2>Closed Investments</h2>
                <div style={{ marginTop: '20px' }}>
                  <PendingInvestmentsSearchBar
                    placeholder="Search by company name"
                    onChange={(e) => setCompanyNameSearchValue(e.target.value)}
                    value={companyNameSearchValue}
                    disabled={loadingPendingInvestments}
                    fullWidth
                  />
                </div>
                <div style={{ marginTop: '20px' }}>
                  <AvDataGrid
                    pageSize={pageSize}
                    setPageSize={setPageSize}
                    rows={filteredPendingInvestments}
                    columns={pendingInvestmentsTableDef}
                    loading={loadingPendingInvestments}
                  />
                </div>
              </Col>

            </Row>
          </CardBody>
        </Card>
      </Container>
    </>

  );
}

export default LandingPage;
