import React, { useState } from 'react';
import { Helmet } from 'react-helmet';
import {
  Container, Row, Card, CardBody, Col,
} from 'reactstrap';
import { v4 } from 'uuid';
import {
  Stack, TextField, MenuItem,
} from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import { useHistory, useParams } from 'react-router-dom';
import DealInformation from '../shared/components/DealInformation';
import usePendingInvestmentDetail from '../shared/hooks/usePendingInvestmentDetail';
import PartialPageLoader from '../shared/components/Loader/PartialPageLoader';
import ReadOnlyTextField from '../shared/components/ReadOnlyTextField';
import getNumberValue from '../shared/utils/getNumberValue';
import formatCurrencyAsUsd from '../shared/utils/formatCurrencyAsUsd';
import { spacing } from '../shared/themes/appTheme';
import { createAQSplitsTotalsTableDefWithCashAdvances } from '../AQSplits/AQSplitsTotalsTableDefinition';
import { createAQSplitsTableDefWithCashAdvances } from '../AQSplits/AQSplitsTableDefinition';
import AvDataGrid from '../shared/components/AvDataGrid';
import {
  prepareFundAllocationsForGrid,
} from '../AQSplits/helpers';
import DocumentViewer from '../shared/components/DocumentViewer';
import {
  updatePendingInvestment,
} from '../shared/services/apiGateway';
import generateRequest from '../shared/services/generateRequest';
import StageStatusMessage from '../shared/components/StageStatusMessage';
import getStageStatus from '../shared/utils/checkUserPermissions';
import CloseOnConfirmModal from '../shared/components/CloseOnConfirmModal';
import getSearchParams from '../shared/utils/getSearchParams';

const REJECTION_REASONS = [
  '',
  'Incomplete Signed Deal Docs',
  'Incomplete Legal Docs',
  'Incorrect Wire Amount',
  'Cancelled Deal',
];
const createCustomFormatDateStr = (strOrDate) => {
  if (strOrDate) {
    return new Date(strOrDate).toLocaleDateString('en-US', { month: '2-digit', day: '2-digit', year: 'numeric' });
  }
  return '';
};

const styles = {
  sectionSpacing: { margin: '15px 0 5px 0' }, questionsFontSize: '15px',
};

function WireSignOff() {
  const { id: dealId } = useParams();
  const closeOnConfirm = getSearchParams('closeoncompletion');
  const history = useHistory();

  const [dealName, setDealName] = useState('');
  const [wireVerificationInfo, setWireVerificationInfo] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isRejecting, setIsRejecting] = useState(false);
  const [isConfirming, setIsConfirming] = useState(false);
  const [fundAllocations, setFundAllocations] = useState(null);
  const [wireAmountDoc, setWireAmountDoc] = useState(null);
  const [wireInstructionsDoc, setWireInstructionsDoc] = useState(null);
  const [signedDealDocs, setSignedDealDocs] = useState([]);
  const [closingDocs, setClosingDocs] = useState([]);
  const [proofOfBankTransferDocument, setProofOfBankTransferDocument] = useState(null);
  const [advanceAgreementDocuments, setAdvanceAgreementDocuments] = useState([]);
  const [rejectionReason, setRejectionReason] = useState('');
  const [rejectionError, setRejectionError] = useState(false);
  const [stageStatus, setStageStatus] = useState({
    isEditableByRole: false,
  });
  const [completionModalOpen, setCompletionModalOpen] = useState(false);
  const totalInvestmentsId = v4();
  const [avInvestmentTotals, setAvInvestmentTotals] = useState([{
    id: totalInvestmentsId,
    avInvestmentTotal: 0,
    avInvestmentATotal: 0,
    avInvestmentQTotal: 0,
    avCashAdvanceTotal: 0,
  }]);

  const calculateTotals = (funds) => {
    const investmentTotals = {
      id: totalInvestmentsId,
      avInvestmentTotal: 0,
      avInvestmentATotal: 0,
      avInvestmentQTotal: 0,
      avCashAdvanceTotal: 0,
    };
    if (funds) {
      for (const fund of funds) {
        const aAllocation = getNumberValue(fund.aAmount);
        const qAllocation = getNumberValue(fund.qAmount);
        investmentTotals.avInvestmentATotal += aAllocation;
        investmentTotals.avInvestmentQTotal += qAllocation;
        investmentTotals.avInvestmentTotal += (aAllocation + qAllocation);
        investmentTotals.avCashAdvanceTotal += getNumberValue(fund.cashAdvanceAmount);
      }
    }

    investmentTotals.avInvestmentTotal = formatCurrencyAsUsd(investmentTotals.avInvestmentTotal);
    investmentTotals.avInvestmentATotal = formatCurrencyAsUsd(investmentTotals.avInvestmentATotal);
    investmentTotals.avInvestmentQTotal = formatCurrencyAsUsd(investmentTotals.avInvestmentQTotal);
    investmentTotals.avCashAdvanceTotal = formatCurrencyAsUsd(investmentTotals.avCashAdvanceTotal);
    return investmentTotals;
  };

  const deal = usePendingInvestmentDetail(dealId, (dealData) => {
    setStageStatus({
      ...getStageStatus(dealData),
    });
    let responseDealName = '';
    if (dealData.companyName) {
      responseDealName = dealData.companyName;
    } else if (dealData.hsDealObj && dealData.hsDealObj.dealName) {
      responseDealName = dealData.hsDealObj.dealName;
    }
    setDealName(responseDealName);
    setWireVerificationInfo(dealData.wireVerificationInfo || {});
    setFundAllocations(prepareFundAllocationsForGrid(dealData.funds));
    setAvInvestmentTotals([calculateTotals(dealData.funds)]);

    setWireAmountDoc(dealData.wireAmountDocument || null);
    setWireInstructionsDoc(dealData.wireInstructionsDocument || null);
    setSignedDealDocs(dealData.signedDealDocuments || []);
    setClosingDocs(dealData.closingDocuments || []);
    setAdvanceAgreementDocuments(dealData.advanceAgreementDocuments || []);
    setProofOfBankTransferDocument(dealData.proofOfBankTransferDocument || null);
  });

  const confirmStep = async () => {
    setIsSubmitting(true);
    setIsConfirming(true);
    deal.stagesCompleted.wireSignOff = new Date().toISOString();
    await updatePendingInvestment(generateRequest(deal));
    setIsSubmitting(false);
    setIsConfirming(false);
    if (!closeOnConfirm) {
      history.push(`/deal-stages/${dealId}`);
    } else {
      setCompletionModalOpen(true);
    }
  };

  const validateRejectionReason = () => {
    if (rejectionReason === '') {
      setRejectionError(true);
      return false;
    }
    setRejectionError(false);
    return true;
  };
  const submitRejectDeal = async () => {
    if (!rejectionError) {
      const dealCancelledAction = {
        label: 'dealCancelled',
        value: false,
      };
      let redirect = `/deal-stages/${deal.id}`;
      deal.dealCancelled = false;
      switch (rejectionReason) {
        case 'Incomplete Signed Deal Docs':
          deal.stagesCompleted.signedDealDocumentsUpload = false;
          deal.stagesCompleted.wireSignOff = false;
          break;
        case 'Incomplete Legal Docs':
          deal.stagesCompleted.closingDocsReview = false;
          deal.stagesCompleted.wireSignOff = false;
          break;
        case 'Incorrect Wire Amount':
          deal.stagesCompleted.uploadWireInfo = false;
          deal.stagesCompleted.verifyWireInfo = false;
          deal.stagesCompleted.wireSignOff = false;
          break;
        case 'Cancelled Deal':
          dealCancelledAction.value = true;
          redirect = '/';
          break;
        default:
      }
      deal.wireSignOffRejectionReason = rejectionReason;
      setIsSubmitting(true);
      setIsRejecting(true);
      await updatePendingInvestment(generateRequest(deal, [dealCancelledAction, {
        label: 'rejectDeal',
        value: { rejectionReason, originStep: '/wire-sign-off/' },
      }]));
      setIsSubmitting(false);
      setIsRejecting(false);

      if (!closeOnConfirm) {
        history.push(redirect);
      } else {
        setCompletionModalOpen(true);
      }
    }
  };
  return (
    <>
      <Helmet>
        <title>Finance Wire Approval</title>
      </Helmet>
      <Container>
        <Card>
          {!deal && (
            PartialPageLoader()
          )}
          <CardBody>
            {deal && (
              <>
                <Row>
                  <Col>
                    <h3>Finance Wire Approval for {dealName}</h3>
                  </Col>
                </Row>
                <Row className="w-100">
                  <Col
                    lg={12}
                    style={{
                      marginTop: spacing(2),
                    }}
                  >
                    <DealInformation
                      dealInfo={{ dealName, sponsorTeam: deal.sponsorTeam, iTeamMemberName: deal.iTeamMemberName }}
                      setDealInfo={() => false}
                    />
                    <ReadOnlyTextField
                      label="Wire Amount"
                      value={formatCurrencyAsUsd(deal.wireAmount)}
                      fullWidth
                    />
                    <hr />
                    {deal.digitalAssetsQuestions && deal.digitalAssetsQuestions.length > 0 && (
                      <>
                        <h5 style={{ ...styles.sectionSpacing }}>
                          Digital Asset Questions
                        </h5>
                        {deal.digitalAssetsQuestions.map((question) => {
                          let displayAnswer = question.value;
                          if (question.value === true) {
                            displayAnswer = 'Yes';
                          } else if (question.value === false) {
                            displayAnswer = 'No';
                          }
                          return (
                            <div key={question.label}>
                              <h5 style={{ ...styles.sectionSpacing, fontSize: styles.questionsFontSize }}>{question.label}</h5>
                              <h6>&emsp;<i>Answer:</i>&ensp;“{displayAnswer}”</h6>
                            </div>
                          );
                        })}
                      </>
                    )}
                    <hr />
                    <h5 style={styles.sectionSpacing}> Wire Info Verification</h5>
                    <ReadOnlyTextField
                      label="Name"
                      value={wireVerificationInfo.financeMemberName || ''}
                      fullWidth
                    />
                    <ReadOnlyTextField
                      label="Date"
                      value={createCustomFormatDateStr(wireVerificationInfo.verificationDate || '')}
                      fullWidth
                    />
                    {
                      wireVerificationInfo.additionalInfo && (
                        <ReadOnlyTextField
                          label="Finance Notes"
                          value={wireVerificationInfo.additionalInfo}
                          fullWidth
                          multiline
                        />
                      )
                    }
                    <hr />
                    <h5 style={styles.sectionSpacing}>iTeam Approval Of Closing Docs</h5>
                    <ReadOnlyTextField
                      label="Name"
                      value={deal.iTeamMemberName}
                      fullWidth
                    />
                    <ReadOnlyTextField
                      label="Date"
                      value={createCustomFormatDateStr(deal.stagesCompleted.closingDocsReview)}
                      fullWidth
                    />
                    <hr />
                    {
                      (deal.closingDocsReviewQuestions && deal.closingDocsReviewQuestions.length > 0)
                        && (
                        <>
                          <h5 style={{ ...styles.sectionSpacing, fontSize: styles.questionsFontSize }}>Questions answered “No”:</h5>
                          {deal.closingDocsReviewQuestions && deal.closingDocsReviewQuestions.filter((item) => !item.value).map((item) => (
                            <div key={item.label}>
                              <h5 style={{ ...styles.sectionSpacing, fontSize: styles.questionsFontSize }}>{item.label}</h5>
                              <h6>&emsp;<i>Reason:</i>&ensp;“{item.exp}”</h6>
                            </div>
                          ))}
                        </>
                        )
                    }
                    <hr />
                    <div style={{ marginTop: '20px' }}>
                      <h5 style={styles.sectionSpacing}>Uploaded Documents</h5>
                      <DocumentViewer id={deal.id} document={wireAmountDoc} label="Wire Amount Document" />
                      <DocumentViewer id={deal.id} document={wireInstructionsDoc} label="Wire Instructions Document" />
                      <DocumentViewer id={deal.id} documents={signedDealDocs} label="Signed Deal Documents" />
                      <DocumentViewer id={deal.id} documents={closingDocs} label="Closing Documents" />
                      <DocumentViewer id={deal.id} document={proofOfBankTransferDocument} label="Proof Of Bank Transfer Document" />
                      { !!advanceAgreementDocuments.length && (
                      <DocumentViewer
                        id={deal.id}
                        documents={advanceAgreementDocuments}
                        label="Advance Agreement Documents"
                      />
                      ) }
                    </div>
                    <hr />
                    <h4 style={styles.sectionSpacing}>Detailed Allocations</h4>
                    <div style={{ marginTop: '10px' }}>
                      <AvDataGrid
                        rows={fundAllocations}
                        columns={createAQSplitsTableDefWithCashAdvances()}
                        loading={!fundAllocations}
                        initialState={{
                          pinnedColumns: { left: ['name'] },
                        }}
                      />
                    </div>
                    <div style={{ marginTop: '20px' }}>
                      <AvDataGrid
                        pageSize={1}
                        rows={avInvestmentTotals}
                        columns={createAQSplitsTotalsTableDefWithCashAdvances()}
                        loading={!deal}
                        hideFooter
                        density="compact"
                        disableColumnMenu
                        disableColumnFilter
                        disableColumnSelector
                        sx={{
                          fontSize: '17px',
                        }}
                      />
                    </div>
                    {((stageStatus.isEditableByRole) && !stageStatus.isCompleted) && (
                      <>
                        <Stack
                          direction="row"
                          justifyContent="space-between"
                          alignItems="center"
                          spacing={2}
                          mt={4}
                        >
                          <TextField
                            sx={{ mb: 2 }}
                            fullWidth
                            id="rejection-reason-select"
                            select
                            label="Reason for rejection: (required if rejecting wire information step)"
                            required
                            value={rejectionReason}
                            onChange={(e) => { setRejectionReason(e.target.value); }}
                            variant="standard"
                            error={rejectionError}
                            helperText={rejectionReason === '' ? 'Required if rejecting the deal' : null}
                          >
                            {REJECTION_REASONS.map((reason) => (
                              <MenuItem style={{ paddingBottom: '15px', paddingTop: '15px' }} key={reason} value={reason}>{reason}
                              </MenuItem>
                            ))}
                          </TextField>
                        </Stack>
                        <Stack
                          direction="row"
                          justifyContent="space-between"
                          alignItems="center"
                          spacing={2}
                          mt={2}
                        >
                          <LoadingButton
                            variant="outlined"
                            color="danger"
                            disabled={isSubmitting}
                            loading={isRejecting}
                            onClick={async () => {
                              if (validateRejectionReason()) {
                                await submitRejectDeal();
                              }
                            }}
                          >Reject and request Re-Submission
                          </LoadingButton>
                          <LoadingButton
                            variant="contained"
                            color="secondary"
                            disabled={isSubmitting}
                            loading={isConfirming}
                            onClick={async () => {
                              await confirmStep();
                            }}
                          >
                            Submit
                          </LoadingButton>
                        </Stack>
                      </>
                    )}
                  </Col>
                </Row>
                <StageStatusMessage stageStatus={stageStatus} />
              </>
            )}
          </CardBody>
        </Card>

        <CloseOnConfirmModal modalOpen={completionModalOpen} />
      </Container>
    </>
  );
}

export default WireSignOff;
