import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { propTypes } from '@alumni-ventures-group/react-shared';
import SidebarContent from './SidebarContent';

const { SidebarProps } = propTypes;

function Sidebar({
  changeMobileSidebarVisibility, sidebar,
}) {
  const sidebarClass = classNames({
    'sidebar sidebar--no-desktop': true,
    'sidebar--show': sidebar.show,
  });
  return (
    <div className={sidebarClass}>
      <button className="sidebar__back" type="button" onClick={changeMobileSidebarVisibility} />
      <div className="sidebar__wrapper sidebar__wrapper--mobile">
        <SidebarContent
          onClick={changeMobileSidebarVisibility}
        />
      </div>
    </div>
  );
}

Sidebar.propTypes = {
  sidebar: SidebarProps.isRequired,
  changeMobileSidebarVisibility: PropTypes.func.isRequired,
};

export default Sidebar;
