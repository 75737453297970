import React from 'react';
import {
  UncontrolledDropdown, DropdownToggle, DropdownItem, DropdownMenu,
} from 'reactstrap';
import ChevronDownIcon from 'mdi-react/ChevronDownIcon';

const Fund = () => (
  <UncontrolledDropdown>
    <DropdownToggle className="icon icon--right" outline>
      <span className="lnr lnr-pencil">
        <ChevronDownIcon />
      </span>
    </DropdownToggle>
    <DropdownMenu right className="dropdown__menu">
      <DropdownItem>Action</DropdownItem>
      <DropdownItem>Another Action</DropdownItem>
      <DropdownItem divider />
      <DropdownItem>Another Action</DropdownItem>
    </DropdownMenu>
  </UncontrolledDropdown>
);

export default Fund;
