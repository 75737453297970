import * as React from 'react';
import { LoginCallback } from '@okta/okta-react';
import UserLogout from './shared/components/UserLogout';

export default function CustomLoginCallback() {
  return (
    <LoginCallback
      errorComponent={() => {
        UserLogout();
        return '';
      }}
    />
  );
}
