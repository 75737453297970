class Dictionary {
  constructor(items = {}) {
    this.items = items;
  }

  get(dictionaryCategory, dictionaryItem, defaultValue = '') {
    if (!this.items[dictionaryCategory]) {
      return '';
    }
    if (this.items[dictionaryCategory][dictionaryItem] === undefined) {
      return defaultValue;
    }
    return this.items[dictionaryCategory][dictionaryItem];
  }

  getArray(dictionaryCategory) {
    if (!this.items[dictionaryCategory]) {
      return [];
    }
    return this.items[dictionaryCategory];
  }
}
export default Dictionary;
