import React from 'react';
import PropTypes from 'prop-types';
import {
  Button, Dialog, DialogActions, DialogContent, DialogTitle,
} from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';

export default function ConfirmationModal({
  bodyText, headerText, isOpen, onConfirm, onCancel, disableBackdropClick,
}) {
  const [isConfirming, setIsConfirming] = React.useState(false);
  return (
    <Dialog
      open={isOpen}
      onClose={(event, reason) => {
        if (reason && reason === 'backdropClick' && disableBackdropClick) {
          return;
        }
        onCancel();
      }}
    >
      <DialogTitle>
        {headerText}
      </DialogTitle>
      <DialogContent>
        <p style={{ fontSize: '16px' }}>{bodyText}</p>
      </DialogContent>
      <DialogActions>
        {onCancel
          && (
          <Button
            variant="outlined"
            color="secondary"
            disabled={isConfirming}
            onClick={onCancel}
          >
            Cancel
          </Button>
          )}
        {onConfirm
          && (
            <LoadingButton
              variant="contained"
              color="secondary"
              loading={isConfirming}
              onClick={async () => {
                try {
                  setIsConfirming(true);
                  await onConfirm();
                } finally {
                  setIsConfirming(false);
                }
              }}
            >
              Confirm
            </LoadingButton>
          )}
      </DialogActions>
    </Dialog>
  );
}
ConfirmationModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  headerText: PropTypes.string,
  bodyText: PropTypes.string,
  onConfirm: PropTypes.func,
  onCancel: PropTypes.func,
  disableBackdropClick: PropTypes.bool,
};
ConfirmationModal.defaultProps = {
  headerText: 'Are you sure?',
  bodyText: 'Please confirm you want to perform this action.',
  onConfirm: null,
  onCancel: null,
  disableBackdropClick: false,
};
