import { fixInvestmentType, changeInvestmentType } from '../services/fundService';

const investmentTypeArray = [0, 1, 2, 3, 4];

export default class Investment {
  constructor(rawInvestment) {
    this.id = {
      label: 'ID', type: null, readOnly: true, value: rawInvestment.id,
    };
    this.name = {
      label: 'Name', type: 'text', readOnly: false, value: rawInvestment.name,
    };
    this.investedType = {
      label: 'Investment Type',
      type: 'select',
      readOnly: false,
      value: fixInvestmentType(rawInvestment.investedType),
      display: rawInvestment.investedType,
      options: investmentTypeArray.map(investment => ({ label: changeInvestmentType(investment), value: investment })),
    };
    this.date_invested = {
      label: 'Date Invested', type: 'text', readOnly: false, value: rawInvestment.dateInvested,
    };
    this.dateConverted = {
      label: 'Date Converted',
      type: 'text',
      readOnly: false,
      value: rawInvestment.dateConverted ? rawInvestment.dateConverted.substring(0, 10) : '',
    };
    this.dateLiquidated = {
      label: 'Date Liquidated',
      type: 'text',
      readOnly: false,
      value: rawInvestment.dateLiquidated ? rawInvestment.dateLiquidated.substring(0, 10) : '',
    };
    this.purchasePrice = {
      label: 'Purchase Price', type: 'text', readOnly: false, value: rawInvestment.purchasePrice,
    };
    this.numShares = {
      label: 'Number of Shares', type: 'text', readOnly: false, value: rawInvestment.numShares,
    };
    this.investedAmount = {
      label: 'Invested Amount', type: 'text', readOnly: false, value: rawInvestment.investedAmount,
    };
  }
}
