import getNumberValue from '../shared/utils/getNumberValue';
import formatCurrencyAsUsd from '../shared/utils/formatCurrencyAsUsd';
import exportGridValues from '../shared/components/AvDataGrid/exportGridValues';

export const createTempTableFundId = (fund) => `${fund.name}_${fund.investmentAmount}`;

export const assignLocalEditValues = (pendingInvestment, tableFundData, otherValues) => {
  let tempPendingInvestment = JSON.parse(JSON.stringify(pendingInvestment));
  tempPendingInvestment = Object.assign(tempPendingInvestment, otherValues);
  let totalWireAmount = 0;
  let totalInvestmentAmount = 0;

  for (const fund of tempPendingInvestment.funds) {
    for (const tableFundDataItem of tableFundData) {
      if (createTempTableFundId(fund) === tableFundDataItem.id) {
        Object.keys(fund).forEach((key) => {
          if (Object.prototype.hasOwnProperty.call(tableFundDataItem, key)) {
            fund[key] = tableFundDataItem[key];
          }
        });
        fund.qAmount = tableFundDataItem.qAllocation;
        fund.aAmount = tableFundDataItem.aAllocation;
        fund.cashAdvanceAmount = tableFundDataItem.cashAdvanceAmount;

        // add to totals constant
        totalWireAmount += getNumberValue(fund.qAmount) + getNumberValue(fund.aAmount);
        totalInvestmentAmount += getNumberValue(fund.qAmount) + getNumberValue(fund.aAmount);
      }
    }
  }
  // append totals as wireAmount in tempPendingInvestment
  tempPendingInvestment.wireAmount = totalWireAmount;
  tempPendingInvestment.totalInvestmentAmount = totalInvestmentAmount;
  return tempPendingInvestment;
};

export const prepareFundAllocationsForGrid = (funds) => {
  const formatted = [];
  for (const fund of funds) {
    formatted.push({
      id: createTempTableFundId(fund),
      name: fund.name,
      qPercent: fund.qPercent || 0,
      aPercent: fund.aPercent || 0,
      aAllocation: 0,
      qAllocation: 0,
      investmentAmount: fund.investmentAmount,
      qFundId: fund.qFundId,
      aFundId: fund.aFundId,
      cashAdvanceAmount: fund.cashAdvanceAmount || 0,
      isSponsorFund: fund.sponsor || false,
    });
  }
  return formatted;
};

export const calculateTotals = (funds, totalInvestmentsId) => {
  const investmentTotals = {
    id: totalInvestmentsId,
    avInvestmentTotal: 0,
    avInvestmentATotal: 0,
    avInvestmentQTotal: 0,
    avCashAdvanceTotal: 0,
  };
  funds.forEach((fundAllocation) => {
    const aAllocation = getNumberValue(fundAllocation.aAllocation);
    const qAllocation = getNumberValue(fundAllocation.qAllocation);
    const cashAdvanceAmount = getNumberValue(fundAllocation.cashAdvanceAmount);
    investmentTotals.avInvestmentTotal += (aAllocation + qAllocation);
    investmentTotals.avInvestmentATotal += (aAllocation);
    investmentTotals.avInvestmentQTotal += (qAllocation);
    investmentTotals.avCashAdvanceTotal += (cashAdvanceAmount);
  });
  investmentTotals.avInvestmentTotal = formatCurrencyAsUsd(investmentTotals.avInvestmentTotal);
  investmentTotals.avInvestmentATotal = formatCurrencyAsUsd(investmentTotals.avInvestmentATotal);
  investmentTotals.avInvestmentQTotal = formatCurrencyAsUsd(investmentTotals.avInvestmentQTotal);
  investmentTotals.avCashAdvanceTotal = formatCurrencyAsUsd(investmentTotals.avCashAdvanceTotal);

  return investmentTotals;
};

export const boxFooterStyle = (function () {
  return {
    display: 'flex',
    justifyContent: 'center',
    m: 3,
  };
}());

export const initTotalsAndOnCellClickHandler = (breakdownDGRef, fundAllocations, loadingPendingInvestments, setTotals) => {
  if (breakdownDGRef) {
    if (fundAllocations && fundAllocations[0]) {
      setTotals(exportGridValues(breakdownDGRef.current, Object.keys(fundAllocations[0])));
    }
    if (!loadingPendingInvestments) {
      return breakdownDGRef.current.subscribeEvent(
        'cellModeChange',
        (params, event) => {
          event.defaultMuiPrevented = true;
        },
        { isFirst: true },
      );
    }
  }
  return null;
};
