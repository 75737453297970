/* eslint-disable no-await-in-loop */
import React, { useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import Button from '@mui/material/Button';
import LoadingButton from '@mui/lab/LoadingButton';
import TextField from '@mui/material/TextField';
import Stack from '@mui/material/Stack';
import { Container } from '@mui/material';
import {
  Card, CardBody, Row, Col,
} from 'reactstrap';
import { Helmet } from 'react-helmet';
import DealInformation from '../shared/components/DealInformation';
import formatCurrencyAsUsd from '../shared/utils/formatCurrencyAsUsd';
import ReadOnlyTextField from '../shared/components/ReadOnlyTextField';
import {
  updatePendingInvestment,
  getPresignedUrl, postFileToS3, deleteDocuments,
} from '../shared/services/apiGateway';
import usePendingInvestmentDetail from '../shared/hooks/usePendingInvestmentDetail';
import generateRequest from '../shared/services/generateRequest';
import PartialPageLoader from '../shared/components/Loader/PartialPageLoader';
import AvDropzone from '../shared/components/AvDropzone';
import { isNonEmptyStr } from '../shared/utils/validators';
import ConfirmationModal from '../shared/components/ConfirmationModal';
import { spacing } from '../shared/themes/appTheme';
import getStageStatus from '../shared/utils/checkUserPermissions';
import StageStatusMessage from '../shared/components/StageStatusMessage';
import CloseOnConfirmModal from '../shared/components/CloseOnConfirmModal';
import getSearchParams from '../shared/utils/getSearchParams';

const DEFAULT_SIGNATORY_NAME = 'Mark Edwards';

function SignDealDocs() {
  const { id } = useParams();
  const closeOnConfirm = getSearchParams('closeoncompletion');
  const history = useHistory();

  const MAX_FILES = 6;
  const [dealName, setDealName] = useState('');
  const [iTeamMemberName, setITeamMemberName] = useState('');
  const [sponsorTeam, setSponsorTeam] = useState('');
  const [wireAmount, setWireAmount] = useState(0);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [isPostingDealDoc, setIsPostingDealDoc] = useState(false);
  const [remainingUploadMax, setRemainingUploadMax] = useState(0);
  const [dzDocumentsToUpload, setDzDocumentsToUpload] = useState([]);
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [signatoryName, setSignatoryName] = useState(DEFAULT_SIGNATORY_NAME);
  const [errorText, setErrorText] = useState('');
  const [filesToRemove, setFilesToRemove] = useState([]);
  const [isConfirmationModalOpen, setConfirmationModalOpen] = useState(false);

  const [stageStatus, setStageStatus] = useState({
    isEditableByRole: false,
  });
  const [completionModalOpen, setCompletionModalOpen] = useState(false);

  const dealData = usePendingInvestmentDetail(id, (deal) => {
    let payloadDealName = '';
    if (deal.companyName) {
      payloadDealName = deal.companyName;
    } else if (deal.hsDealObj && deal.hsDealObj.dealName) {
      payloadDealName = deal.hsDealObj.dealName;
    }

    let payloadSponsorTeam = '';
    if (deal.sponsorTeam) {
      payloadSponsorTeam = deal.sponsorTeam;
    } else if (deal.hsDealObj && deal.hsDealObj.sponsorTeam && deal.hsDealObj.sponsorTeam.name) {
      payloadSponsorTeam = deal.hsDealObj.sponsorTeam.name;
    }

    let payloadWireAmount = 0;
    if (deal.wireAmount) {
      payloadWireAmount = deal.wireAmount;
    } else if (deal.hsDealObj && deal.hsDealObj.amount) {
      payloadWireAmount = deal.hsDealObj.amount;
    }

    const signedDealDocumentsUploadCount = deal.signedDealDocuments ? deal.signedDealDocuments.length : 0;
    setStageStatus({
      ...getStageStatus(deal),
    });
    setDealName(payloadDealName);
    setSponsorTeam(payloadSponsorTeam);
    setWireAmount(formatCurrencyAsUsd(payloadWireAmount));
    setRemainingUploadMax(MAX_FILES - signedDealDocumentsUploadCount);
    setITeamMemberName(deal.iTeamMemberName || '');
    setSignatoryName(deal.signatoryName || DEFAULT_SIGNATORY_NAME);
    setUploadedFiles(deal.signedDealDocuments || []);
  });

  const uploadFiles = async () => {
    const upload = async (file) => {
      const sanitizeRegex = /[^a-z0-9 ._-]/gi;
      const body = {
        deal: id,
        documenttype: file.avType,
        fileType: file.type,
        filename: `${file.name}`.replace(sanitizeRegex, ''),
        filedescription: '',
      };
      const response = await getPresignedUrl(body);
      if (!response) {
        setErrorText(`Failed to upload: ${body.filename}. Please contact support if this error persists.`);
        return null;
      }

      const { url, filekey } = response;
      const formData = new FormData();
      formData.append('file', file);
      formData.append('Content-Type', file.type);

      try {
        const uploadResponse = await postFileToS3(url, file, file.type);
        console.log(uploadResponse);
        setErrorText('');
        return {
          documentKey: filekey,
          documentType: file.avType,
          documentName: body.filename,
        };
      } catch (error) {
        setErrorText(`An error occurred while uploading: ${body.filename}. Please contact support if this error persists.`);
        console.log(error);
      }
      return null;
    };

    const newUploadedFiles = [];
    const dzNewUploadedFiles = [];
    let quotaRemaining = MAX_FILES - uploadedFiles.length;
    for (const dzDocumentToUpload of dzDocumentsToUpload) {
      if (quotaRemaining > 0) {
        dzDocumentToUpload.file.avType = 'signedDealDocument';
        dzNewUploadedFiles.push(JSON.stringify(dzDocumentToUpload));
        const document = await upload(dzDocumentToUpload.file);
        newUploadedFiles.push(document);
        quotaRemaining -= 1;
      }
    }

    setDzDocumentsToUpload(dzDocumentsToUpload.filter((item) => !dzNewUploadedFiles.includes(JSON.stringify(item))));
    setUploadedFiles([...uploadedFiles, ...newUploadedFiles]);
    setRemainingUploadMax(quotaRemaining);
  };

  const removeFiles = async () => {
    if (filesToRemove.length > 0) {
      await deleteDocuments({
        deal: id,
        documents: filesToRemove,
      });
      setFilesToRemove([]);
    }
  };

  const saveChanges = async (confirmStep) => {
    setIsSubmitting(true);
    setIsSaving(!confirmStep);
    dealData.signatoryName = signatoryName;
    if (confirmStep) {
      dealData.stagesCompleted.signedDealDocumentsUpload = new Date().toISOString();
    }
    await removeFiles();
    await uploadFiles();
    await updatePendingInvestment(generateRequest(dealData));
    setIsSubmitting(false);
    setIsSaving(false);
  };

  const openFile = async (documentId) => {
    const body = {
      deal: id,
      filekey: documentId,
    };
    const response = await getPresignedUrl(body);
    window.open(response);
  };

  return (
    <>
      <Helmet>
        <title> Upload Signed Deal Documents </title>
      </Helmet>
      <Container className="med-width-form">
        <Card>
          {!dealData && (
            PartialPageLoader()
          )}
          {
            dealData && (
              <CardBody>
                <Row>
                  <Col>
                    <h3>Upload Signed Deal Documents for {dealName}</h3>
                  </Col>
                </Row>
                <Row className="w-100">
                  <Col
                    style={{
                      marginTop: spacing(2),
                    }}
                  >
                    <DealInformation
                      dealInfo={{ dealName, sponsorTeam, iTeamMemberName }}
                      setDealInfo={() => false}
                      rejectionReason={dealData.stagesCompleted && dealData.stagesCompleted.signedDealDocumentsUpload === false
                        ? dealData.wireSignOffRejectionReason : ''}
                    />

                    <ReadOnlyTextField
                      label="Wire Amount"
                      value={wireAmount}
                      fullWidth
                    />
                    <TextField
                      label="Signatory Name"
                      variant="standard"
                      value={signatoryName}
                      fullWidth
                      required
                      style={{ margin: '10px 0 10px 0' }}
                      onChange={(e) => setSignatoryName(e.target.value)}
                      inputProps={{ maxLength: 200 }}
                    />
                    {
                      (stageStatus.isEditableByRole && !stageStatus.isCompleted && remainingUploadMax > 0) && (
                      <AvDropzone
                        header={false}
                        maxFiles={remainingUploadMax}
                        onFilesChange={setDzDocumentsToUpload}
                        style={{ width: '100%' }}
                        value={dzDocumentsToUpload}
                        label={(
                          <div className="av-dropzone-label">
                            <h5>Click to upload signed deal documents.</h5>
                          </div>
                      )}
                      />
                      )
                    }
                    {uploadedFiles && uploadedFiles.length > 0 && (
                      <div style={{ margin: '30px 0 30px 0' }}>
                        <h4>Uploaded documents:</h4>
                        {
                        uploadedFiles.map((item) => (
                          <Stack
                            direction="row"
                            justifyContent="space-between"
                            alignItems="center"
                            spacing={2}
                            mt={1}
                            key={item.documentKey}
                          >
                            <h5>
                              {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events */}
                              <span
                                className="av-false-anchor"
                                role="link"
                                tabIndex={-1}
                                onClick={async () => {
                                  await openFile(item.documentKey);
                                }}
                              >
                                {item.documentName}
                              </span>
                            </h5>
                            {((stageStatus.isEditableByRole) && !stageStatus.isCompleted) && (
                            <Button
                              variant="outlined"
                              color="secondary"
                              onClick={async () => {
                                setFilesToRemove([...filesToRemove, item]);
                                setUploadedFiles(uploadedFiles.filter((element) => element.documentKey !== item.documentKey));
                                let currentRemainingQuota = remainingUploadMax;
                                currentRemainingQuota += 1;
                                setRemainingUploadMax(currentRemainingQuota);
                              }}
                            >Remove
                            </Button>
                            )}

                          </Stack>
                        ))
                      }
                      </div>
                    )}
                    {((stageStatus.isEditableByRole) && !stageStatus.isCompleted) && (
                    <Stack
                      direction="row"
                      justifyContent="space-between"
                      alignItems="center"
                      spacing={2}
                      mt={2}
                    >
                      <LoadingButton
                        variant="outlined"
                        color="secondary"
                        disabled={isSubmitting}
                        loading={isSaving}
                        onClick={async () => saveChanges()}
                      >
                        Save
                      </LoadingButton>
                      <LoadingButton
                        variant="contained"
                        color="secondary"
                        disabled={isSubmitting
                          || !isNonEmptyStr(signatoryName)
                          || (uploadedFiles.length <= 0 && dzDocumentsToUpload.length <= 0)}
                        loading={isPostingDealDoc}
                        onClick={async () => {
                          setIsPostingDealDoc(true);
                          setConfirmationModalOpen(true);
                        }}
                      >
                        Submit
                      </LoadingButton>
                    </Stack>
                    )}

                    <h4 className="error red">{errorText}</h4>
                    <StageStatusMessage stageStatus={stageStatus} />
                  </Col>
                </Row>
              </CardBody>
            )
          }
        </Card>
      </Container>
      <ConfirmationModal
        isOpen={isConfirmationModalOpen}
        headerText="Please Verify"
        bodyText="I confirm that I have uploaded all signed documents associated with this deal."
        onConfirm={async () => {
          setConfirmationModalOpen(false);
          await saveChanges(true);
          if (!closeOnConfirm) {
            history.push(`/deal-stages/${id}`);
          } else {
            setCompletionModalOpen(true);
          }
        }}
        onCancel={() => {
          setConfirmationModalOpen(false);
          setIsPostingDealDoc(false);
        }}
      />
      <CloseOnConfirmModal modalOpen={completionModalOpen} />
    </>
  );
}

export default SignDealDocs;
