import React, { PureComponent } from 'react';
import { uid } from 'react-uid';
import { Col } from 'reactstrap';
import PropTypes from 'prop-types';
import { isEqual } from 'lodash';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import Checkbox from '@material-ui/core/Checkbox';
import MatTableHead from './MatTableHead';
import MatTableToolbar from './MatTableToolbar';
import comparer from '../../../../helpers/CompareHelper';
import CustomizedCellValue from './CustomizedCellValue';

function createData(item) {
  return { ...item };
}

export default class MatTable extends PureComponent {
  static getDerivedStateFromProps(props, state) {
    // Any time the data array changes,
    // Reset any parts of state that are tied to that data array.
    if (!isEqual(props.dataArray, state.data)) {
      return {
        data: props.dataArray.map(dataPoint => createData(dataPoint)),
      };
    }
    return null;
  }

  constructor(props) {
    super(props);
    const { dataArray } = this.props;
    this.state = {
      order: props.preSort ? props.preSort.order : 'asc',
      orderBy: props.preSort ? props.preSort.field : 'id',
      selected: new Map(props.preselectedRows),
      selectedData: props.preselectedRows,
      data: dataArray.map(dataPoint => createData(dataPoint)),
      page: 0,
      rowsPerPage: props.rowsPerPage ? props.rowsPerPage : 10,
      disabledCheckboxRows: props.disabledCheckboxRows,
    };
  }

  componentDidUpdate() {
    const { useConfirmModal, actionConfirmed } = this.props;
    if (useConfirmModal && actionConfirmed) {
      this.clearSelection();
    }
  }

  clearSelection = () => {
    const { setActionConfirmed } = this.props;
    this.setState({ selected: new Map([]) });
    setActionConfirmed(false);
  };

  handleRequestSort = (event, property) => {
    const orderBy = property;
    let order = 'desc';
    const { orderBy: stateOrderBy, order: stateOrder } = this.state;

    if (stateOrderBy === property && stateOrder === 'desc') { order = 'asc'; }

    this.setState({ order, orderBy });
  };

  handleSelectAllClick = (_event, checked) => {
    const { showWhatsSelected } = this.props;
    if (checked) {
      const { data, disabledCheckboxRows } = this.state;
      const newSelected = new Map();
      data.map(n => newSelected.set(n.id, disabledCheckboxRows.length > 0 ? !disabledCheckboxRows.includes(n.id) : true));
      const newSelectedData = data.filter(item => (
        newSelected.has(item.id) && newSelected.get(item.id) === true
      ));
      this.setState({ selected: newSelected, selectedData: newSelectedData });
      if (showWhatsSelected) {
        showWhatsSelected(newSelected);
      }
    } else {
      if (showWhatsSelected) {
        showWhatsSelected([]);
      }
      this.setState({ selected: new Map([]), selectedData: [] });
    }
  };

  handleClick = (_event, id) => {
    const { selected, data } = this.state;
    const { showWhatsSelected } = this.props;
    const newSelected = new Map(selected);
    const value = newSelected.get(id);
    let isActive = true;
    if (value) {
      isActive = false;
    }
    newSelected.set(id, isActive);
    const newSelectedData = data.filter(item => (
      newSelected.has(item.id) && newSelected.get(item.id) === true
    ));
    this.setState({ selected: newSelected, selectedData: newSelectedData });
    if (showWhatsSelected) {
      showWhatsSelected(newSelected);
    }
  };

  handleChangePage = (_event, page) => {
    this.setState({ page });
  };

  handleChangeRowsPerPage = (event) => {
    this.setState({ rowsPerPage: Number(event.target.value) });
  };

  handleEditSelected = () => {
    const { edit } = this.props;
    const { selected } = this.state;
    if (edit) {
      edit(selected);
    }
  }

  handleSendSelected = () => {
    const { sendEmail } = this.props;
    const { selected } = this.state;
    if (sendEmail) {
      sendEmail(selected);
    }
  }

  handleSpyLogInSelected = () => {
    const { spyLogIn } = this.props;
    const { selected } = this.state;
    if (spyLogIn) {
      spyLogIn(selected);
    }
  }

  handleViewPublishedAnnouncementClicked = (selected) => {
    const { viewPublishedAnnouncement } = this.props;
    if (viewPublishedAnnouncement && selected) {
      viewPublishedAnnouncement(selected[0]);
    }
  }

  handleDeleteSelected = () => {
    const { data } = this.state;
    let copyData = [...data];
    const { selected } = this.state;
    const { deleteRow, useConfirmModal } = this.props;

    if (deleteRow) {
      deleteRow(selected);
    }

    for (let i = 0; i < [...selected].filter(el => el[1]).length; i += 1) {
      copyData = copyData.filter(obj => obj.id !== selected[i]);
    }

    if (useConfirmModal) {
      this.setState({ data: copyData });
    } else {
      this.setState({ data: copyData, selected: new Map([]) });
    }
  };

  isSelected = (id) => {
    const { selected } = this.state;
    return !!selected.get(id);
  };

  handleRowInfo = () => {
    const { selected } = this.state;
    const { rowInfo } = this.props;
    if (rowInfo) {
      rowInfo(selected);
    }
  };

  render() {
    const {
      columnTitles,
      title,
      selectable,
      edit,
      sendEmail,
      doNotSort,
      spyLogIn,
      downloadCsv,
      rowInfo,
      deleteRow,
      showToolbar,
      wrapHeaders,
      viewPublishedAnnouncement,
      customSort,
      hidePagination,
      customPresort,
      customStyling,
    } = this.props;
    const {
      data,
      order,
      orderBy,
      selected,
      rowsPerPage,
      page,
      selectedData,
      disabledCheckboxRows,
    } = this.state;
    const presortedData = customPresort ? customPresort(data) : data;
    const sortedData = doNotSort ? presortedData : presortedData.sort(comparer(orderBy, order));

    if (customSort) {
      customSort(sortedData, orderBy, order);
    }

    return (
      <Col>
        <div className="card__title">
          <h5 className="bold-text">{title}</h5>
        </div>
        {showToolbar && (
          <MatTableToolbar
            numSelected={[...selected].filter(el => el[1]).length}
            selectedData={selectedData}
            handleDeleteSelected={deleteRow ? this.handleDeleteSelected : null}
            handleEditSelected={this.handleEditSelected}
            handleSendSelected={sendEmail ? this.handleSendSelected : null}
            handleSpyLogInSelected={spyLogIn ? this.handleSpyLogInSelected : null}
            handleViewPublishedAnnouncementSelected={viewPublishedAnnouncement
              ? this.handleViewPublishedAnnouncementClicked : null}
            onRequestSort={this.handleRequestSort}
            editable={!!edit}
            downloadCsv={downloadCsv}
            columnTitles={columnTitles}
            handleRowInfo={this.handleRowInfo}
            rowInfo={rowInfo}
          />
        )}
        <div className="material-table__wrap">
          <Table className="material-table">
            <MatTableHead
              rows={columnTitles}
              numSelected={[...selected].filter(el => el[1]).length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={this.handleSelectAllClick}
              onRequestSort={this.handleRequestSort}
              rowCount={data.length - disabledCheckboxRows.length}
              selectable={selectable}
              wrapHeaders={wrapHeaders}
              customStyling={customStyling && customStyling.header ? customStyling.header : null}
            />
            <TableBody>
              {sortedData
                .slice(page * rowsPerPage, (page * rowsPerPage) + rowsPerPage)
                .map((d) => {
                  const isSelected = this.isSelected(d.id);
                  const isRowDisabled = disabledCheckboxRows.includes(d.id);
                  return (
                    <TableRow
                      className="material-table__row"
                      role="checkbox"
                      onClick={event => (!isRowDisabled && selectable ? this.handleClick(event, d.id) : event.preventDefault())}
                      aria-checked={isSelected}
                      tabIndex={-1}
                      key={uid(d)}
                      selected={isSelected}
                    >
                      {selectable && (
                        <TableCell className="material-table__cell" padding="checkbox">
                          <Checkbox
                            data-testid="rowCheckBoxField"
                            checked={!isRowDisabled && isSelected}
                            disabled={isRowDisabled}
                            indeterminate={isRowDisabled}
                            className="material-table__checkbox"
                          />
                        </TableCell>
                      )}
                      {columnTitles.map(col => (
                        col.insertRaw ? (
                          <TableCell
                            key={uid(d[col.id] + col.id)}
                            data-title={col.label}
                            component={col.id === 'name' ? 'th' : undefined}
                            scope={col.id === 'name' ? 'row' : undefined}
                            className="material-table__cell material-table__cell-right"
                            padding={col.disablePadding ? 'none' : 'normal'}
                            dangerouslySetInnerHTML={{ __html: d[col.id] }}
                          />
                        ) : (
                          <TableCell
                            key={uid(d[col.id] + col.id)}
                            data-title={col.label}
                            component={col.id === 'name' ? 'th' : undefined}
                            scope={col.id === 'name' ? 'row' : undefined}
                            className="material-table__cell material-table__cell-right"
                            padding={col.disablePadding ? 'none' : 'normal'}
                            align={col.alignment ? 'left' : 'right'}
                          >
                            <CustomizedCellValue fieldName={col.id} fieldValue={d[col.id]} bag={d} />
                          </TableCell>
                        )
                      ))}
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
        </div>
        {
          !hidePagination
          && (
          <TablePagination
            component="div"
            className="material-table__pagination"
            count={data.length}
            rowsPerPage={rowsPerPage}
            page={page}
            backIconButtonProps={{ 'aria-label': 'Previous Page' }}
            nextIconButtonProps={{ 'aria-label': 'Next Page' }}
            onPageChange={this.handleChangePage}
            onRowsPerPageChange={this.handleChangeRowsPerPage}
            rowsPerPageOptions={[5, 10, 25, 50, 100]}
            dir="ltr"
            SelectProps={{
              inputProps: { 'aria-label': 'rows per page' },
              native: true,
            }}
          />
          )
        }
      </Col>
    );
  }
}

MatTable.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  dataArray: PropTypes.array.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  columnTitles: PropTypes.array.isRequired,
  title: PropTypes.string,
  selectable: PropTypes.bool,
  edit: PropTypes.func,
  deleteRow: PropTypes.func,
  preSort: PropTypes.shape({
    field: PropTypes.string.isRequired,
    order: PropTypes.string.isRequired,
  }),
  sendEmail: PropTypes.func,
  spyLogIn: PropTypes.func,
  useConfirmModal: PropTypes.bool,
  actionConfirmed: PropTypes.bool,
  setActionConfirmed: PropTypes.func,
  doNotSort: PropTypes.bool,
  showWhatsSelected: PropTypes.func,
  downloadCsv: PropTypes.bool,
  rowInfo: PropTypes.func,
  rowsPerPage: PropTypes.number,
  showToolbar: PropTypes.bool,
  wrapHeaders: PropTypes.bool,
  viewPublishedAnnouncement: PropTypes.func,
  customSort: PropTypes.func,
  hidePagination: PropTypes.bool,
  customPresort: PropTypes.func,
  // eslint-disable-next-line react/forbid-prop-types
  preselectedRows: PropTypes.array,
  // eslint-disable-next-line react/forbid-prop-types
  disabledCheckboxRows: PropTypes.array,
  // eslint-disable-next-line react/forbid-prop-types
  customStyling: PropTypes.object,
};

MatTable.defaultProps = {
  title: null,
  selectable: false,
  edit: null,
  deleteRow: null,
  preSort: null,
  sendEmail: null,
  spyLogIn: null,
  useConfirmModal: false,
  actionConfirmed: false,
  setActionConfirmed: null,
  doNotSort: false,
  showWhatsSelected: null,
  downloadCsv: false,
  rowInfo: null,
  rowsPerPage: 10,
  showToolbar: true,
  wrapHeaders: false,
  viewPublishedAnnouncement: null,
  customSort: null,
  hidePagination: false,
  customPresort: null,
  preselectedRows: [],
  disabledCheckboxRows: [],
  customStyling: null,
};
