import { getMoiLabel } from '../services/investorService';
import countryMap from './CountryMap';
import stateMap from './StateMap';

const moiValuesArray = [0, 1, 2, 3, 4];
const accreditationArray = [
  { label: 'Accredited', value: 0 },
  { label: 'Not Accredited', value: 1 },
  { label: 'Qualified Client', value: 2 },
  { label: 'Qualified Purchaser', value: 3 },
  { label: 'Undetermined', value: 4 },
];

export default class Investment {
  constructor(rawInvestment) {
    this.fundName = {
      label: 'Fund Name', type: 'text', readOnly: true, value: rawInvestment.fund.name,
    };
    this.id = {
      label: 'ID', type: null, readOnly: true, value: rawInvestment.fund.investmentDetails.id,
    };
    this.groupId = {
      label: 'Group ID', type: null, readOnly: true, value: rawInvestment.fund.investmentDetails.investmentId,
    };
    // this.userId = {
    //   label: 'User ID', type: null, readOnly: true, value: rawInvestment.userId,
    // };
    this.methodOfInvestment = {
      label: 'Method of Investment',
      type: 'select',
      readOnly: false,
      display: getMoiLabel(rawInvestment.fund.investmentDetails.methodOfInvestment),
      value: rawInvestment.fund.investmentDetails.methodOfInvestment,
      options: moiValuesArray.map(moiValue => ({ label: getMoiLabel(moiValue), value: moiValue })),
    };
    this.accreditation = {
      label: 'Accreditation',
      type: 'select',
      readOnly: false,
      display: accreditationArray
        .filter(obj => obj.value === rawInvestment.fund.investmentDetails.accreditation)
        .map(obj => obj.label),
      value: rawInvestment.fund.investmentDetails.accreditation,
      options: accreditationArray,
    };
    this.ssn = {
      label: 'SSN', type: 'text', readOnly: false, value: rawInvestment.fund.investmentDetails.ssn,
    };
    this.ein = {
      label: 'EIN', type: 'text', readOnly: false, value: rawInvestment.fund.investmentDetails.ein,
    };
    this.entityName = {
      label: 'Entity Name', type: 'text', readOnly: false, value: rawInvestment.fund.investmentDetails.entityName,
    };
    this.trustName = {
      label: 'Trust Name', type: 'text', readOnly: false, value: rawInvestment.fund.investmentDetails.trustName,
    };
    this.iraName = {
      label: 'IRA Name', type: 'text', readOnly: false, value: rawInvestment.fund.investmentDetails.iraName,
    };
    this.address1 = {
      label: 'Address', type: 'text', readOnly: false, value: rawInvestment.fund.investmentDetails.address1,
    };
    this.address2 = {
      label: 'Address Line 2', type: 'text', readOnly: false, value: rawInvestment.fund.investmentDetails.address2,
    };
    this.city = {
      label: 'City', type: 'text', readOnly: false, value: rawInvestment.fund.investmentDetails.city,
    };
    this.state = {
      label: 'State',
      type: 'select',
      readOnly: false,
      display: stateMap.map(
        state => state.value === rawInvestment.fund.investmentDetails.state && state.label,
      ),
      value: rawInvestment.fund.investmentDetails.state,
      options: stateMap,
    };
    this.province = {
      label: 'Province', type: 'text', readOnly: false, value: rawInvestment.fund.investmentDetails.province,
    };
    this.postalCode = {
      label: 'Zip Code', type: 'text', readOnly: false, value: rawInvestment.fund.investmentDetails.postalCode,
    };
    this.country = {
      label: 'Country',
      type: 'select',
      readOnly: false,
      display: countryMap.map(
        country => country.value === rawInvestment.fund.investmentDetails.country && country.label,
      ),
      value: rawInvestment.fund.investmentDetails.country,
      options: countryMap,
    };
    this.amount = {
      label: 'Investment Amount', type: 'text', readOnly: false, value: rawInvestment.fund.investmentDetails.amount,
    };
  }
}
