import { useState, useEffect } from 'react';
import { getDueDiligenceReport } from '../services/apiGateway';

/**
 * @param {*} id the due diligence report id
 * @param {*} onLoad optional callback
 * @returns the due diligence report or an error on fail
 */
function useDueDiligenceReport(id, onLoad) {
  const [dueDiligenceReport, setDueDiligenceReport] = useState(null);

  useEffect(() => {
    const abortController = new AbortController();
    let ignore = false;

    async function fetchData() {
      const response = await getDueDiligenceReport(id);
      setDueDiligenceReport(response);
      if (onLoad) {
        onLoad(response);
      }
    }

    fetchData();

    return () => {
      ignore = true;
      abortController.abort();
    };
  }, []);

  return dueDiligenceReport;
}

export default useDueDiligenceReport;
