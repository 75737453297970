import {
  REQUEST_ALL_PORTFOLIO_UPDATE,
  RECEIVE_ALL_PORTFOLIO_UPDATE,
  RECEIVE_ALL_PORTFOLIO_UPDATE_ERROR,
} from '../actions/getPortfolioUpdatesActions';

export default function (state = {
  isFetching: false,
  portfolioUpdateArray: [],
  error: null,
}, action) {
  switch (action.type) {
    case REQUEST_ALL_PORTFOLIO_UPDATE:
      return Object.assign({}, state, {
        isFetching: true,
        error: action.error,
      });
    case RECEIVE_ALL_PORTFOLIO_UPDATE:
      return Object.assign({}, state, {
        isFetching: false,
        portfolioUpdateArray: action.portfolioUpdates,
        lastUpdated: action.receivedAt,
      });
    case RECEIVE_ALL_PORTFOLIO_UPDATE_ERROR:
      return Object.assign({}, state, {
        isFetching: false,
        error: action.error,
      });

    default:
      return state;
  }
}
