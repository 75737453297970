import React, { useState } from 'react';
import { Helmet } from 'react-helmet';
import {
  Container, Row, Card, CardBody, Col,
} from 'reactstrap';
import { useHistory } from 'react-router-dom';
import PendingInvestmentsSearchBar from '../shared/components/PendingInvestmentsSearchBar';
import AvDataGrid from '../shared/components/AvDataGrid';
import usePendingInvestments from '../shared/hooks/usePendingInvestments';
import createPendingInvestmentsTableDefinition from './PendingInvestmentsTableDefinition';
import useFilteredBySearch from '../shared/hooks/useFilteredBySearch';

const createTableData = (pendingInvestments) => {
  const tableData = [];
  if (pendingInvestments && pendingInvestments.length > 0) {
    const postWireDeals = pendingInvestments.filter((dd) => !dd.stagesCompleted
    || (dd.stagesCompleted && Object.keys(dd.stagesCompleted).length < 1));
    tableData.push(...postWireDeals);
  }
  return tableData;
};

function LandingPage() {
  const [loadingPendingInvestments, setPendingInvestmentsLoading] = useState(true);
  const pendingInvestments = usePendingInvestments(setPendingInvestmentsLoading);
  const pendingInvestmentsTableDef = createPendingInvestmentsTableDefinition(useHistory());
  const [companyNameSearchValue, setCompanyNameSearchValue] = useState('');
  const [pageSize, setPageSize] = useState(10);
  const filteredPendingInvestments = createTableData(useFilteredBySearch(pendingInvestments, companyNameSearchValue));

  return (
    <>
      <Helmet>
        <title>Pending Investments - Deal Portal</title>
      </Helmet>
      <Container className="dashboard" style={{ maxWidth: '100%' }}>
        <Card>
          <CardBody>
            <Row>
              <Col>
                <h2>Pending Investments</h2>
                <div style={{ marginTop: '20px' }}>
                  <PendingInvestmentsSearchBar
                    placeholder="Search by company name"
                    onChange={(e) => setCompanyNameSearchValue(e.target.value)}
                    value={companyNameSearchValue}
                    disabled={loadingPendingInvestments}
                    fullWidth
                  />
                </div>
                <div style={{ marginTop: '20px' }}>
                  <AvDataGrid
                    pageSize={pageSize}
                    setPageSize={setPageSize}
                    rows={filteredPendingInvestments}
                    columns={pendingInvestmentsTableDef}
                    loading={loadingPendingInvestments}
                  />
                </div>
              </Col>

            </Row>
          </CardBody>
        </Card>
      </Container>
    </>

  );
}

export default LandingPage;
