export default [
  {
    label: 'Pacific Time (PDT)',
    tz: 'PDT',
    type: 'US',
  },
  {
    label: 'Eastern Time (EDT)',
    tz: 'EDT',
    type: 'US',
  },
  {
    label: 'Hawaii Time (HST)',
    tz: 'HST',
    type: 'US',
  },
  {
    label: 'Alaska Time (AKDT)',
    tz: 'AKDT',
    type: 'US',
  },
  {
    label: 'Mountain Time (MDT)',
    tz: 'MDT',
    type: 'US',
  },
  {
    label: 'Central Time (CDT)',
    tz: 'CDT',
    type: 'US',
  },
  {
    label: 'Pacific Standard Time (PDT)',
    tz: 'PDT',
    type: 'Intl',
  },
  {
    label: 'Mountain Standard Time (MDT)',
    tz: 'MDT',
    type: 'Intl',
  },
  {
    label: 'Central America Standard Time (CAST)',
    tz: 'CAST',
    type: 'Intl',
  },
  {
    label: 'Central Standard Time (CDT)',
    tz: 'CDT',
    type: 'Intl',
  },
  {
    label: 'Canada Central Standard Time (CCST)',
    tz: 'CCST',
    type: 'Intl',
  },
  {
    label: 'SA Pacific Standard Time (SPST)',
    tz: 'SPST',
    type: 'Intl',
  },
  {
    label: 'Venezuela Standard Time (VST)',
    tz: 'VST',
    type: 'Intl',
  },
  {
    label: 'Paraguay Standard Time (PYT)',
    tz: 'PYT',
    type: 'Intl',
  },
  {
    label: 'Atlantic Standard Time (ADT)',
    tz: 'ADT',
    type: 'Intl',
  },
  {
    label: 'Central Brazilian Standard Time (CBST)',
    tz: 'CBST',
    type: 'Intl',
  },
  {
    label: 'SA Western Standard Time (SWST)',
    tz: 'SWST',
    type: 'Intl',
  },
  {
    label: 'Pacific SA Standard Time (PSST)',
    tz: 'PSST',
    type: 'Intl',
  },
  {
    label: 'Newfoundland Standard Time (NDT)',
    tz: 'NDT',
    type: 'Intl',
  },
  {
    label: 'E. South America Standard Time (ESAST)',
    tz: 'ESAST',
    type: 'Intl',
  },
  {
    label: 'Argentina Standard Time (AST)',
    tz: 'AST',
    type: 'Intl',
  },
  {
    label: 'SA Eastern Standard Time (SEST)',
    tz: 'SEST',
    type: 'Intl',
  },
  {
    label: 'Greenland Standard Time (GDT)',
    tz: 'GDT',
    type: 'Intl',
  },
  {
    label: 'Dateline Standard Time (DST)',
    tz: 'DST',
    type: 'Intl',
  },
  {
    label: 'Montevideo Standard Time (MST)',
    tz: 'MST',
    type: 'Intl',
  },
  {
    label: 'Bahia Standard Time (BST)',
    tz: 'BST',
    type: 'Intl',
  },
  {
    label: 'UTC-11 Time',
    tz: 'U',
    type: 'Intl',
  },
  {
    label: 'UTC-02 Time',
    tz: 'U',
    type: 'Intl',
  },
  {
    label: 'Mid-Atlantic Standard Time (MDT)',
    tz: 'MDT',
    type: 'Intl',
  },
  {
    label: 'Azores Standard Time (ADT)',
    tz: 'ADT',
    type: 'Intl',
  },
  {
    label: 'Cape Verde Standard Time (CVST)',
    tz: 'CVST',
    type: 'Intl',
  },
  {
    label: 'Morocco Standard',
    tz: 'MDT',
    type: 'Intl',
  },
  {
    label: 'UTC',
    tz: 'UTC',
    type: 'Intl',
  },
  {
    label: 'British Summer',
    tz: 'BST',
    type: 'Intl',
  },
  {
    label: 'GMT Standard',
    tz: 'GDT',
    type: 'Intl',
  },
  {
    label: 'Greenwich Standard Time (GST)',
    tz: 'GST',
    type: 'Intl',
  },
  {
    label: 'W. Europe Standard Time (WEDT)',
    tz: 'WEDT',
    type: 'Intl',
  },
  {
    label: 'Central Europe Standard Time (CEDT)',
    tz: 'CEDT',
    type: 'Intl',
  },
  {
    label: 'Romance Standard Time (RDT)',
    tz: 'RDT',
    type: 'Intl',
  },
  {
    label: 'Central European Standard Time (CEDT)',
    tz: 'CEDT',
    type: 'Intl',
  },
  {
    label: 'W. Central Africa Standard Time (WCAST)',
    tz: 'WCAST',
    type: 'Intl',
  },
  {
    label: 'Namibia Standard Time (NST)',
    tz: 'NST',
    type: 'Intl',
  },
  {
    label: 'GTB Standard Time (GDT)',
    tz: 'GDT',
    type: 'Intl',
  },
  {
    label: 'Middle East Standard Time (MEDT)',
    tz: 'MEDT',
    type: 'Intl',
  },
  {
    label: 'Egypt Standard Time (EST)',
    tz: 'EST',
    type: 'Intl',
  },
  {
    label: 'Syria Standard Time (SDT)',
    tz: 'SDT',
    type: 'Intl',
  },
  {
    label: 'E. Europe Standard Time (EEDT)',
    tz: 'EEDT',
    type: 'Intl',
  },
  {
    label: 'South Africa Standard Time (SAST)',
    tz: 'SAST',
    type: 'Intl',
  },
  {
    label: 'FLE Standard Time (FDT)',
    tz: 'FDT',
    type: 'Intl',
  },
  {
    label: 'Turkey Standard Time (TDT)',
    tz: 'TDT',
    type: 'Intl',
  },
  {
    label: 'Israel Standard Time (JDT)',
    tz: 'JDT',
    type: 'Intl',
  },
  {
    label: 'Libya Standard Time (LST)',
    tz: 'LST',
    type: 'Intl',
  },
  {
    label: 'Jordan Standard Time (JST)',
    tz: 'JST',
    type: 'Intl',
  },
  {
    label: 'Arabic Standard Time (AST)',
    tz: 'AST',
    type: 'Intl',
  },
  {
    label: 'Kaliningrad Standard Time (KST)',
    tz: 'KST',
    type: 'Intl',
  },
  {
    label: 'Arab Standard Time (AST)',
    tz: 'AST',
    type: 'Intl',
  },
  {
    label: 'E. Africa Standard Time (EAST)',
    tz: 'EAST',
    type: 'Intl',
  },
  {
    label: 'Moscow Standard Time (MSK)',
    tz: 'MSK',
    type: 'Intl',
  },
  {
    label: 'Samara Time (SAMT)',
    tz: 'SAMT',
    type: 'Intl',
  },
  {
    label: 'Iran Standard Time (IDT)',
    tz: 'IDT',
    type: 'Intl',
  },
  {
    label: 'Arabian Standard Time (AST)',
    tz: 'AST',
    type: 'Intl',
  },
  {
    label: 'Azerbaijan Standard Time (ADT)',
    tz: 'ADT',
    type: 'Intl',
  },
  {
    label: 'Mauritius Standard Time (MST)',
    tz: 'MST',
    type: 'Intl',
  },
  {
    label: 'Georgian Standard Time (GET)',
    tz: 'GET',
    type: 'Intl',
  },
  {
    label: 'Caucasus Standard Time (CST)',
    tz: 'CST',
    type: 'Intl',
  },
  {
    label: 'Afghanistan Standard Time (AST)',
    tz: 'AST',
    type: 'Intl',
  },
  {
    label: 'West Asia Standard Time (WAST)',
    tz: 'WAST',
    type: 'Intl',
  },
  {
    label: 'Yekaterinburg Time (YEKT)',
    tz: 'YEKT',
    type: 'Intl',
  },
  {
    label: 'Pakistan Standard Time (PKT)',
    tz: 'PKT',
    type: 'Intl',
  },
  {
    label: 'India Standard Time (IST)',
    tz: 'IST',
    type: 'Intl',
  },
  {
    label: 'Sri Lanka Standard Time (SLST)',
    tz: 'SLST',
    type: 'Intl',
  },
  {
    label: 'Nepal Standard Time (NST)',
    tz: 'NST',
    type: 'Intl',
  },
  {
    label: 'Central Asia Standard Time (CAST)',
    tz: 'CAST',
    type: 'Intl',
  },
  {
    label: 'Bangladesh Standard Time (BST)',
    tz: 'BST',
    type: 'Intl',
  },
  {
    label: 'Myanmar Standard Time (MST)',
    tz: 'MST',
    type: 'Intl',
  },
  {
    label: 'SE Asia Standard Time (SAST)',
    tz: 'SAST',
    type: 'Intl',
  },
  {
    label: 'N. Central Asia Standard Time (NCAST)',
    tz: 'NCAST',
    type: 'Intl',
  },
  {
    label: 'China Standard Time (CST)',
    tz: 'CST',
    type: 'Intl',
  },
  {
    label: 'North Asia Standard Time (NAST)',
    tz: 'NAST',
    type: 'Intl',
  },
  {
    label: 'Singapore Standard Time (MPST)',
    tz: 'MPST',
    type: 'Intl',
  },
  {
    label: 'W. Australia Standard Time (WAST)',
    tz: 'WAST',
    type: 'Intl',
  },
  {
    label: 'Taipei Standard Time (TST)',
    tz: 'TST',
    type: 'Intl',
  },
  {
    label: 'Ulaanbaatar Standard Time (UST)',
    tz: 'UST',
    type: 'Intl',
  },
  {
    label: 'North Asia East Standard Time (NAEST)',
    tz: 'NAEST',
    type: 'Intl',
  },
  {
    label: 'Japan Standard Time (JST)',
    tz: 'JST',
    type: 'Intl',
  },
  {
    label: 'Korea Standard Time (KST)',
    tz: 'KST',
    type: 'Intl',
  },
  {
    label: 'Cen. Australia Standard Time (CAST)',
    tz: 'CAST',
    type: 'Intl',
  },
  {
    label: 'AUS Central Standard Time (ACST)',
    tz: 'ACST',
    type: 'Intl',
  },
  {
    label: 'E. Australia Standard Time (EAST)',
    tz: 'EAST',
    type: 'Intl',
  },
  {
    label: 'AUS Eastern Standard Time (AEST)',
    tz: 'AEST',
    type: 'Intl',
  },
  {
    label: 'West Pacific Standard Time (WPST)',
    tz: 'WPST',
    type: 'Intl',
  },
  {
    label: 'Tasmania Standard Time (TST)',
    tz: 'TST',
    type: 'Intl',
  },
  {
    label: 'Yakutsk Standard Time (YST)',
    tz: 'YST',
    type: 'Intl',
  },
  {
    label: 'Central Pacific Standard Time (CPST)',
    tz: 'CPST',
    type: 'Intl',
  },
  {
    label: 'Vladivostok Standard Time (VST)',
    tz: 'VST',
    type: 'Intl',
  },
  {
    label: 'New Zealand Standard Time (NZST)',
    tz: 'NZST',
    type: 'Intl',
  },
  {
    label: 'UTC+12 Time (U)',
    tz: 'U',
    type: 'Intl',
  },
  {
    label: 'Fiji Standard Time (FST)',
    tz: 'FST',
    type: 'Intl',
  },
  {
    label: 'Magadan Standard Time (MST)',
    tz: 'MST',
    type: 'Intl',
  },
  {
    label: 'Kamchatka Standard Time (KDT)',
    tz: 'KDT',
    type: 'Intl',
  },
  {
    label: 'Tonga Standard Time (TST)',
    tz: 'TST',
    type: 'Intl',
  },
  {
    label: 'Samoa Standard Time (SST)',
    tz: 'SST',
    type: 'Intl',
  },
];
