import { postRequest } from './request';
import { checkTokenValidity } from './tokenServices';
import { getAuthServicePath } from '../helpers/getAuthServicePath';
import Cache from '../models/Cache';

const envCache = new Cache('env');

export const getHeaders = async () => {
  const accessToken = await checkTokenValidity();
  const headers = {
    'Content-Type': 'application/json',
    Accept: 'application/json',
    Authorization: `Bearer ${accessToken}`,
  };
  return headers;
};

export const loginAVAdminUser = async (body) => {
  const env = envCache.get();
  const headers = {
    'Content-Type': 'application/json',
    Accept: 'application/json',
  };
  const res = await postRequest(
    getAuthServicePath(env) + `/login`,
    body,
    headers,
    null,
    false,
  );
  return res;
};

export const refreshAVAdminUserToken = async (body) => {
  const env = envCache.get();
  const headers = {
    'Content-Type': 'application/json',
    Accept: 'application/json',
  };
  const res = await postRequest(
    getAuthServicePath(env) + `/refresh`,
    body,
    headers,
    null,
    false
  );
  return res;
};
